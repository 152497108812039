import { Component, Input, OnChanges, Optional, Self, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, FormGroup, NgControl, ValidatorFn, Validators } from '@angular/forms';

import { FormBuilderCityOutput, FormElementState } from '../form-builder-element.model';
import { FormBuilderService } from '../form-builder.service';
import { cities } from './cities';
import { FormBuilderValidators } from '../validators/form-builder-validators';


@Component({
    selector: 'iv-form-builder-elements-city',
    template: `
    <ng-container [formGroup]="formGroup">
        <mat-form-field class="form__element form-builder-elements-city__zip-code">
            <mat-label>{{ label.zipCode }}</mat-label>
            <input matInput
                type="tel"
                formControlName="zipCode"
                [required]="required"
                minlength="4"
                maxlength="4"
                (input)="onModelChange($event)">
                <mat-error *ngIf="formGroup.get('zipCode')?.errors && formGroup.get('zipCode')?.touched">{{ errorMessage(formGroup.get('zipCode').errors) }}</mat-error>
        </mat-form-field>

        <mat-form-field class="form__element form-builder-elements-city__city">
            <mat-label>{{ label.city }}</mat-label>
            <input matInput type="text" formControlName="city" [required]="required" readonly tabindex="-1">
        </mat-form-field>
    </ng-container>
    `
})
export class FormBuilderElementsCityComponent implements ControlValueAccessor, OnChanges {
    @Input() label: { zipCode: string, city: string } = { zipCode: '', city: '' };
    @Input() required = false;

    @Input() validation: ValidatorFn[] | undefined;
    @Input() state: FormElementState;

    control?: UntypedFormControl;

    formGroup: FormGroup = new FormGroup({
        zipCode: new UntypedFormControl(undefined, this.zipCodeRequiredValidation),
        city: new UntypedFormControl(undefined, this.cityRequiredValidation),
    });

    private _inputValue: FormBuilderCityOutput = {
        zipCode: undefined,
        city: undefined
    };

    get inputValue() {
        return this._inputValue;
    }

    set inputValue(value: FormBuilderCityOutput) {
        if (value) {
            this._inputValue = value;
            this._lookupCity();
        }
        else {
            this._inputValue = {
                zipCode: undefined,
                city: undefined
            };
        }
        this.formGroup.patchValue(this._inputValue);
    }

    get formControl(): AbstractControl | null {
        return this.ngControl?.control;
    }

    constructor(@Self() @Optional() private ngControl: NgControl) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        const stateValue: FormElementState = changes["state"]?.currentValue;
        if (stateValue === "reset") {
           this.inputValue = {
                zipCode: undefined,
                city: undefined
            }
            this.formGroup.markAsPristine()
            this.formGroup.markAsUntouched()
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setDisabledState(isDisabled: boolean): void { }

    errorMessage = FormBuilderService.getErrorMessage;

    // eslint-disable-next-line
    validateFn: any = () => { };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange(event: unknown): void { }

    onTouched(): void { }

    writeValue(value: FormBuilderCityOutput) {
        this.inputValue = value;
    }

    validate(c: UntypedFormControl) {
        this.control = c;
        return this.validateFn(c);
    }

    registerOnChange(fn: () => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    onModelChange(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        this.inputValue = { zipCode: value };
        this.onChange(this.inputValue);
    }

    private _lookupCity() {
        this.inputValue.city = this.inputValue.zipCode
            ? cities[this.inputValue.zipCode]
            : undefined;
    }

    private get cityRequiredValidation() {
        return this.required ? [Validators.required] : [];
    }

    private get zipCodeRequiredValidation() {
        return this.required ? [Validators.required, FormBuilderValidators.zipCode(this.required)] : [FormBuilderValidators.zipCode(this.required)];
    }
}
