import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilderCheckbox, FormBuilderTextInput, FormBuilderTypes } from '../form-builder/form-builder-element.model';
import { FormGroup } from '@angular/forms';
import { FormBuilderService } from '../form-builder/form-builder.service';
import { Translations } from '../../translations/translations';
import { MatTableDataSource } from '@angular/material/table';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, merge, of, Subject } from 'rxjs';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { MatPaginator } from '@angular/material/paginator';
import { pen, trash, check, municipalityportal } from '../../scripts/generated/icons';
import { DialogService } from '../shared/dialog.service';
import { InternalUserService } from '../services/internal-user.service';
import { IUser } from '../../api/services';
import { HttpParams } from '@angular/common/http';
import { UtilUrlService } from '../util/util-url.service';
import { ActivatedRoute } from '@angular/router';
import { DialogUserManagementService } from '../shared/dialog-user-management.service';

interface FilterUserModel { Username: string; skip: number; take: number; includeInactive: boolean; }

@Component({
    selector: 'iv-administration-view-users',
    template: `
        <section class="administration-view-users">
            <form #citizensForm [formGroup]="form" class="administration-view-users__form alt-theme form"
                  autocomplete="off">
                <div class="administration-view-users__form-group administration-view-users__form-group--vertical">
                    <iv-form-builder-element *ngFor="let input of inputs"
                                             [form]="form"
                                             [input]="input"
                                             class="administration-view-users__form-element"
                    ></iv-form-builder-element>
                </div>

                <div class="administration-view-users__actions">
                    <div class="administration-view-users__fetching">
                        <mat-spinner *ngIf="isFetching" diameter="34"></mat-spinner>
                    </div>

                    <a [routerLink]="['../' + Translations.municipality.paths.createInternalUser.path]"
                        mat-raised-button
                        class="administration-view-users__create-user-button mat-raised-button mat-primary"
                        type="button">
                        {{ Translations.municipality.paths.createInternalUser.title }}
                    </a>
                </div>

            </form>

            <div class="administration-view-users__feedback">
                <div class="errors" *ngIf="validationErrors">
                    <h4 *ngFor="let error of validationErrors">{{ error }}</h4>
                </div>
            </div>

            <div class="administration-view-users__table">
                <ng-container *ngIf="userList as users; else empty">
                    <ng-container *ngIf="users.data?.length; else empty">
                        <mat-table #table [dataSource]="users || []">
                            <ng-container matColumnDef="userId" >
                                <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
                                <mat-cell *matCellDef="let row">{{ row.userId }}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="name">
                                <mat-header-cell *matHeaderCellDef>${Translations.municipality.viewUsers.table.name}</mat-header-cell>
                                <mat-cell *matCellDef="let row">{{ row.name }}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="username">
                                <mat-header-cell *matHeaderCellDef>${Translations.municipality.viewUsers.table.username}</mat-header-cell>
                                <mat-cell *matCellDef="let row">{{ row.username }}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="roles">
                                <mat-header-cell *matHeaderCellDef>${Translations.municipality.viewUsers.table.roles}</mat-header-cell>
                                <mat-cell *matCellDef="let row" [title]="(row.securityRoles | joinSecurityRoles)" >{{(row.securityRoles | joinSecurityRoles: true) }}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="inactive">
                                <mat-header-cell *matHeaderCellDef>${Translations.municipality.viewUsers.table.inactive}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    <span class="icon administration-view-users__is-deleted">
                                        <ng-container *ngIf="row.isDeleted">${check}</ng-container>
                                    </span>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="actions" stickyEnd>
                                <mat-header-cell class="administration-view-users__table-head" *matHeaderCellDef>
                                    ${Translations.municipality.viewUsers.table.actions}
                                </mat-header-cell>
                                <mat-cell class="administration-view-users__table-body" *matCellDef="let row">
                                    <a class="icon"
                                       [routerLink]="['/${Translations.municipality.paths.editInternalUser.path}', row.userId]"
                                       (click)="storeClickedUser(row)"
                                       matTooltip="${Translations.municipality.viewUsers.labels.editUser}">
                                        ${pen}
                                    </a>
                                    <button class="administration-view-users__change-username icon"
                                            matTooltip="${Translations.municipality.viewUsers.labels.changeUsername}"
                                            (click)="editUsername(row)">
                                        ${municipalityportal}
                                    </button>
                                    <button class="administration-view-users__inactive-user icon"
                                            matTooltip="${Translations.municipality.viewUsers.labels.inactiveUser}"
                                            (click)="inactivateUser(row.username)">
                                        ${trash}
                                    </button>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"
                                     [attr.data-user-id]="row.userId"
                                     [class.administration-view-users__clicked]="+(row.userId) === +clickedUser"
                            ></mat-row>
                        </mat-table>
                    </ng-container>
                </ng-container>

                <mat-paginator
                    [length]="resultsLength"
                    [pageIndex]="pageIndex"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions"
                    [showFirstLastButtons]="true"></mat-paginator>
            </div>
        </section>

        <ng-template #empty>
            <div class="administration-view-users__results-empty">
                ${Translations.forms.labels.municipality.noUsersFound}
            </div>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdministrationViewUsersComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly unsubscribeS$ = new Subject<void>();
    private readonly StoreClickedUserKey = 'clickedUser';
    public readonly Translations = Translations;

    public clickedUser = sessionStorage.getItem(this.StoreClickedUserKey);
    public resultsLength = 0;
    public pageSizeOptions = [10, 20, 30];
    public pageIndex = 0;
    public pageSize = this.pageSizeOptions[0];
    public displayedColumns = ['userId', 'name', 'username', 'roles', 'inactive', 'actions'];

    public inputs: FormBuilderTypes[] = [
        new FormBuilderTextInput({
            name: Translations.forms.labels.municipality.searchUsername.name,
            label: Translations.forms.labels.municipality.searchUsername.label,
            required: false
        }),
        new FormBuilderCheckbox({
            name: Translations.forms.labels.municipality.includeInactive.name,
            label: Translations.forms.labels.municipality.includeInactive.label,
        })
    ];

    private initialData = new Array(this.pageSize).fill({}).map((_, i) => ({ userId: i }));

    public validationErrors: string[] = [];
    public isFetching = false;
    public form: FormGroup = this.formBuilder.toFormGroup(this.inputs);
    public userList: MatTableDataSource<Partial<IUser>> = new MatTableDataSource<Partial<IUser>>(this.initialData);

    private datasource = new MatTableDataSource<Partial<IUser>>(this.userList.data);

    private filterUser: FilterUserModel = { Username: '', skip: 0, take: this.pageSize, includeInactive: false };
    private filterUsersBs$: BehaviorSubject<FilterUserModel> = new BehaviorSubject(this.filterUser);

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private readonly formBuilder: FormBuilderService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly internalUserService: InternalUserService,
        private readonly dialogService: DialogService,
        private readonly dialogUserManagementService: DialogUserManagementService,
        private readonly urlService: UtilUrlService,
        private readonly cd: ChangeDetectorRef
    ) { }

    ngOnInit(): void {

        const { searchText, take, skip, includeInactive } = this.activatedRoute.snapshot.queryParams;

        if (includeInactive === 'true') {
            this.filterUser.includeInactive = true;
            this.form.patchValue({ includeInactive: includeInactive }, { onlySelf: true, emitEvent: false });
        }

        if (take) {
            this.pageSize = +take || this.pageSizeOptions[0];
            this.filterUser.take = this.pageSize;
        }

        if (skip) {
            this.filterUser.skip = +skip || 0;
        }

        if (searchText) {
            this.filterUser.skip = 0;
            this.filterUser.take = this.pageSize;
            this.filterUser.Username = searchText || '';
            this.filterUser.includeInactive = includeInactive;
            this.form.patchValue({ Username: searchText }, { onlySelf: true, emitEvent: false });
        }

        this.pageIndex = this.filterUser.skip > 0 ? (this.filterUser.skip / this.filterUser.take) : 0;
        this.filterUsersBs$.next(this.filterUser);
    }

    ngAfterViewInit(): void {

        merge(this.filterUsersBs$, this.paginator.page)
            .pipe(
                startWith({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, Username: this.form.value.Username }),
                debounceTime(300),
                tap(() => {
                    this.validationErrors = [];
                    this.isFetching = true;
                    this.cd.markForCheck();
                }),
                switchMap(() => {

                    //when paginator event is triggered, we need to get the username from the form  
                    const params = {
                        searchText: this.form.value.Username ?? "",
                        includeInactive: this.form.value.includeInactive,
                        skip: this.paginator.pageIndex * this.paginator.pageSize,
                        take: this.paginator.pageSize
                    };

                    this.urlService.setState(new HttpParams({ fromObject: params }));
                    return this.internalUserService.getInternalUsers(params.searchText, params.skip, params.take, params.includeInactive).pipe(catchError(err => {
                        this.handleWithErrors(err);
                        return of({ numFound: 0, results: [] })
                    }));
                }),
                map(({ numFound, results }) => {
                    this.isFetching = false;
                    this.resultsLength = numFound;

                    // let's replace all the data with the new one
                    this.datasource.data = results ?? [];
                    this.cd.markForCheck();
                    return this.datasource;
                }),
                takeUntil(this.unsubscribeS$)
            ).subscribe(r => this.userList = r);


        this.form.valueChanges.pipe(
            distinctUntilChanged(),
            takeUntil(this.unsubscribeS$)
        ).subscribe(value => {
            if (value.Username) {
                value.skip = 0;
                value.take = this.paginator.pageSize;
                // reset the page index when the user types something to avoid passing the wrong skip value
                this.paginator.pageIndex = 0;
            }

            // let's clear the clicked user
            sessionStorage.setItem(this.StoreClickedUserKey, '');
            this.clickedUser = null;

            this.filterUsersBs$.next(value);
        });
    }

    ngOnDestroy() {
        this.unsubscribeS$.next();
        this.unsubscribeS$.complete();
    }

    editUsername = (user: IUser) => {
        this.dialogUserManagementService.changeUsername(user)
            .pipe(takeUntil(this.unsubscribeS$))
            .subscribe(user => {

                // let's update the username in the list without fetching again all the list
                const userIndex = this.datasource.data.findIndex(x => x.username === user.currentUsername);
                this.datasource.data[userIndex].username = user.newUsername;

                this.dialogService.showSnackMessage({ message: Translations.replaceTokens(Translations.municipality.viewUsers.userUpdated, user.newUsername) });

                this.cd.markForCheck();
            }, (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors));
    }

    inactivateUser = (userName: string) => {
        this.dialogService.confirm(Translations.replaceTokens(Translations.municipality.viewUsers.confirmDialog.description, userName))
            .afterClosed()
            .pipe(
                switchMap(response => {
                    if (!response) {
                        return EMPTY;
                    }

                    return this.internalUserService.inactivateUser(userName);
                }),
                takeUntil(this.unsubscribeS$)
            )
            .subscribe(() => {
                this.userList.data = this.userList.data.map(user => user.username === userName ? { ...user, isDeleted: true } : user);
                this.dialogService.showSnackMessage({ message: Translations.replaceTokens(Translations.municipality.viewUsers.confirmDialog.confirmed, userName) });

            },
                (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors));
    }

    storeClickedUser = (event: IUser): void => {
        sessionStorage.setItem(this.StoreClickedUserKey, JSON.stringify(event.userId));
    }

    private handleWithErrors(err: IntervareHttpErrorResponse) {
        this.validationErrors = [];

        if (err.validationErrors && err.validationErrors.length > 0) {
            this.validationErrors = err.validationErrors;
        } else {
            this.validationErrors.push(err.error?.statusText);
        }

        this.isFetching = false;
        this.datasource.data = [];
    }
}
