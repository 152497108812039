import { trigger, state, style, animate, transition } from '@angular/animations';
import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Translations } from 'Client/translations/translations';

import { CampaignTypeEnum, IBasketRowViewModel, IReservationViewModel } from '../../api/services';
import { CURRENCY_CODE } from '../app.locale_data';
import { BasketService } from '../services/basket.service';
import { getReservationStatus } from './commerce-utils';
import { IReservationStatus } from './commerce.models';

@Component({
    selector: 'iv-commerce-minibasket-item',
    template: `
        <div class="commerce-minibasket-item"
             [class.commerce-minibasket-item--not-available]="reservationStatus?.showReservationErrors && reservationStatus?.isNotAvailable && reservationStatus?.canBeReserved"
             [class.commerce-minibasket-item--has-warning]="reservationStatus?.showReservationErrors && reservationStatus?.hasWarning && reservationStatus?.canBeReserved"
             role="row">
            <a class="commerce-minibasket-item__link"
               [class.commerce-minibasket-item__link--not-available]="reservationStatus?.showReservationErrors && reservationStatus?.isNotAvailable && reservationStatus?.canBeReserved"
               [class.commerce-minibasket-item__link--has-warning]="reservationStatus?.showReservationErrors && reservationStatus?.hasWarning && reservationStatus?.canBeReserved"
               [routerLink]="['', { outlets: { dialog: ['product', item.id] } }]">
                <img
                    *ngIf="item.primaryImage"
                    srcset="{{ item.primaryImage }}&w=40&h=40 1x, {{ item.primaryImage }}&w=80&h=80 2x"
                    src="{{ item.primaryImage }}&w=40&h=40"
                    loading="lazy"
                    width="40"
                    height="40"
                    [attr.alt]="item.name" />
            </a>

            <div class="commerce-minibasket-item__info"
                 [class.commerce-minibasket-item__info--not-available]="reservationStatus?.showReservationErrors && reservationStatus?.isNotAvailable && reservationStatus?.canBeReserved"
                 [class.commerce-minibasket-item__info--has-warning]="reservationStatus?.showReservationErrors && reservationStatus?.hasWarning && reservationStatus?.canBeReserved">
                <div class="commerce-minibasket-item__name" role="heading">
                    <a [routerLink]="['', { outlets: { dialog: ['product', item.id] } }]">
                        {{ item.name }}
                    </a>
                </div>

                <div class="commerce-minibasket-item__prices">
                    <div class="commerce-minibasket-item__price" [class.commerce-minibasket-item__price_campaign-discount]="isItemCampaignDiscount" [attr.aria-label]="'${Translations.commerce.basket.mainPriceAlt}' | translation: item.name">
                        {{ (isItemCampaignDiscount ? item.campaign?.campaignPrice : item.price) | currency : currencyCode : 'symbol-narrow' }}
                    </div>

                    <div class="commerce-minibasket-item__unit-price" [attr.aria-label]="'${Translations.commerce.basket.unitPriceAlt}' | translation: item.name">
                        {{ item.unitPrice }}
                    </div>
                </div>
            </div>

            <div class="commerce-minibasket-item__addtobasket">
                <iv-commerce-addtobasket [product]="item" mode="minibasket"></iv-commerce-addtobasket>
            </div>

            <iv-commerce-basket-item-reservation
                *ngIf="reservationStatus && reservationStatus?.showReservationErrors && (reservationStatus?.hasWarning || reservationStatus?.isNotAvailable) && reservationStatus?.canBeReserved"
                [@reservation]
                [isMiniBasket]="true"
                [productId]="item.id"
                [replacementProduct]="item.replacementProduct"
                [quantity]="item.quantity"
                [reservation]="item.reservation"
            ></iv-commerce-basket-item-reservation>
        </div>
    `,
    animations: [
        trigger('reservation', [
            state('void', style({ height: 0, minHeight: 0, visibility: 'hidden', overflow: 'hidden' })),
            state('*', style({ height: '*', visibility: 'visible', overflow: 'hidden' })),
            transition('void <=> *', animate('325ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class CommerceMinibasketItemComponent implements OnInit, OnChanges {
    @Input() item: IBasketRowViewModel & IReservationViewModel;

    isItemCampaignDiscount = false;
    reservationStatus: IReservationStatus;

    constructor( @Inject(CURRENCY_CODE) public currencyCode: string, private basketService: BasketService) { }

    ngOnInit() {
        this._isItemCampaignDiscount();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.item) {
            const { currentValue } = changes.item;

            if (currentValue.reservation) {
                this.checkForReservation(currentValue.reservation);
            }
        }

    }

    remove() {
        this.basketService.addToBasket(this.item.id!, 0);
    }

    private _isItemCampaignDiscount() {
        if (this.item.campaign) {
            this.isItemCampaignDiscount = this.item.campaign.type === CampaignTypeEnum.ProductCampaignDiscount;
        }
    }

    private checkForReservation(reservation: IReservationViewModel) {
        this.reservationStatus = getReservationStatus(reservation);
    }
}
