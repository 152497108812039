/* eslint-disable @typescript-eslint/no-explicit-any */
import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

import {
    CampaignTypeEnum,
    IFavoriteViewModel,
    IMagazineContainerViewModel,
    IProductListViewModel,
    IProductSimpleBaseViewModel,
    IProductViewModel,
    ISetWeekOffersViewModel,
    ISuggestionsViewModel,
    MagazineContainerViewModel,
    ProductListViewModel,
    ProductViewModel,
    SuggestionsViewModel
} from '../../api/services';
import { Translations } from '../../translations/translations';
import { IProductSettings } from '../commerce/product-settings';
import { CommaDashPipe } from '../shared/comma-dash.pipe';
import { UserService } from '../user/user.service';
import { BaseService } from './base-service';

export interface IChip {
    text: string;
    color?: 'primary' | 'accent' | 'warn';
    selected: boolean;
}

@Injectable()
export class ProductService extends BaseService {
    productInFocus = new Subject<void>();
    searchInFocus = new Subject<void>();
    isSearching = new Subject<boolean>();
    productExists = new Subject<boolean>();

    constructor(
        private http: HttpClient,
        private commaDashPipe: CommaDashPipe,
        private userService: UserService,
        private datePipe: DatePipe
    ) {
        super();
    }

    getProducts(settings: IProductSettings): Observable<IProductListViewModel> {
        const {
            mainCategoryId,
            subCategoryId,
            skip,
            take,
            sorting,
            showDiscounts,
            searchTerm,
            showSpecials,
            isRootCategory
        } = settings;

        if (searchTerm !== undefined) {
            return this.getSearch(settings).pipe(tap(prod => this.triggerSearching(false, prod)));
        } else if (showSpecials === 'weeklyDiscounts') {
            return this.getDiscounts().pipe(tap(prod => this.triggerSearching(false, prod)));
        } else if (showSpecials === Translations.shop.paths.topProductsPage.path) {
            return this.getTopProducts(settings).pipe(tap(prod => this.triggerSearching(false, prod)));
        } else if (showSpecials === 'favorites') {
            return this.getFavorites(settings).pipe(tap(prod => this.triggerSearching(false, prod)));
        }

        const url = this.apiBaseUrl('/Product/GetProductsByCategoryPath');
        return this.userService.deliveryDate$.pipe(
            distinctUntilChanged(),
            switchMap(deliveryDate => {
                const params = new HttpParams({
                    fromObject: {
                        deliveryDate,
                        categoryPath: subCategoryId ? subCategoryId : mainCategoryId,
                        skip: skip.toString(),
                        take: take.toString(),
                        sorting,
                        filter: showDiscounts ? 'discounts' : '',
                        isRootCategory: isRootCategory ? 'true' : 'false'
                    }
                });
                return this.http.get(url, { params }).pipe(
                    map(ProductListViewModel.fromJS),
                    tap(prod => this.triggerSearching(false, prod))
                );
            })
        );
    }

    getProductDetails(productId: string): Observable<IProductViewModel> {
        return this.userService.deliveryDate$.pipe(
            distinctUntilChanged(),
            switchMap(deliveryDate => {
                return this.http
                    .get(this.apiBaseUrl('/Product/ProductDetails'), {
                        params: {
                            deliveryDate,
                            productId
                        }
                    })
                    .pipe(map(ProductViewModel.fromJS));
            })
        );
    }

    getSearch({ skip, take, sorting, searchTerm }: IProductSettings): Observable<IProductListViewModel> {
        if (searchTerm !== undefined && !searchTerm.length) {
            return this.getEmptyProductList();
        }

        return this.userService.deliveryDate$.pipe(
            distinctUntilChanged(),
            switchMap(deliveryDate => {
                return this.http
                    .get(this.apiBaseUrl('/Product/Search'), {
                        params: {
                            deliveryDate,
                            text: searchTerm || '',
                            skip: skip.toString(),
                            take: take.toString(),
                            sorting
                        }
                    })
                    .pipe(map(ProductListViewModel.fromJS));
            })
        );
    }

    getSuggestions(searchTerm: string): Observable<ISuggestionsViewModel> {
        if (!searchTerm || !searchTerm.length) {
            return of(new SuggestionsViewModel());
        }

        return this.userService.deliveryDate$.pipe(
            distinctUntilChanged(),
            switchMap(deliveryDate => {
                return this.http
                    .get(this.apiBaseUrl('/Product/Suggestions'), {
                        params: {
                            deliveryDate,
                            text: searchTerm || ''
                        }
                    })
                    .pipe(map(SuggestionsViewModel.fromJS));
            })
        );
    }

    getDiscounts(): Observable<IProductListViewModel> {
        return this.getEmptyProductList();
    }

    getTopProducts({ sorting }: IProductSettings): Observable<IProductListViewModel> {
        return this.userService.deliveryDate$.pipe(
            distinctUntilChanged(),
            switchMap(deliveryDate => {
                return this.http
                    .get(this.apiBaseUrl('/Product/GetTopProducts'), {
                        params: {
                            deliveryDate,
                            skip: '0',
                            take: '50',
                            sorting
                        }
                    })
                    .pipe(map(ProductListViewModel.fromJS));
            })
        );
    }

    getFavorites({ skip, take, sorting }: IProductSettings): Observable<IProductListViewModel> {
        return this.userService.deliveryDate$.pipe(
            distinctUntilChanged(),
            switchMap(deliveryDate => {
                const params: any = {
                    deliveryDate,
                    skip: skip.toString(),
                    take: take.toString()
                };

                // the sorting was mandatory but should not be empty
                // otherwise will cause a internal server error
                // sorting='' | error
                // sorting=alphabetic | works
                if (sorting) {
                    params.sorting = sorting;
                }

                return this.http
                    .get(this.apiBaseUrl('/Product/GetFavorites'), {
                        params
                    })
                    .pipe(map(ProductListViewModel.fromJS));
            })
        );
    }

    getEmptyProductList(): Observable<IProductListViewModel> {
        const listVm = new ProductListViewModel();
        listVm.numFound = 0;

        return of<IProductListViewModel>(listVm);
    }

    getProductCatalogues(): Observable<IMagazineContainerViewModel[]> {
        return this.http
            .get<IMagazineContainerViewModel[]>(this.apiBaseUrl('/Magasine/GetWeekMagasine'))
            .pipe(map(magasines => magasines.map(MagazineContainerViewModel.fromJS)));
    }

    toggleFavorite(favoriteViewModel: IFavoriteViewModel): Observable<boolean> {
        return this.http.post(this.apiBaseUrl('/Product/ToggleFavorite'), favoriteViewModel).pipe(map(data => !!data));
    }

    getWeeklyOffers(weekNumber: number): Observable<string[]> {
        return this.userService.deliveryDate$.pipe(
            distinctUntilChanged(),
            switchMap(deliveryDate => {
                return this.http.get<string[]>(this.apiBaseUrl('/Product/GetWeekOfferProductNos'), {
                    params: {
                        deliveryDate,
                        weekNumber: weekNumber.toString()
                    }
                });
            })
        );
    }

    setWeeklyOffers(model: ISetWeekOffersViewModel): Observable<boolean> {
        return this.http.post<boolean>(this.apiBaseUrl('/Product/SetWeekOfferProductNos'), model);
    }

    getThisWeeksOffers(): Observable<IProductListViewModel> {
        return this.userService.deliveryDate$.pipe(
            distinctUntilChanged(),
            switchMap(deliveryDate => {
                return this.http
                    .get(this.apiBaseUrl('/product/ThisWeeksOffers'), {
                        params: {
                            deliveryDate
                        }
                    })
                    .pipe(map(ProductListViewModel.fromJS));
            })
        );
    }

    isProductCampaignDiscount(product: IProductSimpleBaseViewModel) {
        if (product.campaign) {
            return product.campaign.type === CampaignTypeEnum.ProductCampaignDiscount;
        }

        return false;
    }

    createChips(product: IProductSimpleBaseViewModel): IChip[] {
        const chips: IChip[] = [];

        if (product.isQuantitySoldOut) {
            chips.push({ text: Translations.commerce.productListItem.temporarilySoldout, selected: false });
        } else {
            if (product.campaign) {
                switch (product.campaign.type) {
                    case CampaignTypeEnum.ProductCampaignMixOffer:
                        chips.push({
                            text: Translations.replaceTokens(
                                Translations.commerce.productListItem.campaign.mixOffer,
                                product.campaign.weekNumber,
                                product.campaign.minQuantity,
                                this.commaDashPipe.transform(
                                    product.campaign.campaignPrice * product.campaign.minQuantity
                                )
                            ),
                            color: 'warn',
                            selected: true
                        });
                        break;
                    case CampaignTypeEnum.ProductCampaignBuyXForY:
                        chips.push({
                            text: Translations.replaceTokens(
                                Translations.commerce.productListItem.campaign.xForY,
                                product.campaign.weekNumber,
                                product.campaign.minQuantity,
                                this.commaDashPipe.transform(
                                    product.campaign.campaignPrice * product.campaign.minQuantity
                                )
                            ),
                            color: 'warn',
                            selected: true
                        });
                        break;
                    case CampaignTypeEnum.ProductCampaignDiscount:
                        chips.push({
                            text: Translations.replaceTokens(
                                Translations.commerce.productListItem.campaign.discount,
                                product.campaign.weekNumber
                            ),
                            color: 'warn',
                            selected: true
                        });
                        break;
                }
            }

            if (product.discountItem) {
                chips.push({
                    text: Translations.commerce.productListItem.discountItem,
                    color: 'accent',
                    selected: true
                });
            }
        }

        if (product.lastPurchased) {
            chips.push({
                text: Translations.replaceTokens(
                    Translations.commerce.productListItem.lastPurchased,
                    this.datePipe.transform(product.lastPurchased, 'shortDate')
                ),
                selected: false
            });
        }

        return chips;
    }

    focusFirstProduct() {
        this.productInFocus.next();
    }

    focusSearch() {
        this.searchInFocus.next();
    }

    private triggerSearching(isSearching: boolean, prod?: IProductListViewModel): void {
        this.isSearching.next(isSearching);
        if (prod) {
            this.productExists.next(!!prod.numFound);
        }
    }
}
