import { ICol, IEvent, ISlot } from '../services/calendar.service';
import { Injectable } from '@angular/core';

export interface IDragEvent {
    value: any;
    where: IEvent | { slot: ISlot, col: ICol; };
    targetCallerProfileId?: number;
}
@Injectable()
export class DragService {
    public dragger: any = {};
    public element: any;

    dragging = false;
}
