import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';

import { BehaviorSubject, Subject } from 'rxjs';
import { CreateUserViewModel, IUser, UpdateUserViewModel } from '../../api/services';
import { Translations } from '../../translations/translations';
import {
    FormBuilderCheckbox,
    FormBuilderMunicipalityPicker, FormBuilderRadio,
    FormBuilderTextInput,
    FormBuilderTypes
} from '../form-builder/form-builder-element.model';
import { FormBuilderService } from '../form-builder/form-builder.service';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { DialogService } from '../shared/dialog.service';
import { UtilService } from '../util/util.service';
import { MunicipalityService } from './municipality.service';

enum UserTypeValues {
    isSuperUser = 'isSuperUser',
    isPdaUser = 'isPdaUser',
    isFollowUpUser = 'isFollowUpUser',
    noRoles = 'noRoles',
    isDeleted = 'isDeleted'
}

@Component({
    selector: 'iv-municipality-create-user',
    template: `
        <div class="municipality-create-user">
            <form
                class="municipality-create-user__form alt-theme form"
                *ngIf="form"
                [formGroup]="form"
                (submit)="submit(form)"
                autocomplete="off"
            >
                <iv-form-builder-element
                    [form]="form"
                    [input]="input"
                    *ngFor="let input of inputs"
                ></iv-form-builder-element>

                <iv-progress-button class="municipality-create-user__btn" [loadingState]="loading" color="primary" [disabled]="user?.isDeleted">
                {{
                    btnLabel
                }}
            </iv-progress-button>
            </form>
        </div>
    `
})
export class MunicipalityCreateUserComponent implements OnInit, OnDestroy {
    loading = false;
    isEditing = false;

    inputs: FormBuilderTypes[];
    form: UntypedFormGroup;

    hasNoRoles = new BehaviorSubject<boolean>(true);
    isDeletedBS = new BehaviorSubject<boolean>(false);

    btnLabel: string;
    private _scrollContainer: HTMLElement | Window =
        (document.querySelector('#content > .util-hidden-scroll') as HTMLElement) || window;

    private user?: IUser;
    private unsubscribeS$: Subject<void> = new Subject();


    constructor(
        private formBuilder: FormBuilderService,
        private municipality: MunicipalityService,
        private route: ActivatedRoute,
        private dialog: DialogService,
        private utilService: UtilService
    ) { }

    ngOnInit() {
        this.utilService.scrollElementTo(this._scrollContainer, 0);
        this._getUser();
    }

    ngOnDestroy(): void {
        this.unsubscribeS$.next();
        this.unsubscribeS$.complete();
    }

    /**
     * Handles the form submit and makes sure to show the correct snackmessage
     * @param form
     */
    submit(form: UntypedFormGroup) {
        let isValid = form.valid;
        const values = form.value;

        if (
            !values.email &&
            (values.isSuperUser || values.ring1Mail || values.ring2Mail || values.note1Mail || values.note2Mail)
        ) {
            this.dialog.showMessage(Translations.forms.validation.emailRequired, undefined, 'alertdialog');
            isValid = false;
        }

        if (isValid) {
            this.loading = true;

            if (this.isEditing) {
                // Add the username because the values from the form is null or empty
                if (!values.username && this.user) {
                    values.username = this.user.username;
                }

                delete values.password;
            }

            if (values.isPdaUser) {
                values.ring1Mail = false;
                values.ring2Mail = false;
                values.note1Mail = false;
                values.note2Mail = false;
            }

            values.isSuperUser = values.userType === UserTypeValues.isSuperUser;
            values.isPdaUser = values.userType === UserTypeValues.isPdaUser;
            delete values.userType;

            const params: CreateUserViewModel | UpdateUserViewModel = {
                ...values,
                confirmPassword: values.password,
                districtId: 0,
                subDistrictIds: values.districts.subdistricts,
                isPrivateSupplier: false
            };

            this.municipality
                .createOrUpdateUser(params, this.isEditing)
                .pipe(
                    finalize(() => (this.loading = false)),
                    takeUntil(this.unsubscribeS$)
                )
                .subscribe(
                    () => {
                        // Show user Updated message
                        if (this.isEditing) {
                            this.dialog.showSnackMessage({
                                message: Translations.forms.labels.municipality.userUpdated,
                                action: Translations.forms.labels.municipality.snackbarDismiss
                            });
                        } else {
                            // Show user created message
                            this.dialog.showSnackMessage({
                                message: Translations.forms.labels.municipality.userCreated,
                                action: Translations.forms.labels.municipality.snackbarDismiss
                            });

                            // Clear form fields
                            Object.entries(this.form.controls)
                                .filter(entry => entry[0] !== 'districts')
                                .forEach(entry => entry[1].reset());

                            // make the form on initial state
                            // after reset
                            if (this.form) {
                                this.form.controls['userType']?.updateValueAndValidity();
                                this.form.updateValueAndValidity();
                            }
                        }
                    },
                    (err: IntervareHttpErrorResponse) => this.dialog.showValidationResult(err.validationErrors)
                );
        }
    }

    /**
     * If edit mode we fetch the user, and creates the form with its data,
     * else we simply create a user formular.
     * @private
     */
    private _getUser() {
        const userId = this.route.snapshot.paramMap.get('id');

        this.btnLabel = userId
            ? Translations.forms.labels.municipality.updateUser
            : Translations.forms.labels.municipality.createUser;

        if (userId) {
            this.isEditing = true;

            this.municipality
                .getUser(userId)
                .pipe(takeUntil(this.unsubscribeS$))
                .subscribe(user => this._buildForm(user));
        } else {
            this._buildForm();
        }
    }

    /**
     * Builds the formular
     * @param user
     * @private
     */
    private _buildForm(user?: IUser) {
        this.user = user;

        this.inputs = [
            new FormBuilderMunicipalityPicker({
                name: 'districts',
                multipleDistricts: true,
                multipleSubdistricts: true,
                required: true,
                value: {
                    municipality: user && user.municipalities ? user.municipalities[0].id : undefined,
                    districts: user && user.districts ? user.districts.map(district => district.id) : [],
                    subdistricts: user && user.subDistricts ? user.subDistricts.map(subdistrict => subdistrict.id) : []
                },
                condition: this.hasNoRoles
            }),
            new FormBuilderRadio({
                name: 'userType',
                options: [
                    { label: Translations.forms.labels.municipality.isSuperUser, value: UserTypeValues.isSuperUser },
                    { label: Translations.forms.labels.municipality.isPdaUser, value: UserTypeValues.isPdaUser },
                    { label: Translations.forms.labels.municipality.isFollowUpUser, value: UserTypeValues.isFollowUpUser },
                    { label: Translations.forms.labels.municipality.noRolesUser, value: UserTypeValues.noRoles },
                    { label: Translations.forms.labels.municipality.isDeleted, value: UserTypeValues.isDeleted }
                ],
                required: true,
                value: this.handleInitialUserType(user),
                valueChanged: ((value, form) => {

                    if (this.isEditing) {
                        this.hasNoRoles.next(true);

                        if (value === UserTypeValues.noRoles || value === UserTypeValues.isDeleted) {
                            this.hasNoRoles.next(false);
                        }
                    }


                    if (value === UserTypeValues.isSuperUser || value === UserTypeValues.isFollowUpUser) {
                        form?.controls['ring1Mail'].enable();
                        form?.controls['ring2Mail'].enable();
                        form?.controls['note1Mail'].enable();
                        form?.controls['note2Mail'].enable();
                        return;
                    }
                    form?.controls['ring1Mail'].enable();
                    form?.controls['ring2Mail'].enable();
                    form?.controls['note1Mail'].enable();
                    form?.controls['note2Mail'].enable();

                    form?.controls['ring1Mail'].setValue(false);
                    form?.controls['ring2Mail'].setValue(false);
                    form?.controls['note1Mail'].setValue(false);
                    form?.controls['note2Mail'].setValue(false);
                    form?.controls['ring1Mail'].updateValueAndValidity();
                    form?.controls['ring2Mail'].updateValueAndValidity();
                    form?.controls['note1Mail'].updateValueAndValidity();
                    form?.controls['note2Mail'].updateValueAndValidity();
                }),
                condition: () => !user?.isDeleted

            }),
            new FormBuilderTextInput({
                name: 'name',
                label: Translations.forms.labels.municipality.name,
                value: user ? user.name : '',
                condition: this.hasNoRoles

            }),
            new FormBuilderTextInput({
                name: 'phoneNumber',
                label: Translations.forms.labels.municipality.phoneNumber,
                value: user ? user.phoneNumber : '',
                condition: this.hasNoRoles

            }),
            new FormBuilderTextInput({
                name: 'email',
                label: Translations.forms.labels.municipality.email,
                validation: [Validators.email],
                value: user ? user.email : '',
                condition: this.hasNoRoles || this.isDeletedBS
            }),
            new FormBuilderTextInput({
                name: 'username',
                label: Translations.forms.labels.municipality.username,
                condition: () => !this.isEditing,
                value: user ? user.username : '',

            }),
            new FormBuilderTextInput({
                name: 'password',
                label: Translations.forms.labels.municipality.password,
                condition: () => !this.isEditing
            }),
            new FormBuilderCheckbox({
                name: 'ring1Mail',
                label: Translations.forms.labels.municipality.ring1Mail,
                value: user ? user.ring1Mail : false,
                condition: () => this.isSuperUser || this.isFollowUpUser
            }),
            new FormBuilderCheckbox({
                name: 'ring2Mail',
                label: Translations.forms.labels.municipality.ring2Mail,
                value: user ? user.ring2Mail : false,
                condition: () => this.isSuperUser || this.isFollowUpUser
            }),
            new FormBuilderCheckbox({
                name: 'note1Mail',
                label: Translations.forms.labels.municipality.note1Mail,
                value: user ? user.note1Mail : false,
                condition: () => this.isSuperUser || this.isFollowUpUser
            }),
            new FormBuilderCheckbox({
                name: 'note2Mail',
                label: Translations.forms.labels.municipality.note2Mail,
                value: user ? user.note2Mail : false,
                condition: () => this.isSuperUser || this.isFollowUpUser
            })
        ];

        this.form = this.formBuilder.toFormGroup(this.inputs);
    }

    private handleInitialUserType(user?: IUser) {
        if (!user) {
            return "";
        }

        if (user.isDeleted) {
            return UserTypeValues.isDeleted;
        }

        if (user.isSuperUser) {
            return UserTypeValues.isSuperUser;
        } else if (user.isPdaUser) {
            return UserTypeValues.isPdaUser;
        } else {
            if (!user.ring1Mail && !user.ring2Mail && !user.note1Mail && !user.note2Mail) {
                return UserTypeValues.noRoles;
            }

            return UserTypeValues.isFollowUpUser;
        }
    }

    private get isFollowUpUser() {
        return this.form?.controls['userType']?.value === UserTypeValues.isFollowUpUser;
    }

    private get isSuperUser() {
        return this.form?.controls['userType']?.value === UserTypeValues.isSuperUser;
    }
}
