import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { Translations } from '../../translations/translations';

export interface ICitizenTableRow {
    field: string;
    new: string;
    old?: string;
}

@Component({
    selector: 'iv-municipality-citizen-table',
    template: `
        <mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="field">
                <mat-header-cell *matHeaderCellDef>${Translations.municipality.citizenTable.field}</mat-header-cell>
                <mat-cell *matCellDef="let element" [innerHtml]="element.field"></mat-cell>
            </ng-container>

            <ng-container matColumnDef="new">
                <mat-header-cell *matHeaderCellDef>${Translations.municipality.citizenTable.new}</mat-header-cell>
                <mat-cell *matCellDef="let element" [innerHTML]="element.new"></mat-cell>
            </ng-container>

            <ng-container matColumnDef="old">
                <mat-header-cell *matHeaderCellDef>${Translations.municipality.citizenTable.old}</mat-header-cell>
                <mat-cell *matCellDef="let element" [innerHTML]="element.old"></mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="editMode ? columnsEdit : columns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: editMode ? columnsEdit : columns"></mat-row>
        </mat-table>
    `
})
export class MunicipalityCitizenTableComponent {
    @Input() dataSource: MatTableDataSource<ICitizenTableRow[]>;
    @Input() editMode = true;

    columns = ['field', 'new'];
    columnsEdit = ['field', 'new', 'old'];
}
