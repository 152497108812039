import { DatePipe } from '@angular/common';
import { Directive, HostListener, Input, OnDestroy } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Translations } from '../../../translations/translations';
import { appInjector } from '../../app-injector';
import { IntervareHttpErrorResponse } from '../../services/base-service';
import { CitizenService } from '../../services/citizen.service';
import { NoteService } from '../../services/note.service';

@Directive({
    selector: '[ivCitizenTooltip],[ivHistoryTooltip]'
})
export class CitizenTooltipDirective extends MatTooltip implements OnDestroy {

    @Input('ivCitizenTooltip')
    customerNo?: string;

    @Input('ivHistoryTooltip')
    historyItemId?: string;

    @Input()
    tryCount?: number;


    /**
     * Override the variable of the super class
     */
    // showDelay = 200; 'showDelay' is defined as an accessor in class 'MatTooltip', but is overridden here in 'CitizenTooltipDirective' as an instance property. (v11 to v13)

    private hasTooltip = false;

    private unsubscribe = new Subject<void>();

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        super.ngOnDestroy();
    }

    @HostListener('mouseenter')
    onMouseOver() {

        if (this.hasTooltip || (!this.customerNo && !this.historyItemId)) {
            return;
        }

        this.message = Translations.callListAdmin.citizenDetails.loading;

        // Using AppInjector to avoid having a local constructor with all dependencies of the super class
        const injector = appInjector();
        const date = injector.get<DatePipe>(DatePipe);

        if (this.customerNo) {

            const citizenService = injector.get<CitizenService>(CitizenService);

            citizenService.getCitizen(this.customerNo).pipe(takeUntil(this.unsubscribe)).subscribe(
                citizen => {
                    this.message = `
                        ${citizen.name}\n
                        ${Translations.callListAdmin.citizenDetails.customerNumber}: ${citizen.customerNumber}\n
                        ${Translations.callListAdmin.citizenDetails.interval}: ${citizen.orderDateTime}\n
                        ${Translations.callListAdmin.citizenDetails.municipality}: ${citizen.municipality}\n
                        ${Translations.callListAdmin.citizenDetails.district}: ${citizen.district}\n
                        ${Translations.callListAdmin.citizenDetails.tryCount}: ${this.tryCount || 0}
                    `;
                },
                (err: IntervareHttpErrorResponse) => this.message = err.validationErrors.join(', ')
            );

        }

        if (this.historyItemId) {

            const noteService = injector.get<NoteService>(NoteService);

            noteService.getCustomerNote(this.historyItemId).pipe(
                takeUntil(this.unsubscribe)
            ).subscribe(noteVm => {

                // replace the <br/> tags from the comment and replace to new line
                const comment = this._replaceBreaklines(noteVm.comment || '');

                this.message = `${Translations.administration.citizenHistory.noteDetails.date}: ${date.transform(noteVm.dateCreated, 'short')}<br/>`;

                if (noteVm.causeName) {
                    this.message += `${Translations.administration.citizenHistory.form.cause}: ${noteVm.causeName}<br/>`;
                }

                if (noteVm.subCauseName) {
                    this.message += `${Translations.administration.citizenHistory.form.subCause}: ${noteVm.subCauseName}<br/>`;
                }

                if (noteVm.subSubCauseName) {
                    this.message += `${Translations.administration.citizenHistory.form.subSubCause}: ${noteVm.subSubCauseName || Translations.administration.citizenHistory.noteDetails.noSubSubCause}<br/>`;
                }

                if (noteVm.causeName) {
                    this.message += `${Translations.administration.citizenHistory.form.comment}: ${comment}`;
                }

                // replace the <br/> tags from the comment and replace to new line
                this.message = this._replaceBreaklines(this.message);
            },
                (err: IntervareHttpErrorResponse) => this.message = err.validationErrors.join(', ')
            );
        }

        if (!this.hasTooltip) {
            this.show();
        }

        this.hasTooltip = true;
    }

    @HostListener('mouseleave')
    onLeave() {
        this.hide();
    }

    private _replaceBreaklines(str: string): string {
        return str.replace(/<br\s*\/?>/mg, '\n');
    }
}
