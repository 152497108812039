import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotesAdminCausesComponent } from './notes-admin-causes.component';
import { NotesAdminRoutingModule } from './notes-admin-routing.module';
import { NotesAdminService } from './notes-admin.service';
import { NotesAdminCauseComponent } from './notes-admin-cause.component';
import { MaterialUiModule } from '../material-ui/material-ui.module';
import { SharedModule } from '../shared/shared.module';
import { NotesAdminCreateCauseComponent } from './notes-admin-create-cause.component';
import { NotesAdminCreateSubcauseComponent } from './notes-admin-create-subcause.component';
import { NotesAdminSubcauseComponent } from './notes-admin-subcause.component';
import { NotesAdminCreateSubSubCauseComponent } from './notes-admin-create-subsubcause.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialUiModule,
        SharedModule,
        NotesAdminRoutingModule
    ],
    providers: [NotesAdminService],
    declarations: [
        NotesAdminCausesComponent,
        NotesAdminCauseComponent,
        NotesAdminCreateCauseComponent,
        NotesAdminCreateSubcauseComponent,
        NotesAdminSubcauseComponent,
        NotesAdminCreateSubSubCauseComponent
    ],
    exports: [NotesAdminCausesComponent, NotesAdminCauseComponent],
})
export class NotesAdminModule { }
