import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Translations } from '../../translations/translations';
import { AuthGuard } from './auth.guard';
import { NewPasswordComponent } from './new-password.component';
import { UserEditPasswordComponent } from './user-edit-password.component';
import { IDENTITIES, ROLES } from './user-roles';


export const userRoutes: Routes = [
    {
        path: Translations.pages.newPassword.path,
        component: NewPasswordComponent,
        data: {
            pageData: {
                title: Translations.pages.newPassword.title
            },
            allowedRoles: [ROLES.Anonymous]
        },
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: Translations.pages.editPassword.path,
        component: UserEditPasswordComponent,
        data: {
            pageData: {
                voluntary: true
            },
            allowedRoles: IDENTITIES.editOwnPassword
        },
        canActivate: [
            AuthGuard
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(userRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class UserRoutingModule { }
