import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { filter, switchMapTo, takeUntil, tap } from 'rxjs/operators';

import { ISubSubCauseCreateModel, ISubSubCauseViewModel, SubSubCauseCreateModel } from '../../api/services';
import { Translations } from '../../translations/translations';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { DialogService } from '../shared/dialog.service';
import { Helpers } from '../util/helpers';
import { NotesAdminService } from './notes-admin.service';
import { Subject } from 'rxjs';

@Component({
    template: `
        <article class="dialog alt-theme">
            <h2 mat-dialog-title>
                {{ editMode ? '${Translations.intervare.notesAdmin.editCause.header}' : '${Translations.intervare.notesAdmin.createCause.header}' }}
            </h2>
            <form #form="ngForm" (submit)="close(form)">
                <mat-dialog-content>
                    <iv-util-hidden-scroll>
                        <mat-form-field class="form__element">
                            <input type="text" matInput placeholder="${Translations.intervare.notesAdmin.createCause.name}" name="name" [(ngModel)]="model.subSubCauseName" required>
                        </mat-form-field>
                        <mat-form-field class="form__element">
                            <mat-chip-grid #chipList>
                                <mat-chip-row *ngFor="let recipient of recipients" [highlighted]="selectable"
                                          [removable]="removable" (removed)="removeRecipient(recipient)">
                                    {{ recipient }}
                                    <span matChipRemove *ngIf="removable">x</span>
                                </mat-chip-row>
                                <input placeholder="${Translations.intervare.notesAdmin.createSubCause.recipients}"
                                       type="email"
                                       [matChipInputFor]="chipList"
                                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                       [matChipInputAddOnBlur]="addOnBlur"
                                       (matChipInputTokenEnd)="addRecipient($event)" />
                            </mat-chip-grid>
                            <mat-hint>${Translations.intervare.notesAdmin.createSubCause.emailHint}</mat-hint>
                        </mat-form-field>
                        <mat-form-field class="form__element">
                            <mat-chip-grid #chipList2>
                                <mat-chip-row *ngFor="let recipient of distCenterDK01Recipients" [highlighted]="selectable"
                                          [removable]="removable" (removed)="removeDK01Recipient(recipient)">
                                    {{ recipient }}
                                    <span matChipRemove *ngIf="removable">x</span>
                                </mat-chip-row>
                                <input placeholder="${Translations.intervare.notesAdmin.createSubCause.recipientsDK01}"
                                       type="email"
                                       [matChipInputFor]="chipList2"
                                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                       [matChipInputAddOnBlur]="addOnBlur"
                                       (matChipInputTokenEnd)="addDK01Recipient($event)" />
                            </mat-chip-grid>
                            <mat-hint>${Translations.intervare.notesAdmin.createSubCause.emailHint}</mat-hint>
                        </mat-form-field>
                        <mat-form-field class="form__element">
                            <mat-chip-grid #chipList3>
                                <mat-chip-row *ngFor="let recipient of distCenterDK02Recipients" [highlighted]="selectable"
                                          [removable]="removable" (removed)="removeDK02Recipient(recipient)">
                                    {{ recipient }}
                                    <span matChipRemove *ngIf="removable">x</span>
                                </mat-chip-row>
                                <input placeholder="${Translations.intervare.notesAdmin.createSubCause.recipientsDK02}"
                                       type="email"
                                       [matChipInputFor]="chipList3"
                                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                       [matChipInputAddOnBlur]="addOnBlur"
                                       (matChipInputTokenEnd)="addDK02Recipient($event)" />
                            </mat-chip-grid>
                            <mat-hint>${Translations.intervare.notesAdmin.createSubCause.emailHint}</mat-hint>
                        </mat-form-field>
                        <mat-form-field class="form__element">
                            <input matInput type="text" [(ngModel)]="model.emailSubject" name="emailSubject" placeholder="${Translations.intervare.notesAdmin.createSubCause.emailSubject}">
                        </mat-form-field>
                        <mat-form-field class="form__element">
                        <textarea matInput
                                  cdkTextareaAutosize
                                  cdkAutosizeMinRows="2"
                                  cdkAutosizeMaxRows="10"
                                  name="template" placeholder="${Translations.intervare.notesAdmin.createSubCause.template}" [(ngModel)]="model.template"></textarea>
                            <mat-hint>${Translations.intervare.notesAdmin.createSubCause.templateHint}</mat-hint>
                        </mat-form-field>
                    </iv-util-hidden-scroll>
                </mat-dialog-content>
                <mat-dialog-actions>
                    <iv-progress-button class="municipality__btn" [loadingState]="loading" color="primary">
                        {{ editMode ? '${Translations.intervare.notesAdmin.editCause.submit}' : '${Translations.intervare.notesAdmin.createCause.submit}' }}
                    </iv-progress-button>
                    <button type="button" mat-button mat-dialog-close>${Translations.global.btnCancel}</button>
                    <iv-progress-button buttonType="button" class="municipality__btn" color="warn" [callback]="delete" *ngIf="editMode">${Translations.global.btnDelete}</iv-progress-button>
                </mat-dialog-actions>
            </form>
        </article>
    `,
    selector: 'iv-notes-admin-create-subsubcause'
})
export class NotesAdminCreateSubSubCauseComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    loading = false;
    selectable = true;
    removable = true;
    addOnBlur = true;
    editMode = false;

    model: ISubSubCauseViewModel | ISubSubCauseCreateModel = new SubSubCauseCreateModel();

    recipients: string[] = [];
    distCenterDK01Recipients: string[] = [];
    distCenterDK02Recipients: string[] = [];
    separatorKeysCodes = [ENTER, COMMA, SPACE];

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: ISubSubCauseViewModel | undefined,
        private dialogRef: MatDialogRef<NotesAdminCreateSubSubCauseComponent>,
        private notesAdmin: NotesAdminService,
        private dialogService: DialogService
    ) { }

    ngOnInit() {
        if (this.dialogData) {
            if (this.dialogData.subSubCauseId) {
                this.model = this.dialogData;
                this.removeRecipients();
                if (this.model.recipients !== undefined) {
                    this.recipients = this.model.recipients.split(',');
                }
                if (this.model.distCenterDK01Recipients !== undefined) {
                    this.distCenterDK01Recipients = this.model.distCenterDK01Recipients.split(',');
                }
                if (this.model.distCenterDK02Recipients !== undefined) {
                    this.distCenterDK02Recipients = this.model.distCenterDK02Recipients.split(',');
                }
                this.editMode = true;
            }

            else if (this.dialogData.subCauseId) {
                this.model.subCauseId = this.dialogData.subCauseId;
            }
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    close(form: NgForm) {
        if (!form.valid) {
            return;
        }
        this.loading = true;
        this.removeRecipients();

        if (this.editMode) {
            this.notesAdmin.updateSubSubCause(this.model as ISubSubCauseViewModel).pipe(takeUntil(this.unsubscribe$)).subscribe(
                () => {
                    this.notesAdmin.getCauses();
                    this.loading = false;
                    this.dialogService.showSnackMessage({
                        message: Translations.intervare.notesAdmin.editCause.messageOk
                    });
                    this.dialogRef.close(this.model);
                },
                (err: IntervareHttpErrorResponse) => {
                    this.loading = false;
                    this.dialogService.showSnackMessage({
                        message: Translations.intervare.notesAdmin.editCause.messageError + ' (' + err.validationErrors.join(', ') + ')'
                    });
                }
            );
        } else {
            this.notesAdmin.createSubSubCause(this.model as ISubSubCauseCreateModel).pipe(takeUntil(this.unsubscribe$)).subscribe(
                () => {
                    this.notesAdmin.getCauses();
                    this.loading = false;
                    this.dialogService.showSnackMessage({
                        message: Translations.intervare.notesAdmin.createCause.messageOk
                    });
                    this.dialogRef.close(this.model);
                },
                (err: IntervareHttpErrorResponse) => {
                    this.loading = false;
                    this.dialogService.showSnackMessage({
                        message: Translations.intervare.notesAdmin.createCause.messageError + ' (' + err.validationErrors.join(', ') + ')'
                    });
                }
            );
        }
    }

    addRecipient(event: MatChipInputEvent) {
        const input = event.chipInput;
        const value = event.value;

        if ((value || '').trim()) {

            if (Helpers.isValidEmail(value)) {
                this.recipients.push(value.trim());
                this.model.recipients = this.recipients.join(',');

                // Reset the input value
                if (input) {
                    input.clear();
                }
            } else {
                this.dialogService.showSnackMessage({
                    message: Translations.intervare.notesAdmin.createSubCause.messageRecipientError
                });
            }
        }
    }

    addDK01Recipient(event: MatChipInputEvent) {
        const input = event.chipInput;
        const value = event.value;

        if ((value || '').trim()) {

            if (Helpers.isValidEmail(value)) {
                this.distCenterDK01Recipients.push(value.trim());
                this.model.distCenterDK01Recipients = this.distCenterDK01Recipients.join(',');

                // Reset the input value
                if (input) {
                    input.clear();
                }
            } else {
                this.dialogService.showSnackMessage({
                    message: Translations.intervare.notesAdmin.createSubCause.messageRecipientError
                });
            }
        }
    }

    addDK02Recipient(event: MatChipInputEvent) {
        const input = event.chipInput;
        const value = event.value;

        if ((value || '').trim()) {

            if (Helpers.isValidEmail(value)) {
                this.distCenterDK02Recipients.push(value.trim());
                this.model.distCenterDK02Recipients = this.distCenterDK02Recipients.join(',');

                // Reset the input value
                if (input) {
                    input.clear();
                }
            } else {
                this.dialogService.showSnackMessage({
                    message: Translations.intervare.notesAdmin.createSubCause.messageRecipientError
                });
            }
        }
    }

    removeRecipient(recipient: string) {
        const index = this.recipients.indexOf(recipient);

        if (index >= 0) {
            this.recipients.splice(index, 1);
            this.model.recipients = this.recipients.join(',');
        }
    }

    removeDK01Recipient(recipient: string) {
        const index = this.distCenterDK01Recipients.indexOf(recipient);

        if (index >= 0) {
            this.distCenterDK01Recipients.splice(index, 1);
            this.model.distCenterDK01Recipients = this.distCenterDK01Recipients.join(',');
        }
    }

    removeDK02Recipient(recipient: string) {
        const index = this.distCenterDK02Recipients.indexOf(recipient);

        if (index >= 0) {
            this.distCenterDK02Recipients.splice(index, 1);
            this.model.distCenterDK02Recipients = this.distCenterDK02Recipients.join(',');
        }
    }

    delete = (): Promise<boolean> => {
        return this.dialogService.confirm(Translations.intervare.notesAdmin.editCause.confirmDelete).afterClosed().pipe(
            filter((res: boolean) => res && (this.model as ISubSubCauseViewModel).subSubCauseId !== undefined),
            switchMapTo(this.notesAdmin.deleteSubSubCause((this.model as ISubSubCauseViewModel).subSubCauseId)),
            tap(
                () => {
                    this.notesAdmin.getCauses();
                    this.dialogService.showSnackMessage({
                        message: Translations.intervare.notesAdmin.editCause.messageDeleteOk
                    });
                    this.dialogRef.close();
                },
                (err: IntervareHttpErrorResponse) => this.dialogService.showSnackMessage({
                    message: Translations.replaceTokens(Translations.intervare.notesAdmin.editCause.messageDeleteError, err.validationErrors.join(', '))
                })
            ),
            takeUntil(this.unsubscribe$)
        ).toPromise();
    }

    private removeRecipients(): void {
        if (this.model.recipients === '') {
            delete this.model.recipients;
        }
        if (this.model.distCenterDK01Recipients === '') {
            delete this.model.distCenterDK01Recipients;
        }
        if (this.model.distCenterDK02Recipients === '') {
            delete this.model.distCenterDK02Recipients;
        }
    }
}
