import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { Translations } from '../../translations/translations';

@Pipe({
    name: 'commaDash'
})
export class CommaDashPipe implements PipeTransform {
    constructor(private currencyPipe: CurrencyPipe) { }

    transform(value: number): string | null {
        return Math.round((value - Math.floor(value)) * 100) === 0 ? Math.round(value) + ',-' : this.currencyPipe.transform(value, Translations.variables.currencyCode, 'symbol-narrow');
    }
}
