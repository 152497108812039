import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

import { DragService } from './../drag.service';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[dragger]'
})
export class DraggerDirective implements OnInit {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() dragger: any;
    constructor(private ele: ElementRef, private dragService: DragService) {}

    @HostListener('dragstart')
    onDragstart() {
        this.dragService.dragger = this.dragger;
        this.dragService.element = this.ele.nativeElement;
    }

    ngOnInit() {
        this.ele.nativeElement.draggable = true;
        this.ele.nativeElement.classList.add('dragdirective');
    }
}
