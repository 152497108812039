import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import {
    ActivateTemporaryAddressViewModel,
    CustomerSubType,
    DayAndTime,
    DeactivateTemporaryAddressModel,
    DeliveryFrequency,
    ICitizenAddressViewmodel,
    IDetailedCitizenViewModel,
    TimeOfDay
} from '../../api/services';
import { check } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import {
    FormBuilderCity,
    FormBuilderDatepicker,
    FormBuilderMunicipalityOutput,
    FormBuilderMunicipalityPicker,
    FormBuilderOption,
    FormBuilderSelect,
    FormBuilderTextInput,
    FormBuilderTypes
} from '../form-builder/form-builder-element.model';
import { toFormOptions } from '../form-builder/form-builder-mappings';
import { FormBuilderService, IFormValues } from '../form-builder/form-builder.service';
import { AddressService } from '../services/address.service';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { CitizenService } from '../services/citizen.service';
import { DriverService } from '../services/driver.service';
import { AddressPipe } from '../shared/address.pipe';
import { DialogService } from '../shared/dialog.service';
import { RecievedDialogComponent } from '../shared/recieved-dialog.component';
import { UserService } from '../user/user.service';
import { flatten } from '../util/helpers';
import { ICitizenTableRow } from './municipality-citizen-table.component';
import { MunicipalityDeliveryHelpers } from './municipality-delivery-helpers';
import { MunicipalityService } from './municipality.service';

@Component({
    selector: 'iv-municipality-temporary-address',
    template: `
        <div class="municipality-temporary-address">
            <ng-container *ngIf="!fetching; else unavailable">
                <iv-citizen-details-info [citizen]="citizen"></iv-citizen-details-info>

                <div
                    class="municipality-temporary-address__status"
                    *ngIf="hasActiveTemporaryAddress; else activateTemporaryAddress"
                >
                    <div class="municipality-temporary-address__deactivate">
                        <div class="municipality-temporary-address__deactivate-text">
                            ${Translations.municipality.temporaryAddress.deactivate.text}<br />
                            {{ citizen.address | address }}<br />
                            ${Translations.municipality.temporaryAddress.deactivate.text2}
                        </div>

                        <button mat-raised-button color="primary" (click)="deactivate()">
                            ${Translations.municipality.temporaryAddress.deactivate.btn}
                        </button>
                    </div>
                </div>

                <ng-template #activateTemporaryAddress>
                    <div class="municipality-temporary-address__form-container" *ngIf="form && !tableData">
                        <form [formGroup]="form" class="municipality-temporary-address__form alt-theme form">
                            <iv-form-builder-element
                                *ngFor="let input of inputs"
                                [form]="form"
                                [input]="input"
                            ></iv-form-builder-element>
                        </form>

                        <button
                            class="municipality-temporary-address__activate"
                            (click)="activate()"
                            mat-raised-button
                            color="primary"
                        >
                            ${Translations.municipality.temporaryAddress.activate}
                        </button>
                    </div>

                    <div class="municipality-temporary-address__receipt" *ngIf="tableData">
                        <div class="municipality-temporary-address__alert alert alert-success" role="status">
                            ${Translations.municipality.temporaryAddress.activated} ${check}
                        </div>

                        <iv-municipality-citizen-table
                            [dataSource]="tableData"
                            [editMode]="false"
                        ></iv-municipality-citizen-table>
                    </div>
                </ng-template>
            </ng-container>

            <ng-template #unavailable>
                <div class="municipality-temporary-address__status alt-theme" *ngIf="impersonated">
                    <mat-progress-spinner
                        color="accent"
                        mode="indeterminate"
                        [strokeWidth]="3"
                        [diameter]="60"
                    ></mat-progress-spinner>
                </div>

                <div class="municipality-temporary-address__status" *ngIf="!impersonated">
                    ${Translations.commerce.basket.impersonate}
                </div>
            </ng-template>
        </div>
    `
})
export class MunicipalityTemporaryAddressComponent implements OnInit, OnDestroy {
    form: UntypedFormGroup;
    inputs: FormBuilderTypes[];
    citizen: IDetailedCitizenViewModel;

    fetching = true;
    impersonated = false;
    hasActiveTemporaryAddress = false;

    temporaryAddressList: ICitizenAddressViewmodel[];

    municipality$ = new Subject<number>();

    temporaryAddress$ = new BehaviorSubject<FormBuilderOption[]>([]);

    drivers$ = new BehaviorSubject<FormBuilderOption[]>([]);

    availableDeliveryDays$ = new BehaviorSubject<number[]>([]);

    deliveryDays$ = new BehaviorSubject<FormBuilderOption[]>([]);
    orderDays$ = new BehaviorSubject<FormBuilderOption[]>([]);
    timeSlots$ = new BehaviorSubject<FormBuilderOption[]>([]);

    biWeeklyDeliveryDays$ = new BehaviorSubject<FormBuilderOption[]>([]);
    biWeeklyOrderDays$ = new BehaviorSubject<FormBuilderOption[]>([]);
    biWeeklyTimeSlots$ = new BehaviorSubject<FormBuilderOption[]>([]);

    tableData: MatTableDataSource<ICitizenTableRow>;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private userService: UserService,
        private citizenService: CitizenService,
        private formBuilder: FormBuilderService,
        private driverService: DriverService,
        private municipalityService: MunicipalityService,
        private addressService: AddressService,
        private addressPipe: AddressPipe,
        private dialogService: DialogService,
        private router: Router
    ) {}

    ngOnInit() {
        this.userService.isImpersonating
            .pipe(
                tap(quickInfo => {
                    this.fetching = true;
                    this.impersonated = !!(quickInfo && quickInfo.customerNumber);
                }),
                filter(() => this.impersonated),
                map(quickInfo => quickInfo!.customerNumber!),
                switchMap(customerNumber =>
                    combineLatest([
                        this.citizenService.getCitizenDetails(customerNumber),
                        this.citizenService.getCitizenTemporaryAddress(customerNumber)
                    ])
                ),
                takeUntil(this.unsubscribe)
            )
            .subscribe(([citizen, temporaryList]) => {
                this.fetching = false;

                this.temporaryAddressList = temporaryList;
                this.citizen = citizen;
                this.hasActiveTemporaryAddress = !!(citizen.address && citizen.address.isTemporary);
                if (!this.hasActiveTemporaryAddress) {
                    this.temporaryAddress$.next(
                        temporaryList.map(address =>
                            toFormOptions(address.temporaryAddressId, this.addressPipe.transform(citizen.address!))
                        )
                    );
                    this._buildForm();
                }
            });

        this.municipality$
            .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe))
            .subscribe(municipalityId => this._getMunicipalityData(municipalityId));

        this.availableDeliveryDays$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() =>
                MunicipalityDeliveryHelpers.updateDeliveryDays(
                    this.availableDeliveryDays$.getValue(),
                    undefined,
                    this.deliveryDays$,
                    this.biWeeklyDeliveryDays$
                )
            );
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    activate() {
        if (this.form.valid) {
            this._openDialog().then(values => {
                if (values) {
                    const temporaryAddress = ActivateTemporaryAddressViewModel.fromJS(this.form.value);
                    temporaryAddress.activateTempAdr = true;
                    temporaryAddress.deactivateTempAdr = false;
                    temporaryAddress.customerNo = this.citizen.customerNumber!;
                    temporaryAddress.receivedDate = values.ReceivedDate;
                    temporaryAddress.receivedFrom = values.ReceivedFrom;
                    temporaryAddress.receivedMethod = values.ReceivedMethod;

                    temporaryAddress.zipCode =
                        this.form.controls[Translations.municipality.temporaryAddress.form.city.name].value['zipCode'];
                    temporaryAddress.cityName =
                        this.form.controls[Translations.municipality.temporaryAddress.form.city.name].value['city'];

                    temporaryAddress.subDistrictId =
                        this.form.controls[Translations.municipality.temporaryAddress.form.districts.name].value[
                            'subdistricts'
                        ];

                    temporaryAddress.deliveryDayIds = [
                        new DayAndTime({
                            dayOfWeek:
                                this.form.controls[Translations.municipality.temporaryAddress.form.deliveryDay.name]
                                    .value,
                            timeOfDay: TimeOfDay.TwelvePm
                        })
                    ];
                    if (
                        this.form.controls[Translations.municipality.temporaryAddress.form.deliveryFrequencyId.name]
                            .value === DeliveryFrequency.BiWeekly
                    ) {
                        temporaryAddress.deliveryDayIds.push(
                            new DayAndTime({
                                dayOfWeek:
                                    this.form.controls[
                                        Translations.municipality.temporaryAddress.form.biWeeklyDeliveryDay.name
                                    ].value,
                                timeOfDay: TimeOfDay.TwelvePm
                            })
                        );
                    }

                    temporaryAddress.orderDayIds = [
                        new DayAndTime({
                            dayOfWeek:
                                this.form.controls[Translations.municipality.temporaryAddress.form.orderDay.name].value,
                            timeOfDay: this.form.controls[Translations.municipality.temporaryAddress.form.timeslot.name]
                                .value
                                ? this.form.controls[Translations.municipality.temporaryAddress.form.timeslot.name]
                                      .value
                                : TimeOfDay.TwelvePm
                        })
                    ];
                    if (
                        this.form.controls[Translations.municipality.temporaryAddress.form.deliveryFrequencyId.name]
                            .value === DeliveryFrequency.BiWeekly
                    ) {
                        temporaryAddress.orderDayIds.push(
                            new DayAndTime({
                                dayOfWeek:
                                    this.form.controls[
                                        Translations.municipality.temporaryAddress.form.biWeeklyOrderDay.name
                                    ].value,
                                timeOfDay: this.form.controls[
                                    Translations.municipality.temporaryAddress.form.biWeeklyTimeslot.name
                                ].value
                                    ? this.form.controls[
                                          Translations.municipality.temporaryAddress.form.biWeeklyTimeslot.name
                                      ].value
                                    : TimeOfDay.TwelvePm
                            })
                        );
                    }

                    this.addressService
                        .activateTemporaryAddress(temporaryAddress)
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe(
                            () =>
                                (this.tableData = new MatTableDataSource(
                                    flatten(Object.values(this.formBuilder.formToTable(this.inputs, this.form.value)))
                                )),
                            (err: IntervareHttpErrorResponse) =>
                                this.dialogService.showValidationResult(err.validationErrors)
                        );
                }
            });
        }
    }

    deactivate() {
        this._openDialog().then(values => {
            if (values) {
                const temporaryAddress = DeactivateTemporaryAddressModel.fromJS(values);
                temporaryAddress.customerNo = this.citizen.customerNumber!;

                this.addressService
                    .deactivateTemporaryAddress(temporaryAddress)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe(
                        () => {
                            this.dialogService.showSnackMessage({
                                message: Translations.municipality.temporaryAddress.deactivated
                            });
                            // eslint-disable-next-line no-null/no-null
                            this.router.navigate([{ outlets: { primary: null } }]);
                        },
                        (err: IntervareHttpErrorResponse) =>
                            this.dialogService.showValidationResult(err.validationErrors)
                    );
            }
        });
    }

    private _openDialog() {
        return this.dialogService
            .openDialogWithComponent(RecievedDialogComponent)
            .afterClosed()
            .pipe(
                map(x => x as IFormValues | undefined),
                takeUntil(this.unsubscribe)
            )
            .toPromise();
    }

    private _buildForm() {
        this.inputs = [
            new FormBuilderDatepicker({
                name: Translations.municipality.temporaryAddress.form.startDate.name,
                label: Translations.municipality.temporaryAddress.form.startDate.label,
                required: true
            }),
            new FormBuilderDatepicker({
                name: Translations.municipality.temporaryAddress.form.endDate.name,
                label: Translations.municipality.temporaryAddress.form.endDate.label
            }),
            new FormBuilderSelect({
                name: Translations.municipality.temporaryAddress.form.existingAddresses.name,
                label: Translations.municipality.temporaryAddress.form.existingAddresses.label,
                options: this.temporaryAddress$,
                valueChanged: value => {
                    this._setAddressOnForm(value);
                },
                excludeFromTable: true
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.temporaryAddress.form.deliveryAddressCode.name,
                label: Translations.municipality.temporaryAddress.form.deliveryAddressCode.label,
                validation: [Validators.maxLength(10)],
                required: true
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.temporaryAddress.form.streetName.name,
                label: Translations.municipality.temporaryAddress.form.streetName.label,
                validation: [Validators.maxLength(100)],
                required: true
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.temporaryAddress.form.streetNumber.name,
                label: Translations.municipality.temporaryAddress.form.streetNumber.label,
                validation: [Validators.maxLength(20)],
                required: true
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.temporaryAddress.form.addressFloor.name,
                label: Translations.municipality.temporaryAddress.form.addressFloor.label,
                validation: [Validators.maxLength(10)]
            }),
            new FormBuilderTextInput({
                name: Translations.municipality.temporaryAddress.form.addressSide.name,
                label: Translations.municipality.temporaryAddress.form.addressSide.label,
                validation: [Validators.maxLength(10)]
            }),
            new FormBuilderCity({
                name: Translations.municipality.temporaryAddress.form.city.name,
                required: true
            }),
            new FormBuilderMunicipalityPicker({
                name: Translations.municipality.temporaryAddress.form.districts.name,
                multipleDistricts: false,
                required: true,
                valueChanged: (value: FormBuilderMunicipalityOutput) => this.municipality$.next(value.municipality)
            }),
            new FormBuilderSelect({
                name: Translations.municipality.temporaryAddress.form.driverId.name,
                label: Translations.municipality.temporaryAddress.form.driverId.label,
                required: true,
                options: this.drivers$
            }),
            new FormBuilderSelect({
                name: Translations.municipality.temporaryAddress.form.deliveryFrequencyId.name,
                label: Translations.municipality.temporaryAddress.form.deliveryFrequencyId.label,
                required: true,
                valueChanged: (value: number, form: UntypedFormGroup | undefined) => {
                    if (form) {
                        form.controls[
                            Translations.municipality.temporaryAddress.form.biWeeklyDeliveryDay.name
                        ].updateValueAndValidity();
                        form.controls[
                            Translations.municipality.temporaryAddress.form.biWeeklyOrderDay.name
                        ].updateValueAndValidity();
                        form.controls[
                            Translations.municipality.temporaryAddress.form.biWeeklyTimeslot.name
                        ].updateValueAndValidity();
                    }

                    if (value !== DeliveryFrequency.BiWeekly) {
                        MunicipalityDeliveryHelpers.updateDeliveryDays(
                            this.availableDeliveryDays$.getValue(),
                            undefined,
                            this.deliveryDays$,
                            this.biWeeklyDeliveryDays$
                        );
                    }
                },
                options: Object.values(DeliveryFrequency)
                    .filter(x => typeof x === 'string')
                    .map(x => toFormOptions(DeliveryFrequency[x], Translations.global.frequencyDelivery[x]))
            }),
            new FormBuilderSelect({
                name: Translations.municipality.temporaryAddress.form.deliveryDay.name,
                label: Translations.municipality.temporaryAddress.form.deliveryDay.label,
                required: true,
                valueChanged: (value: number) => {
                    MunicipalityDeliveryHelpers.updateDeliveryDays(
                        this.availableDeliveryDays$.getValue(),
                        value,
                        this.biWeeklyDeliveryDays$
                    );
                    MunicipalityDeliveryHelpers.updateOrderDays(value, this.orderDays$);
                },
                options: this.deliveryDays$
            }),
            new FormBuilderSelect({
                name: Translations.municipality.temporaryAddress.form.orderDay.name,
                label: Translations.municipality.temporaryAddress.form.orderDay.label,
                valueChanged: (value: number) => this._getTimeslots(value, this.timeSlots$),
                required: true,
                options: this.orderDays$
            }),
            new FormBuilderSelect({
                name: Translations.municipality.temporaryAddress.form.timeslot.name,
                label: Translations.municipality.temporaryAddress.form.timeslot.label,
                options: this.timeSlots$,
                required: true,
                condition: () => this.citizen.customerType === CustomerSubType.Ringeborger
            }),
            new FormBuilderSelect({
                name: Translations.municipality.temporaryAddress.form.biWeeklyDeliveryDay.name,
                label: Translations.municipality.temporaryAddress.form.biWeeklyDeliveryDay.label,
                condition: () =>
                    this.form
                        ? this.form.controls[Translations.municipality.temporaryAddress.form.deliveryFrequencyId.name]
                              .value === DeliveryFrequency.BiWeekly
                        : false,
                required: this.form
                    ? this.form.controls[Translations.municipality.temporaryAddress.form.deliveryFrequencyId.name]
                          .value === DeliveryFrequency.BiWeekly
                    : false,
                options: this.biWeeklyDeliveryDays$,
                valueChanged: (value: number) => {
                    MunicipalityDeliveryHelpers.updateDeliveryDays(
                        this.availableDeliveryDays$.getValue(),
                        value,
                        this.deliveryDays$
                    );
                    MunicipalityDeliveryHelpers.updateOrderDays(value, this.biWeeklyOrderDays$);
                }
            }),
            new FormBuilderSelect({
                name: Translations.municipality.temporaryAddress.form.biWeeklyOrderDay.name,
                label: Translations.municipality.temporaryAddress.form.biWeeklyOrderDay.label,
                condition: () =>
                    this.form
                        ? this.form.controls[Translations.municipality.temporaryAddress.form.deliveryFrequencyId.name]
                              .value === DeliveryFrequency.BiWeekly
                        : false,
                required: this.form
                    ? this.form.controls[Translations.municipality.temporaryAddress.form.deliveryFrequencyId.name]
                          .value === DeliveryFrequency.BiWeekly
                    : false,
                options: this.biWeeklyOrderDays$,
                valueChanged: (value: number) => this._getTimeslots(value, this.biWeeklyTimeSlots$)
            }),
            new FormBuilderSelect({
                name: Translations.municipality.temporaryAddress.form.biWeeklyTimeslot.name,
                label: Translations.municipality.temporaryAddress.form.biWeeklyTimeslot.label,
                required: this.form
                    ? this.form.controls[Translations.municipality.temporaryAddress.form.deliveryFrequencyId.name]
                          .value === DeliveryFrequency.BiWeekly &&
                      this.citizen.customerType === CustomerSubType.Ringeborger
                    : false,
                options: this.biWeeklyTimeSlots$,
                condition: () =>
                    this.form
                        ? this.form.controls[Translations.municipality.temporaryAddress.form.deliveryFrequencyId.name]
                              .value === DeliveryFrequency.BiWeekly &&
                          this.citizen.customerType === CustomerSubType.Ringeborger
                        : false
            })
        ];

        this.form = this.formBuilder.toFormGroup(this.inputs);
    }

    private _getMunicipalityData(municipalityId: number) {
        if (municipalityId) {
            this.driverService
                .getDrivers(municipalityId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(drivers => this.drivers$.next(drivers.map(x => toFormOptions(x.id, x.driverName))));

            this.municipalityService
                .getMunicipalityDeliveryDays(municipalityId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(days => this.availableDeliveryDays$.next(days));
        } else {
            this.drivers$.next([]);
            this.availableDeliveryDays$.next([]);
        }
    }

    private _getTimeslots(dayOfWeek: number, subject: Subject<FormBuilderOption[]>) {
        if (dayOfWeek) {
            this.municipalityService
                .getAvailabilities(dayOfWeek)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(timeslots => MunicipalityDeliveryHelpers.updateTimeslots(timeslots, subject));
        } else {
            subject.next([]);
        }
    }

    private _setAddressOnForm(value: number) {
        const currentAddress = this.temporaryAddressList.find(current => current.temporaryAddressId === value);

        if (currentAddress) {
            if (currentAddress.streetName) {
                this.form.controls[Translations.municipality.temporaryAddress.form.streetName.name].setValue(
                    currentAddress.streetName
                );
            }
            if (currentAddress.streetNumber) {
                this.form.controls[Translations.municipality.temporaryAddress.form.streetNumber.name].setValue(
                    currentAddress.streetNumber
                );
            }
            if (currentAddress.floor) {
                this.form.controls[Translations.municipality.temporaryAddress.form.addressFloor.name].setValue(
                    currentAddress.floor
                );
            }
            if (currentAddress.side) {
                this.form.controls[Translations.municipality.temporaryAddress.form.addressSide.name].setValue(
                    currentAddress.side
                );
            }
            if (currentAddress.zipCode) {
                this.form.controls[Translations.municipality.temporaryAddress.form.city.name].setValue({
                    zipCode: currentAddress.zipCode
                });
            }
        }
    }
}
