import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { ISkipTakeResultObjectOfOrderHistoryViewModel, OrderHistoryStatus } from '../../api/services';
import { check, cross } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { OrderService } from '../services/order.service';
import { UserService } from '../user/user.service';
import { UtilService } from '../util/util.service';

@Component({
    selector: 'iv-commerce-orders',
    template: `
        <div class="commerce-orders">
            <div class="commerce-orders__orders" *ngIf="orders && orders.numFound; else unavailable">
                <mat-accordion>
                    <mat-expansion-panel class="commerce-orders__order" *ngFor="let order of orders.results"
                        [hideToggle]="true"
                        [expanded]="queryOrderId === order.orderNo"
                        (opened)="order.open = true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h3 class="commerce-orders__order-head">
                                    {{ '${Translations.commerce.orders.order}' | translation : order.orderNo }}
                                </h3>
                            </mat-panel-title>
                            <mat-panel-description class="commerce-orders__order-delivery-day">
                                {{ '${Translations.commerce.orders.deliveryDay}' | translation : (order.deliveryDate | date) }}
                                <div class="commerce-orders__order-status" [ngSwitch]="order.orderHistoryStatus">
                                    <ng-container *ngSwitchCase="OrderHistoryStatus.Ordered">
                                        ${check}
                                        ${Translations.commerce.orders.status.ordered}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="OrderHistoryStatus.Processing">
                                        ${check}
                                        ${Translations.commerce.orders.status.processing}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="OrderHistoryStatus.Delivered">
                                        ${check}
                                        ${Translations.commerce.orders.status.delivered}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="OrderHistoryStatus.Canceled">
                                        ${cross}
                                        ${Translations.commerce.orders.status.canceled}
                                    </ng-container>
                                </div>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <iv-commerce-order *ngIf="order.open" [orderNo]="order.orderNo" (update)="getOrders(true)"></iv-commerce-order>

                    </mat-expansion-panel>
                </mat-accordion>

                <mat-paginator *ngIf="orders.numFound > paginatorSettings.take" [length]="orders.numFound" [pageSize]="paginatorSettings.take" [pageIndex]="paginatorSettings.skip / paginatorSettings.take" (page)="onPaginatorChange($event)"></mat-paginator>
            </div>

            <ng-template #unavailable>
                <div class="commerce-orders__status alt-theme" *ngIf="!orders && !validationErrors.length">
                    <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="3" [diameter]="60"></mat-progress-spinner>
                </div>

                <div class="commerce-orders__status" *ngIf="orders && !validationErrors.length">
                   ${Translations.commerce.orders.noOrders}
                </div>

                <div class="commerce-orders__status" *ngIf="validationErrors.length">
                    <div class="commerce-orders__status-error" *ngFor="let error of validationErrors">
                        {{ error }}
                    </div>
                </div>
            </ng-template>
        </div>
    `
})
export class CommerceOrdersComponent implements OnInit, OnDestroy {
    orders: ISkipTakeResultObjectOfOrderHistoryViewModel;
    queryOrderId = '';
    paginatorSettings = {
        customerNo: '',
        skip: 0,
        take: 10
    };
    validationErrors: string[] = [];
    OrderHistoryStatus = OrderHistoryStatus;

    private firstLoad = true;
    private unsubscribe: Subject<void> = new Subject();
    private _scrollContainer: HTMLElement | Window = window;

    constructor(
        private route: ActivatedRoute,
        private orderService: OrderService,
        private userService: UserService,
        private utilService: UtilService
    ) { }

    ngOnInit() {

        // Get the current order id
        this.queryOrderId = this.route.snapshot.queryParamMap.get('orderId') || '';

        // Get the current customer no
        this.paginatorSettings.customerNo = this.route.snapshot.queryParamMap.get('customerNo') || '';

        this.userService.user$.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(user => {
            if (user) {
                this._scrollContainer = (document.querySelector('#content > .util-hidden-scroll') as HTMLElement) || window;
                this.getOrders();
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getOrders(resetPage = false) {
        if (resetPage) {
            this.paginatorSettings.skip = 0;
        }

        this.orderService.getOrdersByCustomerNo(this.paginatorSettings).pipe(
            tap(() => this.validationErrors.splice(0)),
            takeUntil(this.unsubscribe)
        ).subscribe(orders => {
            if (!this.firstLoad) {
                this.queryOrderId = '';
            }
            this.orders = orders;
            this.firstLoad = false;
            this._scrollToTop();
        }, err => this.validationErrors = err.validationErrors);
    }

    onPaginatorChange(event: PageEvent) {
        this.paginatorSettings.skip = event.pageIndex * this.paginatorSettings.take;
        this.getOrders();
        this._scrollToTop();
    }

    private _scrollToTop() {
        this.utilService.scrollElementTo(this._scrollContainer, 0);
    }
}
