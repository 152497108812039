import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Translations } from '../../translations/translations';
import { IDENTITIES } from '../user/user-roles';
import { UserService } from '../user/user.service';

@Component({
    selector: 'iv-municipality-menu',
    template: `
    <iv-util-hidden-scroll height="100vh">
        <nav class="category-menu" role="navigation">
            <div class="category-menu__level">
                <a class="category-menu__link"
                    routerLinkActive="category-menu__link_active"
                    routerLink="/${Translations.municipality.paths.citizenStatus.path}">
                    ${Translations.municipality.paths.citizenStatus.title}
                </a>
            </div>
            <div class="category-menu__level">
                <a class="category-menu__link"
                    routerLinkActive="category-menu__link_active"
                    routerLink="/${Translations.municipality.paths.citizenOverview.path}">
                    ${Translations.municipality.paths.citizenOverview.title}
                </a>
            </div>
            <div class="category-menu__level">
                <a class="category-menu__link"
                    routerLinkActive="category-menu__link_active"
                    routerLink="/${Translations.municipality.paths.viewCitizens.path}">
                    ${Translations.municipality.paths.viewCitizens.title}
                </a>
            </div>
            <div class="category-menu__level">
                <a class="category-menu__link"
                    routerLinkActive="category-menu__link_active"
                    routerLink="/${Translations.municipality.paths.pdaStatistics.path}">
                    ${Translations.municipality.paths.pdaStatistics.title}
                </a>
            </div>
            <div class="category-menu__level" *ngIf="access.municipalityAdmin">
                <a class="category-menu__link"
                    routerLinkActive="category-menu__link_active"
                    routerLink="/${Translations.municipality.paths.citizenDrafts.path}">
                    ${Translations.municipality.paths.citizenDrafts.title}
                </a>
            </div>
            <ng-container *ngIf="access.userAdministration">
                <div class="category-menu__level">
                    <a class="category-menu__link"
                        routerLinkActive="category-menu__link_active"
                        routerLink="/${Translations.municipality.paths.createUser.path}">
                        ${Translations.municipality.paths.createUser.title}
                    </a>
                </div>
                <div class="category-menu__level">
                    <a class="category-menu__link"
                        routerLinkActive="category-menu__link_active"
                        routerLink="/${Translations.municipality.paths.userAdministration.path}">
                        ${Translations.municipality.paths.userAdministration.title}
                    </a>
                </div>
            </ng-container>
        </nav>
    </iv-util-hidden-scroll>
  `
})
export class MunicipalityMenuComponent implements OnInit, OnDestroy {

    access = {
        userAdministration: false,
        municipalityAdmin: false
    };

    private unsubscribe = new Subject<void>();

    constructor(private userService: UserService) { }

    ngOnInit() {
        this.userService.activeRoles.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(roles => {
            this.access.userAdministration = roles.some(x => IDENTITIES.editUsers.includes(x));
            this.access.municipalityAdmin = roles.some(x => IDENTITIES.municipalityAdmin.includes(x));
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
