import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IOrderHistoryViewModel, OrderHistoryViewModel } from '../../api/services';
import { check } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { CURRENCY_CODE } from '../app.locale_data';
import { OrderService } from '../services/order.service';
import { BASKETIDENTITIES } from '../user/user-roles';
import { UserService } from '../user/user.service';

@Component({
    selector: 'iv-commerce-receipt',
    template: `
        <div class="commerce-receipt">
            <div class="commerce-receipt__container" *ngIf="order && order !== 'orderNotFound'; else unavailable">
                <div class="commerce-receipt__check">${check}</div>

                <h1 class="commerce-receipt__head">${Translations.commerce.order.orderHead}</h1>

                <div class="commerce-receipt__delivery">
                    <ng-container *ngIf="municipalityUser; else citizen">
                        {{ '${Translations.commerce.order.delivery.municipality}' | translation : customerName : (order.deliveryDate | date) }}
                    </ng-container>
                    <ng-template #citizen>
                        {{ '${Translations.commerce.order.delivery.citizen}' | translation : (order.deliveryDate | date) }}
                    </ng-template>
                </div>

                <a routerLink="/pda" class="commerce-receipt__continue-shopping" mat-raised-button color="primary" *ngIf="municipalityUser">
                    ${Translations.commerce.order.continueShopping}
                </a>

                <div class="commerce-receipt__info">
                    <h3 class="commerce-receipt__sub-head">${Translations.commerce.order.info.head}</h3>

                    <div class="commerce-receipt__row">
                        <div class="commerce-receipt__info-key">${Translations.commerce.order.info.id}&nbsp;</div>
                        <div class="commerce-receipt__info-value">{{ order.orderNo }}</div>
                    </div>

                    <div class="commerce-receipt__row">
                        <div class="commerce-receipt__info-key">${Translations.commerce.order.info.total}&nbsp;</div>
                        <div class="commerce-receipt__info-value">{{ order.amount | currency : currencyCode : 'symbol-narrow' }}</div>
                    </div>
                </div>

                <div class="commerce-receipt__order">
                    <h3 class="commerce-receipt__sub-head">${Translations.commerce.order.summary.head}</h3>

                    <iv-commerce-orders-rows [rows]="order.rows"></iv-commerce-orders-rows>

                    <iv-commerce-summary [data]="order"></iv-commerce-summary>
                </div>

                <a routerLink="/" class="commerce-receipt__go-to-frontpage" mat-raised-button color="primary" *ngIf="!municipalityUser">
                    ${Translations.commerce.order.goToFrontpage}
                </a>
            </div>

            <ng-template #unavailable>
                <div class="commerce-receipt__status alt-theme" *ngIf="!order">
                    <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="3" [diameter]="60"></mat-progress-spinner>
                </div>

                <div class="commerce-receipt__status" *ngIf="order === 'orderNotFound'">
                    ${Translations.commerce.order.notFound}
                </div>
            </ng-template>
        </div>
    `
})
export class CommerceReceiptComponent implements OnInit, OnDestroy {
    order: IOrderHistoryViewModel | 'orderNotFound';
    municipalityUser = false;
    customerName = '';

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        @Inject(CURRENCY_CODE) public currencyCode: string,
        private route: ActivatedRoute,
        private orderService: OrderService,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.route.queryParamMap.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(params => {
            const orderNo = params.get('orderNo');
            if (orderNo) {
                this.orderService.getOrderByNo(orderNo).pipe(
                    takeUntil(this.unsubscribe)
                ).subscribe(orderViewModel => {
                    this.order = orderViewModel;
                    this._setCustomerName();
                }, () => this.order = 'orderNotFound');
            }
            else {
                this.order = 'orderNotFound';
            }
        });

        this.userService.activeRoles.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(userRoles => this.municipalityUser = userRoles.some(x => BASKETIDENTITIES.municipality.includes(x)));
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private _setCustomerName() {
        if (this.order instanceof OrderHistoryViewModel) {
            this.customerName += this.order.customerFirstName ? this.order.customerFirstName : '';
            this.customerName += this.order.customerMiddleName ? ' ' + this.order.customerMiddleName : '';
            this.customerName += this.order.customerLastName ? ' ' + this.order.customerLastName : '';
        }
    }
}
