import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { ProductService } from '../services/product.service';
import { Translations } from '../../translations/translations';
import { IMagazineContainerViewModel } from '../../api/services';
import { environment } from '../../environments/environment.prod';

@Component({
    selector: 'iv-commerce-catalogues',
    template: `
        <section class="commerce-catalogues" *ngIf="productCatalogues; else loading">
            <mat-accordion class="commerce-catalogues__catalogues">
                <mat-expansion-panel
                    [expanded]="catalogues.year === currentYear"
                    *ngFor="let catalogues of productCatalogues | async"
                    hideToggle="true"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ catalogues.year }}</mat-panel-title>
                    </mat-expansion-panel-header>

                    <section class="commerce-catalogues__catalogues-content">
                        <ng-container *ngIf="catalogues.specialCatalogues.length > 0">
                            <h3 class="commerce-catalogues__head">
                                {{ '${Translations.commerce.catalogues.specialLabel}' }}
                            </h3>
                            <ul class="commerce-catalogues__items">
                                <li
                                    class="commerce-catalogues__items-item"
                                    *ngFor="let special of catalogues.specialCatalogues"
                                >
                                    <a
                                        mat-button
                                        color="basic"
                                        target="_blank"
                                        title="{{ special.name }}"
                                        href="${environment.apiBaseUrl}/api/Magasine/GetWeekMagasinePdf?path={{
                                            special.url
                                        }}"
                                        >{{ special.name }}</a
                                    >
                                </li>
                            </ul>
                        </ng-container>

                        <ng-container *ngIf="catalogues.weekCatalogues.length > 0">
                            <h3 class="commerce-catalogues__head">
                                {{ '${Translations.commerce.catalogues.weekLabel}' }}
                            </h3>
                            <ul class="commerce-catalogues__items">
                                <li
                                    class="commerce-catalogues__items-item"
                                    *ngFor="let week of catalogues.weekCatalogues"
                                >
                                    <a
                                        mat-button
                                        color="basic"
                                        target="_blank"
                                        title="{{ week.name }}"
                                        href="${environment.apiBaseUrl}/api/Magasine/GetWeekMagasinePdf?path={{
                                            week.url
                                        }}"
                                        >{{ week.name }}</a
                                    >
                                </li>
                            </ul>
                        </ng-container>
                    </section>
                </mat-expansion-panel>
            </mat-accordion>
        </section>

        <ng-template #loading>
            <div class="commerce-catalogues__loading">
                <mat-progress-spinner
                    color="accent"
                    mode="indeterminate"
                    [strokeWidth]="3"
                    [diameter]="60"
                ></mat-progress-spinner>
            </div>
        </ng-template>
    `,
    styles: []
})
export class CommerceCataloguesComponent implements OnInit {
    productCatalogues: Observable<IMagazineContainerViewModel[]>;
    currentYear: number;

    constructor(private productService: ProductService) {}

    ngOnInit() {
        this.currentYear = new Date().getFullYear();
        this.productCatalogues = this.productService.getProductCatalogues();
    }
}
