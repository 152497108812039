import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MaterialUiModule } from '../material-ui/material-ui.module';
import { ServicesModule } from '../services/services.module';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './login.component';
import { NewPasswordComponent } from './new-password.component';
import { UserRoutingModule } from './user-routing.module';
import { UserService } from './user.service';
import { UserEditPasswordComponent } from './user-edit-password.component';
import { AuthGuard } from './auth.guard';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MaterialUiModule,
        ServicesModule,
        SharedModule,
        UserRoutingModule
    ],
    declarations: [
        LoginComponent,
        NewPasswordComponent,
        UserEditPasswordComponent
    ],
    exports: [
        LoginComponent,
        UserEditPasswordComponent
    ]
})
export class UserModule {
    static forRoot(): ModuleWithProviders<UserModule> {
        return {
            ngModule: UserModule,
            providers: [
                UserService,
                AuthGuard
            ]
        };
    }

    static forChild(): ModuleWithProviders<UserModule> {
        return {
            ngModule: UserModule
        };
    }
}
