import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Translations } from '../../translations/translations';
import { DialogOutletComponent } from '../shared/dialog-outlet.component';
import { AuthGuard } from '../user/auth.guard';
import { ROLES } from '../user/user-roles';
import { NotesAdminCreateCauseComponent } from './notes-admin-create-cause.component';
import { NotesAdminCreateSubcauseComponent } from './notes-admin-create-subcause.component';
import { NotesAdminCreateSubSubCauseComponent } from './notes-admin-create-subsubcause.component';
import { NotesAdminCauseResolver } from './resolvers/notes-admin-cause.resolver';
import { NotesAdminSubCauseResolver } from './resolvers/notes-admin-subcause.resolver';
import { NotesAdminSubSubCauseResolver } from './resolvers/notes-admin-subsubcause.resolver';

export const notesAdmin: Routes = [
    {
        path: Translations.intervare.paths.notesAdmin.path + '/cause/:id',
        component: DialogOutletComponent,
        outlet: 'dialog',
        resolve: {
            data: NotesAdminCauseResolver
        },
        data: {
            allowedRoles: [ROLES.Admin],
            dialogComponent: NotesAdminCreateCauseComponent
        },
        canActivate: [AuthGuard]
    },
    {
        path: Translations.intervare.paths.notesAdmin.path + '/subCause/:causeId/:id',
        component: DialogOutletComponent,
        outlet: 'dialog',
        resolve: {
            data: NotesAdminSubCauseResolver
        },
        data: {
            allowedRoles: [ROLES.Admin],
            dialogComponent: NotesAdminCreateSubcauseComponent
        },
        canActivate: [AuthGuard]
    },
    {
        path: Translations.intervare.paths.notesAdmin.path + '/subSubCause/:subCauseId/:id',
        component: DialogOutletComponent,
        outlet: 'dialog',
        resolve: {
            data: NotesAdminSubSubCauseResolver
        },
        data: {
            allowedRoles: [ROLES.Admin],
            dialogComponent: NotesAdminCreateSubSubCauseComponent
        },
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(notesAdmin)],
    exports: [RouterModule]
})
export class NotesAdminRoutingModule { }
