import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { merge, of, Subject } from 'rxjs';
import { catchError, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { ICustomerLedgerViewModel, LedgerEntryTypeEnum } from '../../api/services';
import { Translations } from '../../translations/translations';
import { CURRENCY_CODE } from '../app.locale_data';
import { CitizenService } from '../services/citizen.service';

@Component({
    selector: 'iv-citizen-details-debtorposts',
    template: `
        <article class="citizen-debtorposts">

            <div class="mat-table__shade" *ngIf="isLoadingResults">
                <mat-spinner></mat-spinner>
            </div>

            <mat-table #table class="citizen-debtorposts__table" [dataSource]="dataSource">
                <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef>${Translations.administration.citizenDebtorPosts.columns.date}</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{ row.accountingDate | date: 'shortDate' }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef>${Translations.administration.citizenDebtorPosts.columns.type}</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{ entryType(row.ledgerEntryType) }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="appendixNo">
                    <mat-header-cell *matHeaderCellDef>${Translations.administration.citizenDebtorPosts.columns.typeNo}</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{ row.appendixNo }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef>${Translations.administration.citizenDebtorPosts.columns.description}</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{ row.ledgerEntryDescription }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="amount">
                    <mat-header-cell *matHeaderCellDef>${Translations.administration.citizenDebtorPosts.columns.amount}</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{ row.amount | currency : currencyCode : 'symbol-narrow' }}</mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <mat-paginator [length]="resultsLength" [pageSize]="10" [showFirstLastButtons]="true"></mat-paginator>

            <div class="citizen-debtorposts__no-results" *ngIf="!isLoadingResults && !dataSource.data.length">${Translations.administration.citizenDebtorPosts.noRecords}</div>
        </article>
    `
})
export class CitizenDetailsDebtorpostsComponent implements OnInit, OnDestroy {

    @Input() customerNo: string;

    displayedColumns = ['date', 'type', 'appendixNo', 'description', 'amount'];
    dataSource = new MatTableDataSource<ICustomerLedgerViewModel>();

    isLoadingResults = true;
    resultsLength = 0;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    private unsubscribe = new Subject<void>();

    constructor(
        @Inject(CURRENCY_CODE) public currencyCode: string,
        private citizenService: CitizenService
    ) { }

    ngOnInit() {
        merge(this.paginator.page).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.citizenService.getCustomerLedgerRecords(this.customerNo, (this.paginator.pageIndex * this.paginator.pageSize) || 0, this.paginator.pageSize || 10);
            }),
            map(data => {
                this.isLoadingResults = false;
                this.resultsLength = data.numFound;
                return data.results || [];
            }),
            catchError((err) => {
                this.isLoadingResults = false;
                return of<ICustomerLedgerViewModel[]>([]);
            }),
            takeUntil(this.unsubscribe)
        ).subscribe(data => this.dataSource.data = data);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    entryType(type: LedgerEntryTypeEnum) {
        switch (type) {
            case LedgerEntryTypeEnum.Invoice:
                return Translations.administration.citizenDebtorPosts.types.invoice;

            case LedgerEntryTypeEnum.CreditNote:
                return Translations.administration.citizenDebtorPosts.types.creditNote;

            case LedgerEntryTypeEnum.Payment:
                return Translations.administration.citizenDebtorPosts.types.payment;

            case LedgerEntryTypeEnum.Refund:
                return Translations.administration.citizenDebtorPosts.types.refund;

            case LedgerEntryTypeEnum.Reminder:
                return Translations.administration.citizenDebtorPosts.types.reminder;

            default:
                return '';
        }
    }

}
