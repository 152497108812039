import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

import { RouteService } from '../shared/route.service';
import { IUserRoles, ROLES } from './user-roles';
import { UserService } from './user.service';

/**
 * The Auth Guard should be used to mark router paths as locked behind authentication
 * and may optionally require specific roles to access.
 * It will look for the "allowedRoles" data property for the route and validate those against the user.
 *
 * Default roles: *
 *
 * @export
 * @class AuthGuard
 * @implements {CanActivate}
 */
@Injectable()
export class AuthGuard  {

    canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
        const allowedRoles: IUserRoles[] = next.data.allowedRoles || [];

        return this.userService.activeRoles.pipe(
            map(roles => {
                // No allowedRoles = allow logged-in access only
                if (!allowedRoles.length) {
                    return roles.length > 0;
                }

                // Roles = logged in
                if (roles.length) {
                    return roles.some(x => allowedRoles.includes(x));
                }

                // Logged out - Allow only anonymous access
                else if (allowedRoles.includes(ROLES.Anonymous)) {
                    return true;
                }

                return false;
            }),
            tap(result => !result && this.routeService.isFirstLoad && this.router.navigate(['/'])),
            first()
        );
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean> {
        return this.canActivate(childRoute);
    }

    constructor(
        private userService: UserService,
        private router: Router,
        private routeService: RouteService,
        private location: Location
    ) { }
}
