import { Component, OnInit } from '@angular/core';
import { Translations } from '../../translations/translations';
import { environment } from '../../environments/environment';
import * as Raven from 'raven-js';

@Component({
    selector: 'iv-page-not-found',
    template: `
        <div class="page-not-found">
            <h1 class="page-not-found__title">
                ${Translations.global.pageNotFound.title}
            </h1>

            <div class="page-not-found__text">
                ${Translations.global.pageNotFound.text}
            </div>

            <a [routerLink]="['', { outlets: { primary: null } }]" class="page-not-found__btn" mat-raised-button color="primary">
                ${Translations.global.pageNotFound.goToFrontPage}
            </a>
        </div>
    `
})
export class PageNotFoundComponent implements OnInit {
    ngOnInit() {
        if (environment.production) {
            try {
                Raven.captureException(`404 - Not Found: ${location.href}`);
            }
            catch { }
        }
    }
}
