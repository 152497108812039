import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import * as Raven from 'raven-js';
import { EMPTY, Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { IDetailedCitizenViewModel } from '../../../api/services';
import { CitizenService } from '../../services/citizen.service';

export function CitizenResolver(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<IDetailedCitizenViewModel> {
    const citizenService: CitizenService = inject(CitizenService);
    const citizenNo = activatedRouteSnapshot.paramMap.get('id');

    try {
        if (citizenNo) {
            return citizenService.getCitizenDetails(citizenNo).pipe(
                catchError(() => {
                    return EMPTY;
                })
            );
        }

        Raven.captureMessage(
            `Citizen error: Tried to access citizen with no id on path "${activatedRouteSnapshot.pathFromRoot}"`
        );
        return throwError('No citizen number found');
    } catch {
        return throwError('No citizen number found');
    }
}
