import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinSecurityRoles'
})
export class JoinSecurityRolesPipe implements PipeTransform {

    transform(value: string[] | undefined, hide: boolean = false): string {
        if (!value || value.length === 0) {
            return '';
        }

        if (hide) {
            const [first, ...rest] = value;
            if (rest.length >= 1) {
                return `${first} (+${rest.length})`;
            }

            return first || '';
        }

        return value.join(', ');
    }
}
