import { Component } from '@angular/core';

import { MenuService } from '../services/menu.service';

@Component({
    selector: 'iv-category-menu',
    template: `
        <nav role="navigation" class="category-menu">
            <iv-category-menu-level [category]="category" *ngFor="let category of (menuService.getCategoryMenu() | async)?.categories"></iv-category-menu-level>
        </nav>
    `
})
export class CategoryMenuComponent {
    constructor(public menuService: MenuService) { }
}
