import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';

import { ICaller, ICreateCallListModel, IUpdateCallListModel } from '../../api/services';
import { Translations } from '../../translations/translations';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { CallListAdminService } from '../services/call-list-admin.service';
import { DialogService } from '../shared/dialog.service';
import { filter, switchMapTo, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'iv-edit-call-list',
    template: `
    <form (submit)="onSubmit()" #form="ngForm">
        <h1 mat-dialog-title>{{ editMode ? '${Translations.callListAdmin.editCallList.edit}' : '${Translations.callListAdmin.editCallList.add}' }} ${Translations.callListAdmin.editCallList.header}</h1>
        <mat-dialog-content class="low-density-dialog-content">

            <mat-form-field>
                <input matInput placeholder="${Translations.callListAdmin.editCallList.name}" [(ngModel)]="callList.name" name="name" required>
            </mat-form-field>
            <br/>
            <mat-form-field>
                <mat-select placeholder="${Translations.callListAdmin.editCallList.primaryCaller}" [(ngModel)]="callList.actualCallerProfileId" name="actualCallerProfileId" required>
                    <mat-option *ngFor="let caller of callers | async" [value]="caller.callerProfileId">
                        {{ caller.firstName + ' ' + caller.lastName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <br/>
            <mat-form-field>
                <mat-select placeholder="${Translations.callListAdmin.editCallList.actualCaller}" [(ngModel)]="callList.plannedCallerProfileId" name="plannedCallerProfileId" required>
                    <mat-option *ngFor="let caller of callers | async" [value]="caller.callerProfileId">
                        {{ caller.firstName + ' ' + caller.lastName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions class="alt-theme">
            <button mat-raised-button color="warn" type="button" (click)="onDelete()" *ngIf="editMode">${Translations.callListAdmin.editCallList.deleteBtn}</button>
            <button mat-raised-button type="button" mat-dialog-close>${Translations.callListAdmin.editCallList.cancelBtn}</button>
            <button mat-raised-button color="primary" [disabled]="!form.valid">${Translations.callListAdmin.editCallList.okBtn}</button>
        </mat-dialog-actions>
    </form>
    `
})
export class EditCallListComponent implements OnInit, OnDestroy {

    callList: IUpdateCallListModel | ICreateCallListModel;
    editMode = false;
    id?: number;
    callers: Observable<ICaller[]>;

    private unsubscribeS$: Subject<void> = new Subject();

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: IUpdateCallListModel | ICreateCallListModel,
        private dialogRef: MatDialogRef<EditCallListComponent>,
        private dialogService: DialogService,
        private callListAdminService: CallListAdminService
    ) {}

    ngOnInit() {
        this.callList = this.data;
        if ((this.callList as IUpdateCallListModel).callListId) {
            this.id = (this.callList as IUpdateCallListModel).callListId;
            this.editMode = true;
        }
        this.callers = this.callListAdminService.getCallers();
    }

    ngOnDestroy(): void {
        this.unsubscribeS$.next();
        this.unsubscribeS$.complete();
    }

    onSubmit() {
        this.callListAdminService.saveCallList(this.callList)
        .pipe(takeUntil(this.unsubscribeS$))
        .subscribe(
            () => {
                this.callListAdminService.getCallLists();
                this.dialogRef.close();
            },
            (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
        );
    }

    onDelete() {
        this.dialogService.confirm(Translations.callListAdmin.editCallList.confirmText).afterClosed().pipe(
            filter((res: boolean) => res),
            switchMapTo(this.callListAdminService.deleteCallList(this.id!)),
            takeUntil(this.unsubscribeS$)
        ).subscribe(
            () => {
                this.callListAdminService.getCallLists();
                this.dialogRef.close();
            },
            () => this.dialogService.showSnackMessage({ message: Translations.callListAdmin.editCallList.messageError }));
    }
}
