import { ICategory, ISuggestionsViewModel } from '../../api/services';
import { CategoryHelper } from '../services/menu-models';

export class SuggestionsCategory {

    name: string;
    url: string;

    static fromDto(category: ICategory): SuggestionsCategory {

        const suggestionsCategory = new SuggestionsCategory();

        suggestionsCategory.name = `${category.name}`.replace(/¤/gi, ' / ');
        suggestionsCategory.url = CategoryHelper.generateUrl(category.menuPath);

        return suggestionsCategory;
    }
}

export class Suggestions {
    categories: SuggestionsCategory[] = [];
    suggestions: string[] = [];

    static fromDto(vm: ISuggestionsViewModel): Suggestions {
        const suggestions = new Suggestions();
        suggestions.suggestions = vm.suggestions || [];
        suggestions.categories = [];

        if (vm.categories) {
            suggestions.categories = vm.categories
                .map((category) => SuggestionsCategory.fromDto(category));
        }

        return suggestions;
    }
}
