import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { DriverMessage, DriverMessageBase, IDriverMessage } from '../../api/services';
import { Translations } from '../../translations/translations';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { CitizenService } from '../services/citizen.service';
import { DialogService } from '../shared/dialog.service';
import { UserService } from '../user/user.service';

@Component({
    selector: 'iv-municipality-driver-messages',
    template: `
        <div class="municipality-driver-messages">
            <ng-container *ngIf="states.impersonated; else unavailable">
                <form class="municipality-driver-messages__form">
                    <div class="municipality-driver-messages__form-field">
                        <mat-form-field>
                            <input #contentTemp matInput type="text" name="content"
                                   placeholder="${Translations.municipality.driverMessages.placeholderTemp}">
                        </mat-form-field>
                        <button mat-raised-button color="primary" type="button"
                                (click)="onAdd(contentTemp.value, true); contentTemp.value = '';">
                            ${Translations.municipality.driverMessages.btnTemporaryAdd}
                        </button>
                    </div>
                    <div class="municipality-driver-messages__form-field">
                        <mat-form-field>
                            <input #contentPerm matInput type="text" name="content"
                                   placeholder="${Translations.municipality.driverMessages.placeholderPerm}">
                        </mat-form-field>
                        <button mat-raised-button color="primary" type="button"
                                (click)="onAdd(contentPerm.value, false); contentPerm.value = '';">
                            ${Translations.municipality.driverMessages.btnPermanentAdd}
                        </button>
                    </div>
                </form>

                <div class="municipality-driver-messages__msg-area">
                    <table class="municipality-driver-messages__table" *ngIf="messages.length; else empty">
                        <thead>
                        <tr>
                            <th>${Translations.municipality.driverMessages.headerContent}</th>
                            <th>${Translations.municipality.driverMessages.headerIsTemporary}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let message of messages">
                            <td [class.strikethrough]="deleteMessages | includesDataValue: message.driverMessageId">
                                {{ message.content }}
                            </td>
                            <td>{{ message.isTemporary ? '${Translations.global.textSet.yes}' : '' }}</td>
                            <td>
                                <button
                                    *ngIf="message.driverMessageId && !(deleteMessages | includesDataValue: message.driverMessageId)"
                                    mat-raised-button
                                    color="warn"
                                    (click)="onDelete(message.driverMessageId)"
                                >
                                    ${Translations.municipality.driverMessages.btnDelete}
                                </button>
                                <button
                                    *ngIf="(deleteMessages | includesDataValue: message.driverMessageId)"
                                    mat-raised-button
                                    color="accent"
                                    (click)="onUndo(message.driverMessageId)"
                                    style="margin-right: 16px"
                                >
                                    ${Translations.municipality.driverMessages.undo}
                                </button>
                                <button
                                    *ngIf="!message.driverMessageId || (deleteMessages | includesDataValue: message.driverMessageId)"
                                    mat-raised-button
                                    disabled
                                    color="warn"
                                >
                                    ${Translations.municipality.driverMessages.unsynchronized}
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="municipality-driver-messages__controls">
                    <button
                        (click)="submitMessages()"
                        [disabled]="!hasNewMessages && !deleteMessages.length"
                        mat-raised-button
                        color="primary"
                    >
                        ${Translations.municipality.driverMessages.btnSubmitMessages}
                    </button>
                </div>
            </ng-container>

            <ng-template #empty>
                <p>${Translations.municipality.driverMessages.noMessages}</p>
            </ng-template>

            <ng-template #unavailable>
                <div class="municipality-driver-messages__status">
                    ${Translations.commerce.basket.impersonate}
                </div>
            </ng-template>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MunicipalityDriverMessagesComponent implements OnInit, OnDestroy {
    messages: IDriverMessage[] = [];
    hasNewMessages = false;
    deleteMessages: number[] = [];

    states = {
        impersonated: false
    };

    private citizenNo?: string;

    private unsubscribe = new Subject<void>();

    constructor(
        private cd: ChangeDetectorRef,
        private userService: UserService,
        private citizenService: CitizenService,
        private dialogService: DialogService
    ) {
    }

    ngOnInit() {
        this.userService.isImpersonating.pipe(
            tap(quickInfo => {
                this.states.impersonated = !!(quickInfo && quickInfo.customerNumber);
            }),
            filter(() => this.states.impersonated),
            map(quickInfo => quickInfo!.customerNumber!),
            takeUntil(this.unsubscribe)
        ).subscribe(citizenNo => {
            this.citizenNo = citizenNo;
            this.getMessages();
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getMessages(): void {
        if (!this.citizenNo) {
            return;
        }

        this.citizenService.getDriverMessages(this.citizenNo).pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(messages => {
            this.hasNewMessages = false;
            this.deleteMessages = [];
            this.messages = messages;
            this.cd.markForCheck();
        });
    }

    onAdd(content: string, isTemporary: boolean): void {
        const message = new DriverMessage();
        message.content = content;
        message.isTemporary = isTemporary;

        this.messages.push(message);

        this.hasNewMessages = true;
        this.cd.detectChanges();
    }

    onDelete(driverMessageId: number): void {
        this.deleteMessages.push(driverMessageId);
        this.cd.detectChanges();
    }

    onUndo(driverMessageId: number): void {
        const index = this.deleteMessages.indexOf(driverMessageId);
        if (index > -1) {
            this.deleteMessages.splice(index, 1);
        }
    }

    submitMessages(): void {
        const newMessages: DriverMessageBase[] = [];

        this.messages.forEach(message => {
            if (!message.driverMessageId) {
                const driverMessageBase = new DriverMessageBase();
                driverMessageBase.content = message.content;
                driverMessageBase.isTemporary = message.isTemporary;

                newMessages.push(driverMessageBase);
            }
        });

        this.citizenService.updateDriverMessage({
            customerNo: this.citizenNo,
            newDriverMessages: newMessages,
            deleteMessagesIds: this.deleteMessages
        })
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.dialogService.showSnackMessage({message: Translations.municipality.driverMessages.msgAdd});
                this.getMessages();
            }, (err: IntervareHttpErrorResponse) => {
                this.dialogService.showValidationResult(err.validationErrors);
                this.getMessages();
            });
    }
}
