import { Component, OnInit } from '@angular/core';

import { ICitizenQuickInfo } from '../../api/services';
import { Translations } from '../../translations/translations';
import { CallListService } from '../services/call-list.service';
import { UserService } from '../user/user.service';

@Component({
    selector: 'iv-administration-call-info',
    template: `
    <div *ngIf="impersonatingUser" class="call-section">
        <h4 class="call-section-header">${Translations.administration.callSection.currentUser}</h4>
        <div class="call-section-info">
            <h3 class="call-section-info-header"><a [routerLink]="['', { outlets: { admin: ['citizen', impersonatingUser.customerNumber, ''] } }]">{{ impersonatingUser.name }}</a></h3>
            <h4 class="call-section-info-header">{{ impersonatingUser.phoneNumber | chunk }}</h4>
        </div>
    </div>
  `
})
export class AdministrationCallInfoComponent implements OnInit {

    impersonatingUser?: ICitizenQuickInfo;

    constructor(
        private userService: UserService,
        private callListService: CallListService
    ) { }

    ngOnInit() {
        this.userService.isImpersonating
            .subscribe(isImpersonating => this.impersonatingUser = isImpersonating);
    }
}
