import { Injectable, inject } from '@angular/core';
import { Translations } from 'Client/translations/translations';
import { Observable, throwError, from, ReplaySubject, of, combineLatest } from 'rxjs';
import { switchMap, catchError, map, mapTo, take } from 'rxjs/operators';
import { GenesysService } from '../genesys/genesys.service';
import { Params, Router } from '@angular/router';
import { IUserViewModel, RealTimeInboundCallEvent } from 'Client/api/services';
import { IDENTITIES, IUserRoles } from 'Client/app/user/user-roles';
import { DialogService } from 'Client/app/shared/dialog.service';
import { GenesysConversationErrorDetails } from '../genesys/genesys.model';
import { CallEventMessage, EventSourceReadyState } from './calls.models';
import { CallListService } from '../call-list.service';
import axios, { AxiosError } from 'axios';
import { UserService } from 'Client/app/user/user.service';

@Injectable({
    providedIn: 'root'
})
export class CallsService {
    private eventSource?: EventSource;
    private genesysService = inject(GenesysService);
    private dialogService = inject(DialogService);
    private router = inject(Router);
    private callListService = inject(CallListService);
    private userService = inject(UserService);
    private callMessage = new ReplaySubject<CallEventMessage>(1);
    private readonly CancelSubscriptionMessage = 'CANCEL_SUBSCRIPTION';

    public callMessage$ = this.callMessage.asObservable();

    init(user?: IUserViewModel): void {
        const isCustomerServiceUser = user?.roles?.some(x =>
            IDENTITIES.adminAndCustomerService.includes(x as IUserRoles)
        );

        if (!this.eventSource && isCustomerServiceUser && user?.hasCallerProfile) {
            this.connect();
        }
    }

    callCitizen(customerNo: string, phoneNumber: string): Observable<boolean> {
        return combineLatest([this.genesysService.conversationSettings$, this.userService.user$]).pipe(
            take(1),
            switchMap(([{ useListOfAllowedPhoneNumbers, listOfAllowedPhoneNumbers }, user]) => {
                if (useListOfAllowedPhoneNumbers && !listOfAllowedPhoneNumbers?.includes(phoneNumber)) {
                    console.warn('Phone number not allowed', phoneNumber);
                    return throwError({ message: Translations.genesys.errors.phoneNotAllowed });
                }

                if (!user?.hasCallerProfile) {
                    console.warn('User does not have caller profile');
                    return throwError({ message: Translations.genesys.errors.noCallerProfile });
                }

                return from(this.genesysService.conversationsApiInstance.postConversationsCalls({ phoneNumber })).pipe(
                    switchMap(() => {
                        return this.callListService.logCitizenCall(customerNo, phoneNumber).pipe(
                            mapTo(true),
                            catchError(err => {
                                console.warn('Error logging citizen call', err);
                                return of(false);
                            })
                        );
                    }),
                    catchError((err: GenesysConversationErrorDetails | AxiosError) => {
                        if (axios.isAxiosError(err) && err.response?.status === 401) {
                            console.warn(
                                'There was a failure calling postConversationsCalls regarding authentication',
                                err
                            );
                            this.genesysService.authenticate();
                            return of();
                        }
                        console.warn('There was a failure calling postConversationsCalls', err);
                        return throwError(err);
                    })
                );
            }),
            map(() => true)
        );
    }

    checkCallStatus(params: Params, user?: IUserViewModel): Observable<boolean> {
        return this.genesysService.checkAuthentication(params, user);
    }

    validateCallEvent(callEventMessage: CallEventMessage): void {
        if (callEventMessage?.message) {
            const { message } = callEventMessage;

            if (message?.citizens && message?.citizens.length > 1) {
                this.showCallEventError(
                    Translations.replaceTokens(Translations.genesys.errors.multiplePhoneNumber, message.phoneNumber)
                );
                return;
            }

            if (message?.citizens && message?.citizens.length === 1) {
                const citizen = message?.citizens[0];

                this.router.navigate([
                    '',
                    { outlets: { admin: ['citizen', citizen.customerNo, '', message.inboundCallNotificationId] } }
                ]);
            }
        }

        if (callEventMessage?.error) {
            this.showCallEventError(callEventMessage.error);
        }
    }

    showCallEventError(message: string): void {
        this.dialogService.showValidationResult([message ?? '']);
    }

    disconnect(): void {
        if (!this.eventSource) {
            return;
        }
        this.eventSource.removeEventListener('message', this.onMessage.bind(this));
        this.eventSource.removeEventListener('error', this.onError.bind(this));
        this.eventSource.close();
        this.eventSource = undefined;
    }

    private connect(): void {
        console.log('eventSource connecting...');
        const url = `${location.protocol}//${location.host}/api/RealTimeCommunications/Subscribe`;
        const eventSource = new EventSource(url);

        eventSource.addEventListener('message', this.onMessage.bind(this));
        eventSource.addEventListener('error', this.onError.bind(this));

        this.eventSource = eventSource;
    }

    private onMessage = (event: MessageEvent) => {
        console.warn('eventSource on message', event);

        if (event.data === this.CancelSubscriptionMessage) {
            console.warn('Canceling subscription...');
            this.disconnect();
            return;
        }

        const messageData = JSON.parse(event.data);
        const realTimeInboundCallEvent = messageData.map(({ Data }) => RealTimeInboundCallEvent.fromJS(Data));

        this.callMessage.next({
            type: 'Success',
            readyState: EventSourceReadyState.OPEN,
            message: realTimeInboundCallEvent[0]
        });
    };

    private onError = (event: Event) => {
        console.warn('eventSource on error', event);

        this.callMessage.next({
            type: 'Error',
            readyState: EventSourceReadyState.CLOSED,
            error: Translations.genesys.errors.sourceEvent
        });
    };
}
