import { Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as MediumEditor from 'medium-editor';
import { CoreOptions } from 'medium-editor';

@Component({
    selector: 'iv-form-builder-elements-richtext',
    template: ` <div class="form__element medium-editor" #host></div> `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormBuilderElementsRichtextComponent),
            multi: true
        }
    ]
})
export class FormBuilderElementsRichtextComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @ViewChild('host', { static: true }) host: ElementRef;

    @Input() options: CoreOptions;
    @Input() placeholder: string;
    @Input() required = false;

    private editor: MediumEditor.MediumEditor;

    ngOnInit() {
        this.options =
            typeof this.options === 'string'
                ? JSON.parse(this.options)
                : typeof this.options === 'object'
                ? this.options
                : {};
        if (this.placeholder && this.placeholder !== '') {
            if (this.required) {
                this.placeholder += ' *';
            }
            Object.assign(this.options, {
                placeholder: { text: this.placeholder }
            });
        }
        this.editor = new MediumEditor(this.host.nativeElement, this.options);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.editor.subscribe('editableInput', () => {
            let value = this.editor['elements'][0].innerHTML;

            if (value === '<p><br></p>') {
                value = '';
            }

            this.onChange(value);
        });
    }

    ngOnDestroy() {
        if (this.editor) {
            this.editor.destroy();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    propagateChange = (_: unknown) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    propagateTouched = () => {};

    writeValue(value: string) {
        if (this.editor && value && value !== '') {
            this.editor.setContent(value);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnTouched(fn: any) {
        this.propagateTouched = fn;
    }

    private onChange(newValue: string) {
        this.propagateChange(newValue);
        this.propagateTouched();
    }
}
