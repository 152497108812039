import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ISimpleMessageViewModel, IUserViewModel } from '../../api/services';
import { Translations } from '../../translations/translations';
import { MessageService } from '../services/message.service';
import { ROLES } from '../user/user-roles';
import { UserService } from '../user/user.service';
import { UtilService } from '../util/util.service';

@Component({
    selector: 'iv-page-frontpage',
    template: `
        <section class="page-frontpage" *ngIf="user; else loginBlock">
            <ng-container *ngIf="!isHomeCarer">
                <div role="status" class="alert alert-success">
                    <div class="alert__header">{{ '${Translations.pages.frontpage.welcomeHeader}' | translation : user.impersonatedCitizenQuickInfo ? user.impersonatedCitizenQuickInfo.name : user.name! }}</div>
                    <div class="alert__content">${Translations.pages.frontpage.welcomeMessage}</div>
                </div>

                <div role="status" class="page-frontpage__message alert alert-info" *ngFor="let message of message$ | async" [innerHTML]="message.textMessage"></div>
            </ng-container>

            <div role="alert" class="alert alert-info" *ngIf="user.orderNo">
                ${Translations.pages.frontpage.existingOrderText}
                <a [routerLink]="['${Translations.shop.paths.ordersPage.path}', user.orderNo]">${Translations.pages.frontpage.existingOrderLink}</a>
            </div>

            <header [ngSwitch]="isHomeCarer && !!user.impersonatedCitizenQuickInfo">
                <h2 *ngSwitchCase="true">{{ user.impersonatedCitizenQuickInfo.name }}:</h2>
                <h2 *ngSwitchDefault>${Translations.pages.frontpage.quicklinks}</h2>
            </header>
            <nav class="page-frontpage__navigation quicklinks">
                <a class="quicklinks__item" routerLink="${Translations.shop.paths.topProductsPage.path}">${Translations.layout.navigation.topProducts}</a>
                <a class="quicklinks__item" routerLink="${Translations.shop.paths.shopPage.path}/${Translations.shop.paths.discountCategory.path}">${Translations.shop.paths.discountCategory.title}</a>
                <a class="quicklinks__item" routerLink="${Translations.shop.paths.ordersPage.path}">${Translations.layout.navigation.orders}</a>
                <a class="quicklinks__item" routerLink="${Translations.shop.paths.creditNotePage.path}" *ngIf="showCreditNotes || isTastSelv">${Translations.shop.paths.creditNotePage.title}</a>
                <a class="quicklinks__item" routerLink="${Translations.shop.paths.favoritesPage.path}">${Translations.layout.navigation.favourite}</a>
                <a class="quicklinks__item" routerLink="pda" *ngIf="isHomeCarer">${Translations.pages.frontpage.chooseAnotherCitizen}</a>
                <a class="quicklinks__item" href="${Translations.pages.frontpage.weeklyNewspaperLink}" target="_blank" *ngIf="(isCustomerService || isTastSelv) && !isMobile">${Translations.pages.frontpage.weeklyNewspaper}</a>
            </nav>
        </section>
        <ng-template #loginBlock>
            <iv-login></iv-login>
        </ng-template>
    `
})
export class PageFrontpageComponent implements OnInit, OnDestroy {
    user?: IUserViewModel;
    message$: Observable<ISimpleMessageViewModel[]>;
    isHomeCarer = false;
    isTastSelv = false;
    isMobile = false;
    isCustomerService = false;
    showCreditNotes = false;

    private unsubscribe = new Subject<void>();

    constructor(
        public userService: UserService,
        private messageService: MessageService,
        private route: ActivatedRoute,
        private utilService: UtilService
    ) { }

    ngOnInit() {
        combineLatest([
            this.userService.user$,
            this.route.queryParamMap
        ]).pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(data => {
            this.user = data[0];
            if (this.user && this.user.roles) {
                this.showCreditNotes = this.user && this.user.impersonatedCitizenQuickInfo && this.user.impersonatedCitizenQuickInfo.customerNumber ? true : false;
                this.isHomeCarer = this.user.roles.indexOf(ROLES.HomeCarer) !== -1;
                this.isTastSelv = this.user.roles.indexOf(ROLES.Citizen) !== -1;
                this.isCustomerService = this.user.roles.indexOf(ROLES.CustomerService) !== -1;
            } else {
                this.isHomeCarer = false;
                this.isTastSelv = false;
            }
        });

        this.utilService.deviceType$.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(device => this.isMobile = device === 'mobile');

        this.message$ = this.messageService.getActiveMessages();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
