import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CitizenSearchFieldComponent } from './citizen-search-field.component';
import { SearchFieldComponent } from './search-field.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        MatProgressSpinnerModule
    ],
    declarations: [
        SearchFieldComponent,
        CitizenSearchFieldComponent
    ],
    exports: [
        SearchFieldComponent,
        CitizenSearchFieldComponent
    ]
})
export class SearchModule { }
