import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, of, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { Translations } from '../../translations/translations';
import { AuthService } from '../services/auth.service';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { SettingsService } from '../services/settings.service';
import { DialogService } from '../shared/dialog.service';

@Component({
    selector: 'iv-administration-settings',
    template: `
        <div class="administration-settings">
            <div class="administration-settings__option-group">
                <mat-slide-toggle
                    color="primary"
                    [(ngModel)]="settings.isStockServiceActivated"
                    (change)="onStockServiceActivation()"
                    title="${Translations.intervare.settingsAdmin.stockService.title}">
                    ${Translations.intervare.settingsAdmin.stockService.name}
                </mat-slide-toggle>

                <span class="administration-settings__message">${Translations.intervare.settingsAdmin.stockService.title}</span>
            </div>

            <div class="administration-settings__option-group"
                 [class.administration-settings__option-group--disabled]="!settings.isStockServiceActivated">
                <mat-slide-toggle
                    color="primary"
                    [disabled]="!settings.isStockServiceActivated"
                    [(ngModel)]="settings.isStockServiceErrorHandlingActivated"
                    (change)="onShowReservationErrors()"
                    title="${Translations.intervare.settingsAdmin.stockServiceErrorHandling.title}">
                    ${Translations.intervare.settingsAdmin.stockServiceErrorHandling.name}
                </mat-slide-toggle>

                <span class="administration-settings__message">${Translations.intervare.settingsAdmin.stockServiceErrorHandling.title}</span>
            </div>

            <div class="administration-settings__option-group">
                <mat-slide-toggle
                    color="primary"
                    [(ngModel)]="settings.IsPdfEmailJobActivated"
                    (change)="onSetPdfEmailJobActivationStatus()"
                    title="${Translations.intervare.settingsAdmin.setPdfEmailJobActivationStatus.title}">
                    ${Translations.intervare.settingsAdmin.setPdfEmailJobActivationStatus.name}
                </mat-slide-toggle>

                <span class="administration-settings__message">${Translations.intervare.settingsAdmin.setPdfEmailJobActivationStatus.title}</span>
            </div>
        </div>
    `
})
export class AdministrationSettingsComponent implements OnInit, OnDestroy {
    private readonly unsubscribe = new Subject();

    settings = {
        isStockServiceActivated: false,
        isStockServiceErrorHandlingActivated: false,
        IsPdfEmailJobActivated: false
    };

    constructor(
        private _settingsService: SettingsService,
        private _dialogService: DialogService,
        private _authService: AuthService,
    ) { }

    ngOnInit() {
        combineLatest([this._settingsService.isStockServiceActivated(), this._settingsService.IsStockServiceErrorHandlingActivated(), this._settingsService.IsPdfEmailJobActivated()])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([isStockServiceActivated, isStockServiceErrorHandlingActivated, IsPdfEmailJobActivated]) => {
                this.updateSettings(isStockServiceActivated, isStockServiceErrorHandlingActivated, IsPdfEmailJobActivated);
            });
    }

    public ngOnDestroy() {
        // we need to get the current user to get the updated basket with the correct
        // setting, otherwise we will se old/wrong information under the basket
        // only after we can unsubscribe all the events for this
        this._authService.getCurrentUser().pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.unsubscribe.next();
            this.unsubscribe.complete();
        });
    }

    onStockServiceActivation(): void {
        this._settingsService.setStockServiceActivation(this.settings.isStockServiceActivated)
            .pipe(
                switchMap(stockServiceActivated => stockServiceActivated === false && this.settings.isStockServiceErrorHandlingActivated ? this._settingsService.setStockServiceErrorHandling(false) : of(false)),
                tap(isStockServiceErrorHandlingActivated => {
                    this.updateSettings(this.settings.isStockServiceActivated, isStockServiceErrorHandlingActivated, this.settings.IsPdfEmailJobActivated);
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(
                () => this._dialogService.showSnackMessage({ message: Translations.intervare.settingsAdmin.stockService.messageOk }),
                (err: IntervareHttpErrorResponse) => this._dialogService.showValidationResult(err.validationErrors)
            );
    }

    onShowReservationErrors(): void {
        this._settingsService.setStockServiceErrorHandling(this.settings.isStockServiceErrorHandlingActivated)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                () => this._dialogService.showSnackMessage({ message: Translations.intervare.settingsAdmin.stockServiceErrorHandling.messageOk }),
                (err: IntervareHttpErrorResponse) => this._dialogService.showValidationResult(err.validationErrors)
            );
    }

    onSetPdfEmailJobActivationStatus(): void {
        this._settingsService.setPdfEmailJobActivationStatus(this.settings.IsPdfEmailJobActivated)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                () => this._dialogService.showSnackMessage({ message: Translations.intervare.settingsAdmin.setPdfEmailJobActivationStatus.messageOk }),
                (err: IntervareHttpErrorResponse) => this._dialogService.showValidationResult(err.validationErrors)
            );
    }

    private updateSettings(isStockServiceActivated: boolean, isStockServiceErrorHandlingActivated: boolean, IsPdfEmailJobActivated: boolean): void {
        this.settings.isStockServiceActivated = isStockServiceActivated;
        this.settings.isStockServiceErrorHandlingActivated = isStockServiceErrorHandlingActivated;
        this.settings.IsPdfEmailJobActivated = IsPdfEmailJobActivated;
    }
}
