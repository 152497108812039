import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import {
    CitizenTransferReason,
    ICitizenWithOrderInfoViewModel,
    ISimpleMessageViewModel,
    ISkipTakeResultObjectOfCitizenWithOrderInfoViewModel,
    UserViewModel,
} from '../../api/services';
import { check, transfer } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { AuthService } from '../services/auth.service';
import { CitizenService, ICitizenSearchHomecarer } from '../services/citizen.service';
import { MessageService } from '../services/message.service';
import { DialogService } from '../shared/dialog.service';
import { TransferDetails, TransferDetailsDialogComponent } from '../shared/transfer-details-dialog.component';
import { TransferReasonDialogService } from '../shared/transfer-reason-dialog.service';
import { UserService } from '../user/user.service';
import { UtilService } from '../util/util.service';
import { Helpers } from '../util/helpers';

@Component({
    selector: 'iv-page-homecarer',
    template: `
        <section class="page-homecarer">
            <div role="status" class="alert alert-success" *ngIf="(userService.user$ | async) as user">
                <div class="alert__header">{{ '${Translations.pages.homecarer.welcome}' | translation: user.name }}</div>
            </div>

            <div role="status" class="page-frontpage__message alert alert-info" *ngFor="let message of message$ | async" [innerHTML]="message.textMessage"></div>

            <ng-container *ngIf="orderDays.length; else noOrderDays">
                <h3 class="page-homecarer__section-header">${Translations.pages.homecarer.headerSearch}</h3>

                <div class="page-homecarer__select-group alt-theme">
                    <mat-form-field class="page-homecarer__select-form-field">
                        <mat-select [(ngModel)]="searchSettings.date" (selectionChange)="onDateChange($event)" class="administration-homecarer__select" placeholder="${Translations.pages.homecarer.orderDay}">
                            <mat-option *ngFor="let orderDay of orderDays" [value]="orderDay">{{ orderDay | date : 'fullDate'}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <iv-citizen-search-field [text]="searchSettings.text" placeholder="${Translations.administration.citizenSearch.byParameters}" (searched)="onSearchInput($event)"></iv-citizen-search-field>

                <h3 class="page-homecarer__section-header">${Translations.pages.homecarer.headerCitizens}</h3>
            </ng-container>

            <ng-template #noOrderDays>
                <div class="page-homecarer__status">
                    ${Translations.pages.homecarer.noOrderDays}
                </div>
            </ng-template>

            <div class="page-homecarer__loading center-content" *ngIf="isLoading">
                <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="2" [diameter]="20"></mat-progress-spinner>
            </div>

            <ng-container *ngIf="citizenList">
                <div class="page-homecarer__citizens" *ngIf="citizenList.numFound; else unavailable">
                    <div class="page-homecarer__citizen" *ngFor="let citizen of citizenList.results"
                        [ngClass]="{
                            'page-homecarer__citizen_inactive' : citizen.isCustomerActive === false || citizen.isBlocked === true,
                            'page-homecarer__citizen_impersonated' : (userService.isImpersonating | async)?.customerNumber === citizen.customerNo
                        }">
                        <div class="page-homecarer__citizen-content">
                            <div class="page-homecarer__info">
                                <h3 class="page-homecarer__name">
                                    {{ citizen.firstName }} {{ citizen.middleName }} {{ citizen.lastName }}
                                </h3>
                                <div class="page-homecarer__address">
                                    {{ citizen | address }}
                                </div>
                            </div>

                            <div *ngIf="citizen.isDeliveryDateChanged" class="page-homecarer__delivery-changed page-homecarer__delivery-changed--desktop">
                                ${Translations.layout.navigation.impersonated.deliveryChanged} {{ citizen.deliveryDate | date : 'fullDate' }}
                            </div>

                            <div class="page-homecarer__controls">
                                <ng-container *ngIf="citizen.isCustomerActive; else inactive">
                                    <ng-container *ngIf="!citizen.isBlocked; else inactive">
                                        <ng-container *ngIf="!citizen.transferedCustomerId; else transferred">
                                            <ng-container *ngIf="!citizen.orderId; else status">
                                                <iv-progress-button
                                                    buttonType="button"
                                                    class="page-homecarer__transfer btn-with-icon"
                                                    color="warn"
                                                    width="full"
                                                    [callback]="transfer.bind(this, citizen)">
                                                    ${Translations.pages.homecarer.unsubscribe}
                                                </iv-progress-button>

                                                <iv-progress-button
                                                    buttonType="button"
                                                    class="page-homecarer__impersonate"
                                                    color="primary"
                                                    width="full"
                                                    [disabled]="citizen.isBlocked"
                                                    [callback]="impersonate.bind(this, citizen.customerNo)">
                                                    ${Translations.pages.homecarer.impersonate}
                                                </iv-progress-button>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>

                                <ng-template #status>
                                    <iv-progress-button buttonType="button" color="primary"
                                        [disabled]="citizen.isBlocked"
                                        [callback]="impersonateAndSeeOrderDetails.bind(this, citizen.customerNo, citizen.orderId)"
                                        class="page-homecarer__see-order btn-with-icon">

                                        <span class="icon" aria-hidden="true">${check}</span>
                                        ${Translations.pages.homecarer.seeOrder}
                                    </iv-progress-button>
                                </ng-template>

                                <ng-template #transferred>
                                    <div class="page-homecarer__transferred">
                                        <div class="page-homecarer__transferred-text">
                                            <div class="page-homecarer__see-order-icon icon">${check}</div>
                                            <div class="page-homecarer__see-order-text">${Translations.pages.homecarer.transferred} ({{ formatTransferReason(citizen.transferReason) }})</div>
                                        </div>
                                        <button
                                            type="button"
                                            class="page-homecarer__transfer-details-btn"
                                            mat-raised-button
                                            color="primary"
                                            (click)="transferDetails(citizen)">
                                            ${Translations.global.transferDetails.seeDetailsBtn}
                                        </button>
                                    </div>
                                </ng-template>

                                <ng-template #inactive>
                                    ${Translations.pages.homecarer.inactive}
                                </ng-template>
                            </div>
                        </div>
                        <div *ngIf="citizen.isDeliveryDateChanged" class="page-homecarer__delivery-changed page-homecarer__delivery-changed--mobile">
                            ${Translations.layout.navigation.impersonated.deliveryChanged} {{ citizen.deliveryDate | date : 'fullDate' }}
                        </div>
                    </div>

                    <mat-paginator *ngIf="citizenList.numFound > searchSettings.take" [length]="citizenList.numFound" [pageSize]="searchSettings.take" [pageIndex]="searchSettings.skip / searchSettings.take" (page)="onPaginatorChange($event)"></mat-paginator>
                </div>
            </ng-container>

            <ng-template #unavailable>
                <div class="page-homecarer__status alt-theme" *ngIf="!citizenList && !isLoading">
                    <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="3" [diameter]="60"></mat-progress-spinner>
                </div>

                <div class="page-homecarer__status" *ngIf="citizenList && !isLoading">
                    ${Translations.pages.homecarer.noCitizens}
                </div>
            </ng-template>
        </section>
    `
})
export class PageHomecarerComponent implements OnInit, OnDestroy {
    citizenList?: ISkipTakeResultObjectOfCitizenWithOrderInfoViewModel;
    message$: Observable<ISimpleMessageViewModel[]>;

    orderDays: Date[] = [];

    searchSettings = {
        text: '',
        skip: 0,
        take: 20,
        date: new Date()
    };

    isLoading = false;

    private unsubscribe = new Subject<void>();

    constructor(
        public userService: UserService,
        private router: Router,
        private citizenService: CitizenService,
        private dialogService: DialogService,
        private authService: AuthService,
        private utilService: UtilService,
        private transferReasonDialogService: TransferReasonDialogService,
        private messageService: MessageService
    ) { }

    ngOnInit() {
        this.citizenService.searchHomecarer$.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(citizenList => {
            this.citizenList = citizenList;
            this.isLoading = false;
        }, () => this.isLoading = false);

        this.citizenService.getCitizensForHomeCarerDays().pipe(
            map((dates: Date[]) => dates.map(date => Helpers.convertDateToTimeZone(date) )),
            takeUntil(this.unsubscribe)
        ).subscribe(orderDays => {
            this.orderDays = orderDays;

            if (this.orderDays.length) {
                // Select the current day or in this case the first day;
                this.searchSettings.date = this.orderDays[0];
                this.userService.storeOrderDate(this.orderDays[0]);

                this.search();
            }
        });

        this.message$ = this.messageService.getActiveMessages();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    search() {
        // Reset the list of result and show loading information
        this.isLoading = true;

        // If we have a text then do not search for date
        const searchModel: ICitizenSearchHomecarer = {
            skip: this.searchSettings.skip,
            take: this.searchSettings.take,
            text: this.searchSettings.text,
            date: ''
        };

        if (!searchModel.text) {
            searchModel.date = this.searchSettings.date.toISOString();
        }

        this.citizenService.getCitizensForHomecarer(searchModel);
    }

    transfer = (citizen: ICitizenWithOrderInfoViewModel): Promise<boolean> => {

        const citizenName = this._concatName(citizen);

        return this.transferReasonDialogService.open({
            customerNo: citizen.customerNo || '',
            noOrderDate: this.searchSettings.date
        }).pipe(
            map(res => res.success),
            tap(
                res => res ? this.successfullyTransferred(citizen) : false,
                err => this.failedTransfer(citizen)
            )).toPromise();
    }

    transferDetails(citizen: ICitizenWithOrderInfoViewModel): void {
        if (!citizen.transferedCustomerId) {
            return;
        }

        const data: TransferDetails = {
            citizenName: this._concatName(citizen),
            transfer: citizen
        };

        this.dialogService.openDialogWithComponent(TransferDetailsDialogComponent, { data }).afterClosed()
            .subscribe(res => res ? this.search() : true);
    }

    impersonate = (customerNo: string): Promise<boolean> => {
        return this._impersonate(customerNo)
            .toPromise()
            .then(() => {
                this.utilService.scrollElementTo(window, 0);
                this.router.navigate(['']);
                return true;
            });
    }

    impersonateAndSeeOrderDetails = (customerNo: string, orderId: string): Promise<boolean> => {
        return this._impersonate(customerNo)
            .toPromise()
            .then(() => {
                this.router.navigate([Translations.shop.paths.ordersPage.path, orderId]);
                return true;
            });
    }

    onSearchInput(text: string) {

        if (text.length > 0) {
            // when starting search the stored orer date should be clear to avoid search for a citizen
            // with a different order day and make buys with the wrong order date
            this.userService.clearOrderDate();
        } else {
            // if the search field is empty, the order day should be setted again based on the
            // the citizen order day
            this.userService.storeOrderDate(this.orderDays[0]);
        }

        this.searchSettings.date = this.orderDays[0];
        this.searchSettings.text = text;
        this.searchSettings.skip = 0;
        this.search();
    }

    onDateChange(event: MatSelectChange) {
        this.searchSettings.text = '';
        this.searchSettings.skip = 0;

        // Save the orderDay to be used on order date when the user is impersonated and the buy day is not the same
        // as defined on the citizen
        this.userService.storeOrderDate(event.value);

        this.search();
    }

    onPaginatorChange(event: PageEvent) {
        this.utilService.scrollElementTo(window, 0);
        this.searchSettings.skip = event.pageIndex * this.searchSettings.take;
        this.search();
    }

    successfullyTransferred(citizen: ICitizenWithOrderInfoViewModel) {
        this.dialogService.showSnackMessage({ message: Translations.replaceTokens(Translations.administration.citizenQuickView.transferDialog.messageOk, this._concatName(citizen)) });
        this.search();
    }

    failedTransfer(citizen: ICitizenWithOrderInfoViewModel) {
        this.dialogService.showSnackMessage({ message: Translations.replaceTokens(Translations.administration.citizenQuickView.citizenActionError, this._concatName(citizen)) });
    }

    formatTransferReason(transferReason: CitizenTransferReason): string {
        return Translations.global.transferReasons[CitizenTransferReason[transferReason]];
    }

    private _impersonate(customerNo: string): Observable<UserViewModel> {
        return this.authService.switchToCitizen({ customerNo, takeOverLock: true }).pipe(
            filter(response => response instanceof UserViewModel),
            map(user => user as UserViewModel),
            tap(user => this.dialogService.showSnackMessage({ message: Translations.replaceTokens(Translations.administration.citizenQuickView.switchCitizenPrompt.messageOk, user.impersonatedCitizenQuickInfo!.name!) })),
            takeUntil(this.unsubscribe)
        );
    }

    /**
     * Concat the the properties of the name, like FirstName and LastName;
     *
     * @private
     * @param {CitizenWithoutOrder} citizen
     * @returns {string}
     * @memberof MunicipalityCitizenStatusComponent
     */
    private _concatName(citizen: ICitizenWithOrderInfoViewModel): string {
        let name = '';
        name += citizen.firstName ? citizen.firstName : '';
        name += citizen.middleName ? ' ' + citizen.middleName : '';
        name += citizen.lastName ? ' ' + citizen.lastName : '';
        return name;
    }
}
