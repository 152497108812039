import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'iv-administration-stock',
    template: `
    <p>
      Lager komponenten virker!
    </p>
  `
})
export class AdministrationStockComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
