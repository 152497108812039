import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
    selector: 'iv-progress-button',
    template: `
        <button
            mat-raised-button
            class="progress-button"
            [class.loading]="loadingState"
            [class.progress-button_full-width]="width === 'full'"
            [color]="color"
            (click)="handleClick($event)"
            [type]="buttonType"
            [disabled]="loadingState || disabled"
        >
            <span class="progress-button__text">
                <ng-content></ng-content>
            </span>

            <span class="progress-button__spinner-wrapper" *ngIf="loadingState">
                <!--TODO: INT-2007: add custom spinner until cpu burn is fixed -->

                <!--  <mat-progress-spinner
                    class="progress-button__spinner"
                    [color]="color"
                    [diameter]="spinnerDiameterInt"
                    [strokeWidth]="strokeWidth"
                    mode="indeterminate"
                >
                </mat-progress-spinner>  -->

                <span class="progress-button__spinner-wrapper__spinner"
                    [ngClass]="{'accent': color==='accent', 'primary': color==='primary'}"
                ></span>
            </span>
        </button>
    `
})
export class ProgressButtonComponent implements OnInit {
    @Input() buttonType: 'submit' | 'button' | 'reset' = 'submit';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() callback: (event: Event) => Promise<any>;
    @Input() color: ThemePalette;
    @Input() diameter: number;
    @Input() loadingState = false;
    @Input() disabled: boolean;

    @HostBinding('class.progress-button_full-width') get fullWidth() {
        return this.width === 'full';
    }
    @Input() width: 'full' | 'auto' = 'auto';
    @Output() action = new EventEmitter<Event>();

    spinnerDiameterInt: number;
    strokeWidth = 3;

    constructor(private readonly elementRef: ElementRef) {}

    ngOnInit() {
        this.spinnerDiameterInt = this.diameter ? this.diameter : 25;
    }

    handleClick(event: Event) {
        this.action.emit(event);
        if (this.callback) {
            this.loadingState = true;
            this.callback(event)
                .then(() => (this.loadingState = false))
                .catch(() => (this.loadingState = false));
        }
    }
}
