import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DialogOutletComponent } from '../shared/dialog-outlet.component';
import { AuthGuard } from '../user/auth.guard';
import { IDENTITIES } from '../user/user-roles';
import { AssignCitizensComponent } from './assign-citizens.component';
import { CallListPageComponent } from './call-list-page.component';
import { DisplayCallcountComponent } from './display-callcount.component';
import { EditCallDayComponent } from './edit-call-day.component';
import { EditCallListComponent } from './edit-call-list.component';
import { EditCallerComponent } from './edit-caller.component';
import { EndDayComponent } from './end-day.component';
import { EndFaxDayComponent } from './end-fax-day.component';
import { GenerateListComponent } from './generate-list.component';
import { PlanningPageComponent } from './planning-page.component';
import { CallDayResolver } from './resolvers/call-day.resolver';
import { CallListPlanningResolver } from './resolvers/call-list-planning.resolver';
import { CallListResolver } from './resolvers/call-list.resolver';
import { CallerResolver } from './resolvers/caller.resolver';
import { PlanningDayResolver } from './resolvers/planning-day.resolver';

export const callListAdmin: Routes = [
    {
        path: 'planning/:planning-day-id',
        component: PlanningPageComponent,
        data: {
            allowedRoles: IDENTITIES.adminAndCustomerService,
            reuse: false
        },
        canActivate: [
            AuthGuard
        ],
        resolve: {
            pageData: PlanningDayResolver
        }
    },
    {
        path: 'calllist/:call-list-id',
        component: CallListPageComponent,
        data: {
            allowedRoles: IDENTITIES.adminAndCustomerService,
            reuse: false
        },
        canActivate: [
            AuthGuard
        ],
        resolve: {
            pageData: CallListPlanningResolver
        }
    },
    {
        path: 'assigncitizens',
        component: DialogOutletComponent,
        outlet: 'dialog',
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.adminAndCustomerService,
            dialogComponent: AssignCitizensComponent,
            fullscreen: false,
            nomargin: true
        }
    },
    {
        path: 'edit-call-day/:call-day-id',
        component: DialogOutletComponent,
        outlet: 'dialog',
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.adminAndCustomerService,
            dialogComponent: EditCallDayComponent,
            fullscreen: false,
            nomargin: true
        },
        resolve: {
            data: CallDayResolver
        }
    },
    {
        path: 'edit-call-list/:call-list-id',
        component: DialogOutletComponent,
        outlet: 'dialog',
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.adminAndCustomerService,
            dialogComponent: EditCallListComponent,
            fullscreen: false,
            nomargin: true
        },
        resolve: {
            data: CallListResolver
        }
    },
    {
        path: 'edit-caller/:caller',
        component: DialogOutletComponent,
        outlet: 'dialog',
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.adminAndCustomerService,
            dialogComponent: EditCallerComponent,
            fullscreen: false,
            nomargin: true
        },
        resolve: {
            data: CallerResolver
        }
    },
    {
        path: 'end-day',
        component: DialogOutletComponent,
        outlet: 'dialog',
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.adminAndCustomerService,
            dialogComponent: EndDayComponent,
            fullscreen: false,
            nomargin: true
        }
    },
    {
        path: 'end-fax-day',
        component: DialogOutletComponent,
        outlet: 'dialog',
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.adminAndCustomerService,
            dialogComponent: EndFaxDayComponent,
            fullscreen: false,
            nomargin: true
        }
    },
    {
        path: 'generate-list',
        component: DialogOutletComponent,
        outlet: 'dialog',
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.adminAndCustomerService,
            dialogComponent: GenerateListComponent,
            fullscreen: false,
            nomargin: true
        }
    },
    {
        path: 'display-callcount',
        component: DialogOutletComponent,
        outlet: 'dialog',
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.adminAndCustomerService,
            dialogComponent: DisplayCallcountComponent,
            fullscreen: false,
            nomargin: true
        }
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(callListAdmin)
    ],
    exports: [
        RouterModule
    ]
})
export class CallListAdminRoutingModule { }
