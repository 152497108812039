import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { map } from 'rxjs/operators';

import { Translations } from '../../../translations/translations';
import { CallListAdminService, ICallListDetails } from '../../services/call-list-admin.service';
import { IDefaultPageData } from '../../shared/route-data';
import { Observable } from 'rxjs';

export const CallListPlanningResolver: ResolveFn<Observable<IDefaultPageData<ICallListDetails>>> = (activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<IDefaultPageData<ICallListDetails>> => {
    const callListAdminService: CallListAdminService = inject(CallListAdminService);
    const id = activatedRouteSnapshot.paramMap.get('call-list-id') || '';

    return callListAdminService.getCallListDetails(+id).pipe(
        map(weekPlan => ({ title: Translations.callListAdmin.callListPage.header + ' ' + weekPlan.title, data: weekPlan }))
    );
}
