import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, Subject } from 'rxjs';

import { finalize, takeUntil } from 'rxjs/operators';
import { Translations } from '../../translations/translations';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { ProductService } from '../services/product.service';
import { DialogService } from '../shared/dialog.service';
import { Helpers } from '../util/helpers';
import { UtilService } from '../util/util.service';

@Component({
    selector: 'iv-administration-weeklyoffers-admin',
    template: `
        <section class="administration-weeklyoffers">
            <div class="administration-weeklyoffers__block administration-weeklyoffers__form">
                <h3 class="administration-weeklyoffers__header">
                    ${Translations.intervare.weeklyOffersAdmin.inputOffers}
                </h3>

                <form class="administration-weeklyoffers__weeknos form">
                    <mat-form-field class="form__element">
                        <mat-label>${Translations.intervare.weeklyOffersAdmin.inputWeekNo}</mat-label>
                        <mat-select
                            (selectionChange)="getCurrentWeek()"
                            [(ngModel)]="weekNo"
                            name="weekNo"
                            required
                        >
                            <mat-option *ngFor="let weekNo of weekNos" [value]="weekNo">{{ weekNo }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>

                <form class="form" (submit)="onSubmit()">
                    <mat-form-field class="form__element">
                        <mat-label>${Translations.intervare.weeklyOffersAdmin.placeholder}</mat-label>
                        <mat-chip-grid #chipList>
                            <mat-chip-row
                                *ngFor="let productNo of productNos"
                                [highlighted]="selectable"
                                [removable]="removable"
                                (removed)="removeProductNo(productNo)"
                            >
                                {{ productNo }}
                                <button matChipRemove [attr.aria-label]="'remove ' + productNo">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip-row>
                            <input
                                type="tel"
                                [matChipInputFor]="chipList"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="addOnBlur"
                                (matChipInputTokenEnd)="addProductNo($event)"
                            />
                        </mat-chip-grid>
                        <mat-hint>${Translations.intervare.weeklyOffersAdmin.hint}</mat-hint>
                    </mat-form-field>
                    <iv-progress-button color="primary" [loadingState]="loading"
                        >${Translations.intervare.weeklyOffersAdmin.btnSubmit}</iv-progress-button
                    >
                </form>
            </div>
            <div class="administration-weeklyoffers__block">
                <h3 class="administration-weeklyoffers__header">
                    ${Translations.intervare.weeklyOffersAdmin.offersHeader}
                </h3>
                <ng-container *ngIf="weeklyOffer$ | async as weeklyOffers">
                    <ul class="administration-weeklyoffers__list" *ngIf="weeklyOffers.length">
                        <li *ngFor="let productNo of weeklyOffer$ | async">{{ productNo }}</li>
                    </ul>
                    <div role="alert" class="error" *ngIf="!weeklyOffers.length">
                        ${Translations.intervare.weeklyOffersAdmin.noOffers}
                    </div>
                </ng-container>
            </div>
        </section>
    `
})
export class AdministrationWeeklyoffersAdminComponent implements OnInit, OnDestroy {
    weekNo: number = Helpers.weekNumber(new Date());
    weekNos: number[] = [];
    weeklyOffer$?: Observable<string[]>;
    productNos: string[] = [];

    loading = false;
    selectable = true;
    removable = true;
    addOnBlur = true;
    separatorKeysCodes = [ENTER, COMMA, SPACE];

    private unsubscribeS$: Subject<void> = new Subject();

    constructor(
        private utilService: UtilService,
        private productService: ProductService,
        private dialogService: DialogService
    ) {}

    ngOnInit() {
        this.getCurrentWeek();

        const numOfWeeks = this.utilService.weeksInYear(new Date().getFullYear());
        for (let i = 1; i <= numOfWeeks; i++) {
            this.weekNos.push(i);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeS$.next();
        this.unsubscribeS$.complete();
    }

    getCurrentWeek(): void {
        this.productNos = [];
        this.weeklyOffer$ = this.productService.getWeeklyOffers(this.weekNo);
    }

    onSubmit() {
        this.loading = true;
        this.productService
            .setWeeklyOffers({ weekNumber: this.weekNo, productsNo: this.productNos })
            .pipe(
                finalize(() => (this.loading = false)),
                takeUntil(this.unsubscribeS$)
            )
            .subscribe(
                () => this.getCurrentWeek(),
                (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
            );
    }

    addProductNo(event: MatChipInputEvent) {
        const input = event.chipInput.inputElement;
        const value = event.value;

        if ((value || '').trim()) {
            this.productNos.push(value.trim());

            // Reset the input value
            if (input) {
                input.value = '';
            }
        }
    }

    removeProductNo(productNo: string) {
        const index = this.productNos.indexOf(productNo);

        if (index >= 0) {
            this.productNos.splice(index, 1);
        }
    }
}
