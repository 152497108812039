import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'iv-accordion',
    preserveWhitespaces: false,
    template: `
        <div class="accordion__container accordion--{{state}}" [ngClass]="{ 'accordion--animating': isAnimating }">
            <button class="accordion__title" (click)="toggle()">
                <ng-content select="[accordion-title]"></ng-content>
            </button>
            <div class="accordion__content" [@content]="state" (@content.start)="setAnimating(true)" (@content.done)="setAnimating(false)">
                <ng-content select="[accordion-content]"></ng-content>
            </div>
        </div>
	`,
    animations: [
        trigger('content', [
            state('closed', style({ height: '0px' })),
            state('open', style({ height: '*' })),
            transition('open => closed', [
                style({ display: 'block', overflow: 'hidden' }),
                animate('300ms ease-in-out')
            ]),
            transition('closed => open', [
                style({ display: 'block', overflow: 'hidden' }),
                animate('300ms ease-in-out')
            ]),
        ]),
    ]
})

export class AccordionComponent {

    public state: 'closed' | 'open' = 'closed';
    public isAnimating: boolean;

    @Output() open = new EventEmitter();
    @Output() close = new EventEmitter();

    @Input()
    set opened(open: boolean) {
        if (open) {
            this.doOpen();
        } else {
            this.doClose();
        }
    }
    get opened(): boolean {
        return this.state === 'open';
    }

    setAnimating(animating: boolean) {
        this.isAnimating = animating;
    }

    private doClose() {
        if (!this.isAnimating) {
            this.state = 'closed';
            this.close.emit();
        }
    }

    private doOpen() {
        if (!this.isAnimating) {
            this.state = 'open';
            this.open.emit();
        }
    }

    toggle() {
        return this.state === 'open' ? this.doClose() : this.doOpen();
    }

    constructor() { }
}
