import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';

import { IUserViewModel } from '../api/services';
import { Translations } from '../translations/translations';
import { AnimationState, IAppState } from './app-state';
import { IDefaultPageData } from './shared/route-data';
import { RouteService } from './shared/route.service';
import { IDENTITIES, ROLES } from './user/user-roles';
import { UserService } from './user/user.service';
import { UtilService } from './util/util.service';

import { SettingsService } from './services/settings.service';
import { CallsService } from './services/calls/calls.service';

@Component({
    selector: 'iv-root',
    template: `
        <div
            class="app"
            [ngClass]="{
                'app_customer-center': stateObj.customerCenter,
                'app_customer': !stateObj.customerCenter,
                'app_admin-mode': stateObj.adminMode,
                'app_homecarer-mode': stateObj.homecarerMode,
                'app_impersonated': stateObj.showImpersonated
            }"
        >
            <nav class="app__administration-menu" role="navigation" *ngIf="stateObj.customerCenter">
                <iv-administration-menu></iv-administration-menu>
            </nav>
            <aside
                class="app__aside"
                [class.app__aside_hide]="!stateObj.showLeftMenu && !stateObj.customerCenter"
                *ngIf="stateObj.device === 'desktop'"
            >
                <div class="app__aside-outlet">
                    <router-outlet name="admin"></router-outlet>
                </div>

                <iv-util-hidden-scroll
                    class="app__aside-menu"
                    [@flyInOut]="stateObj.showCategory"
                    [focus]="true"
                    height="100vh"
                    [active]="stateObj.customerCenter"
                >
                    <iv-category-menu></iv-category-menu>
                </iv-util-hidden-scroll>
            </aside>

            <div class="app__content">
                <iv-layout-navigation
                    [adminMode]="stateObj.adminMode"
                    [showMenuTrigger]="stateObj.showMenuTrigger"
                    [showImpersonated]="stateObj.showImpersonated"
                    [homecarerMode]="stateObj.homecarerMode"
                    (menuToggled)="toggleMenu()"
                ></iv-layout-navigation>

                <iv-util-hidden-scroll id="content" [active]="stateObj.customerCenter">
                    <div class="app__wrapper">
                        <div class="app__page" [ngSwitch]="stateObj.mustChangePwd">
                            <iv-user-edit-password *ngSwitchCase="true"></iv-user-edit-password>
                            <article class="app__article" *ngSwitchDefault>
                                <header *ngIf="stateObj.pageTitle">
                                    <h1>{{ stateObj.pageTitle }}</h1>
                                </header>
                                <router-outlet></router-outlet>
                            </article>
                            <iv-layout-footer *ngIf="!stateObj.adminMode"></iv-layout-footer>
                        </div>
                    </div>
                </iv-util-hidden-scroll>
            </div>

            <aside
                class="app__commerce-minibasket"
                *ngIf="stateObj.width >= 1800 && stateObj.showMinibasket"
                [@shrink]="minibasketAnimationState.shrink"
                (@shrink.done)="minibasketAnimationDone($event)"
            >
                <iv-commerce-minibasket
                    [isCustomerCenter]="stateObj.customerCenter"
                    [@fade]="minibasketAnimationState.fade"
                    (@fade.done)="minibasketAnimationDone($event)"
                ></iv-commerce-minibasket>
            </aside>

            <iv-layout-navigation-device
                [show]="stateObj.menuTriggerState"
                (menuToggled)="toggleMenu()"
                *ngIf="stateObj.device !== 'desktop'"
            ></iv-layout-navigation-device>
            <iv-layout-cookie-warning
                (accepted)="acceptCookie()"
                *ngIf="stateObj.showCookie"
            ></iv-layout-cookie-warning>

            <router-outlet name="dialog"></router-outlet>

            <div *ngIf="appVersion" class="app__version">v.{{ appVersion }}</div>
        </div>
    `,
    animations: [
        trigger('flyInOut', [
            state(
                'open',
                style({
                    transform: 'translateX(0)'
                })
            ),
            state(
                'close',
                style({
                    transform: 'translateX(-100%)'
                })
            ),
            transition('close <=> open', animate('200ms ease-in-out')),
            transition(
                '* => inactive',
                style({
                    transform: 'translateX(0)'
                })
            )
        ]),
        trigger('shrink', [
            state(
                'open',
                style({
                    width: '18.75%',
                    display: 'block'
                })
            ),
            state(
                'close',
                style({
                    width: '0',
                    display: 'none'
                })
            ),
            state(
                'openCustomer',
                style({
                    transform: 'translateX(0)',
                    display: 'block'
                })
            ),
            state(
                'closeCustomer',
                style({
                    transform: 'translateX(100%)',
                    display: 'none'
                })
            ),
            transition('open <=> close', animate('200ms ease-in-out')),
            transition('openCustomer <=> closeCustomer', animate('200ms ease-in-out'))
        ]),
        trigger('fade', [
            state(
                'open',
                style({
                    opacity: '1',
                    display: 'block'
                })
            ),
            state(
                'close',
                style({
                    opacity: '0',
                    display: 'none'
                })
            ),
            transition('close <=> open', animate('200ms ease-in-out'))
        ])
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    public stateObj: IAppState = {
        customerCenter: false,
        showLeftMenu: false,
        showCookie: false,
        showCategory: AnimationState.Inactive,
        showMenuTrigger: false,
        showMinibasket: false,
        menuTriggerState: false,
        width: 0,
        device: '',
        title: '',
        pageTitle: '',
        mustChangePwd: false,
        adminMode: false,
        showImpersonated: false,
        homecarerMode: false
    };

    minibasketAnimationState = {
        shrink: '',
        fade: ''
    };

    public appVersion = '0.0.0';

    private unsubscribeS$: Subject<void> = new Subject();

    constructor(
        private utilService: UtilService,
        private routeService: RouteService,
        private titleService: Title,
        private userService: UserService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private settingService: SettingsService,
        private callsService: CallsService
    ) {}

    ngOnInit() {
        // We should disable cookie warning.
        // this could be temporary...
        // NOTE: please check https://jira.impact.dk/browse/INT-1605
        this.acceptCookie();

        this.settingService
            .getVersion()
            .pipe(take(1), takeUntil(this.unsubscribeS$))
            .subscribe(version => (this.appVersion = version));

        this.utilService.size$.pipe(takeUntil(this.unsubscribeS$)).subscribe(({ width }) => {
            this.stateObj.width = width;
            this.changeDetectorRef.detectChanges();
        });
        this.utilService.deviceType$.pipe(takeUntil(this.unsubscribeS$)).subscribe(device => {
            if (this.stateObj.device !== device) {
                if (this.stateObj.device === '') {
                    this.stateObj.device = device;
                    this.changeDetectorRef.detectChanges();
                } else {
                    this.stateObj.device = device;
                    this.changeDetectorRef.detectChanges();
                    this.showMenu();
                }
            }
        });

        this.isCookieSet();

        // Close menu if open in customerCenter when admin outlet changes
        this.routeService.secondaryRoutes$
            .pipe(
                filter(snapshot => snapshot.outlet === 'admin'),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                distinctUntilChanged<any, string>(
                    (a, b) => a === b,
                    x => x.routeConfig.path
                ),
                takeUntil(this.unsubscribeS$)
            )

            .subscribe(() => {
                if (this.stateObj.showCategory === AnimationState.Open) {
                    this.toggleMenu();
                }
            });

        // Process route
        combineLatest([this.routeService.activeRoute$, this.userService.user$])
            .pipe(takeUntil(this.unsubscribeS$))
            .subscribe(data => this._processRoute(data[0], data[1]));

        // Determine user state
        this.userService.activeRoles
            .pipe(takeUntil(this.unsubscribeS$))
            .subscribe(
                roles =>
                    (this.stateObj.customerCenter = !!(
                        roles.length && roles.some(x => IDENTITIES.customerCenter.includes(x))
                    ))
            );

        this.userService.isLoggedIn.pipe(takeUntil(this.unsubscribeS$)).subscribe(isLoggedIn => {
            if (!isLoggedIn) {
                this.stateObj.menuTriggerState = false;
            }
        });

        // Subscribe server-sent events from inbound calls
        this.callsService.callMessage$.pipe(takeUntil(this.unsubscribeS$)).subscribe(
            event => {
                return this.callsService.validateCallEvent(event);
            },
            err => {
                console.warn('Error watching call status', err);
                this.callsService.showCallEventError(Translations.genesys.errors.sourceEvent);
            }
        );
    }

    ngOnDestroy(): void {
        this.unsubscribeS$.next();
        this.unsubscribeS$.complete();
    }

    acceptCookie() {
        localStorage.setItem('cookieAccept', 'accept');
        this.stateObj.showCookie = false;
    }

    isCookieSet() {
        try {
            this.stateObj.showCookie = !localStorage.getItem('cookieAccept');
        } catch {
            console.warn('show cookie is not set or have an error');
        }
    }

    showMenu() {
        if (this.stateObj.homecarerMode) {
            this.stateObj.showMenuTrigger = false;
        } else if (this.stateObj.showLeftMenu) {
            if (this.stateObj.customerCenter) {
                this.stateObj.showMenuTrigger = true;
                this.stateObj.showCategory = this.stateObj.menuTriggerState
                    ? AnimationState.Open
                    : AnimationState.Close;
            } else {
                this.stateObj.showCategory = AnimationState.Inactive;
                this.stateObj.showMenuTrigger = this.stateObj.device !== 'desktop';
            }
        } else {
            if (this.stateObj.device === 'desktop') {
                if (this.stateObj.customerCenter) {
                    this.stateObj.showMenuTrigger = true;
                    this.stateObj.showCategory = this.stateObj.menuTriggerState
                        ? AnimationState.Open
                        : AnimationState.Close;
                } else {
                    this.stateObj.showCategory = AnimationState.Inactive;
                    this.stateObj.showMenuTrigger = false;
                }
            } else {
                this.stateObj.showMenuTrigger = true;
            }
        }
    }

    toggleMenu() {
        this.stateObj.menuTriggerState = !this.stateObj.menuTriggerState;
        this.showMenu();
    }

    showMinibasket() {
        if (this.stateObj.customerCenter) {
            if (this.stateObj.showMinibasket) {
                this.minibasketAnimationState.shrink = 'open';
            } else {
                this.minibasketAnimationState.fade = 'close';
            }
        } else {
            this.minibasketAnimationState.fade = '';
            this.minibasketAnimationState.shrink = this.stateObj.showMinibasket ? 'openCustomer' : 'closeCustomer';
        }
    }

    minibasketAnimationDone(event: AnimationEvent) {
        if (this.stateObj.showMinibasket) {
            if (
                event.triggerName === 'shrink' &&
                event.toState === 'open' &&
                (event.fromState === 'shrink' || event.fromState === 'void')
            ) {
                this.minibasketAnimationState.fade = event.toState;
            } else if (event.triggerName === 'fade' && event.toState === 'open' && event.fromState === 'open') {
                this.minibasketAnimationState.shrink = event.toState;
            }
        } else {
            this.minibasketAnimationState.shrink = 'shrink';
        }
        this.changeDetectorRef.detectChanges();
    }

    private _processRoute(snapshot: ActivatedRouteSnapshot, user: IUserViewModel | undefined) {
        const pageData: IDefaultPageData = snapshot.data.pageData || {};
        let pageTitle = '';
        let title = Translations.global.title;
        if (pageData.title) {
            pageTitle = pageData.title;
            title = title + ' - ' + pageTitle;
        }

        this.stateObj.mustChangePwd = false;
        this.stateObj.homecarerMode = false;

        const isLoggedIn = !!user;

        if (user && user.mustChangePwd) {
            this.stateObj.mustChangePwd = true;
            this.stateObj.showLeftMenu = false;
            this.stateObj.showMinibasket = false;
            this.stateObj.customerCenter = false;
        } else {
            this.stateObj.showImpersonated =
                user && user.roles && user.impersonatedCitizenQuickInfo
                    ? user.roles.indexOf(ROLES.HomeCarer) !== -1
                    : false;

            const routeUrl = this.router.url.split('?')[0].split('(')[0].substring(1);
            switch (routeUrl) {
                case '':
                    this.stateObj.showLeftMenu = isLoggedIn;
                    this.stateObj.showMinibasket = isLoggedIn;
                    break;

                case Translations.shop.paths.basketPage.path:
                    this.stateObj.showLeftMenu = false;
                    this.stateObj.showMinibasket = false;
                    break;

                case Translations.shop.paths.receiptPage.path:
                    this.stateObj.showLeftMenu = false;
                    this.stateObj.showMinibasket = false;
                    break;

                case 'pda':
                    this.stateObj.showLeftMenu = false;
                    this.stateObj.showMinibasket = false;
                    this.stateObj.showImpersonated = false;
                    this.stateObj.homecarerMode = true;
                    break;

                default:
                    this.stateObj.showLeftMenu = true;
                    this.stateObj.showMinibasket = isLoggedIn;
            }

            if (
                routeUrl.indexOf(Translations.callListAdmin.paths.calllist.path) === 0 ||
                routeUrl.indexOf(Translations.callListAdmin.paths.planning.path) === 0
            ) {
                // the call-list admin calendar views should hide the basket
                // todo: should we possibly refactor this?
                this.stateObj.showMinibasket = false;
                this.stateObj.adminMode = true;
            } else if (
                Object.values(Translations.municipality.paths)
                    .concat(Object.values(Translations.intervare.paths))
                    .map(x => x.path)
                    .filter(x => routeUrl.indexOf(x) === 0).length
            ) {
                this.stateObj.showMinibasket = false;
                this.stateObj.adminMode = true;
            } else {
                this.stateObj.adminMode = false;
            }

            this.showMinibasket();
            this.showMenu();

            if (!this.stateObj.customerCenter) {
                this.utilService.scrollElementTo(window, 0, false);
            }

            this.titleService.setTitle(title);
            this.stateObj.title = !pageData.hideTitleTag ? title : '';
            this.stateObj.pageTitle = !pageData.hideTitleTag ? pageTitle : '';
        }
    }
}
