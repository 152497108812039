import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { state, trigger, transition, style, animate } from '@angular/animations';
import { Translations } from 'Client/translations/translations';

import { IBasketRowViewModel, IReservationViewModel } from '../../api/services';
import { CURRENCY_CODE } from '../app.locale_data';
import { IChip, ProductService } from '../services/product.service';
import { getReservationStatus } from './commerce-utils';
import { IReservationStatus } from './commerce.models';

@Component({
    selector: 'iv-commerce-basket-item',
    template: `
        <div class="basket-item-layout commerce-basket-item"
             [class.commerce-basket-item--not-available]="reservationStatus?.showReservationErrors && reservationStatus?.isNotAvailable && reservationStatus?.canBeReserved"
             [class.commerce-basket-item--has-warning]="reservationStatus?.showReservationErrors && reservationStatus?.hasWarning && reservationStatus?.canBeReserved"
            role="row">
            <a class="basket-item-layout__image"
               [class.commerce-basket-item__image--not-available]="reservationStatus?.showReservationErrors && reservationStatus?.isNotAvailable && reservationStatus?.canBeReserved"
               [class.commerce-basket-item__image--has-warning]="reservationStatus?.showReservationErrors && reservationStatus?.hasWarning && reservationStatus?.canBeReserved"
               [routerLink]="['', { outlets: { dialog: ['product', item.id] } }]" role="cell">
                <img
                    *ngIf="item.primaryImage"
                    class="commerce-basket-item__image"
                    srcset="{{ item.primaryImage }}&w=105&h=105 1x, {{ item.primaryImage }}&w=210&h=210 2x"
                    src="{{ item.primaryImage }}&w=105&h=105"
                    width="105"
                    height="105"
                    loading="lazy"
                    [attr.alt]="item.name" />
            </a>

            <div class="basket-item-layout__content commerce-basket-item__content">
                <div class="basket-item-layout__row">
                    <div class="basket-item-layout__info commerce-basket-item__info"
                         [class.commerce-basket-item__info--not-available]="reservationStatus?.showReservationErrors && reservationStatus?.isNotAvailable && reservationStatus?.canBeReserved"
                         [class.commerce-basket-item__info--has-warning]="reservationStatus?.showReservationErrors && reservationStatus?.hasWarning && reservationStatus?.canBeReserved"
                         role="cell">
                        <div class="commerce-basket-item__name" role="heading">
                            <a [routerLink]="['', { outlets: { dialog: ['product', item.id] } }]">
                                {{ item.name }}
                            </a>
                        </div>

                        <div class="commerce-basket-item__prices_mobile">
                            <div class="commerce-basket-item__discount-price" *ngIf="isProductCampaignDiscount && item.campaign" [attr.aria-label]="'${Translations.commerce.basket.campaignPriceAlt}' | translation: item.name">
                                {{ item.campaign?.campaignPrice | currency : currencyCode : 'symbol-narrow' }}
                            </div>

                            <div class="commerce-basket-item__price" [class.commerce-basket-item__price_line-through]="isProductCampaignDiscount" [attr.aria-label]="'${Translations.commerce.basket.mainPriceAlt}' | translation: item.name">
                                {{ item.price | currency : currencyCode : 'symbol-narrow' }}
                            </div>
                        </div>

                        <div class="commerce-basket-item__description">
                            {{ item.description }} <span class="commerce-basket-item__unit-price_mobile">/ {{ item.unitPrice }}</span>
                        </div>
                    </div>

                    <div class="basket-item-layout__id commerce-basket-item__id" role="cell">
                        {{ item.catalogItemNo }}
                    </div>

                    <div class="basket-item-layout__favorite commerce-basket-item__favorite" role="cell">
                        <iv-commerce-toggle-favorite [productId]="item.id" [isFavorite]="item.favorite"></iv-commerce-toggle-favorite>
                    </div>

                    <div class="basket-item-layout__prices commerce-basket-item__prices" role="cell">
                        <div class="commerce-basket-item__discount-price" *ngIf="isProductCampaignDiscount && item.campaign && item?.campaign?.campaignPrice !== item.price" [attr.aria-label]="'${Translations.commerce.basket.campaignPriceAlt}' | translation: item.name">
                            {{ item.campaign?.campaignPrice | currency : currencyCode : 'symbol-narrow' }}
                        </div>

                        <div class="commerce-basket-item__price" [class.commerce-basket-item__price_line-through]="isProductCampaignDiscount && item?.campaign?.campaignPrice !== item.price" [attr.aria-label]="'${Translations.commerce.basket.mainPriceAlt}' | translation: item.name">
                            {{ item.price | currency : currencyCode : 'symbol-narrow' }}
                        </div>

                        <div class="commerce-basket-item__unit-price" [attr.aria-label]="'${Translations.commerce.basket.unitPriceAlt}' | translation: item.name">
                            {{ item.unitPrice }}
                        </div>
                    </div>

                    <div class="basket-item-layout__total-row-price commerce-basket-item__total-row-price" *ngIf="item.totalRowPrice" [attr.aria-label]="'${Translations.commerce.basket.totalPriceAlt}' | translation: item.name" role="cell">
                        {{ item.totalRowPrice | currency : currencyCode : 'symbol-narrow' }}
                    </div>

                    <div class="basket-item-layout__addtobasket commerce-basket-item__addtobasket" role="cell">
                        <iv-commerce-addtobasket [product]="item" mode="basket"></iv-commerce-addtobasket>
                    </div>
                </div>

                <div class="commerce-basket-item__bottom" *ngIf="item.labels?.length || chips.length" role="cell">
                    <div class="commerce-basket-item__labels" *ngIf="item.labels?.length">
                        <iv-commerce-labels [labels]="item.labels"></iv-commerce-labels>
                    </div>

                    <mat-chip-listbox class="commerce-basket-item__chips" *ngIf="chips.length">
                        <mat-chip class="commerce-basket-item__chip" *ngFor="let chip of chips" [color]="chip.color" [highlighted]="chip.selected">
                            {{ chip.text }}
                        </mat-chip>
                    </mat-chip-listbox>
                </div>
            </div>

            <iv-commerce-basket-item-reservation
                *ngIf="reservationStatus && reservationStatus?.showReservationErrors && (reservationStatus?.hasWarning || reservationStatus?.isNotAvailable) && reservationStatus?.canBeReserved"
                [@reservation]
                [replacementProduct]="item.replacementProduct"
                [quantity]="item.quantity"
                [productId]="item.id"
                [reservation]="item.reservation"
            ></iv-commerce-basket-item-reservation>
        </div>
    `,
    animations: [
        trigger('reservation', [
            state('void', style({ height: 0, minHeight: 0, visibility: 'hidden', overflow: 'hidden' })),
            state('*', style({ height: '*', visibility: 'visible', overflow: 'hidden' })),
            transition('void <=> *', animate('325ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class CommerceBasketItemComponent implements OnInit, OnChanges {
    @Input() item: IBasketRowViewModel & IReservationViewModel;

    chips: IChip[] = [];
    isProductCampaignDiscount = false;
    isSkeleton = true;
    reservationStatus: IReservationStatus;

    constructor(@Inject(CURRENCY_CODE) public currencyCode: string, private productService: ProductService) { }

    ngOnInit() {
        this.isProductCampaignDiscount = this.productService.isProductCampaignDiscount(this.item);
        this.chips = this.productService.createChips(this.item);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.item) {
            const { currentValue } = changes.item;

            if (currentValue.reservation) {
                this.checkForReservation(currentValue.reservation);
            }
        }

    }

    private checkForReservation(reservation: IReservationViewModel) {
        this.reservationStatus = getReservationStatus(reservation);
    }
}

