import { Component, OnDestroy } from '@angular/core';

import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Translations } from '../../translations/translations';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { CallListAdminService } from '../services/call-list-admin.service';
import { DialogService } from '../shared/dialog.service';
import { UtilService } from '../util/util.service';

@Component({
    selector: 'iv-assign-citizens',
    template: `
        <form (submit)="onConfirm()" #form>
            <mat-dialog-content>
                <h1 mat-dialog-title>${Translations.callListAdmin.assigncitizens.header}</h1>
                <p>${Translations.callListAdmin.assigncitizens.confirmText}</p>
            </mat-dialog-content>
            <mat-dialog-actions class="alt-theme">
                <button mat-raised-button color="primary">${Translations.callListAdmin.assigncitizens.okBtn}</button>
                <button mat-raised-button mat-dialog-close type="button">
                    ${Translations.callListAdmin.assigncitizens.cancelBtn}
                </button>
            </mat-dialog-actions>
        </form>
    `
})
export class AssignCitizensComponent implements OnDestroy {
    private unsubscribeS$: Subject<void> = new Subject();

    constructor(
        private dialogService: DialogService,
        private utilService: UtilService,
        private router: Router,
        private callListAdminService: CallListAdminService
    ) {}

    ngOnDestroy(): void {
        this.unsubscribeS$.next();
        this.unsubscribeS$.complete();
    }

    onConfirm() {
        this.callListAdminService
            .assignNewCitizens()
            .pipe(takeUntil(this.unsubscribeS$))
            .subscribe(
                () => {
                    if (this.utilService.isBrowser()) {
                        this.router
                            // eslint-disable-next-line no-null/no-null
                            .navigate([{ outlets: { dialog: null } }])
                            // eslint-disable-next-line no-self-assign
                            .then(() => (location.href = location.href));
                    }
                },
                (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
            );
    }
}
