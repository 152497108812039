import './app.locale_data';

import { ErrorHandler, LOCALE_ID, NgModule, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as Raven from 'raven-js';

import { environment } from '../environments/environment';
import { Translations } from '../translations/translations';
import { AdministrationModule } from './administration/administration.module';
import { CitizenModule } from './citizen/citizen.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CURRENCY_CODE } from './app.locale_data';
import { CommerceModule } from './commerce/commerce.module';
import { GlobalErrorHandler } from './global-error-handler';
import { LayoutModule } from './layout/layout.module';
import { MenuModule } from './menu/menu.module';
import { PageModule } from './page/page.module';
import { SharedModule } from './shared/shared.module';
import { UserModule } from './user/user.module';
import { UtilModule } from './util/util.module';
import { MunicipalityModule } from './municipality/municipality.module';
import { CallListAdminModule } from './call-list-admin/call-list-admin.module';
import { NotesAdminModule } from './notes-admin/notes-admin.module';
import { appInjector } from './app-injector';

if (environment.production) {
    Raven.config('https://bf9a1916e38d44ffbaeb08ccb32cb0d7@sentry.io/229169', {
        environment: environment.production ? 'production' : 'dev'
    }).install();
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,

        UtilModule.forRoot(),
        SharedModule.forRoot(),
        UserModule.forRoot(),

        LayoutModule,
        MenuModule,

        PageModule,
        AdministrationModule,
        CitizenModule,
        MunicipalityModule,
        CommerceModule,

        CallListAdminModule,
        NotesAdminModule,
        AppRoutingModule
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'da' },
        { provide: CURRENCY_CODE, useValue: Translations.variables.currencyCode },
        { provide: ErrorHandler, useClass: GlobalErrorHandler }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(private injector: Injector) {
        appInjector(injector);
    }
}
