import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { merge, Observable, of, Subject } from 'rxjs';
import { filter, switchMapTo, tap, map, takeUntil } from 'rxjs/operators';

import { IAddCitizenToIntervalResponse, ICallerDaySummaryViewModel, ISimpleCitizenDetail } from '../../api/services';
import { Translations } from '../../translations/translations';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { ICol, IEvent } from '../services/calendar.service';
import { CallListAdminService, IPlanningDayDetails } from '../services/call-list-admin.service';
import { DialogService } from '../shared/dialog.service';
import { IDefaultRouteData } from '../shared/route-data';
import { IDragEvent } from './drag.service';
import { UtilService } from '../util/util.service';

interface ICitizenDragValue {
    id: number;
    type: 'add' | 'move';
    targetCallerProfileId?: number;
}

@Component({
    selector: 'iv-planning-page',
    template: `
        <iv-drag-events-calendar
            [cols]="data.cols"
            (titleClicked)="onTitleClicked($event)"
            (eventClicked)="onEventClicked($event)"
            (dropped)="onDropped($event)"></iv-drag-events-calendar>
        <mat-tab-group [selectedIndex]="selectedIndex">
            <mat-tab label="${Translations.callListAdmin.planningPage.propertiesTab.tabHeader}">
                <div *ngIf="!selectedCallList">
                    <h3>${Translations.callListAdmin.planningPage.propertiesTab.header}</h3>
                    <p>${Translations.callListAdmin.planningPage.propertiesTab.description}</p>
                </div>
                <div *ngIf="selectedCallList && selectedSummary">
                    <h3>{{ selectedCallList.title }}</h3>

                    <form (submit)="onUpdateCapacity()" #form="ngForm">
                        <mat-form-field>
                            <input matInput
                                type="number"
                                placeholder="${Translations.callListAdmin.planningPage.propertiesTab.capacity}"
                                [(ngModel)]="capacity"
                                name="capacity"
                                required>
                        </mat-form-field>
                        <button *ngIf="capacity !== selectedSummary.kPIs!.capacity" mat-raised-button color="primary" [disabled]="!form.valid">${Translations.callListAdmin.planningPage.propertiesTab.updateCapacityBtn}</button>
                    </form>

                    <dl>
                        <dt>${Translations.callListAdmin.planningPage.propertiesTab.availableCapacity}:</dt>
                        <dd>{{ selectedSummary.kPIs!.availabilityCapacity }}</dd>

                        <dt>${Translations.callListAdmin.planningPage.propertiesTab.remainingCalls}:</dt>
                        <dd>{{ selectedSummary.kPIs!.missingCallsCount }}</dd>
                    </dl>

                    <h3>${Translations.callListAdmin.planningPage.propertiesTab.remainingCallsHeader}</h3>
                    <div class="citizen-controls" *ngIf="selectedSummary.missingCallCitizens.length">
                        <button mat-button type="button" (click)="missingCallsList.selectAll()">${Translations.global.btnSelectAll}</button>
                        <button mat-button type="button" (click)="missingCallsList.deselectAll()">${Translations.global.btnDeselectAll}</button>
                    </div>
                    <mat-selection-list #missingCallsList [(ngModel)]="missingCallList" [dragger]="missingCallList">
                        <mat-list-option
                            *ngFor="let call of selectedSummary.missingCallCitizens"
                            [value]="{ id: call.callRecordId, type: 'move' }"
                            checkboxPosition="before">
                                <div
                                    [ivCitizenTooltip]="call.customerNo"
                                    [tryCount]="call.tryCount"
                                    [ivCitizenColor]="call">
                                    {{ call.customerName }}
                                    <ng-container *ngIf="call.isPreferedDay">({{ call.preferedHour + '-' + (1 + call.preferedHour) }})</ng-container>
                                </div>
                        </mat-list-option>
                    </mat-selection-list>
                    <br>
                    <button mat-raised-button color="warn" *ngIf="(selectedEvent || selectedColumn) && missingCallList.length" (click)="removeCitizens()">${Translations.callListAdmin.planningPage.propertiesTab.removeBtn}</button>

                    <br/>
                    <h3>${Translations.callListAdmin.planningPage.propertiesTab.completedCallsHeader}</h3>
                    <mat-list>
                        <mat-list-item *ngFor="let call of selectedSummary.callDoneCitizens">
                            <div
                                [ivCitizenTooltip]="call.customerNo"
                                [tryCount]="call.tryCount"
                                [ivCitizenColor]="call">
                                {{ call.customerName }}
                                <ng-container *ngIf="call.isPreferedDay">({{ call.preferedHour + '-' + (1 + call.preferedHour) }})</ng-container>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </div>
            </mat-tab>
            <mat-tab label="${Translations.callListAdmin.planningPage.citizensTab.tabHeader}">
                <h3>${Translations.callListAdmin.planningPage.citizensTab.header}</h3>
                <p>${Translations.callListAdmin.planningPage.citizensTab.description}</p>
                <ng-container *ngIf="citizens$ | async | keyvalue as citizensMap;">
                    <ng-container *ngIf="citizensMap.length">
                        <div class="citizen-controls">
                            <button mat-button type="button" (click)="citizenSelectionList.selectAll()">${Translations.global.btnSelectAll}</button>
                            <button mat-button type="button" (click)="citizenSelectionList.deselectAll()">${Translations.global.btnDeselectAll}</button>
                        </div>
                        <mat-selection-list #citizenSelectionList [(ngModel)]="citizenList" [dragger]="citizenList">
                            <div *ngFor="let citizenEntry of citizensMap">
                                <h3>{{ citizenEntry.key }}</h3>

                                <mat-list-option
                                    *ngFor="let citizen of citizenEntry.value"
                                    [value]="{ id: citizen.customerId, type: 'add' }"
                                    checkboxPosition="before">
                                        <div
                                            [ivCitizenTooltip]="citizen.customerNo"
                                            [ivCitizenColor]="citizen">
                                            {{ citizen.customerName }}
                                            <ng-container *ngIf="citizen.isPreferedDay">({{ citizen.preferedHour + '-' + (1 + citizen.preferedHour) }})</ng-container>
                                        </div>
                                </mat-list-option>
                                <mat-divider></mat-divider>
                            </div>
                        </mat-selection-list>
                        <button mat-raised-button color="primary" (click)="assign()">${Translations.callListAdmin.planningPage.citizensTab.assignBtn}</button>
                    </ng-container>
                </ng-container>
                <ng-template #loading>
                    <div class="spinner">
                        <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="2" [diameter]="20"></mat-progress-spinner>
                    </div>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    `
})
export class PlanningPageComponent implements OnInit, OnDestroy {
    citizens$: Observable<Map<number, ISimpleCitizenDetail[]>>;

    data: IPlanningDayDetails = { title: '', cols: [], id: '' };

    selectedIndex = 0;
    capacity = 0;

    selectedCallList?: ICol;
    selectedSummary?: ICallerDaySummaryViewModel;
    selectedEvent?: IEvent;
    selectedColumn?: ICol;

    citizenList: ICitizenDragValue[] = [];
    missingCallList: ICitizenDragValue[] = [];
    
    private _scrollContainer: HTMLElement | Window = (document.querySelector('#content > .util-hidden-scroll') as HTMLElement) || window;
    private unsubscribeS$: Subject<void> = new Subject();

    constructor(
        private route: ActivatedRoute,
        private callListAdminService: CallListAdminService,
        private dialogService: DialogService,
        private utilService: UtilService
    ) { }

    ngOnInit() {
        this.route.data
            .pipe(takeUntil(this.unsubscribeS$))
            .subscribe((data: IDefaultRouteData<IPlanningDayDetails>) => {
                this.utilService.scrollElementTo(this._scrollContainer, 0);
                this.data = data.pageData!.data;
            });

        this.getCitizens();
    }

    ngOnDestroy(): void {
        this.unsubscribeS$.next();
        this.unsubscribeS$.complete();
    }

    getCitizens() {
        this.citizens$ = this.callListAdminService.getCitizensForCallday(this.data.id).pipe(
            map(citizens => {
                const citizensMap = new Map();
                citizens.forEach(citizen => {
                    const key = citizen.preferedHour;
                    const collection = citizensMap.get(key);
                    if (!collection) {
                        citizensMap.set(key, [citizen]);
                    } else {
                        collection.push(citizen);
                    }
                });
                return citizensMap;
            })
        );
    }

    assign() {
        this.callListAdminService.assignCitizensForCallday().pipe(
            switchMapTo(this.callListAdminService.getPlanningDayDetails(this.data.id)),
            takeUntil(this.unsubscribeS$)
        ).subscribe(data => {
            // citizens assigned automatically
            this.data = data;
            this.getCitizens();
        }, this.onValidationError);
    }

    onTitleClicked(column: ICol) {
        this.setSelected(column);
        this.selectedColumn = column;
        this.selectedIndex = 0;
    }

    onUpdateCapacity() {
        if (this.selectedCallList) {
            this.callListAdminService.updateCapacity(this.selectedCallList.id, this.capacity, this.data.id)
                .pipe(takeUntil(this.unsubscribeS$))   
                .subscribe(details => {
                    this.data = details;
                    this.getSummary();
                }, this.onValidationError);
        }
    }

    onEventClicked(event: IEvent) {
        if (event.disableDrag) { return; }
        // find the parent-callList and assign as selectedCallList
        const column = this.data.cols.filter(col => col.slots.filter(slot => slot.events && slot.events.filter(x => x === event)[0])[0])[0];
        event.selected = true;
        this.setSelected(column, event);
    }

    onDropped({ value, where, targetCallerProfileId }: IDragEvent) {
        const citizens: ICitizenDragValue[] = value;
        const addCitizens = citizens.filter(x => x.type === 'add').map(x => x.id);
        const moveCitizens = citizens.filter(x => x.type === 'move').map(x => x.id);

        const addOperation = addCitizens.length
            ? this.callListAdminService.addCitizens(where['intervalId'], addCitizens)
            : of<IAddCitizenToIntervalResponse>(({ isSuccess: true }));
        const moveOperation = moveCitizens.length
            ? this.callListAdminService.moveCitizens(where['intervalId'], moveCitizens, targetCallerProfileId)
            : of<IAddCitizenToIntervalResponse>(({ isSuccess: true }));

        merge(
            addOperation,
            moveOperation
        ).pipe(
            tap(data => {
                if (data.citizensNotAllowedToMove && data.citizensNotAllowedToMove.length) {
                    this.dialogService.showMessage(Translations.replaceTokens(Translations.callListAdmin.planningPage.moveCitizensError, '<br>' + data.citizensNotAllowedToMove.map(x => '- ' + x.customerName).join('<br>')));
                }
            }),
            switchMapTo(this.callListAdminService.getPlanningDayDetails(this.data.id)),
            takeUntil(this.unsubscribeS$)
        ).subscribe(details => {
            this.data = details;
            this.getCitizens();
            this.getSummary();
        }, this.onValidationError);
    }

    removeCitizens() {
        if (!this.selectedCallList || (!this.selectedEvent && !this.selectedColumn)) {
            return;
        }

        this.dialogService.confirm(Translations.callListAdmin.planningPage.propertiesTab.removeConfirm).afterClosed().pipe(
            filter((res: boolean) => res),
            switchMapTo(this.callListAdminService.removeCitizensFromPlanningDayInterval(this.missingCallList.map(x => x.id))),
            switchMapTo(this.callListAdminService.getPlanningDayDetails(this.data.id)),
            takeUntil(this.unsubscribeS$)
        ).subscribe(details => {
            this.data = details;
            this.getCitizens();
            this.getSummary();
        }, this.onValidationError);
    }

    onValidationError = (err: IntervareHttpErrorResponse) => {
        this.dialogService.showValidationResult(err.validationErrors);
    }

    private setSelected(column: ICol, event?: IEvent) {
        this.capacity = 0;
        this.selectedEvent = event;
        this.selectedCallList = column;
        this.data.cols = this.data.cols.map(col => {
            col.selected = col.id === column.id;

            col.slots = col.slots.map(slot => {
                if (slot.events && slot.events.length) {
                    slot.events.forEach(x => {
                        x.selected = event ? x.intervalId === event.intervalId : false;
                    });
                }
                return slot;
            });

            return col;
        });

        this.getSummary();
    }

    private getSummary() {
        this.selectedSummary = undefined;

        if (!this.selectedCallList) {
            return;
        }

        this.missingCallList = [];
        this.citizenList = [];

        this.callListAdminService.getPlanningDaySummary(this.selectedCallList.id, this.data.id, this.selectedEvent ? this.selectedEvent.intervalId : undefined)
            .pipe(takeUntil(this.unsubscribeS$))    
            .subscribe(summary => {
                this.selectedSummary = summary;
                this.capacity = summary.kPIs!.capacity;
            });
    }
}
