import { Component, Input } from '@angular/core';

import { ICauseViewModel, SubCauseViewModel } from '../../api/services';
import { pen } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { DialogService } from '../shared/dialog.service';
import { NotesAdminCreateSubcauseComponent } from './notes-admin-create-subcause.component';

@Component({
    selector: 'iv-notes-admin-cause',
    template: `
        <iv-accordion (open)="accordionOpen = true" (close)="accordionOpen = false">
            <span class="category-menu__link" [class.category-menu__link_active]="accordionOpen" accordion-title>
                {{ cause.causeName }}
                <a [routerLink]="['', { outlets: { dialog: ['${Translations.intervare.paths.notesAdmin.path}', 'cause', cause.causeId]} }]" class="edit-icon" (click)="onEdit($event)">${pen}</a>
            </span>
            <div class="category-menu__level" accordion-content>
                <a class="category-menu__link" [routerLink]="['', { outlets: { dialog: ['${Translations.intervare.paths.notesAdmin.path}', 'subCause', cause.causeId, ''] } }]" routerLinkActive="category-menu__link_active">${Translations.intervare.notesAdmin.navigation.createSubCause}</a>
                <ng-container *ngIf="accordionOpen">
                    <iv-notes-admin-subcause [subCause]="subCause" *ngFor="let subCause of cause.subCauses"></iv-notes-admin-subcause>
                </ng-container>
            </div>
        </iv-accordion>
  `
})
export class NotesAdminCauseComponent {
    @Input() cause: ICauseViewModel;
    accordionOpen = false;

    constructor(
        private dialog: DialogService
    ) { }

    onEdit(event: Event) {
        event.stopPropagation();
    }
}
