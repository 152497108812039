import { WeekDays, IAvailabilityViewModel } from '../../api/services';
import { Translations } from '../../translations/translations';
import { toFormOptions } from '../form-builder/form-builder-mappings';
import { Subject } from 'rxjs';
import { FormBuilderOption } from '../form-builder/form-builder-element.model';

export class MunicipalityDeliveryHelpers {
    static updateDeliveryDays(value: number[], exclude?: number, ...subjects: Subject<FormBuilderOption[]>[]) {
        const next = value.length ? value.filter(x => x !== exclude).map(x => toFormOptions(x, Translations.municipality.subscribeCitizen.weekDaysTranslations[x])) : [];

        subjects.forEach(subject => {
            subject.next(next);
        });
    }

    static updateOrderDays(exclude: number, ...subjects: Subject<FormBuilderOption[]>[]) {
        const next = exclude ? Object.values(WeekDays).filter(x => this.filterDays(x, exclude)).map(x => toFormOptions(x, Translations.municipality.subscribeCitizen.weekDaysTranslations[x])) : [];

        subjects.forEach(subject => {
            subject.next(next);
        });
    }

    static updateTimeslots(timeslots: IAvailabilityViewModel[], ...subjects: Subject<FormBuilderOption[]>[]) {
        subjects.forEach(subject => {
            subject.next(timeslots.map(x => toFormOptions(x.startingHour, `${Translations.municipality.subscribeCitizen.info.clock} ${x.startingHour}.${x.startingMinutes !== 0 ? x.startingMinutes : '00'} - ${x.endingHour}.${x.endingMinutes !== 0 ? x.endingMinutes : '00'} (${x.availability})`)));
        });
    }

    static filterDays(day: number | string, exclude: number): boolean {
        const previousDay = exclude - 1 !== 0 ? exclude - 1 : 5;
        return typeof day !== 'string' && day !== exclude && day !== previousDay && day !== WeekDays.Unspecified && day !== WeekDays.Saturday && day !== WeekDays.Sunday;
    }
}
