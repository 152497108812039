import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';
import { IBasketRowViewModel, IUserViewModel, UserViewModel } from '../../api/services';
import { CURRENCY_CODE } from '../app.locale_data';
import { BasketService, BasketStates } from '../services/basket.service';
import { Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { Translations } from '../../translations/translations';
import { IDENTITIES, IUserRoles } from '../user/user-roles';
import { UserService } from '../user/user.service';

@Component({
    selector: 'iv-commerce-minibasket',
    template: `
        <div class="commerce-minibasket">
            <div class="commerce-minibasket__top">
                <div class="commerce-minibasket__head">
                    ${Translations.commerce.basket.basket}
                </div>

                <div class="commerce-minibasket__items" *ngIf="basket && basket !== 'empty' && basket !== 'impersonate'; else unavailable">
                    <iv-util-hidden-scroll class="commerce-minibasket__hidden-scroll" height="100%">
                        <iv-commerce-minibasket-item [item]="item" *ngFor="let item of basket.rows; trackBy: trackByItem"></iv-commerce-minibasket-item>
                    </iv-util-hidden-scroll>
                </div>

                <ng-template #unavailable>
                    <div class="commerce-minibasket__status alt-theme" *ngIf="!basket">
                        <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="3" [diameter]="60"></mat-progress-spinner>
                    </div>

                    <div class="commerce-minibasket__status" *ngIf="basket === 'empty'">
                        ${Translations.commerce.basket.emptyBasket}
                    </div>

                    <div class="commerce-minibasket__status" *ngIf="basket === 'impersonate'">
                        ${Translations.commerce.basket.impersonate}
                    </div>
                </ng-template>
            </div>

            <iv-commerce-delivery-date *ngIf="isAdminOrCustomerService && basket && basket !== 'empty' && basket !== 'impersonate' && state"
                                       [basket]="basket"
                                       [isCompact]="true"
                                       [showDeliveryDateButtons]="false"
            ></iv-commerce-delivery-date>

            <div class="commerce-minibasket__bottom">
                <ng-container *ngIf="basket && basket !== 'empty' && basket !== 'impersonate'">
                    <div class="commerce-minibasket__max-buy" *ngIf="basket.maxBuyLimit">
                        <div class="alert alert-info" *ngIf="basket.remainingBeforeBuyLimit >= 0; else buyLimitReached">
                                {{ '${Translations.commerce.basket.maxbuy}' | translation : (basket.maxBuyLimit | currency : currencyCode : 'symbol-narrow' : '1.0-2') }}
                            </div>

                        <ng-template #buyLimitReached>
                            <div class="alert alert-danger">
                                {{ '${Translations.commerce.basket.buyLimitReached}' | translation : (-basket.remainingBeforeBuyLimit | currency : currencyCode : 'symbol-narrow') }}
                            </div>
                        </ng-template>
                    </div>

                    <div class="commerce-minibasket__info">
                        <div class="commerce-minibasket__quantity">
                            {{ '${Translations.commerce.basket.quantity}' | translation : basket.rows.length + ' ' + (basket.rows.length === 1 ? '${Translations.commerce.basket.linePluralization.line}' : '${Translations.commerce.basket.linePluralization.lines}') : basket.totalProducts + ' ' +  (basket.totalProducts === 1 ? '${Translations.commerce.basket.productPluralization.product}' : '${Translations.commerce.basket.productPluralization.products}') }}
                        </div>

                        <div class="commerce-minibasket__amount">
                            {{ basket.amount | currency : currencyCode : 'symbol-narrow' }}
                        </div>
                    </div>
                </ng-container>

                <a class="commerce-minibasket__go-to-basket" routerLink="/${Translations.shop.paths.basketPage.path}" mat-raised-button color="primary">${Translations.commerce.basket.goToBasket}</a>
            </div>
        </div>
    `
})
export class CommerceMinibasketComponent implements OnInit, OnDestroy {
    @Input() isCustomerCenter: boolean;
    @Input() state = true;

    basket: BasketStates;
    isAdminOrCustomerService = false;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        @Inject(CURRENCY_CODE) public currencyCode: string,
        private basketService: BasketService,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.userService.user$.pipe(
            filter(user => {
                if (!user) { return false; }
                return user instanceof UserViewModel;
            }),
            map(user => user as IUserViewModel),
            tap(user => {
                const userRoles = user.roles as IUserRoles[];
                this.isAdminOrCustomerService = userRoles.length && userRoles.some(x => IDENTITIES.adminAndCustomerService.includes(x)) ? true : false;
            }),
            switchMap(_ => this.basketService.basket$),
            takeUntil(this.unsubscribe)
        ).subscribe(basket => this.basket = basket);
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    trackByItem(index: number, item: IBasketRowViewModel): string | number {
        return item.id ?? index;
    }
}
