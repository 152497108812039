import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { ISubSubCauseViewModel } from '../../../api/services';
import { NotesAdminService } from '../notes-admin.service';
import { Observable } from 'rxjs';

export function NotesAdminSubSubCauseResolver(activatedRouteSnapshot: ActivatedRouteSnapshot,): Observable<ISubSubCauseViewModel> | ISubSubCauseViewModel | undefined  {
    const notesAdminService: NotesAdminService = inject(NotesAdminService);
    const id: string | null = activatedRouteSnapshot.paramMap.get('id');
    const subCauseId: string | null = activatedRouteSnapshot.paramMap.get('subCauseId');
    return id
        ? notesAdminService.getSubSubCause(id)
        : subCauseId
            ? { subCauseId: +subCauseId } as ISubSubCauseViewModel
            : undefined;
}
