import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { ISubCauseViewModel } from '../../../api/services';
import { NotesAdminService } from '../notes-admin.service';
import { Observable } from 'rxjs';


export function NotesAdminSubCauseResolver(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<ISubCauseViewModel> | ISubCauseViewModel | undefined {

    const notesAdminService: NotesAdminService = inject(NotesAdminService);
    const id: string | null = activatedRouteSnapshot.paramMap.get('id');
    const causeId: string | null = activatedRouteSnapshot.paramMap.get('causeId');
    return id
        ? notesAdminService.getSubCause(id)
        : causeId
            ? { causeId: +causeId } as ISubCauseViewModel
            : undefined;
}
