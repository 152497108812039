import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'includesDataValue',
    pure: false
})
export class IncludesDataValuePipe implements PipeTransform {
    public transform(data: string[] | number[] | any[], value: string | number): boolean {
        if (Array.isArray(data)) {
            return data.includes(value);
        }
        return false;
    }
}
