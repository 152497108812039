import { Pipe, PipeTransform } from '@angular/core';
import { OrderHistoryRowViewModel } from 'Client/api/services';
import { getOrdersRowQuantity } from '../helpers/orders.helper';

@Pipe({
    name: 'orderQuantity'
})
export class OrderQuantityPipe implements PipeTransform {
    transform(order: OrderHistoryRowViewModel): number {
        return getOrdersRowQuantity(order);
    }
}
