import { HttpParams } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Subject, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ISkipTakeResultObjectOfUser, IUser } from '../../api/services';
import { environment } from '../../environments/environment';
import { excel, lock, municipalityportal, pen, trash } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import {
    FormBuilderCheckbox,
    FormBuilderMunicipalityPicker, FormBuilderSeparator,
    FormBuilderTextInput,
    FormBuilderTypes,
    FormUser
} from '../form-builder/form-builder-element.model';
import { FormBuilderService } from '../form-builder/form-builder.service';
import { AuthService } from '../services/auth.service';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { DialogService } from '../shared/dialog.service';
import { UserService } from '../user/user.service';
import { UtilUrlService } from '../util/util-url.service';
import { UtilService } from '../util/util.service';
import { IUserDefaultMunicipalities, MunicipalityService } from './municipality.service';
import { DialogUserManagementService } from '../shared/dialog-user-management.service';
import { transformQueryParamsIntoMunicipalities } from '../util/helpers';
import { InternalUserService } from '../services/internal-user.service';

@Component({
    selector: 'iv-municipality-user-list',
    template: `
        <section class="municipality-user-list" *ngIf="!isFetching; else fetching">
            <div class="municipality-user-list__form">
                <form [formGroup]="form" class="alt-theme form no-margin-bottom">
                    <iv-form-builder-element
                        *ngFor="let input of inputs"
                        [form]="form"
                        [input]="input"
                    ></iv-form-builder-element>
                </form>

                <div class="mat-table__shade municipality-user-list__fetching" *ngIf="isLoadingResults">
                    <mat-spinner diameter="48"></mat-spinner>
                </div>
            </div>

            <div class="errors" *ngIf="validationErrors">
                <h4 *ngFor="let error of validationErrors">{{ error }}</h4>
            </div>

            <div class="municipality-user-list__table">


                <ng-container *ngIf="users && users.data.length">
                    <hr />

                    <a href="${environment.apiBaseUrl}/api/User/ExportMunicipalityUsers?{{ params }}"
                        target="_blank"
                        class="btn-with-icon btn-with-icon_last"
                        mat-raised-button
                        color="primary"
                    >
                        ${Translations.municipality.common.exportToExcel}<span class="icon">${excel}</span>
                    </a>

                    <hr />
                </ng-container>

                <mat-table #table [dataSource]="users">
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef>
                            <iv-ellipsis text="${Translations.municipality.userList.table.name}"></iv-ellipsis>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <iv-ellipsis [text]="row.name"></iv-ellipsis>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="username">
                        <mat-header-cell *matHeaderCellDef>
                            <iv-ellipsis text="${Translations.municipality.userList.table.username}"></iv-ellipsis>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <iv-ellipsis [text]="row.username"></iv-ellipsis>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="district">
                        <mat-header-cell *matHeaderCellDef>
                            <iv-ellipsis text="${Translations.municipality.userList.table.district}"></iv-ellipsis>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" [title]="row.districts | districtNames">
                        <iv-ellipsis [text]="row.districts | districtNames : true"></iv-ellipsis>

                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="subdistrict">
                        <mat-header-cell *matHeaderCellDef>
                            <iv-ellipsis text="${Translations.municipality.userList.table.subDistrict}"></iv-ellipsis>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" [title]="row.subDistricts | subDistrictNames">
                            <iv-ellipsis [text]="row.subDistricts | subDistrictNames: true"></iv-ellipsis>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="superuser">
                        <mat-header-cell *matHeaderCellDef>
                            <iv-ellipsis text="${Translations.municipality.userList.table.superuser}"></iv-ellipsis>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{ row.isSuperUser ? 'x' : '' }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="pdauser">
                        <mat-header-cell *matHeaderCellDef>
                            <iv-ellipsis text="${Translations.municipality.userList.table.pdauser}"></iv-ellipsis>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{ row.isPdaUser ? 'x' : '' }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="ring1">
                        <mat-header-cell *matHeaderCellDef>
                            <iv-ellipsis text="Ring1"></iv-ellipsis>
                        </mat-header-cell>                        
                       <mat-cell *matCellDef="let row">{{ row.ring1Mail ? 'x' : '' }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="ring2">
                        <mat-header-cell *matHeaderCellDef>
                            <iv-ellipsis text="Ring2"></iv-ellipsis>
                       </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{ row.ring2Mail ? 'x' : '' }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="seddel1">
                        <mat-header-cell *matHeaderCellDef>
                            <iv-ellipsis text="Seddel1"></iv-ellipsis>
                      </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{ row.note1Mail ? 'x' : '' }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="seddel2">
                        <mat-header-cell *matHeaderCellDef>
                            <iv-ellipsis text="Seddel2"></iv-ellipsis>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{ row.note2Mail ? 'x' : '' }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="inactive">
                        <mat-header-cell *matHeaderCellDef>
                        <iv-ellipsis text="${Translations.municipality.userList.table.inactive}"></iv-ellipsis>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{ row.isDeleted ? 'x' : '' }}</mat-cell>
                    </ng-container>
                    

                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <a
                                class="icon"
                                [routerLink]="['/${Translations.municipality.paths.createUser.path}', row.userId]"
                                matTooltip="${Translations.municipality.userList.table.editUserTitle}"
                                >${pen}</a
                            >
                            <button
                                class="municipality-user-list__edit-username icon"
                                (click)="editUsername(row)"
                                matTooltip="${Translations.municipality.userList.table.changeUsernameTitle}"
                            >
                                ${municipalityportal}
                            </button>
                            <button
                                class="municipality-user-list__edit-password icon"
                                (click)="editPassword(row.username)"
                                matTooltip="${Translations.municipality.userList.table.changePasswordTitle}"
                            >
                                ${lock}
                            </button>
                            <button class="municipality-user-list__inactive-user icon"
                                matTooltip="${Translations.municipality.userList.table.inactiveUser}"
                                (click)="inactivateUser(row.username)"
                            >
                                ${trash}
                            </button>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>
            </div>

            <div class="municipality-user-list__results-empty" *ngIf="users && users.data.length === 0">
                ${Translations.forms.labels.municipality.noUsersFound}
            </div>
        </section>

        <ng-template #fetching>
            <div class="municipality-citizen-drafts__fetching center-content alt-theme">
                <mat-progress-spinner
                    color="accent"
                    mode="indeterminate"
                    [strokeWidth]="3"
                    [diameter]="60"
                ></mat-progress-spinner>
            </div>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MunicipalityUserListComponent implements OnInit, OnDestroy {
    inputs: FormBuilderTypes[];
    form: UntypedFormGroup;
    users = new MatTableDataSource<IUser>();
    displayedColumns = [
        'name',
        'username',
        'district',
        'subdistrict',
        'superuser',
        'pdauser',
        'ring1',
        'ring2',
        'seddel1',
        'seddel2',
        'inactive',
        'actions',
    ];

    isLoadingResults = false;
    isFetching = true;

    validationErrors: string[] = [];

    params: string;

    private unsubscribe = new Subject();
    private _scrollContainer: HTMLElement | Window =
        (document.querySelector('#content > .util-hidden-scroll') as HTMLElement) || window;

    constructor(
        private userService: UserService,
        private formBuilder: FormBuilderService,
        private municipality: MunicipalityService,
        private authService: AuthService,
        private dialogService: DialogService,
        private dialogUserManagementService: DialogUserManagementService,
        private urlService: UtilUrlService,
        private route: ActivatedRoute,
        private utilService: UtilService,
        private cd: ChangeDetectorRef,
        private readonly internalUserService: InternalUserService,
    ) { }

    ngOnInit() {
        this.utilService.scrollElementTo(this._scrollContainer, 0);
        // Get the user roles
        this.userService.user$
            .pipe(
                map(user => {
                    const municipalityParams = transformQueryParamsIntoMunicipalities(user, this.route.snapshot.queryParams);
                    const searchParams = this.route.snapshot.queryParams?.searchText ?? '';
                    const includeInactive = this.route.snapshot.queryParams?.includeInactive === "true" ? true : false;

                    return {
                        municipality: municipalityParams,
                        Username: searchParams,
                        includeInactive: includeInactive
                    };
                }),
                catchError((err, caught) => {
                    this.handleWithErrors(err);
                    return caught;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(
                results => this._buildForm(results.municipality, results.Username, results.includeInactive),
                err => this.handleWithErrors(err)
            );
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    editPassword(userName: string) {
        this.dialogUserManagementService.changePassword()
            .pipe(
                filter(res => !!res),
                map(res => ({ userName, password: res.Password })),
                switchMap(res => this.authService.putResetPassword(res)),
                takeUntil(this.unsubscribe)
            )
            .subscribe(
                () =>
                    this.dialogService.showSnackMessage({
                        message: Translations.forms.labels.editPassword.passwordChanged
                    }),
                (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
            );
    }

    editUsername(user: IUser) {
        this.dialogUserManagementService.changeUsername(user)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (user) => {

                    // let's update the username in the list without fetching again all the list
                    const userIndex = this.users.data.findIndex(x => x.username === user.currentUsername);
                    this.users.data[userIndex].username = user.newUsername;
                    this.dialogService.showSnackMessage({
                        message: Translations.forms.labels.editPassword.passwordChanged
                    })

                    this.cd.markForCheck();
                },
                (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
            );
    }

    inactivateUser(username: string) {
        this.dialogService.confirm(Translations.replaceTokens(Translations.municipality.viewUsers.confirmDialog.description, username))
            .afterClosed()
            .pipe(
                switchMap(response => {
                    if (!response) {
                        return EMPTY;
                    }

                    return this.internalUserService.inactivateUser(username);
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                // reset all the flags without fetching the list again
                this.users.data = this.users.data.map(user => user.username === username ? { ...user, isDeleted: true, isPdaUser: false, isSuperUser: false, ring1Mail: false, ring2Mail: false, note1Mail: false, note2Mail: false } : user);
                this.dialogService.showSnackMessage({ message: Translations.replaceTokens(Translations.municipality.viewUsers.confirmDialog.confirmed, username) });
            },
                (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors));
    }

    /**
     * Builds the form converts it to a formgroup for usage
     * @private
     */
    private _buildForm(defaultMunicipalities?: IUserDefaultMunicipalities, username?: string, includeInactive?: boolean) {
        this.inputs = [
            new FormBuilderMunicipalityPicker({
                name: 'districts',
                value: {
                    municipality: defaultMunicipalities ? defaultMunicipalities.municipality : 0,
                    districts: defaultMunicipalities ? defaultMunicipalities.districts : 0,
                    subdistricts: defaultMunicipalities ? defaultMunicipalities.subdistricts : [],
                },
                multipleSubdistricts: true,
                required: true,
                valueChanged: (value, form) => {
                    if (value) {
                        this.resetForm(form, {
                            Username: ''
                        });
                    }
                }
            }),
            new FormBuilderSeparator({ name: 'separator' }),
            new FormBuilderTextInput({
                name: Translations.forms.labels.municipality.searchUsername.name,
                label: Translations.forms.labels.municipality.searchUsername.label,
                required: false,
                value: username,
                valueChanged: (value, form) => {
                    if (value) {
                        this.resetForm(form, {
                            districts: {
                                municipality: '',
                                districts: '',
                                subdistricts: ''
                            }
                        });
                    }
                }
            }),
            new FormBuilderCheckbox({
                name: Translations.forms.labels.municipality.includeInactive.name,
                label: Translations.forms.labels.municipality.includeInactive.label,
                value: includeInactive,
            })
        ];

        this.form = this.formBuilder.toFormGroup(this.inputs);
        this._watchFormChanges();
        this.form.updateValueAndValidity();
    }

    /**
     *
     * Listens for changes in the formular and fetches the list of users
     * based on the filters chosen
     *
     * @private
     */
    private _watchFormChanges() {

        this.form.valueChanges
            .pipe(
                distinctUntilChanged(),
                tap(data => {
                    this.isLoadingResults = true;
                    this.cd.markForCheck();

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const params: any = {};
                    if (data.districts.municipality) {
                        params.municipalities = data.districts.municipality;
                    }
                    if (data.districts.districts) {
                        params.districts = data.districts.districts;
                    }
                    if (data.districts.subdistricts && data.districts.subdistricts.length > 0) {
                        params.subDistricts = data.districts.subdistricts;
                    }
                    if (data.Username) {
                        params.searchText = data.Username;
                    }

                    params.includeInactive = data.includeInactive;

                    this.params = new HttpParams({ fromObject: params }).toString();
                    this.urlService.setState(this.params);
                }),
                switchMap(data => {
                    if (data.districts.municipality > 0) {
                        return this.municipality.getUsers(
                            data.districts.municipality,
                            data.districts.districts,
                            data.districts.subdistricts,
                            0,
                            9999,
                            data.includeInactive
                        );
                    }

                    if (data?.Username) {
                        return this.municipality.getUserByName(data.Username, data.includeInactive);
                    }

                    return of<ISkipTakeResultObjectOfUser>({ results: [], numFound: 0 });
                }),
                catchError((err, caught) => {
                    this.handleWithErrors(err);
                    return caught;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(
                res => {
                    this.users.data = res.results || [];
                    this.isFetching = false;
                    this.isLoadingResults = false;
                    this.cd.markForCheck();
                },
                err => {
                    this.handleWithErrors(err);
                }
            );
    }

    private resetForm(form?: UntypedFormGroup, values: FormUser = {}): void {
        form?.patchValue(values, { emitEvent: false });
    }

    private handleWithErrors(err: IntervareHttpErrorResponse) {
        this.validationErrors = [];

        if (err.validationErrors && err.validationErrors.length > 0) {
            this.validationErrors = err.validationErrors;
        } else {
            this.validationErrors.push(err.error?.statusText);
        }

        this.isFetching = false;
        this.isLoadingResults = false;

        this.users.data = [];
        this.cd.markForCheck();
    }
}
