import { Component, OnInit } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMapTo, tap } from 'rxjs/operators';

import { ISimpleCitizen, IUnassignedCitizensPerDayOfWeek } from '../../api/services';
import { Translations } from '../../translations/translations';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { CalendarService, ICol, IEvent, ISlot } from '../services/calendar.service';
import { CallListAdminService, ICallListDetails, ITimeSlot } from '../services/call-list-admin.service';
import { DialogService } from '../shared/dialog.service';
import { IDefaultRouteData } from '../shared/route-data';
import { IDragEvent } from './drag.service';
import { UtilService } from '../util/util.service';

interface ICitizenDay {
    title: string;
    callList: ISimpleCitizen[];
}

@Component({
    selector: 'iv-call-list-page',
    template: `
        <iv-drag-slots-calendar
            *ngIf="selectedIndex === 2"
            [cols]="callList.cols"
            (dropped)="onIntervalDropped($event)"
            (titleClicked)="onTitleClicked($event)"
            (eventClicked)="onEventClicked($event)"></iv-drag-slots-calendar>

        <iv-drag-events-calendar
            *ngIf="selectedIndex === 0 || selectedIndex === 1"
            [cols]="callList.cols"
            (dropped)="onCitizensDropped($event)"
            (titleClicked)="onTitleClicked($event)"
            (eventClicked)="onEventClicked($event)"></iv-drag-events-calendar>

        <mat-tab-group [(selectedIndex)]="selectedIndex">
            <mat-tab label="${Translations.callListAdmin.callListPage.citizenTab.tabHeader}">
                <h3>${Translations.callListAdmin.callListPage.citizenTab.header}</h3>
                <p>${Translations.callListAdmin.callListPage.citizenTab.description}</p>
                <ng-container *ngIf="citizens$ | async as citizens">
                    <div class="citizen-controls" *ngIf="citizens.length">
                        <button mat-button type="button" (click)="citizenSelectionList.selectAll()">${Translations.global.btnSelectAll}</button>
                        <button mat-button type="button" (click)="citizenSelectionList.deselectAll()">${Translations.global.btnDeselectAll}</button>
                    </div>
                    <mat-selection-list #citizenSelectionList [(ngModel)]="citizenList" [dragger]="citizenList" [class.fetching]="fetchingCitizens">
                        <div *ngFor="let day of citizens">
                            <h3>{{ day.title }}</h3>
                            <mat-list-option
                                *ngFor="let citizen of day.callList"
                                [value]="citizen.customerId"
                                checkboxPosition="before">
                                    <div
                                        [ivCitizenTooltip]="citizen.customerNo"
                                        [ivCitizenColor]="citizen">
                                        {{ citizen.customerName }}
                                    </div>
                            </mat-list-option>
                            <mat-divider></mat-divider>
                        </div>
                        <div class="spinner" *ngIf="fetchingCitizens">
                            <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="2" [diameter]="20"></mat-progress-spinner>
                        </div>
                    </mat-selection-list>
                </ng-container>
            </mat-tab>
            <mat-tab label="${Translations.callListAdmin.callListPage.propertiesTab.tabHeader}">
                <h3>${Translations.callListAdmin.callListPage.propertiesTab.header}</h3>
                <p *ngIf="!selectedEvent">${Translations.callListAdmin.callListPage.propertiesTab.noneSelected}</p>

                <div *ngIf="selectedEvent">
                    <form (submit)="saveEvent()">
                        <mat-form-field>
                            <mat-select placeholder="${Translations.callListAdmin.callListPage.propertiesTab.startHoursLabel}" [(ngModel)]="startHour" name="startHour">
                                <mat-option *ngFor="let time of startHours" [value]="time">{{ time }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-select placeholder="${Translations.callListAdmin.callListPage.propertiesTab.startMinsLabel}" [(ngModel)]="startMin" name="startMin">
                                <mat-option *ngFor="let time of startMins" [value]="time">{{ time }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <button mat-raised-button color="primary">${Translations.callListAdmin.callListPage.propertiesTab.okBtn}</button><br/>
                        <button mat-raised-button color="warn" type="button" (click)="deleteEvent()">${Translations.callListAdmin.callListPage.propertiesTab.deleteBtn}</button>
                    </form>
                    <ng-container *ngIf="!selectedEvent.isPause">
                        <br/><br/>
                        <h3>${Translations.callListAdmin.callListPage.propertiesTab.citizenHeader}</h3>
                        <div class="citizen-controls" *ngIf="!fetchingAssignedCitizens && assignedCitizens.length">
                            <button mat-button type="button" (click)="selectedCitizens.selectAll()">${Translations.global.btnSelectAll}</button>
                            <button mat-button type="button" (click)="selectedCitizens.deselectAll()">${Translations.global.btnDeselectAll}</button>
                        </div>
                        <mat-selection-list #selectedCitizens>
                            <div *ngIf="!fetchingAssignedCitizens">
                                <mat-list-option
                                    *ngFor="let citizen of assignedCitizens"
                                    [value]="citizen.customerId"
                                    checkboxPosition="before">
                                        <div
                                            [ivCitizenTooltip]="citizen.customerNo"
                                            [ivCitizenColor]="citizen">
                                            {{ citizen.customerName }}
                                        </div>
                                </mat-list-option>
                            </div>
                        </mat-selection-list>

                        <button mat-raised-button color="warn" type="button" (click)="removeCitizens(selectedCitizens)">${Translations.callListAdmin.callListPage.propertiesTab.removeBtn}</button>
                    </ng-container>
                    <div class="spinner" *ngIf="fetchingAssignedCitizens">
                        <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="2" [diameter]="20"></mat-progress-spinner>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="${Translations.callListAdmin.callListPage.intervalTab.tabHeader}">
                <h3>${Translations.callListAdmin.callListPage.intervalTab.header}</h3>
                <div class="draggers">
                    <div *ngFor="let timeslot of timeslots" [ngClass]="'min-'+timeslot.duration+' type-'+timeslot.timeslotType" [dragger]="timeslot">
                        {{ timeslot.timeslotType === 'W' ? '${Translations.callListAdmin.callListPage.intervalTab.callBlock}' : '${Translations.callListAdmin.callListPage.intervalTab.breakBlock}' }} ({{timeslot.duration}} min)
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    `
})
export class CallListPageComponent implements OnInit {

    timeslots: ITimeSlot[] = this.calendar.timeslots;

    startHours = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
    startMins = [0, 15, 30, 45];
    startMin: number;
    startHour: number;
    dayOfWeekNo: number;

    selectedIndex = 0;

    citizenList = [];

    selectedEvent: IEvent | undefined;

    callList: ICallListDetails;

    assignedCitizens: ISimpleCitizen[] = [];
    fetchingAssignedCitizens = true;
    private _scrollContainer: HTMLElement | Window = (document.querySelector('#content > .util-hidden-scroll') as HTMLElement) || window;

    _citizens = new BehaviorSubject<IUnassignedCitizensPerDayOfWeek[]>([]);
    citizens$: Observable<ICitizenDay[]> = this._citizens.pipe(map(daysAndIntervals => {
        const days: Record<string, ICitizenDay> = {};

        daysAndIntervals.forEach(dayAndInterval => {
            if (!dayAndInterval.citizens || !dayAndInterval.citizens.length) {
                return;
            }

            const day = dayAndInterval.dayOfWeekNo;
            if (!days[day]) {
                days[day] = {
                    title: Translations.callListAdmin.weekDays[day],
                    callList: []
                };
            }
            dayAndInterval.citizens = dayAndInterval.citizens.map(x => {
                x.customerName = dayAndInterval.startingHour + '-' + dayAndInterval.endingHour + ': ' + x.customerName;
                return x;
            });
            days[day].callList = days[day].callList.concat(dayAndInterval.citizens);
        });

        return Object.values(days);
    }));
    fetchingCitizens = true;

    getCitizens() {
        this.fetchingCitizens = true;
        this.callListAdminService.getCitizensForCallList()
            .subscribe(data => {
                this._citizens.next(data);
                this.fetchingCitizens = false;
            });
    }

    onIntervalDropped({ where, value }: IDragEvent) {
        const slot: ISlot = where['slot'];
        const col = where['col'];
        this.callListAdminService.addInterval({
            callListId: this.callList.id,
            timeSlotId: value.timeslotId,
            dayOfWeekNo: col.id,
            startingHour: slot.hour,
            startingMinute: slot.min
        }).subscribe(
            callListDetails => this.callList = callListDetails,
            this.onValidationError
        );
    }

    onCitizensDropped({ where, value }: IDragEvent) {
        this.callListAdminService.addCitizensToCallListInterval(where['intervalId'], value).pipe(
            tap(data => {
                if (data.citizensNotAllowedToMove && data.citizensNotAllowedToMove.length) {
                    this.dialogService.showMessage(Translations.replaceTokens(Translations.callListAdmin.callListPage.addCitizensError, '<br>' + data.citizensNotAllowedToMove.map(x => '- ' + x.customerName).join('<br>')));
                }
            }),
            switchMapTo(this.callListAdminService.getCallListDetails(this.callList.id))
        ).subscribe(
            callListDetails => {
                this.callList = callListDetails;
                this.getCitizens();
                if (this.selectedEvent && this.selectedEvent.intervalId === where['intervalId']) {
                    this.getAssignedCitizens(this.selectedEvent.intervalId);
                }
            },
            this.onValidationError
        );
    }

    onTitleClicked() {
        // unselect
        this.setEvent();
    }

    saveEvent() {
        if (this.selectedEvent) {
            this.callListAdminService.updateEvent(this.selectedEvent.intervalId, this.selectedEvent.timeslotId, this.startHour, this.startMin, this.dayOfWeekNo, this.callList.id)
                .subscribe(callListDetails => {
                    this.callList = callListDetails;
                }, this.onValidationError);
        }
    }

    deleteEvent() {
        if (this.selectedEvent) {
            this.dialogService.confirm(Translations.callListAdmin.callListPage.propertiesTab.confirmDelete).afterClosed().pipe(
                filter((res: boolean) => res),
                switchMapTo(this.callListAdminService.deleteEvent(this.selectedEvent.intervalId, this.callList.id))
            ).subscribe(callListDetails => {
                this.dialogService.showSnackMessage({ message: Translations.callListAdmin.callListPage.propertiesTab.messageDeleteOk });
                this.selectedEvent = undefined;
                this.callList = callListDetails;
                this.getCitizens();
            }, this.onValidationError);
        }
    }

    removeCitizens(citizenList: MatSelectionList) {
        if (this.selectedEvent) {
            const selectedCitizenIds = citizenList.selectedOptions.selected.map((m: any) => m.value);
            this.callListAdminService.removeCitizensFromInterval(this.selectedEvent.intervalId, selectedCitizenIds, this.callList.id)
                .subscribe(callListDetails => {
                    this.callList = callListDetails;
                    this.getCitizens();
                    if (this.selectedEvent) {
                        this.getAssignedCitizens(this.selectedEvent.intervalId);
                    }
                }, this.onValidationError);
        }
    }

    selectedCols(cols: ICol[]) {
        return cols.map(col => {
            col.data = col.data.map(ev => {
                ev.selected = ev.intervalId === (this.selectedEvent ? this.selectedEvent.intervalId : 0);
                return ev;
            });
            return col;
        });
    }

    private setEvent(event?: IEvent) {
        this.selectedEvent = event;
        this.selectedCols(this.callList.cols);

        this.startHour = event ? event.startingHour : 0;
        this.startMin = event ? event.startingMinutes : 0;
        this.dayOfWeekNo = event ? event.dayOfWeekNo || 0 : 0;
    }

    getAssignedCitizens(id: number) {
        this.fetchingAssignedCitizens = true;
        this.callListAdminService.getCitizensForCallListInterval(id)
            .subscribe(list => {
                this.assignedCitizens = list;
                this.fetchingAssignedCitizens = false;
            });
    }

    onEventClicked(event: IEvent) {
        if (event.disableDrag) {
            this.setEvent();
            return;
        }
        this.selectedIndex = 1;
        this.getAssignedCitizens(event.intervalId);
        this.setEvent(event);
    }

    ngOnInit() {
        this.route.data.subscribe((data: IDefaultRouteData<ICallListDetails>) => {
            this.utilService.scrollElementTo(this._scrollContainer, 0);
            this.callList = data.pageData!.data!;
        });
        this.getCitizens();
    }

    onValidationError = (err: IntervareHttpErrorResponse) => {
        this.dialogService.showValidationResult(err.validationErrors);
    }

    constructor(
        private route: ActivatedRoute,
        private dialogService: DialogService,
        private callListAdminService: CallListAdminService,
        private calendar: CalendarService,
        private utilService: UtilService
    ) { }
}
