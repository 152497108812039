import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, of } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { Translations } from '../../translations/translations';
import { FormBuilderRadio, FormBuilderSelect, FormBuilderTypes } from '../form-builder/form-builder-element.model';
import { FormBuilderService } from '../form-builder/form-builder.service';
import { IDialogOptionsData } from './dialog-interfaces';

@Component({
    selector: 'iv-dialog-options',
    template: `
        <div class="dialog-options" *ngIf="matData">
            <h3 mat-dialog-title>{{ matData.title }}</h3>
            <form [formGroup]="form" novalidate (submit)="onSubmit()">
                <mat-dialog-content class="dialog-options__content" [ngClass]="{'dialog-options__content__small': this.matData.isSmallerContent }">
                    <iv-util-hidden-scroll height="100%">
                        <ng-container *ngIf="!isFetching; else fetching">
                            <ng-container *ngFor="let input of inputs">
                                <iv-form-builder-element
                                    [form]="form"
                                    *ngIf="form"
                                    [input]="input"
                                ></iv-form-builder-element>
                            </ng-container>
                        </ng-container>
                        <ng-template #fetching>
                            <div class="center-content alt-theme">
                                <mat-progress-spinner
                                    color="accent"
                                    mode="indeterminate"
                                    [strokeWidth]="3"
                                    [diameter]="60"
                                ></mat-progress-spinner>
                            </div>
                        </ng-template>
                    </iv-util-hidden-scroll>
                </mat-dialog-content>
                <mat-dialog-actions class="dialog__actions alt-theme" *ngIf="form">
                    <button type="submit" [disabled]="form.invalid" mat-raised-button color="primary">
                        {{ matData.actionText }}
                    </button>
                    <button type="button" mat-raised-button color="accent" mat-dialog-close>
                        ${Translations.form.actions.cancel}
                    </button>
                </mat-dialog-actions>
            </form>
        </div>
    `
})
export class DialogOptionsComponent implements OnInit, OnDestroy {
    inputs: FormBuilderTypes[] = [];
    form = new UntypedFormGroup({});

    isFetching = true;

    private unsubscribeS$: Subject<void> = new Subject();

    constructor(
        @Inject(MAT_DIALOG_DATA) public matData: IDialogOptionsData,
        private dialogRef: MatDialogRef<DialogOptionsComponent>,
        private formBuilder: FormBuilderService
    ) {}

    ngOnInit() {
        if (this.matData) {
            const options$ =
                this.matData.options instanceof Observable ? this.matData.options : of(this.matData.options);

            options$.pipe(takeUntil(this.unsubscribeS$)).subscribe(
                options => {
                    if (this.matData.isDropdown || this.matData.multiple) {
                        this.inputs.push(
                            new FormBuilderSelect({
                                name: 'selectedOption',
                                multiple: this.matData.multiple,
                                required: true,
                                label: this.matData.placeholder,
                                options
                            })
                        );
                    } else {
                        this.inputs.push(
                            new FormBuilderRadio({
                                name: 'selectedOption',
                                required: true,
                                validation: [Validators.required],
                                label: this.matData.placeholder,
                                options
                            })
                        );
                    }

                    this.form = this.formBuilder.toFormGroup(this.inputs);

                    if (this.form.contains('selectedOption')) {
                        //  to avoid twice click on the options
                        this.form.controls['selectedOption'].markAsTouched();
                    }

                    this.isFetching = false;
                },
                () => (this.isFetching = false)
            );
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeS$.next();
        this.unsubscribeS$.complete();
    }

    onSubmit(): void {
        const values = this.form.value;
        this.dialogRef.close(values.selectedOptionText ? values.selectedOptionText : values.selectedOption);
    }
}
