import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CreateCallListModel, ICallListViewModel, ICreateCallListModel } from '../../../api/services';
import { CallListAdminService } from '../../services/call-list-admin.service';

export const CallListResolver: ResolveFn<Observable<ICallListViewModel | ICreateCallListModel>> = (activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<ICallListViewModel | ICreateCallListModel> => {

    const callListAdminService: CallListAdminService = inject(CallListAdminService)

    const id = activatedRouteSnapshot.paramMap.get('call-list-id') || '';
    if (id === 'new') {
        return of(new CreateCallListModel());
    } else {
        return callListAdminService.getCallList(+id);
    }

}
