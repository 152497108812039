import { Pipe, PipeTransform } from '@angular/core';
import { OrderHistoryRowViewModel } from 'Client/api/services';
import { IPriceAdjustmentOrderReturnLine } from 'Client/app/citizen/citizen-price-adjustment-dialog.component';
import { getNewTotalPrice } from '../helpers/orders.helper';

@Pipe({
    name: 'newTotalPrice'
})
export class NewTotalPricePipe implements PipeTransform {
    transform(formGroup: Record<string, IPriceAdjustmentOrderReturnLine>, order: OrderHistoryRowViewModel): number {
        return getNewTotalPrice(formGroup, order);
    }
}
