import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { IDetailedCitizenViewModel } from '../../api/services';
import { Translations } from '../../translations/translations';
import { CURRENCY_CODE } from '../app.locale_data';
import { AddressPipe } from '../shared/address.pipe';
import { getCityByZipCode } from '../util/helpers';

@Component({
    selector: 'iv-citizen-details-info',
    template: `
        <section class="citizen-details__info" *ngIf="citizen">

            <section class="citizen-details__info-container">
                <header class="citizen-details__info-header">
                    <h3>${Translations.administration.citizen.informationHeader}</h3>
                </header>
                <dl class="citizen-details__info-content side-by-side">
                    <dt>${Translations.administration.citizen.number}</dt>
                    <dd>{{ citizen.customerNumber }}</dd>
                    <dt>${Translations.administration.citizen.name}<br/>
                        ${Translations.administration.citizen.address}<br/>
                        ${Translations.administration.citizen.zipCodeCity}
                    </dt>
                    <dd>{{ citizen.name }}<br/>
                        {{ address }}<br/>
                        {{ zipAndCity }}
                    </dd>
                    <dt>${Translations.global.textSet.municipality}</dt>
                    <dd>{{ citizen.municipality }}</dd>
                    <dt>${Translations.global.textSet.district}</dt>
                    <dd>{{ citizen.district }}</dd>
                    <dt>${Translations.global.textSet.subDistrict}</dt>
                    <dd>{{ citizen.subDistrict }}</dd>
                    <dt>${Translations.administration.citizen.customerType}</dt>
                    <dd>{{ citizen.customerType | customerType }}</dd>
                    <ng-container *ngIf="citizen.username">
                        <dt>${Translations.administration.citizen.username}</dt>
                        <dd>{{ citizen.username }}</dd>
                    </ng-container>
                    <ng-container *ngIf="citizen.specialStatus">
                        <dt class="strong">${Translations.administration.citizen.customerStatus}</dt>
                        <dd class="strong">{{ citizen.specialStatus.statusText }}: {{ citizen.specialStatus.statusDate }}</dd>
                    </ng-container>
                    <ng-container *ngIf="!citizen.allowReplacementProducts">
                        <dt class="strong warn">${Translations.administration.citizen.replacementProducts}</dt>
                        <dd class="strong warn">${Translations.administration.citizen.replacementProductsValue}</dd>
                    </ng-container>
                    <ng-container *ngIf="citizen.isBlocked">
                        <dt class="strong warn">${Translations.administration.citizen.isBlocked}</dt>
                        <dd class="strong warn">${Translations.administration.citizen.isBlockedValue}</dd>
                    </ng-container>
                </dl>
            </section>

            <section class="citizen-details__info-container">
                <header class="citizen-details__info-header">
                    <h3>${Translations.administration.citizen.deliveryHeader}</h3>
                </header>
                <dl class="citizen-details__info-content side-by-side">
                    <dt>${Translations.administration.citizen.deliveryInterval}</dt>
                    <dd [innerHTML]="citizen.orderDateTime"></dd>
                    <dt>${Translations.administration.citizen.deliveryDay}</dt>
                    <dd [innerHTML]="citizen.deliveryDateTime"></dd>
                    <dt>${Translations.administration.citizen.keyForCustomer}</dt>
                    <dd>{{ citizen.keyForCustomer || '-' }}</dd>
                    <dt>${Translations.administration.citizen.deliveryDriver}</dt>
                    <dd>{{ citizen.driverName }}</dd>
                    <dt>${Translations.administration.citizen.lastBuyDate}</dt>
                    <dd>{{ citizen.lastBuyDate }}</dd>
                    <dt [ngStyle]="{'color': citizen.deliveryDateChanged && citizen.deliveryDateChanged === true ? 'red' : ''}">${Translations.administration.citizen.nextDeliveryDate} <span *ngIf="citizen.deliveryDateChanged && citizen.deliveryDateChanged === true">
                        ${Translations.municipality.deliveryDateChange.notifyingMessage}
                    </span></dt>
                    <dd [ngStyle]="{'color': citizen.deliveryDateChanged && citizen.deliveryDateChanged === true ? 'red' : ''}">{{ citizen.nextDeliveryDate }}</dd>
                    <ng-container *ngIf="citizen.plannedCallerProfileName">
                        <dt>${Translations.administration.citizen.plannedCaller}</dt>
                        <dd>{{ citizen.plannedCallerProfileName }}</dd>
                    </ng-container>
                </dl>
            </section>

            <section class="citizen-details__info-container">
                <header class="citizen-details__info-header">
                    <h3>${Translations.administration.citizen.specialInformationHeader}</h3>
                </header>
                <div class="citizen-details__info-content">
                    <iv-citizen-specialinformation [citizenNo]="citizen.customerNumber"></iv-citizen-specialinformation>
                </div>
            </section>

            <section class="citizen-details__info-container">
                <header class="citizen-details__info-header">
                    <h3>${Translations.administration.citizen.paymentHeader}</h3>
                </header>
                <dl class="citizen-details__info-content side-by-side">
                    <dt>${Translations.administration.citizen.paymentStatus}</dt>
                    <dd>{{ paymentType }}</dd>
                    <dt>${Translations.administration.citizen.paymentBalance}</dt>
                    <dd>{{ citizen.paymentBalance | currency : currencyCode : 'symbol-narrow' }}</dd>
                </dl>
            </section>
        </section>
    `
})
export class CitizenDetailsInfoComponent implements OnInit, OnDestroy {
    @Input() citizen: IDetailedCitizenViewModel;
    address: string;
    zipAndCity: string;
    citizenType: string;
    paymentType: string;

    private unsubscribe = new Subject<void>();

    constructor(
        @Inject(CURRENCY_CODE) public currencyCode: string,
        private addressPipe: AddressPipe
    ) { }

    ngOnInit() {
        if (this.citizen) {
            this._createAddress();
            this._createZipAndCity();
            this.paymentType = this.citizen.paymentType ? Translations.global.paymentTypes[this.citizen.paymentType] : '';
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private _createAddress(): void {
        if (this.citizen.address) {
            let addressString = this.addressPipe.transform(this.citizen.address, false);

            if (this.citizen.address.isTemporary) {
                addressString = '(' + addressString + ')';
            }

            this.address = addressString;
        }
    }

    private _createZipAndCity(): void {
        if (this.citizen.address) {

            const cityName = getCityByZipCode(this.citizen.address.zipCode, this.citizen.address.city);

            let addressString = this.citizen.address.zipCode + ' ' + cityName;

            if (this.citizen.address.isTemporary) {
                addressString = '(' + addressString + ')';
            }

            this.zipAndCity = addressString;
        }
    }
}
