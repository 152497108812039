import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import * as Raven from 'raven-js';

import { environment } from '../environments/environment';
import { IntervareHttpErrorResponse } from './services/base-service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    /**
     * Handles global Angular errors by reporting them to Sentry.io and showing a generic error message to the user (production mode)
     *
     * @param {*} error
     * @memberof GlobalErrorHandler
     */
    handleError(error: HttpErrorResponse): void {
        if (!(error instanceof IntervareHttpErrorResponse)) {
            if (environment.production) {
                try {
                    Raven.captureException(error);
                }
                catch { }
            }

            throw error;
        }
    }
}
