import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';

import { ProductListItemViewModel } from '../../api/services';
import { Translations } from '../../translations/translations';
import { ProductService } from '../services/product.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'iv-commerce-product-list',
    template: `
        <div class="commerce-product-list">
            <div class="list-item-layout commerce-product-list__head">
                <div class="list-item-layout__image commerce-product-list__image">
                    ${Translations.commerce.productHead.products}
                </div>

                <div class="list-item-layout__content">
                    <div class="list-item-layout__row">
                        <div class="list-item-layout__id">
                            ${Translations.commerce.productHead.orderNumber}
                        </div>

                        <div class="list-item-layout__prices commerce-product-list__prices">
                            ${Translations.commerce.productHead.price}
                        </div>

                        <div class="list-item-layout__addtobasket commerce-product-list__addtobasket">
                            ${Translations.commerce.productHead.quantity}
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="!isSearching && productsArray?.length">
                <iv-commerce-product-list-item [product]="product" [isFirst]="isFirst" *ngFor="let product of productsArray; first as isFirst; trackBy: trackById"></iv-commerce-product-list-item>
            </ng-container>

            <ng-container *ngIf="!isSearching && !productsArray?.length">
                <span class="commerce-product-list-item__no-product">${Translations.commerce.productListItem.noProducts}</span>
            </ng-container>

            <ng-container *ngIf="isSearching">
                <mat-progress-spinner
                    class="commerce-product-list-item__mat-progress-spinner"
                    color="accent"
                    [diameter]="100"
                    [strokeWidth]="3"
                    mode="indeterminate">
                </mat-progress-spinner>
            </ng-container>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommerceProductListComponent implements OnInit, OnChanges, OnDestroy {
    @Input() products: ProductListItemViewModel[];
    @Input() pageSize: number;
    @Input() skip: number;
    @Input() sorting: string;

    isSearching = false;
    productsArray: ProductListItemViewModel[] = [];

    private unsubscribe = new Subject<void>();

    constructor(
        private cd: ChangeDetectorRef,
        private productService: ProductService
    ) {
    }

    ngOnInit(): void {
        this.productService.isSearching
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(isSearching => {
                if (isSearching) {
                    this.productsArray = [];
                }
                this.isSearching = isSearching;
                this.cd.markForCheck();
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.products && !changes.products.firstChange) {
            this.productsArray = this.products;
        }
        else if (changes.pageSize && !changes.pageSize.firstChange) {
            this._addMoreSkeletons();
        }
        else {
            this._replaceCurrentWithSkeletons();
        }
    }

    trackById(index: number, item: ProductListItemViewModel) {
        return item.id;
    }

    private _addMoreSkeletons() {
        const delta = this.products ? this.pageSize - this.products.length : this.pageSize;
        for (let i = 0; i < delta; i++) {
            this.productsArray.push(new ProductListItemViewModel());
        }
    }

    private _replaceCurrentWithSkeletons() {
        this.productsArray.splice(0);
        for (let i = 0; i < this.pageSize; i++) {
            this.productsArray.push(new ProductListItemViewModel());
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
