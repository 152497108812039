import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ICaller } from '../../api/services';
import { pending } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { CallListAdminService } from '../services/call-list-admin.service';

@Component({
    selector: 'iv-administration-calllistadmin-userlist',
    template: `
        <a class="category-menu__link"
            *ngFor="let user of users | async"
            routerLinkActive="category-menu__link_active"
            [routerLink]="['', { outlets: { dialog: ['edit-caller', user.callerProfileId]} }]">
                {{ user.firstName + ' ' + user.lastName }}
        </a>
        <div class="alt-theme" *ngIf="isFetching">
            <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="2" [diameter]="20"></mat-progress-spinner>
        </div>
        <button class="call-list-admin__refresh" type="button" (click)="callListAdminService.getCallers()"><i class="icon">${pending}</i> ${Translations.callListAdmin.navigation.refresh}</button>
  `
})
export class AdministrationCalllistadminUserlistComponent implements OnInit {

    users: Observable<ICaller[]>;

    isFetching = true;

    ngOnInit() {
        this.users = this.callListAdminService.getCallers().pipe(tap(() => {
            this.isFetching = false;
        }));
    }

    constructor(
        public callListAdminService: CallListAdminService
    ) { }
}
