import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges } from '@angular/core';

import { IProductListItemViewModel } from '../../api/services';
import { Translations } from '../../translations/translations';
import { CURRENCY_CODE } from '../app.locale_data';
import { IChip, ProductService } from '../services/product.service';

@Component({
    selector: 'iv-commerce-product-list-item',
    template: `
    <div class="list-item-layout commerce-product-list-item" [ngClass]="{ 'commerce-product-list-item_skeleton' : isSkeleton, 'commerce-product-list-item_soldout' : product.isQuantitySoldOut }">
        <div class="list-item-layout__image commerce-product-list-item__spinner alt-theme" *ngIf="isSkeleton; else img">
            <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="3" [diameter]="60"></mat-progress-spinner>
        </div>
        <ng-template #img>
            <a class="list-item-layout__image" [routerLink]="['', { outlets: { dialog: ['product', product.id] } }]" tabindex="-1">
                <img
                    *ngIf="product.primaryImage"
                    class="commerce-product-list-item__image"
                    srcset="{{ product.primaryImage }}&w=160&h=160 1x, {{ product.primaryImage }}&w=320&h=320 2x"
                    src="{{ product.primaryImage }}&w=160&h=160"
                    width="160"
                    height="160"
                    loading="lazy"
                    [attr.alt]="product.name" />
            </a>
        </ng-template>

        <div class="list-item-layout__content commerce-product-list-item__content">
            <div class="list-item-layout__row">
                <div class="list-item-layout__info commerce-product-list-item__info">
                    <div class="commerce-product-list-item__name">
                        <a [routerLink]="['', { outlets: { dialog: ['product', product.id] } }]" tabindex="-1">
                            {{ product.name }}
                        </a>
                    </div>

                    <div class="commerce-product-list-item__prices_mobile">
                        <div class="commerce-product-list-item__discount-price" *ngIf="isProductCampaignDiscount">
                            {{ product.campaign.campaignPrice | currency : currencyCode : 'symbol-narrow' }}
                        </div>

                        <div class="commerce-product-list-item__price" [class.commerce-product-list-item__price_line-through]="isProductCampaignDiscount">
                            {{ product.price | currency : currencyCode : 'symbol-narrow' }}
                        </div>
                    </div>

                    <div class="commerce-product-list-item__description">
                        {{ product.description }} <span class="commerce-product-list-item__unit-price_mobile">/ {{ product.unitPrice }}</span>
                    </div>
                </div>

                <div class="list-item-layout__id commerce-product-list-item__id">
                    {{ product.catalogItemNo }}
                </div>

                <div class="list-item-layout__favorite commerce-product-list-item__favorite">
                    <iv-commerce-toggle-favorite [productId]="product.id" [isFavorite]="product.favorite"></iv-commerce-toggle-favorite>
                </div>

                <div class="list-item-layout__prices commerce-product-list-item__prices">
                    <div class="commerce-product-list-item__discount-price" *ngIf="isProductCampaignDiscount && product?.campaign?.campaignPrice !== product.price">
                        {{ product.campaign.campaignPrice | currency : currencyCode : 'symbol-narrow' }}
                    </div>

                    <div class="commerce-product-list-item__price" [class.commerce-product-list-item__price_line-through]="isProductCampaignDiscount && product?.campaign?.campaignPrice !== product.price">
                        {{ product.price | currency : currencyCode : 'symbol-narrow' }}
                    </div>

                    <div class="commerce-product-list-item__unit-price">
                        {{ product.unitPrice }}
                    </div>
                </div>

                <div class="list-item-layout__addtobasket commerce-product-list-item__addtobasket">
                    <iv-commerce-addtobasket [product]="product" [isFirst]="isFirst"></iv-commerce-addtobasket>
                </div>
            </div>

            <div class="commerce-product-list-item__bottom" *ngIf="product.labels?.length > 0 || chips.length > 0">
                <div class="commerce-product-list-item__labels" *ngIf="product.labels?.length > 0">
                    <iv-commerce-labels [labels]="product.labels"></iv-commerce-labels>
                </div>

                <mat-chip-listbox class="commerce-product-list-item__chips" *ngIf="chips.length > 0">
                    <mat-chip class="commerce-product-list-item__chip" *ngFor="let chip of chips" [color]="chip.color" [highlighted]="chip.selected">
                        {{ chip.text }}
                    </mat-chip>
                </mat-chip-listbox>
            </div>
        </div>
    </div>

    <div class="commerce-product-list-item_replacement" *ngIf="product.replacementProduct">
        <div class="commerce-product-list-item__replacement-text">${Translations.commerce.productListItem.replacementProduct}</div>

        <iv-commerce-product-list-item [product]="product.replacementProduct" [isReplacementProduct]="true"></iv-commerce-product-list-item>
    </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommerceProductListItemComponent implements OnChanges {
    @Input() product: IProductListItemViewModel;
    @Input() isFirst?: boolean;
    @Input() isReplacementProduct?: boolean;
    chips: IChip[] = [];
    isProductCampaignDiscount = false;
    isSkeleton = true;

    constructor(@Inject(CURRENCY_CODE) public currencyCode: string, private productService: ProductService) { }

    ngOnChanges() {
        this.isSkeleton = Object.getOwnPropertyNames(this.product).length === 0;
        if (!this.isSkeleton) {
            this.isProductCampaignDiscount = this.productService.isProductCampaignDiscount(this.product);
            this.chips = this.productService.createChips(this.product);
        }
    }
}
