import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { finalize, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subject, EMPTY } from 'rxjs';

import {
    ICitizenNoteDraftViewModel,
    ICitizenStatusDraftViewModel,
    ICitizenUnsubscribeDraftViewModel,
    CitizenTransferReason,
} from '../../api/services';
import { Translations } from '../../translations/translations';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { CitizenService } from '../services/citizen.service';
import { IDialogContentSettings } from '../shared/dialog-interfaces';
import { DialogService } from '../shared/dialog.service';
import { ROLES } from '../user/user-roles';
import { UserService } from '../user/user.service';
import { MunicipalityService } from './municipality.service';
import { UtilService } from '../util/util.service';

enum DraftsTypeEnum {
    Status = 1,
    Unsubscribe = 2,
    Note = 3
}

@Component({
    selector: 'iv-municipality-citizen-drafts',
    template: `
    <section class="municipality-citizen-drafts">

        <div class="form alt-theme">
            <mat-form-field *ngIf="municipality$ && currentMunicipality !== undefined">
                <mat-select [(ngModel)]="currentMunicipality" (selectionChange)="search()" name="currentMunicipality" placeholder="${Translations.forms.labels.municipality.muncipalityId}">
                    <mat-option [value]="0">${Translations.forms.labels.municipality.none}</mat-option>
                    <mat-option [value]="municipality.id" *ngFor="let municipality of municipality$ | async">{{ municipality.municipalityName }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div *ngIf="validationErrors" class="municipality-citizen-drafts__errors">
            <h4 *ngFor="let error of validationErrors">{{ error }}</h4>
        </div>

        <!-- Status Table -->
        <mat-card class="municipality-citizen-drafts__card">
            <mat-card-header>
                <mat-card-title>${Translations.municipality.citizenDrafts.statusTitle}</mat-card-title>
            </mat-card-header>

            <mat-card-content>
                <mat-table #statusTable class="municipality-citizen-drafts__table" [dataSource]="statusDataSource">

                    <ng-container matColumnDef="{{column}}" *ngFor="let column of statusColumns">
                        <mat-header-cell class="municipality-citizen-drafts__table-head" *matHeaderCellDef [innerHTML]="_processHeader(column)"></mat-header-cell>
                        <mat-cell class="municipality-citizen-drafts__table-body" *matCellDef="let row">

                            <ng-container *ngIf="column === 'customerNo'">
                                <a title="{{row.firstName}} {{row.lastName}}"
                                    [routerLink]="['', { outlets: { dialog: ['citizen', row.customerNo]} }]"
                                    [queryParams]="{citizenDetails: 'notes'}">
                                        {{ row.customerNo }}
                                </a>
                            </ng-container>
                            <ng-container *ngIf="column !== 'customerNo'">{{ _processRow(column, row) }}</ng-container>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <mat-header-cell class="municipality-citizen-drafts__table-head" *matHeaderCellDef></mat-header-cell>
                        <mat-cell class="municipality-citizen-drafts__table-body" *matCellDef="let row">
                            <iv-progress-button
                                buttonType="button"
                                class="municipality-citizen-drafts__action-button btn-with-icon"
                                color="primary" width="full"
                                [callback]="approveItem.bind(this, row, draftType.Status)">
                                ${Translations.municipality.citizenDrafts.approve}
                            </iv-progress-button>

                            <iv-progress-button
                                buttonType="button"
                                class="municipality-citizen-drafts__action-button btn-with-icon"
                                color="accent" width="full"
                                [callback]="deleteItem.bind(this, row, draftType.Status)">
                                ${Translations.municipality.citizenDrafts.delete}
                            </iv-progress-button>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="statusDisplayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: statusDisplayedColumns;"></mat-row>
                </mat-table>

                <ng-container *ngIf="!isLoading; else loading"></ng-container>

                <ng-container *ngIf="!isLoading && statusDataSource.data.length === 0">
                    <div class="municipality-citizen-drafts__results-empty">
                        ${Translations.municipality.citizenDrafts.noResults}
                    </div>
                </ng-container>
            </mat-card-content>
        </mat-card>

        <!-- Unsubscribe Table -->
        <mat-card class="municipality-citizen-drafts__card">
            <mat-card-header>
                <mat-card-title>${Translations.municipality.citizenDrafts.unsubscribedTitle}</mat-card-title>
            </mat-card-header>

            <mat-card-content>
                <mat-table #unsubscribeTable class="municipality-citizen-drafts__table municipality-citizen-drafts__table-unsubscribe" [dataSource]="unsubscribeDataSource">

                    <ng-container matColumnDef="{{column}}" *ngFor="let column of unsubscribeColumns">
                        <mat-header-cell class="municipality-citizen-drafts__table-head" *matHeaderCellDef [innerHTML]="_processHeader(column)"></mat-header-cell>
                        <mat-cell class="municipality-citizen-drafts__table-body" *matCellDef="let row">

                            <ng-container *ngIf="column === 'customerNo'">
                                <a title="{{row.firstName}} {{row.lastName}}"
                                    [routerLink]="['', { outlets: { dialog: ['citizen', row.customerNo]} }]"
                                    [queryParams]="{citizenDetails: 'notes'}">
                                        {{ row.customerNo }}
                                </a>
                            </ng-container>

                            <ng-container *ngIf="column !== 'customerNo'">{{ _processRow(column, row) }}</ng-container>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <mat-header-cell class="municipality-citizen-drafts__table-head" *matHeaderCellDef></mat-header-cell>
                        <mat-cell class="municipality-citizen-drafts__table-body" *matCellDef="let row">

                            <iv-progress-button
                                buttonType="button"
                                class="municipality-citizen-drafts__action-button btn-with-icon"
                                color="primary" width="full"
                                [callback]="approveItem.bind(this, row, draftType.Unsubscribe)">
                                ${Translations.municipality.citizenDrafts.approve}
                            </iv-progress-button>

                            <iv-progress-button
                                buttonType="button"
                                class="municipality-citizen-drafts__action-button btn-with-icon"
                                color="accent" width="full"
                                [callback]="deleteItem.bind(this, row, draftType.Unsubscribe)">
                                ${Translations.municipality.citizenDrafts.delete}
                            </iv-progress-button>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="unsubscribeDisplayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: unsubscribeDisplayedColumns;"></mat-row>
                </mat-table>

                <ng-container *ngIf="!isLoading; else loading"></ng-container>

                <ng-container *ngIf="!isLoading && unsubscribeDataSource.data.length === 0">
                    <div class="municipality-citizen-drafts__results-empty">
                        ${Translations.municipality.citizenDrafts.noResults}
                    </div>
                </ng-container>
            </mat-card-content>
        </mat-card>

        <!-- Note Table -->
        <mat-card class="municipality-citizen-drafts__card">
            <mat-card-header>
                <mat-card-title>${Translations.municipality.citizenDrafts.noteTitle}</mat-card-title>
            </mat-card-header>

            <mat-card-content>
                <mat-table #noteTable class="municipality-citizen-drafts__table municipality-citizen-drafts__table-notes" [dataSource]="noteDataSource">

                    <ng-container matColumnDef="{{column}}" *ngFor="let column of noteColumns">
                        <mat-header-cell class="municipality-citizen-drafts__table-head" *matHeaderCellDef [innerHTML]="_processHeader(column)"></mat-header-cell>
                        <mat-cell class="municipality-citizen-drafts__table-body" *matCellDef="let row">
                            <ng-container *ngIf="column === 'customerNo'">
                                <a title="{{row.firstName}} {{row.lastName}}"
                                    [routerLink]="['', { outlets: { dialog: ['citizen', row.customerNo]} }]"
                                    [queryParams]="{citizenDetails: 'notes'}">
                                        {{ row.customerNo }}
                                </a>
                            </ng-container>
                            <ng-container *ngIf="column !== 'customerNo'">{{ _processRow(column, row) }}</ng-container>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <mat-header-cell class="municipality-citizen-drafts__table-head" *matHeaderCellDef></mat-header-cell>
                        <mat-cell class="municipality-citizen-drafts__table-body" *matCellDef="let row">

                            <iv-progress-button
                                buttonType="button"
                                class="municipality-citizen-drafts__action-button btn-with-icon"
                                color="primary" width="full"
                                [callback]="approveItem.bind(this, row, draftType.Note)">
                                ${Translations.municipality.citizenDrafts.approve}
                            </iv-progress-button>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="noteDisplayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: noteDisplayedColumns;"></mat-row>
                </mat-table>

                <ng-container *ngIf="!isLoading; else loading"></ng-container>

                <ng-container *ngIf="!isLoading && noteDataSource.data.length === 0">
                    <div class="municipality-citizen-drafts__results-empty">
                        ${Translations.municipality.citizenDrafts.noResults}
                    </div>
                </ng-container>
            </mat-card-content>
        </mat-card>

    </section>

    <ng-template #loading>
        <div class="municipality-citizen-drafts__loading center-content">
            <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="2" [diameter]="20"></mat-progress-spinner>
        </div>
    </ng-template>

    <ng-template #fetching>
        <div class="municipality-citizen-drafts__fetching center-content">
            <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="3" [diameter]="60"></mat-progress-spinner>
        </div>
    </ng-template>
  `
})
export class MunicipalityCitizenDraftsComponent implements OnInit, OnDestroy {

    draftType = DraftsTypeEnum;

    isLoading = true;

    validationErrors: string[] = [];

    currentMunicipality?: number;
    municipality$ = this.municipalityService.getMunicipalities();

    statusColumns = Translations.municipality.citizenDrafts.statusColumns;
    statusDisplayedColumns: string[] = [];

    noteColumns = Translations.municipality.citizenDrafts.noteColumns;
    noteDisplayedColumns: string[] = [];

    unsubscribeColumns = Translations.municipality.citizenDrafts.unsubscribeColumns;
    unsubscribeDisplayedColumns: string[] = [];

    statusDataSource = new MatTableDataSource<ICitizenStatusDraftViewModel>();

    noteDataSource = new MatTableDataSource<ICitizenNoteDraftViewModel>();

    unsubscribeDataSource = new MatTableDataSource<ICitizenUnsubscribeDraftViewModel>();

    private unsubscribe = new Subject();
    private _scrollContainer: HTMLElement | Window = (document.querySelector('#content > .util-hidden-scroll') as HTMLElement) || window;


    constructor(
        private date: DatePipe,
        private userService: UserService,
        private dialogService: DialogService,
        private citizenService: CitizenService,
        private municipalityService: MunicipalityService,
        private utilService: UtilService
    ) { }

    ngOnInit() {
        this.utilService.scrollElementTo(this._scrollContainer, 0);
        // Concat all the displayed columns
        this.statusDisplayedColumns = this.statusDisplayedColumns.concat(this.statusColumns, Translations.municipality.citizenDrafts.commonDisplayedColumn);
        this.noteDisplayedColumns = this.noteDisplayedColumns.concat(this.noteColumns, Translations.municipality.citizenDrafts.commonDisplayedColumn);
        this.unsubscribeDisplayedColumns = this.unsubscribeDisplayedColumns.concat(this.unsubscribeColumns, Translations.municipality.citizenDrafts.commonDisplayedColumn);

        this.userService.user$.pipe(
            first()
        ).subscribe(user => {
            if (user) {
                let currentMunicipality = 0;
                if (user.roles && user.roles.indexOf(ROLES.MunicipalityAdmin) !== -1) {
                    currentMunicipality = user.municipalities ? user.municipalities[0] : 0;
                }

                this.currentMunicipality = currentMunicipality;
                this.search();
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    search(): void {
        this.noteDataSource.data = [];
        this.statusDataSource.data = [];
        this.unsubscribeDataSource.data = [];
        this.isLoading = true;

        this.citizenService.getPendingApprovalItems(this.currentMunicipality).pipe(
            takeUntil(this.unsubscribe),
            finalize(() => this.isLoading = false)
        ).subscribe(
            result => {
                const statusItem = result.filter(current => !!current.statusItem).map(current => current.statusItem!);
                this.statusDataSource.data = statusItem;

                const unsubscribeItem = result.filter(current => !!current.unsubscribeItem).map(current => current.unsubscribeItem!);
                this.unsubscribeDataSource.data = unsubscribeItem;

                const notesItem = result.filter(current => !!current.noteItem).map(current => current.noteItem!);
                this.noteDataSource.data = notesItem;

                this.isLoading = false;
            },
            (err: IntervareHttpErrorResponse) => this.handleWithErrors(err)
        );
    }

    private handleWithErrors(err: IntervareHttpErrorResponse) {

        this.validationErrors = [];

        if (err.validationErrors && err.validationErrors.length > 0) {
            this.validationErrors = err.validationErrors;
        } else {
            this.validationErrors.push(err.error.statusText);
        }

        this.isLoading = false;
    }

    private _processHeader(column: string): string {
        return Translations.municipality.citizenDrafts[column];
    }

    private _processRow(column: string, row: ICitizenStatusDraftViewModel) {

        if (column === 'statusFrom' || column === 'statusTo') {
            return this.date.transform(row[column], 'shortDate');
        }

        if (column === 'createDate') {
            return this.date.transform(row[column], 'short');
        }

        if (column === 'inactivityReason') {
            return Translations.global.transferReasons[CitizenTransferReason[row[column]]];
        }

        return row[column];
    }

    deleteItem = (draft: ICitizenStatusDraftViewModel | ICitizenUnsubscribeDraftViewModel, type: DraftsTypeEnum): Promise<boolean> => {

        const draftType = DraftsTypeEnum[type];
        const draftName = Translations.municipality.citizenDrafts.draftsType[draftType];
        const headerTitle = Translations.replaceTokens(Translations.municipality.citizenDrafts.deletePrompt.header, draftName);
        const contentText = Translations.replaceTokens(Translations.municipality.citizenDrafts.deletePrompt.text, draft.firstName + ' ' + draft.lastName, draftName);

        const citizenDraftId = draft.citizenDraftId;

        const dialogSettings = this._dialogSettings(headerTitle, contentText);

        return this.dialogService.openDialog(dialogSettings).afterClosed().pipe(
            switchMap(res => res ? this.citizenService.rejectCitizenChanges(draft.customerNo!, citizenDraftId) : EMPTY),
            tap(result => result ? this._updateDataTableAndNotify(draft, type, true) : this.showErrorMessage('delete'))
        ).toPromise();

    }

    approveItem = (draft: ICitizenStatusDraftViewModel | ICitizenUnsubscribeDraftViewModel | ICitizenNoteDraftViewModel, type: DraftsTypeEnum): Promise<boolean> => {

        const draftType = DraftsTypeEnum[type];
        const draftName = Translations.municipality.citizenDrafts.draftsType[draftType];
        const headerTitle = Translations.replaceTokens(Translations.municipality.citizenDrafts.approvePrompt.header, draftName);
        const contentText = Translations.replaceTokens(Translations.municipality.citizenDrafts.approvePrompt.text, draft.firstName + ' ' + draft.lastName, draftName);

        let noteId = 0;
        let citizenDraftId = 0;

        if (type === DraftsTypeEnum.Note) {
            draft = draft as ICitizenNoteDraftViewModel;
            noteId = draft.noteId;
        }

        if (type === DraftsTypeEnum.Status) {
            draft = draft as ICitizenStatusDraftViewModel;
            citizenDraftId = draft.citizenDraftId;
        }

        if (type === DraftsTypeEnum.Unsubscribe) {
            draft = draft as ICitizenUnsubscribeDraftViewModel;
            citizenDraftId = draft.citizenDraftId;
        }

        const dialogSettings = this._dialogSettings(headerTitle, contentText);

        return this.dialogService.openDialog(dialogSettings).afterClosed().pipe(
            switchMap(res => res ? this.citizenService.approveCitizenChanges(draft.customerNo!, citizenDraftId, noteId) : EMPTY),
            tap(result => result ? this._updateDataTableAndNotify(draft, type) : this.showErrorMessage('approve'))
        ).toPromise();

    }

    private _updateDataTableAndNotify(draft: ICitizenStatusDraftViewModel | ICitizenNoteDraftViewModel | ICitizenUnsubscribeDraftViewModel, type: DraftsTypeEnum, isToDelete: boolean = false) {

        const draftType = DraftsTypeEnum[type];
        const draftName = Translations.municipality.citizenDrafts.draftsType[draftType];
        const customerName = draft.firstName + ' ' + draft.lastName;

        let dataSource;
        let index = -1;

        switch (type) {
            case DraftsTypeEnum.Note:
                const currNotDraft = draft as ICitizenNoteDraftViewModel;
                dataSource = this.noteDataSource.data;
                index = dataSource.findIndex(item => item.customerNo === currNotDraft.customerNo && item.noteId === currNotDraft.noteId);
                dataSource.splice(index, 1);
                this.noteDataSource.data = dataSource;
                break;

            case DraftsTypeEnum.Status:
                const currStatusDraft = draft as ICitizenStatusDraftViewModel;
                dataSource = this.statusDataSource.data;
                index = dataSource.findIndex(item => item.customerNo === currStatusDraft.customerNo && item.citizenDraftId === currStatusDraft.citizenDraftId);
                dataSource.splice(index, 1);
                this.statusDataSource.data = dataSource;
                break;

            case DraftsTypeEnum.Unsubscribe:
                const currUnsubscribeDraft = draft as ICitizenUnsubscribeDraftViewModel;
                dataSource = this.unsubscribeDataSource.data;
                index = dataSource.findIndex(item => item.customerNo === currUnsubscribeDraft.customerNo && item.citizenDraftId === currUnsubscribeDraft.citizenDraftId);
                dataSource.splice(index, 1);
                this.unsubscribeDataSource.data = dataSource;
                break;
        }

        let message = Translations.replaceTokens(Translations.municipality.citizenDrafts.approvePrompt.messageOk, draftName, customerName);

        if (isToDelete) {
            message = Translations.replaceTokens(Translations.municipality.citizenDrafts.deletePrompt.messageOk, draftName, customerName);
        }

        this.showOkMessage(message);
    }

    private showOkMessage(text: string) {
        this.dialogService.showSnackMessage({ message: text });
    }

    private showErrorMessage(action: string) {
        const actionResult = Translations.municipality.citizenDrafts[action];
        this.dialogService.showSnackMessage({
            message: Translations.replaceTokens(Translations.municipality.citizenDrafts.messageError, actionResult)
        });
    }

    private _dialogSettings(headerTitle: string, contentText: string): IDialogContentSettings {
        return {
            data: {
                header: headerTitle,
                content: contentText,
                buttons: {
                    button1: {
                        color: 'primary',
                        confirm: true,
                        text: Translations.global.btnAcknowledge
                    },
                    button2: {
                        color: 'accent',
                        confirm: false,
                        text: Translations.global.btnCancel
                    }
                },
            },
            disableClose: true
        };
    }

}
