import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { cross } from '../../scripts/generated/icons';
import { IDialogContent } from './dialog-interfaces';

@Component({
    selector: 'iv-dialog',
    template: `
        <div class="dialog alt-theme" [ngClass]="settingsObj.componentClass">
            <button *ngIf="!settingsObj.buttons" class="dialog__close-button" mat-icon-button mat-dialog-close>${cross}</button>
            <h2 mat-dialog-title *ngIf="settingsObj.header" class="dialog__header">{{settingsObj.header}}</h2>
            <mat-dialog-content class="dialog__content"><div [innerHtml]="settingsObj.content"></div></mat-dialog-content>
            <mat-dialog-actions *ngIf="settingsObj.buttons" class="dialog__header dialog__actions">
                <button *ngIf="settingsObj.buttons.button1" mat-raised-button [color]="settingsObj.buttons.button1.color" [mat-dialog-close]="settingsObj.buttons.button1.confirm">{{settingsObj.buttons.button1.text}}</button>
                <button *ngIf="settingsObj.buttons.button2" mat-raised-button [color]="settingsObj.buttons.button2.color" [mat-dialog-close]="settingsObj.buttons.button2.confirm">{{settingsObj.buttons.button2.text}}</button>
            </mat-dialog-actions>
        </div>
    `,
})
export class DialogComponent {
    constructor( @Inject(MAT_DIALOG_DATA) public settingsObj: IDialogContent) { }
}
