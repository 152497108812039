import { NgModule } from '@angular/core';

import { CitizenModule } from '../citizen/citizen.module';
import { SharedModule } from '../shared/shared.module';
import { AssignCitizensComponent } from './assign-citizens.component';
import { CallListAdminRoutingModule } from './call-list-admin-routing.module';
import { CallListPageComponent } from './call-list-page.component';
import { DraggerDirective } from './directives/dragger.directive';
import { DragTargetDirective } from './directives/dragtarget.directive';
import { DisplayCallcountComponent } from './display-callcount.component';
import { DragEventsCalendarComponent } from './drag-events-calendar.component';
import { DragSlotsCalendarComponent } from './drag-slots-calendar.component';
import { DragService } from './drag.service';
import { EditCallDayComponent } from './edit-call-day.component';
import { EditCallListComponent } from './edit-call-list.component';
import { EditCallerComponent } from './edit-caller.component';
import { EndDayComponent } from './end-day.component';
import { EndFaxDayComponent } from './end-fax-day.component';
import { GenerateListComponent } from './generate-list.component';
import { PlanningPageComponent } from './planning-page.component';

@NgModule({
    declarations: [
        DragSlotsCalendarComponent,
        DragEventsCalendarComponent,
        DraggerDirective,
        DragTargetDirective,
        CallListPageComponent,
        PlanningPageComponent,
        AssignCitizensComponent,
        EndDayComponent,
        EditCallDayComponent,
        EditCallListComponent,
        EditCallerComponent,
        DisplayCallcountComponent,
        GenerateListComponent,
        EndFaxDayComponent
    ],
    imports: [
        SharedModule,
        CitizenModule,
        CallListAdminRoutingModule
    ],
    providers: [
        DragService
    ]
})
export class CallListAdminModule { }
