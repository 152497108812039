import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ICitizen, ICitizenAddress, ICitizenPayment, IDetailedCitizenViewModel, IPaymentSettings } from '../../api/services';
import { Translations } from '../../translations/translations';
import { FormBuilderRadio, FormBuilderTextInput, FormBuilderTypes } from '../form-builder/form-builder-element.model';
import { toFormOptions } from '../form-builder/form-builder-mappings';
import { FormBuilderService } from '../form-builder/form-builder.service';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { CitizenService } from '../services/citizen.service';
import { SettingsService } from '../services/settings.service';
import { DialogService } from '../shared/dialog.service';
import { UserService } from '../user/user.service';

@Component({
    selector: 'iv-municipality-citizen-payment',
    template: `
        <section class="municipality-citizen-payment">
            <div role="alert" class="municipality-citizen-payment__sync-status alert alert-info" *ngIf="citizen && !citizen.isSync">
                ${Translations.municipality.citizenPayment.messageIsSync}
            </div>

            <ng-container *ngIf="citizen && form; else unavailable">
                <iv-citizen-details-info [citizen]="citizenDetails" *ngIf="citizenDetails"></iv-citizen-details-info>

                <form class="municipality-citizen-payment__form form" [formGroup]="form" (submit)="submit()">
                    <iv-form-builder-element [form]="form" [input]="input" *ngFor="let input of inputs"></iv-form-builder-element>

                    <div class="municipality-citizen-payment__links" [ngSwitch]="paymentMethod.value">
                        <ng-container *ngSwitchCase="'PBS'">
                            <ng-container *ngTemplateOutlet="cprTemplate"></ng-container>
                        </ng-container>
                        <button type="button" mat-raised-button color="accent" (click)="goToBs()" *ngSwitchCase="'PBS'">
                            ${Translations.municipality.citizenPayment.bsButton}
                        </button>
                        <ng-container *ngSwitchCase="'DIBS'">
                            <ng-container *ngTemplateOutlet="cprTemplate"></ng-container>
                        </ng-container>
                        <button type="button" mat-raised-button color="accent" (click)="goToDibs()" *ngSwitchCase="'DIBS'">
                            ${Translations.municipality.citizenPayment.dibsButton}
                        </button>
                    </div>

                    <div class="municipality-citizen-payment__controls">
                        <iv-progress-button
                            color="primary"
                            [loadingState]="states.isLoading"
                            [disabled]="(form && form.invalid) || (citizen && !citizen.isSync) || (!citizen.customerCardInformation && paymentMethod.value === 'DIBS')"
                        >
                            ${Translations.global.btnSave}
                        </iv-progress-button>
                    </div>
                </form>
            </ng-container>

            <ng-template #cprTemplate>
                <form (submit)="fetchCpr()">
                    <mat-form-field>
                        <mat-label>${Translations.municipality.citizenPayment.cprNumber}</mat-label>
                        <input [value]="cpr" matInput type="text" name="cpr" readonly>
                    </mat-form-field>
                    <button mat-raised-button color="primary">${Translations.municipality.citizenPayment.getCprBtn}</button>
                </form>
            </ng-template>

            <ng-template #unavailable>
                <div role="status" class="municipality-citizen-payment__status alt-theme" *ngIf="states.impersonated">
                    <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="3" [diameter]="60"></mat-progress-spinner>
                </div>

                <div role="status" class="municipality-citizen-payment__status" *ngIf="!states.impersonated">
                    ${Translations.commerce.basket.impersonate}
                </div>
            </ng-template>
        </section>
    `
})
export class MunicipalityCitizenPaymentComponent implements OnInit, OnDestroy {
    citizen?: ICitizen;
    citizenDetails?: IDetailedCitizenViewModel;
    inputs?: FormBuilderTypes[];
    form?: UntypedFormGroup;
    paymentSettings?: IPaymentSettings;
    cpr = '';

    states = {
        impersonated: false,
        isLoading: false
    };

    private unsubscribe = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private dialogService: DialogService,
        private userService: UserService,
        private citizenService: CitizenService,
        private settingsService: SettingsService,
        private formBuilder: FormBuilderService
    ) { }

    ngOnInit() {
        this.userService.isImpersonating.pipe(
            takeUntil(this.unsubscribe),
            tap(() => this.states.impersonated = false),
            filter(quickInfo => !!quickInfo),
            map(quickInfo => quickInfo!.customerNumber!),
            switchMap(customerNo => combineLatest([
                this.citizenService.getCitizenByCustomerNo(customerNo),
                this.citizenService.getCitizenDetails(customerNo),
                this.settingsService.getPaymentSettings()
            ]))
        ).subscribe(([citizen, citizenDetails, paymentSettings]) => {
            this.citizen = citizen;
            this.citizenDetails = citizenDetails;
            this.paymentSettings = paymentSettings;
            this._buildPaymentForm(citizen);
            this.states.impersonated = true;
            this.fetchCpr();
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    get paymentMethod() {
        return this.form ? this.form.get(Translations.municipality.citizenPayment.paymentMethodCode.name) : undefined;
    }

    get dibsTicket() {
        return this.form ? this.form.get(Translations.municipality.citizenPayment.dibsTicketId.name) : undefined;
    }

    submit(): void {
        if (this.form && this.form.valid && this.citizen) {

            const model: ICitizenPayment = this.form.value;

            this.citizenService.updateCitizenPayment(model).pipe(
                takeUntil(this.unsubscribe)
            ).subscribe(
                () => this.dialogService.showSnackMessage({ message: Translations.municipality.citizenPayment.messageOk }),
                (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
            );
        }
    }

    fetchCpr(): void {
        if (this.citizen) {
            this.citizenService.getCitizenCpr(this.citizen.customerNo!).pipe(
                takeUntil(this.unsubscribe)
            ).subscribe(cpr => this.cpr = cpr);
        }
    }

    goToBs(): void {
        if (!this.citizen || !this.paymentSettings || !this.paymentSettings.bsNumber) {
            return;
        }

        const params = {
            dbnr: this.citizen.customerNo || ''
        };
        const paramObj = new HttpParams({ fromObject: params });
        const url = 'https://pbssubscription.intervare.dk/Subscription_form?' + paramObj.toString();

        window.open(url, '_blank');
    }

    goToDibs(): void {
        if (!this.citizen || !this.paymentSettings || !this.paymentSettings.dibsMerchant) {
            return;
        }

        window.open('https://kortbetaling.intervare.dk/', '_blank');
    }

    private _createInput(name: string, value: string): HTMLInputElement {
        const input = document.createElement<'input'>('input');
        input.type = 'hidden';
        input.name = decodeURIComponent(name);
        input.value = decodeURIComponent(value);

        return input;
    }

    private _buildPaymentForm(citizen: ICitizen) {
        const knownMethods = ['PBS', 'DIBS', 'GIROKORT'];
        const paymentTexts = Translations.municipality.citizenPayment;
        const dibsPreauth = this.route.snapshot.queryParamMap.get('transact');
        const dibsStatus = this.route.snapshot.queryParamMap.get('status');
        const dibsStatusCode = this.route.snapshot.queryParamMap.get('statuscode');

        let paymentMethodCode = citizen.citizenPayment!.paymentMethodCode;
        let dibsTicket = citizen.citizenPayment!.dIBSTicketId;

        if (paymentMethodCode && !knownMethods.includes(paymentMethodCode)) {
            knownMethods.push(paymentMethodCode);
        }

        if (dibsStatus) {
            paymentMethodCode = 'DIBS';
            if (dibsStatus === 'success' && dibsPreauth) {
                dibsTicket = dibsPreauth;
                this.dialogService.showSnackMessage({ message: paymentTexts.dibsSuccess });
            } else {
                this.dialogService.showMessage(Translations.replaceTokens(paymentTexts.dibsError, dibsStatusCode));
            }
        }

        this.inputs = [
            new FormBuilderRadio({
                name: paymentTexts.paymentMethodCode.name,
                label: paymentTexts.paymentMethodCode.label,
                value: paymentMethodCode || 'PBS',
                options: knownMethods.map(x => toFormOptions(x, Translations.global.paymentTypes[x])),
                valueChanged: () => {
                    if (this.dibsTicket) {
                        this.dibsTicket.updateValueAndValidity();
                    }
                },
                required: true
            }),
            new FormBuilderTextInput({
                name: paymentTexts.dibsTicketId.name,
                label: paymentTexts.dibsTicketId.label,
                value: dibsTicket,
                condition: () => false,
                required: true,
                readonly: true
            })
        ];

        this.form = this.formBuilder.toFormGroup(this.inputs);
    }

    private _buildStreetName(address: ICitizenAddress): string {
        let addr = address.streetName + '+' + address.streetNumber;
        if (address.addressFloor) {
            addr += '+' + address.addressFloor;
        }
        if (address.addressSide) {
            addr += ',+' + address.addressSide;
        }
        return addr;
    }

}
