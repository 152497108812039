import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';

import { IProductViewModel } from '../../api/services';
import { cross } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { CURRENCY_CODE } from '../app.locale_data';
import { IChip, ProductService } from '../services/product.service';

@Component({
    selector: 'iv-commerce-product-details',
    template: `
        <article class="dialog product-details__wrapper">
            <button class="dialog__close-button" mat-icon-button mat-dialog-close>${cross}</button>
            <mat-dialog-content class="product-details dialog__content">
                <iv-util-hidden-scroll class="product-details__hidden-scroll" height="100%">
                    <div class="product-details__row">
                        <div class="product-details__col product-details__image-wrapper">
                            <img
                                *ngIf="product.primaryImage"
                                class="product-details__image"
                                srcset="{{ product.primaryImage }}&w=420&h=420 1x, {{ product.primaryImage }}&w=840&h=840 2x"
                                src="{{ product.primaryImage }}&w=420&h=420"
                                width="420"
                                height="420"
                                [attr.alt]="product.name" />
                        </div>
                        <div class="product-details__col product-details__data">
                            <header>
                                <h1>{{ product.name }}</h1>
                                <h2 class="product-details__subtitle">{{ product.description }}</h2>
                            </header>

                            <iv-commerce-labels class="product-details__labels" *ngIf="product.labels && product.labels.length" [labels]="product.labels"></iv-commerce-labels>

                            <mat-chip-listbox class="product-details__chips" *ngIf="chips.length">
                                <mat-chip *ngFor="let chip of chips" [color]="chip.color" [highlighted]="chip.selected">
                                    {{ chip.text }}
                                </mat-chip>
                            </mat-chip-listbox>

                            <div class="product-details__actions-prices">

                                <div class="product-details__prices">
                                    <div class="product-details__discount-price" *ngIf="isProductCampaignDiscount && product?.campaign?.campaignPrice !== product.price">
                                        {{ product.campaign.campaignPrice | currency : currencyCode : 'symbol-narrow' }}
                                    </div>

                                    <div class="product-details__price" [ngClass]="{ 'product-details__price_line-through': isProductCampaignDiscount && product?.campaign?.campaignPrice !== product.price }">
                                        {{ product.price | currency : currencyCode : 'symbol-narrow' }}
                                    </div>

                                    <div class="product-details__unit-price">
                                        {{ product.unitPrice }}
                                    </div>
                                </div>

                                <iv-commerce-addtobasket class="product-details__addtobasket" [product]="product" mode="details"></iv-commerce-addtobasket>
                                <iv-commerce-toggle-favorite class="product-details__favorites" [productId]="product.id" [isFavorite]="product.favorite" [text]="true"></iv-commerce-toggle-favorite>
                            </div>

                            <div class="product-details__description">

                                <p class="product-details__attributes">
                                    <span class="clearfix">
                                        <strong>${Translations.commerce.productHead.orderNumber}:</strong> {{ product.catalogItemNo}}
                                    </span>
                                    <ng-container *ngIf="product.attributes && product.attributes.length">
                                        <span class="clearfix" *ngFor="let attr of product.attributes">
                                            <strong>{{ attr.key }}:</strong> {{ attr.value.join(', ') }}
                                        </span>
                                    </ng-container>
                                </p>

                                <p class="product-details__text" *ngIf="product.text" [innerHTML]="product.text" appInterceptLinks></p>
                            </div>

                            <div class="product-details__medicinal text-center_mobile" *ngIf="product.isMedicalProduct">
                                <a target="_blank" href="${Translations.commerce.productDetails.linkToMedicinal}"><img alt="" src="/assets/logo-medicinal-product.png"></a>
                            </div>

                            <iv-commerce-product-declarations class="product-details__declarations" [product]="product"></iv-commerce-product-declarations>
                        </div>
                    </div>
                </iv-util-hidden-scroll>
            </mat-dialog-content>
        </article>
    `
})
export class CommerceProductDetailsComponent implements OnInit, OnDestroy {

    isProductCampaignDiscount = false;
    chips: IChip[] = [];

    private currentTitle: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public product: IProductViewModel,
        @Inject(CURRENCY_CODE) public currencyCode: string,
        private productService: ProductService,
        private title: Title
    ) { }

    ngOnInit() {
        this.currentTitle = this.title.getTitle();

        this.isProductCampaignDiscount = this.productService.isProductCampaignDiscount(this.product);
        this.chips = this.productService.createChips(this.product);
        this.title.setTitle(this.currentTitle + ' - ' + this.product.name);
    }

    ngOnDestroy() {
        this.title.setTitle(this.currentTitle);
    }

}
