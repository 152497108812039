import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Translations } from '../../translations/translations';
import { ICol, IEvent } from '../services/calendar.service';
import { IDragEvent } from './drag.service';

@Component({
    selector: 'iv-drag-events-calendar',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <section class="drag-events-calendar call-list-admin__calendar">
            <div class="times" *ngIf="cols.length">
                <div *ngFor="let slot of cols[0].slots" class="time">
                    <div *ngIf="slot.min === 0">{{ slot.hour }}</div>
                </div>
                <div *ngIf="cols.length" class="time">
                    <div>{{ cols[0].slots[cols[0].slots.length-1].hour+1 }}</div>
                </div>
            </div>
            <div class="columns">
                <div *ngFor="let col of cols" class="column">
                    <button class="title" (click)="onTitleClicked(col)" [ngClass]="{selected: col.selected}">{{ col.title }}</button>
                    <div *ngFor="let slot of col.slots" class="slot">
                        <button *ngFor="let event of slot.events"
                                class="booking booking-{{event.duration}}"
                                [ngClass]="{selected: event.selected}"
                                dragtarget
                                [canDrop]="canDrop(event)"
                                (click)="onEventClicked(event)"
                                (dropComplete)="onDropComplete($event, event, col)">
                                {{ event.isPause ? '${Translations.callListAdmin.callListPage.intervalTab.breakBlock}' : (event.allocation ? event.allocation + ' / ' + event.capacity : '0') }}
                        </button>
                    </div>
                </div>
            </div>
        </section>
    `
})
export class DragEventsCalendarComponent {
    @Output() dropped = new EventEmitter<IDragEvent>();
    @Output() titleClicked = new EventEmitter<ICol>();
    @Output() eventClicked = new EventEmitter<IEvent>();
    @Input() cols: ICol[] = [];

    onTitleClicked(col: ICol) {
        this.titleClicked.emit(col);
    }

    onEventClicked(event: IEvent) {
        this.eventClicked.emit(event);
    }

    canDrop(event: IEvent) {
        return (dragger: any) => {
            if (!(Array.isArray(dragger) && dragger.length)) {
                return false;
            }

            if (event.disableDrag || event.isPause) {
                return false;
            }
            return true;
        };
    }

    onDropComplete(dragger: any, event: IEvent, col: any) {
        this.dropped.emit({ value: dragger, where: event, targetCallerProfileId: col.id });
    }

    constructor() { }
}
