import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { finalize, map, takeUntil, tap } from 'rxjs/operators';

import { CreateCreditNoteRowModel, ICreditNoteViewModel, IOrderHistoryViewModel } from '../../api/services';
import { cross } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { getOrdersRowQuantity } from '../commerce/helpers/orders.helper';
import { FormBuilderOption } from '../form-builder/form-builder-element.model';
import { toFormOptions } from '../form-builder/form-builder-mappings';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { OrderService } from '../services/order.service';
import { DialogService } from '../shared/dialog.service';

export interface IPriceAdjustmentOrderReturnLine {
    qty: number;
    newUnitPrice: number;
    reason: string;
}

interface IOrderCreditFormModel {
    orderNo: string;
    orderLines: FormGroup;
}

interface ICreditNoteDialogState {
    isFetching: boolean;
    isCreating: boolean;
    state: 'initial' | 'order' | 'receipt';
}

/**
 * Component to show the price adjustment dialog
 */
@Component({
    selector: 'iv-citizen-price-adjustment-dialog',
    template: `
        <article class="create-credit-note dialog">
            <button class="dialog__close-button" mat-icon-button mat-dialog-close *ngIf="!hide">${cross}</button>
            <h2 mat-dialog-title class="dialog__header">${Translations.administration.citizenPriceAdjustment.title}</h2>
            <mat-dialog-content class="create-credit-note__content dialog__content">
                <iv-util-hidden-scroll class="create-credit-note__hidden-scroll" height="100%" scrollbox="true">
                    <div class="create-credit-note__input" *ngIf="!data">
                        <form #orderForm="ngForm" [formGroup]="orderNoForm" (submit)="getOrder()">
                            <mat-form-field>
                                <input
                                    formControlName="orderNo"
                                    name="orderNo"
                                    type="text"
                                    matInput
                                    placeholder="${Translations.administration.citizenCreditNotes.labelInvoiceNo}"
                                    pattern="[0-9]+"
                                    required
                                />
                                <mat-error *ngIf="orderNo && orderNo.hasError('notFound') && orderNo.touched">
                                    {{ orderNo.getError('notFound') }}
                                </mat-error>
                            </mat-form-field>
                            <button
                                class="create-credit-note__btn"
                                mat-raised-button
                                color="primary"
                                [disabled]="state.isFetching || !orderNoForm.valid"
                            >
                                ${Translations.administration.citizenCreditNotes.btnGetOrder}
                            </button>
                            <mat-progress-spinner
                                class="create-credit-note__spinner"
                                *ngIf="state.isFetching"
                                color="accent"
                                mode="indeterminate"
                                [strokeWidth]="3"
                                [diameter]="20"
                            ></mat-progress-spinner>
                        </form>
                    </div>

                    <iv-commerce-invoice
                        class="create-credit-note__receipt"
                        [invoice]="creditNoteReceipt"
                        *ngIf="state.state === 'receipt'"
                        [priceAdjustmentMode]="true"
                    ></iv-commerce-invoice>

                    <div class="create-credit-note__order" *ngIf="state.state === 'order' && order$ | async as order">
                        <h3>${Translations.administration.citizenCreditNotes.invoice} {{ order.orderNo }}</h3>
                        <div class="credit-lines" *ngIf="createCreditNoteForm">
                            <iv-commerce-orders-rows
                                [causes]="creditNoteCauses"
                                [rows]="order.rows"
                                [formGroup]="createCreditNoteForm.get('orderLines')"
                                [priceAdjustmentMode]="true"
                            ></iv-commerce-orders-rows>
                        </div>
                    </div>
                </iv-util-hidden-scroll>
            </mat-dialog-content>
            <mat-dialog-actions class="dialog__actions alt-theme" *ngIf="state.state === 'order' && order">
                <iv-progress-button
                    buttonType="button"
                    class="create-credit-note__action-btn create-credit-note__submit"
                    color="primary"
                    [loadingState]="state.isCreating"
                    (click)="onSubmitCreditNote()"
                    >${Translations.administration.citizenCreditNotes.btnPost}
                </iv-progress-button>
                <button type="button" class="create-credit-note__action-btn" mat-button mat-dialog-close>
                    ${Translations.global.btnCancel}
                </button>
            </mat-dialog-actions>
        </article>
    `
})
export class CitizenPriceAdjustmentDialogComponent implements OnInit, OnDestroy {
    @ViewChild('orderForm') orderFormElement: NgForm;

    order$: Observable<IOrderHistoryViewModel>;
    order: IOrderHistoryViewModel;
    creditNoteReceipt: ICreditNoteViewModel;
    creditNoteCauses: FormBuilderOption[];
    state: ICreditNoteDialogState = {
        isFetching: false,
        isCreating: false,
        state: 'initial'
    };

    orderNoForm = this.formBuilder.group({
        orderNo: this.formBuilder.control('', [Validators.required])
    });

    get orderNo() {
        return this.orderNoForm.get('orderNo');
    }

    createCreditNoteForm: FormGroup;

    hide = false;

    private unsubscribeS$: Subject<void> = new Subject();

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public data: string | undefined,
        private orderService: OrderService,
        private formBuilder: FormBuilder,
        private dialogService: DialogService
    ) {}

    ngOnDestroy(): void {
        this.unsubscribeS$.next();
        this.unsubscribeS$.complete();
    }

    ngOnInit() {
        this.orderService
            .getCreditReturnReasons()
            .pipe(
                map(causes => causes.map(cause => toFormOptions(cause.code, cause.description))),
                takeUntil(this.unsubscribeS$)
            )
            .subscribe(causes => (this.creditNoteCauses = causes));

        if (this.data) {
            this.getOrder(this.data);
        }
    }

    getOrder(orderNo?: string) {
        if (this.orderNoForm.valid || orderNo) {
            this.state.isFetching = true;
            this.state.state = 'initial';

            if (!orderNo) {
                orderNo = this.orderNo?.value ?? '';
            }

            if (orderNo) {
                this.state.state = 'order';
                this.order$ = this.orderService.getOrderByNo(orderNo, false, true).pipe(
                    tap(
                        order => {
                            this.order = order;
                            this.orderNoForm.reset();
                            this.resetForm();

                            this.createCreditNoteForm = this.formBuilder.group(this._orderToFormModel(order));
                        },
                        (err: IntervareHttpErrorResponse) => {
                            this.orderNo!.setErrors({
                                notFound: err.validationErrors.join(', ')
                            });
                        }
                    ),
                    finalize(() => (this.state.isFetching = false))
                );
            } else {
                this.state.isFetching = false;
            }
        }
    }

    onSubmitCreditNote(): void {
        if (!this.createCreditNoteForm.valid) {
            return;
        }

        const model = { ...this.createCreditNoteForm.value };
        const rows = this._createCreditNoteRows(model.orderLines, this.order);

        if (!rows.length) {
            return;
        }

        this._createCreditNote(model.orderNo, rows);
        this.hide = true;
    }

    private _createCreditNote(orderNo: string, creditNoteRows: CreateCreditNoteRowModel[]): void {
        this.state.isCreating = true;

        this.orderService
            .createCreditNote({
                orderNo,
                isPriceAdjustmentCreditNote: true,
                creditNoteRows
            })
            .pipe(
                finalize(() => (this.state.isCreating = false)),
                takeUntil(this.unsubscribeS$)
            )
            .subscribe(
                creditNote => {
                    if (creditNote.hasErrors && creditNote.errorMessages) {
                        this.dialogService.showValidationResult(creditNote.errorMessages);
                        return;
                    }

                    this.dialogService.showSnackMessage({
                        message: Translations.administration.citizenCreditNotes.messageOk
                    });
                    this.creditNoteReceipt = creditNote;
                    this.state.state = 'receipt';
                },
                (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
            );
    }

    private _orderToFormModel(order: IOrderHistoryViewModel): IOrderCreditFormModel {
        const orderLines = {};

        if (order.rows) {
            order.rows.forEach(row => {
                if (row.productNo) {
                    const total = getOrdersRowQuantity(row);

                    const totalCtrl = new FormControl(total, [Validators.min(0), Validators.maxLength(4)]);

                    const reasonCtrl = new FormControl({
                        value: this.priceAdjustmentCause,
                        disabled: true
                    });

                    const newUnitPriceCtrl = new FormControl({ value: undefined, disabled: row.quantityCredited > 0 }, [
                        Validators.min(0),
                        Validators.maxLength(4)
                    ]);

                    orderLines[row.bottleRelatesToProduct ? row.lineType + row.bottleRelatesToProduct : row.productNo] =
                        new FormGroup({
                            qty: totalCtrl,
                            newUnitPrice: newUnitPriceCtrl,
                            reason: reasonCtrl
                        });
                }
            });
        }

        const orderFormModel: IOrderCreditFormModel = {
            orderNo: order.orderNo || '',
            orderLines: this.formBuilder.group(orderLines)
        };

        return orderFormModel;
    }

    private _createCreditNoteRows(
        orderLines: Record<string, IPriceAdjustmentOrderReturnLine>,
        originalOrder: IOrderHistoryViewModel
    ): CreateCreditNoteRowModel[] {
        const rows: CreateCreditNoteRowModel[] = [];

        Object.keys(orderLines).forEach(line => {
            const originalLine = originalOrder?.rows?.find(x => x.productNo === line);
            const row = new CreateCreditNoteRowModel();

            if (!originalLine) {
                return;
            }

            const reasonWhy = orderLines[line].reason ?? this.priceAdjustmentCause;
            const newUnitPrice = orderLines[line].newUnitPrice;
            const quantity = orderLines[line].qty;
            const priceAdjustmentUnitPrice = Math.max(0, originalLine.productUnitPrice - orderLines[line].newUnitPrice);

            if (quantity > 0 && newUnitPrice > 0) {
                row.productId = originalLine.productNo;
                row.quantity = quantity;
                row.returnReason = reasonWhy;
                row.priceAdjustmentUnitPrice = +priceAdjustmentUnitPrice.toFixed(2);

                rows.push(row);
            }
        });

        return rows;
    }

    private resetForm() {
        if (this.orderFormElement) {
            this.orderFormElement.resetForm();
        }
    }

    private get priceAdjustmentCause() {
        return (
            this.creditNoteCauses.find(c => c.label === Translations.administration.citizenPriceAdjustment.title)
                ?.value ?? ''
        );
    }
}
