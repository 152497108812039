import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[dontAllowNegativeValues]'
})
export class DontAllowNegativeValuesDirective {
    @HostListener('keydown', ['$event']) onInput(event: KeyboardEvent): void {
        if (event.code === 'Slash' || event.code === 'NumpadSubtract') {
            event?.preventDefault();
        }
    }
}
