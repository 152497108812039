import { HttpParams } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { WeekDays } from '../../api/services';
import { environment } from '../../environments/environment';
import { excel } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { FormBuilderMunicipalityPicker, FormBuilderOption, FormBuilderTypes } from '../form-builder/form-builder-element.model';
import { FormBuilderService } from '../form-builder/form-builder.service';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { CitizenService } from '../services/citizen.service';
import { CustomerTypePipe } from '../shared/customer-type.pipe';
import { IDENTITIES, IUserRoles, ROLES } from '../user/user-roles';
import { UserService } from '../user/user.service';
import { Helpers } from '../util/helpers';
import { UtilUrlService } from '../util/util-url.service';
import { IUserDefaultMunicipalities } from './municipality.service';

// RxJS
// Translation
// Services
// Interfaces

interface ICustomerOverviewItem {
    customerSubType: number;
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
}

interface IParams {
    municipalities?: number;
    districts?: number[] | number;
    subDistricts?: number[] | number;
    dayOfWeekNo?: number;
    customerSubTypes?: number;
}

@Component({
    selector: 'iv-municipality-citizen-overview',
    template: `
        <section class="municipality-citizen-overview" *ngIf="!isFetching; else fetching">
            <form
                *ngIf="form"
                [formGroup]="form"
                class="municipality-citizen-overview__form alt-theme form"
                autocomplete="off"
            >
                <iv-form-builder-element
                    *ngFor="let input of inputs"
                    [form]="form"
                    [input]="input"
                ></iv-form-builder-element>
            </form>

            <div class="errors" *ngIf="validationErrors">
                <h4 *ngFor="let error of validationErrors">{{ error }}</h4>
            </div>

            <div class="municipality-citizen-overview__results">
                <a
                    *ngIf="haveResults"
                    href="${environment.apiBaseUrl}/api/Citizen/ExportCustomerOverview?{{ params }}"
                    target="_blank"
                    class="btn-with-icon btn-with-icon_last"
                    mat-raised-button
                    color="primary"
                >
                    ${Translations.municipality.common.exportToExcel}<span class="icon">${excel}</span>
                </a>

                <div *ngIf="validationErrors" class="municipality-citizen-overview__errors">
                    <h4 *ngFor="let error of validationErrors">
                        {{ error }}
                    </h4>
                </div>

                <mat-table #table class="municipality-citizen-overview__table" [dataSource]="dataSource">
                    <!-- Name Column -->
                    <ng-container matColumnDef="customerSubType">
                        <mat-header-cell *matHeaderCellDef
                            >${Translations.municipality.citizenOverview.typeOfCitizen}</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row">
                            <a
                                [title]="_processTitle(row.customerSubType)"
                                [routerLink]="['/${Translations.municipality.paths.viewCitizens.path}']"
                                [queryParams]="_processQueryParams(row)"
                            >
                                {{ row.customerSubType | customerType }}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="{{ day.value }}" *ngFor="let day of weekDays">
                        <mat-header-cell *matHeaderCellDef>{{ day.label }}</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <a
                                [title]="_processTitle(row.customerSubType, day.value)"
                                [routerLink]="['/${Translations.municipality.paths.viewCitizens.path}']"
                                [queryParams]="_processQueryParams(row, day)"
                            >
                                {{ row[day.value] }}
                            </a>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>

                <div class="municipality-citizen-overview__loading center-content" *ngIf="isLoadingResults">
                    <mat-progress-spinner
                        color="accent"
                        mode="indeterminate"
                        [strokeWidth]="2"
                        [diameter]="30"
                    ></mat-progress-spinner>
                </div>

                <div *ngIf="!haveResults && !isLoadingResults" class="municipality-citizen-overview__results-empty">
                    ${Translations.municipality.common.noResults}
                </div>
            </div>
        </section>

        <ng-template #fetching>
            <div class="municipality-citizen-overview__loading">
                <mat-progress-spinner
                    color="accent"
                    mode="indeterminate"
                    [strokeWidth]="3"
                    [diameter]="60"
                ></mat-progress-spinner>
            </div>
        </ng-template>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MunicipalityCitizenOverviewComponent implements OnInit, OnDestroy {
    isLoadingResults = false;
    haveResults = true;
    isFetching = true;

    form: UntypedFormGroup;
    inputs: FormBuilderTypes[];

    validationErrors: string[];
    dataSource = new MatTableDataSource<ICustomerOverviewItem>();

    displayedColumns: string[] = ['customerSubType', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday'];

    weekDays: FormBuilderOption[];

    params: string;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private userService: UserService,
        private citizenService: CitizenService,
        private formBuilder: FormBuilderService,
        private customerTypePipe: CustomerTypePipe,
        private route: ActivatedRoute,
        private urlService: UtilUrlService,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        // Make an mapping of the week days
        this.weekDays = Object.keys(Translations.global.weekDays).map(day => {
            return { value: day, label: Translations.global.weekDays[day] };
        });

        // Get the user roles
        this.userService.user$
            .pipe(
                map(user => {
                    const defaultMunicipalites: IUserDefaultMunicipalities = {
                        municipality: 0,
                        districts: 0,
                        subdistricts: 0
                    };

                    if (user && user.roles && user.roles.indexOf(ROLES.MunicipalityAdmin) !== -1) {
                        defaultMunicipalites.municipality = user.municipalities ? user.municipalities[0] : 0;
                        defaultMunicipalites.districts = user.districts ? user.districts[0] : 0;
                        defaultMunicipalites.subdistricts = user.subDistricts ? user.subDistricts[0] : 0;
                    }

                    const urlState = this.route.snapshot.queryParams;

                    if (urlState.municipalityId) {
                        defaultMunicipalites.municipality = +urlState.municipalityId;
                    }

                    if (urlState.districtId) {
                        defaultMunicipalites.districts = +urlState.districtId;
                    }

                    if (urlState.subDistrictId) {
                        defaultMunicipalites.subdistricts = +urlState.subDistrictId;
                    }

                    return defaultMunicipalites;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(results => {
                // Build the form
                this._buildForm(results);
            });

        // Subscribe the changes to the form values;
        this.form.valueChanges
            .pipe(
                tap(() => {
                    this.dataSource.data = [];
                    this.isLoadingResults = true;

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const params: { municipalityId?: string, districtId?: string, subDistrictId?: string } = {};
                    if (this.form.value.districts.municipality) {
                        params.municipalityId = this.form.value.districts.municipality;
                    }
                    if (this.form.value.districts.districts) {
                        params.districtId = this.form.value.districts.districts;
                    }
                    if (this.form.value.districts.subdistricts) {
                        params.subDistrictId = this.form.value.districts.subdistricts;
                    }
                    this.params = new HttpParams({ fromObject: params }).toString();

                    this.urlService.setState(this.params);
                }),
                switchMap(formResult => {
                    return this.citizenService.getCitizenOverview(
                        formResult.districts.municipality,
                        formResult.districts.districts,
                        formResult.districts.subdistricts
                    );
                }),
                catchError((err, caught) => {
                    this.handleWithErrors(err);
                    return caught;
                }),
                map(result =>
                    result.reduce((acc: ICustomerOverviewItem[], item) => {
                        const customerOverviewItem: ICustomerOverviewItem = {
                            customerSubType: item.customerSubType,
                            monday: `${item.mondayActive} (${item.mondayInactive})`,
                            tuesday: `${item.tuesdayActive} (${item.tuesdayInactive})`,
                            wednesday: `${item.wednesdayActive} (${item.wednesdayInactive})`,
                            thursday: `${item.thursdayActive} (${item.thursdayInactive})`,
                            friday: `${item.fridayActive} (${item.fridayInactive})`
                        };

                        acc.push(customerOverviewItem);

                        return acc;
                    }, [] as ICustomerOverviewItem[])
                ),
                takeUntil(this.unsubscribe)
            )
            .subscribe(
                (result: ICustomerOverviewItem[]) => {
                    this.isLoadingResults = false;
                    this.haveResults = true;
                    this.isFetching = false;

                    this.dataSource.data = result;

                    if (result.length === 0) {
                        this.haveResults = false;
                    }

                this.cd.markForCheck();
                },
                (err: IntervareHttpErrorResponse) => {
                    this.handleWithErrors(err);
                }
            );

        this.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private handleWithErrors(err: IntervareHttpErrorResponse) {
        this.validationErrors = [];

        if (err.validationErrors && err.validationErrors.length > 0) {
            this.validationErrors = err.validationErrors;
        } else {
            this.validationErrors.push(err.error.statusText);
        }

        this.isLoadingResults = false;
        this.dataSource.data = [];
        this.cd.markForCheck();
    }

    private _buildForm(municipalities: IUserDefaultMunicipalities) {
        const roles = this.userService.user$.getValue()!.roles as IUserRoles[];

        this.inputs = [
            new FormBuilderMunicipalityPicker({
                name: 'districts',
                allowEmptyValue: roles.some(x => IDENTITIES.adminAndCustomerService.includes(x)),
                value: municipalities,
                required: false
            })
        ];

        this.form = this.formBuilder.toFormGroup(this.inputs);
    }

    private _processQueryParams(row: ICustomerOverviewItem, day?: FormBuilderOption): IParams {
        const params: IParams = {};
        const districts = this.form.value.districts;

        if (districts.municipality && districts.municipality > 0) {
            params.municipalities = districts.municipality;
        }

        if (districts.districts && districts.districts > 0) {
            params.districts = districts.districts;
        }

        if (districts.subdistricts && districts.subdistricts > 0) {
            params.subDistricts = districts.subdistricts;
        }

        if (day && day.value) {
            params.dayOfWeekNo = WeekDays[Helpers.capitalizeFirstLetter(day.value)];
        }

        if (row.customerSubType) {
            params.customerSubTypes = row.customerSubType;
        }

        return params;
    }

    private _processTitle(citizenType: number, day: string): string {
        let title = Translations.replaceTokens(
            Translations.municipality.citizenOverview.linkTitle,
            this.customerTypePipe.transform(citizenType)
        );

        if (day) {
            title = Translations.replaceTokens(
                Translations.municipality.citizenOverview.daysLinkTitle,
                this.customerTypePipe.transform(citizenType),
                Translations.global.weekDays[day]
            );
        }

        return title;
    }
}
