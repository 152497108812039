import { Component, OnInit, EventEmitter, Output } from '@angular/core';

import { Translations } from '../../translations/translations';

@Component({
    selector: 'iv-layout-cookie-warning',
    template: `
    <div class="layout-cookie-warning">
        <div class="layout-cookie-warning__container">
            <div class="layout-cookie-warning__text">
                ${Translations.layout.cookieWarning.text}
            </div>

            <button class="layout-cookie-warning__btn" mat-raised-button color="primary" (click)="accept()">
                ${Translations.layout.cookieWarning.btn}
            </button>
        </div>
    </div>
  `
})
export class LayoutCookieWarningComponent {
    @Output() accepted = new EventEmitter();

    accept() {
        this.accepted.emit();
    }
}
