import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ICallListViewModel } from '../../api/services';
import { pen, pending } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { CallListAdminService } from '../services/call-list-admin.service';

@Component({
    selector: 'iv-administration-calllistadmin-calllist-list',
    template: `
        <div class="category-menu__link" routerLinkActive="category-menu__link_active" *ngFor="let callList of callLists | async">
            <a [routerLink]="['','calllist', callList.callListId]">{{ callList.name }}</a>
            <a [routerLink]="['', { outlets: { dialog: ['edit-call-list', callList.callListId]} }]" class="edit-icon">${pen}</a>
        </div>
        <div class="alt-theme" *ngIf="isFetching">
            <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="2" [diameter]="20"></mat-progress-spinner>
        </div>
        <button class="call-list-admin__refresh" type="button" (click)="callListAdminService.getCallLists()"><i class="icon">${pending}</i> ${Translations.callListAdmin.navigation.refresh}</button>
  `
})
export class AdministrationCalllistadminCalllistListComponent implements OnInit {

    callLists: Observable<ICallListViewModel[]>;

    isFetching = true;

    ngOnInit() {
        this.callLists = this.callListAdminService.getCallLists().pipe(tap(() => {
            this.isFetching = false;
        }));
    }

    constructor(
        public callListAdminService: CallListAdminService
    ) { }
}
