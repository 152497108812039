import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { takeUntil, tap, switchMapTo, map, catchError } from 'rxjs/operators';

import { Interval, SimpleCallRecord } from '../../api/services';
import { callerList } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { CallListService } from '../services/call-list.service';
import { DialogService } from '../shared/dialog.service';
import { IDialogContentSettings } from '../shared/dialog-interfaces';
import { GenesysConversationErrorDetails } from '../services/genesys/genesys.model';
import { CallsService } from '../services/calls/calls.service';

@Component({
    selector: 'iv-administration-call-list',
    template: `
        <div class="administration-call-list" [class.administration-call-list_alert]="showNextInterval">
            <ng-container *ngIf="callList$ | async as callList; else fetching">
                <div class="alert alert-info" role="status" *ngIf="showNextInterval">
                    <div class="alert__content">${Translations.administration.callList.textSet.callAhead}</div>
                </div>
                <mat-tab-group
                    *ngIf="callList.incompleteCallsList.length || callList.completedCallsList.length; else notfound"
                >
                    <mat-tab>
                        <ng-template mat-tab-label>
                            ${Translations.administration.callList.tabs.current.title}<span class="call-list-item-count"
                                >({{ incompleteCallsListCount || 0 }})</span
                            >
                        </ng-template>

                        <iv-util-hidden-scroll class="administration-call-list__hidden-scroll">
                            <div class="administration-call-list__tab-body">
                                <ng-container *ngFor="let interval of callList.incompleteCallsList">
                                    <ng-container
                                        *ngIf="interval.simpleCallRecords && interval.simpleCallRecords.length"
                                    >
                                        <h4 class="administration-call-list__interval-header">
                                            {{ interval.startingTime }} - {{ interval.endingTime }}
                                        </h4>
                                        <ul class="administration-call-list__list">
                                            <li
                                                class="call-list-item"
                                                *ngFor="let callRecord of interval.simpleCallRecords"
                                            >
                                                <div class="call-list-item__customer">
                                                    <a
                                                        [routerLink]="[
                                                            '',
                                                            {
                                                                outlets: {
                                                                    admin: [
                                                                        'citizen',
                                                                        callRecord.customerNo,
                                                                        callRecord.callRecordId
                                                                    ]
                                                                }
                                                            }
                                                        ]"
                                                    >
                                                        <h3
                                                            class="call-list-item__customer-name"
                                                            [ivCitizenColor]="callRecord"
                                                        >
                                                            {{ callRecord.citizenName }}
                                                        </h3>
                                                    </a>
                                                    <h4 class="call-list-item__customer-phone-number">
                                                        {{ callRecord.citizenPhoneNumber | chunk }}
                                                    </h4>
                                                </div>
                                                <iv-progress-button
                                                    class="call-list-button"
                                                    color="primary"
                                                    [callback]="
                                                        makeCall.bind(
                                                            this,
                                                            callRecord.customerNo,
                                                            callRecord.callRecordId,
                                                            callRecord.citizenPhoneNumber,
                                                            callRecord.isBlocked
                                                        )
                                                    "
                                                    >${callerList}
                                                    ${Translations.administration.callList.textSet
                                                        .call}</iv-progress-button
                                                >
                                            </li>
                                        </ul>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </iv-util-hidden-scroll>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            ${Translations.administration.callList.tabs.finished.title}<span
                                class="call-list-item-count"
                                >({{ completedCallsList?.length || 0 }})</span
                            >
                        </ng-template>

                        <iv-util-hidden-scroll class="administration-call-list__hidden-scroll">
                            <div class="administration-call-list__tab-body">
                                <ul class="administration-call-list__list">
                                    <li class="call-list-item" *ngFor="let callRecord of callList.completedCallsList">
                                        <div class="call-list-item__customer">
                                            <a
                                                [routerLink]="[
                                                    '',
                                                    {
                                                        outlets: {
                                                            admin: [
                                                                'citizen',
                                                                callRecord.customerNo,
                                                                callRecord.callRecordId
                                                            ]
                                                        }
                                                    }
                                                ]"
                                            >
                                                <h3 class="call-list-item__customer-name" [ivCitizenColor]="callRecord">
                                                    {{ callRecord.citizenName }}
                                                </h3>
                                            </a>
                                            <h4 class="call-list-item__customer-phone-number">
                                                {{ callRecord.citizenPhoneNumber | chunk }}
                                            </h4>
                                        </div>
                                        <iv-progress-button
                                            class="call-list-button"
                                            color="primary"
                                            [callback]="
                                                makeCall.bind(
                                                    this,
                                                    callRecord.customerId,
                                                    callRecord.callRecordId,
                                                    callRecord.citizenPhoneNumber,
                                                    callRecord.isBlocked
                                                )
                                            "
                                            >${callerList}
                                            ${Translations.administration.callList.textSet.call}</iv-progress-button
                                        >
                                    </li>
                                </ul>
                            </div>
                        </iv-util-hidden-scroll>
                    </mat-tab>
                </mat-tab-group>
            </ng-container>

            <ng-template #notfound>
                <div class="center-content">${Translations.administration.callList.textSet.notFound}</div>
            </ng-template>

            <ng-template #fetching>
                <div class="center-content alt-theme">
                    <mat-progress-spinner
                        color="accent"
                        mode="indeterminate"
                        [strokeWidth]="3"
                        [diameter]="60"
                    ></mat-progress-spinner>
                </div>
            </ng-template>
        </div>
    `
})
export class AdministrationCallListComponent implements OnInit, OnDestroy {
    incompleteCallsListCount: number;
    showNextInterval = false;
    callList$: Observable<{ completedCallsList: SimpleCallRecord[]; incompleteCallsList: Interval[] }>;

    private unsubscribe = new Subject<void>();
    constructor(
        private dialogService: DialogService,
        private _callListService: CallListService,
        private router: Router,
        private callsService: CallsService
    ) {}

    ngOnInit() {
        this.callList$ = this._callListService.getCallList().pipe(
            map(callList => {
                const completedCallsList: SimpleCallRecord[] = [];
                let incompleteCallsList: Interval[] = [];

                if (callList.intervals) {
                    callList.intervals.forEach(listGroup => {
                        if (listGroup.simpleCallRecords) {
                            listGroup.simpleCallRecords = listGroup.simpleCallRecords.filter(x => {
                                if (x.isCallCompleted) {
                                    completedCallsList.push(x);
                                    return false;
                                }
                                return true;
                            });
                        }
                    });
                    incompleteCallsList = callList.intervals;
                }

                this.showNextInterval = callList.showNextInterval;

                return { completedCallsList, incompleteCallsList };
            }),
            catchError(() => {
                return of({ completedCallsList: [], incompleteCallsList: [] });
            }),
            takeUntil(this.unsubscribe)
        );

        this._callListService.callListStatus$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(callListStatus => (this.incompleteCallsListCount = callListStatus.incompleteCallCount));
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    makeCall = (
        customerNo: string,
        callRecordId: number,
        citizenPhoneNumber: string,
        isBlocked: boolean
    ): Promise<boolean> => {
        if (isBlocked) {
            const dialogSettings: IDialogContentSettings = {
                data: {
                    header: Translations.administration.callList.citizenBlocked.header,
                    content: Translations.administration.callList.citizenBlocked.text,
                    buttons: {
                        button1: {
                            color: 'primary',
                            confirm: true,
                            text: Translations.global.btnAcknowledge
                        }
                    }
                }
            };

            return this.dialogService
                .openDialog(dialogSettings)
                .afterClosed()
                .pipe(switchMapTo(this._callCitizen(customerNo, callRecordId, citizenPhoneNumber)))
                .toPromise();
        } else {
            return this._callCitizen(customerNo, callRecordId, citizenPhoneNumber).toPromise();
        }
    };

    private _callCitizen(customerNo: string, callRecordId: number, citizenPhoneNumber: string) {
        return this.callsService.callCitizen(customerNo, citizenPhoneNumber).pipe(
            tap(
                () => this.router.navigate(['', { outlets: { admin: ['citizen', customerNo, callRecordId] } }]),
                (err: GenesysConversationErrorDetails) => this.dialogService.showValidationResult([err?.message ?? ''])
            )
        );
    }
}
