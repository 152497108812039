import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Translations } from '../../translations/translations';
import { AuthGuard } from '../user/auth.guard';
import { IDENTITIES } from '../user/user-roles';
import { MunicipalityCitizenDraftsComponent } from './municipality-citizen-drafts.component';
import { MunicipalityCitizenOverviewComponent } from './municipality-citizen-overview.component';
import { MunicipalityCitizenPaymentComponent } from './municipality-citizen-payment.component';
import { MunicipalityCitizenStatusComponent } from './municipality-citizen-status.component';
import { MunicipalityCreateUserComponent } from './municipality-create-user.component';
import { MunicipalityDriverMessagesComponent } from './municipality-driver-messages.component';
import { MunicipalityEditPasswordCitizenComponent } from './municipality-edit-password-citizen.component';
import { MunicipalityPdaStatisticsComponent } from './municipality-pda-statistics.component';
import { MunicipalitySubscribeCitizenComponent } from './municipality-subscribe-citizen.component';
import { MunicipalityTemporaryAddressComponent } from './municipality-temporary-address.component';
import { MunicipalityUserListComponent } from './municipality-user-list.component';
import { MunicipalityViewCitizensComponent } from './municipality-view-citizens.component';

export const municipalityRoutes: Routes = [
    {
        path: Translations.municipality.paths.createUser.path,
        component: MunicipalityCreateUserComponent,
        canActivate: [AuthGuard],
        data: {
            pageData: {
                title: Translations.municipality.paths.createUser.title
            },
            allowedRoles: IDENTITIES.editUsers
        }
    },
    {
        path: Translations.municipality.paths.createUser.path + '/:id',
        component: MunicipalityCreateUserComponent,
        canActivate: [AuthGuard],
        data: {
            pageData: {
                title: Translations.municipality.paths.createUser.title
            },
            allowedRoles: IDENTITIES.editUsers
        }
    },
    {
        path: Translations.municipality.paths.userAdministration.path,
        component: MunicipalityUserListComponent,
        canActivate: [AuthGuard],
        data: {
            pageData: {
                title: Translations.municipality.paths.userAdministration.title
            },
            allowedRoles: IDENTITIES.editUsers
        }
    },
    {
        path: Translations.municipality.paths.subscribeCitizen.path,
        component: MunicipalitySubscribeCitizenComponent,
        canActivate: [AuthGuard],
        data: {
            pageData: {
                title: Translations.municipality.paths.subscribeCitizen.title,
                edit: false
            },
            allowedRoles: IDENTITIES.municipalityAdmin
        }
    },
    {
        path: Translations.municipality.paths.editCitizen.path,
        component: MunicipalitySubscribeCitizenComponent,
        canActivate: [AuthGuard],
        data: {
            pageData: {
                title: Translations.municipality.paths.editCitizen.title,
                edit: true
            },
            allowedRoles: IDENTITIES.municipalityAdmin
        }
    },
    {
        path: Translations.municipality.paths.editPasswordForCitizen.path,
        component: MunicipalityEditPasswordCitizenComponent,
        canActivate: [AuthGuard],
        data: {
            pageData: {
                title: Translations.municipality.paths.editPasswordForCitizen.title
            },
            allowedRoles: IDENTITIES.municipalityAdmin
        }
    },
    {
        path: Translations.municipality.paths.citizenPayment.path,
        component: MunicipalityCitizenPaymentComponent,
        canActivate: [AuthGuard],
        data: {
            pageData: {
                title: Translations.municipality.paths.citizenPayment.title
            },
            allowedRoles: IDENTITIES.municipalityAdmin
        }
    },
    {
        path: Translations.municipality.paths.citizenDriverMessages.path,
        component: MunicipalityDriverMessagesComponent,
        canActivate: [AuthGuard],
        data: {
            pageData: {
                title: Translations.municipality.paths.citizenDriverMessages.title
            },
            allowedRoles: IDENTITIES.municipalityAdmin
        }
    },
    {
        path: Translations.municipality.paths.temporaryAddress.path,
        component: MunicipalityTemporaryAddressComponent,
        canActivate: [AuthGuard],
        data: {
            pageData: {
                title: Translations.municipality.paths.temporaryAddress.title
            },
            allowedRoles: IDENTITIES.municipalityAdmin
        }
    },
    {
        path: Translations.municipality.paths.citizenOverview.path,
        component: MunicipalityCitizenOverviewComponent,
        canActivate: [AuthGuard],
        data: {
            pageData: {
                title: Translations.municipality.paths.citizenOverview.title
            },
            allowedRoles: IDENTITIES.municipalityPortal
        }
    },
    {
        path: Translations.municipality.paths.viewCitizens.path,
        component: MunicipalityViewCitizensComponent,
        canActivate: [AuthGuard],
        data: {
            pageData: {
                title: Translations.municipality.paths.viewCitizens.title
            },
            allowedRoles: IDENTITIES.municipalityPortal
        }
    },
    {
        path: Translations.municipality.paths.citizenDrafts.path,
        component: MunicipalityCitizenDraftsComponent,
        canActivate: [AuthGuard],
        data: {
            pageData: {
                title: Translations.municipality.paths.citizenDrafts.title
            },
            allowedRoles: IDENTITIES.municipalityAdmin
        }
    },
    {
        path: Translations.municipality.paths.citizenStatus.path,
        component: MunicipalityCitizenStatusComponent,
        canActivate: [AuthGuard],
        data: {
            pageData: {
                title: Translations.municipality.paths.citizenStatus.title
            },
            allowedRoles: IDENTITIES.municipalityPortal
        }
    },
    {
        path: Translations.municipality.paths.pdaStatistics.path,
        component: MunicipalityPdaStatisticsComponent,
        canActivate: [AuthGuard],
        data: {
            pageData: {
                title: Translations.municipality.paths.pdaStatistics.title
            },
            allowedRoles: IDENTITIES.municipalityPortal
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(municipalityRoutes)],
    declarations: [],
    exports: [RouterModule]
})
export class MunicipalityRoutingModule { }
