import { Pipe, PipeTransform } from '@angular/core';

import {
    CitizenAddress,
    CitizenAddressViewmodel,
    CitizenRelative,
    CitizenSearchViewModel,
    CitizenWithOrderInfoViewModel,
    ICitizenAddress,
    ICitizenAddressViewmodel,
    ICitizenRelative,
    ICitizenSearchViewModel,
    ShowCitizenModel,
} from '../../api/services';
import { cities } from '../form-builder/elements/cities';
import { getCityByZipCode } from '../util/helpers';

@Pipe({
    name: 'address'
})
export class AddressPipe implements PipeTransform {
    transform(address: ICitizenAddressViewmodel | ICitizenSearchViewModel | ICitizenAddress | ICitizenRelative, showZipCity = true): string {
        let formatted = '';

        if (address instanceof CitizenAddressViewmodel) {
            formatted += address.streetName ? address.streetName : '';
            formatted += address.streetNumber ? ' ' + address.streetNumber : '';
            formatted += address.floor ? ' ' + address.floor : '';
            formatted += address.side ? ' ' + address.side : '';
            formatted += address.zipCode && showZipCity ? ', ' + address.zipCode : '';
            formatted += address.city && showZipCity ? ' ' + getCityByZipCode(address.zipCode, address.city) : '';
        }
        else if (address instanceof CitizenSearchViewModel || address instanceof ShowCitizenModel || address instanceof CitizenWithOrderInfoViewModel) {
            formatted += address.streetName ? address.streetName : '';
            formatted += address.streetNumber ? ' ' + address.streetNumber : '';
            formatted += address.addressFloor ? ' ' + address.addressFloor : '';
            formatted += address.addressSide ? ' ' + address.addressSide : '';
            formatted += address.zipCode && showZipCity ? ', ' + address.zipCode : '';
            formatted += address.cityName && showZipCity ? ' ' + getCityByZipCode(address.zipCode, address.cityName) : '';
        }
        else if (address instanceof CitizenAddress || address instanceof CitizenRelative) {
            formatted += address.streetName ? address.streetName : '';
            formatted += address.streetNumber ? ' ' + address.streetNumber : '';
            formatted += address.addressFloor ? ' ' + address.addressFloor : '';
            formatted += address.addressSide ? ' ' + address.addressSide : '';
            formatted += address.zipCode && showZipCity ? ', ' + address.zipCode : '';
            formatted += address.city && showZipCity ? ' ' + getCityByZipCode(address.zipCode, address.city) : '';
        }

        return formatted;
    }
}
