import { Component } from '@angular/core';

@Component({
    selector: 'iv-commerce-product-list-home',
    template: `
    <p>
        Vælg en kategori i menuen
    </p>
    `
})
export class CommerceProductListHomeComponent {
}
