import { HttpParams } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { isEmpty, transformQueryParamsIntoMunicipalities } from '../util/helpers';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { MatTableDataSource } from '@angular/material/table';
import {
    CallCitizenExportListFilter,
    CitizenTransferReason,
    CitizenWithoutOrder,
    CustomerSubType,
    ICitizenWithoutOrder,
    ICitizensWithoutOrderFilterViewModel
} from '../../api/services';
import { Translations } from '../../translations/translations';
import { FormBuilderMunicipalityPicker, FormBuilderTypes } from '../form-builder/form-builder-element.model';
import { FormBuilderService } from '../form-builder/form-builder.service';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { CitizenService } from '../services/citizen.service';
import { DialogService } from '../shared/dialog.service';
import { ITransferReasonDialogOutput, TransferReasonDialogService } from '../shared/transfer-reason-dialog.service';
import { IDENTITIES, IUserRoles, ROLES } from '../user/user-roles';
import { UserService } from '../user/user.service';
import { UtilUrlService } from '../util/util-url.service';
import { UtilService } from '../util/util.service';
import { IUserDefaultMunicipalities } from './municipality.service';

interface IGroupCitizenWithoutOrders {
    [key: number]: MatTableDataSource<ICitizenWithoutOrder>;
}

@Component({
    selector: 'iv-municipality-citizen-status',
    template: `
        <section class="municipality-citizen-status" *ngIf="!isFetching; else fetching">
            <form *ngIf="form" [formGroup]="form" class="alt-theme form" autocomplete="off">
                <iv-form-builder-element
                    *ngFor="let input of inputs"
                    [form]="form"
                    [input]="input"
                ></iv-form-builder-element>
            </form>

            <div class="errors" *ngIf="validationErrors">
                <h4 *ngFor="let error of validationErrors">{{ error }}</h4>
            </div>

            <div class="municipality-citizen-status__results">
                <div class="municipality-citizen-status__results">
                    <div class="municipality-citizen-status__loading center-content" *ngIf="isLoadingResults">
                        <mat-progress-spinner
                            color="accent"
                            mode="indeterminate"
                            [strokeWidth]="2"
                            [diameter]="30"
                        ></mat-progress-spinner>
                    </div>

                    <ng-container *ngFor="let item of citizenWithoutOrders | keyvalue">
                    <div class="municipality-citizen-status__table-container">
                        <ng-container *ngTemplateOutlet="table; context: { $implicit: item }"></ng-container>
                    </div>
                    </ng-container>

                    <div *ngIf="!haveResults && !isLoadingResults" class="municipality-citizen-status__results-empty">
                        ${Translations.municipality.common.noResults}
                    </div>
                </div>
            </div>
        </section>

        <ng-template #fetching>
            <div class="municipality-citizen-status__fetching">
                <mat-progress-spinner
                    color="accent"
                    mode="indeterminate"
                    [strokeWidth]="3"
                    [diameter]="60"
                ></mat-progress-spinner>
            </div>
        </ng-template>

        <ng-template #table let-item>
            <header>
                <h3>{{ item.key | customerType }}</h3>
            </header>

            <mat-table class="municipality-citizen-status__table" #table [dataSource]="item.value">
                <!-- No Order Date Column -->
                <ng-container matColumnDef="date">
                    <mat-header-cell class="municipality-citizen-status__table-head" *matHeaderCellDef
                        >${Translations.municipality.citizenStatus.table.date}</mat-header-cell
                    >
                    <mat-cell class="municipality-citizen-status__table-body" *matCellDef="let citizen">
                        {{ citizen.noOrderDate | date : 'shortDate' }}
                    </mat-cell>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <mat-header-cell class="municipality-citizen-status__table-head" *matHeaderCellDef
                        >${Translations.municipality.citizenStatus.table.name}</mat-header-cell
                    >
                    <mat-cell class="municipality-citizen-status__table-body" *matCellDef="let citizen">
                        <strong [innerHTML]="_concatName(citizen)"></strong>
                        <span [innerHTML]="_concatAddress(citizen)"></span>
                    </mat-cell>
                </ng-container>

                <!-- phone Column -->
                <ng-container matColumnDef="phone">
                    <mat-header-cell class="municipality-citizen-status__table-head" *matHeaderCellDef
                        >${Translations.municipality.citizenStatus.table.phone}</mat-header-cell
                    >
                    <mat-cell class="municipality-citizen-status__table-body" *matCellDef="let citizen">
                        {{ citizen.phoneNumber }}
                    </mat-cell>
                </ng-container>

                <!-- subdistrict Column -->
                <ng-container matColumnDef="subdistrict">
                    <mat-header-cell class="municipality-citizen-status__table-head" *matHeaderCellDef
                        >${Translations.municipality.citizenStatus.table.subDistrict}</mat-header-cell
                    >
                    <mat-cell class="municipality-citizen-status__table-body" *matCellDef="let citizen">
                        {{ citizen.subDistrictName }}
                    </mat-cell>
                </ng-container>

                <!-- Status/Actions -->
                <ng-container matColumnDef="status" stickyEnd>
                    <mat-header-cell class="municipality-citizen-status__table-head" *matHeaderCellDef
                        >${Translations.municipality.citizenStatus.table.status}</mat-header-cell
                    >
                    <mat-cell class="municipality-citizen-status__table-body" *matCellDef="let citizen">
                        <ng-container *ngIf="_validateTypeOfCitizen(citizen)"
                            >${Translations.municipality.citizenStatus.info}
                        </ng-container>
                        <iv-progress-button
                            *ngIf="!_validateTypeOfCitizen(citizen)"
                            buttonType="button"
                            class="municipality-citizen-status__action-button btn-with-icon"
                            color="accent"
                            width="full"
                            [callback]="openDialogActions.bind(this, citizen)"
                        >
                            ${Translations.municipality.citizenStatus.actionText}
                        </iv-progress-button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MunicipalityCitizenStatusComponent implements OnInit, OnDestroy {
    isFetching = true;
    haveResults = false;
    isLoadingResults = false;

    form: UntypedFormGroup;
    inputs: FormBuilderTypes[];
    validationErrors: string[] = [];

    citizenWithoutOrders?: IGroupCitizenWithoutOrders;

    displayedColumns = ['date', 'name', 'phone', 'subdistrict', 'status'];

    filterCitizenStatus: ICitizensWithoutOrderFilterViewModel = {
        municipalities: [],
        districts: [],
        subDistricts: [],
        customerSubTypes: [],
        fromDate: new Date(1970, 1, 1, 0, 0, 0),
        callRecordsTime: CallCitizenExportListFilter.AllRecords
    };

    private _filterCitizenStatus: BehaviorSubject<ICitizensWithoutOrderFilterViewModel>;

    private unsubscribe: Subject<void> = new Subject();
    private _scrollContainer: HTMLElement | Window =
        (document.querySelector('#content > .util-hidden-scroll') as HTMLElement) || window;

    constructor(
        private userService: UserService,
        private dialogService: DialogService,
        private citizenService: CitizenService,
        private formBuilder: FormBuilderService,
        private transferReasonDialogService: TransferReasonDialogService,
        private route: ActivatedRoute,
        private urlService: UtilUrlService,
        private utilService: UtilService,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        // Instanciate the the filter citizen status Observable to watch for changes on FilterModel;
        this._filterCitizenStatus = new BehaviorSubject<ICitizensWithoutOrderFilterViewModel>(this.filterCitizenStatus);

        // Get the user roles
        this.userService.user$
            .pipe(
                map(user => transformQueryParamsIntoMunicipalities(user, this.route.snapshot.queryParams)),
                takeUntil(this.unsubscribe)
            )
            .subscribe(results => {
                // Check if we have query params and fill or not the form;
                this._buildForm(results);
                this.utilService.scrollElementTo(this._scrollContainer, 0);
            });

        // Watch changes to the filter citizen model;
        // this is ready if we want do some pagination
        this._filterCitizenStatus
            .pipe(
                tap(() => {
                    this.isLoadingResults = true;
                    this.cd.markForCheck();
                }),
                switchMap((changes: ICitizensWithoutOrderFilterViewModel) => {
                    if (changes.districts && changes.districts.toString().length !== 0) {
                        return this.citizenService.getCitizenStatus(changes);
                    }

                    return of([]);
                }),
                map(changes => {
                    return changes.reduce((acc, value: ICitizenWithoutOrder) => {
                        const hasKey = acc[value.customerSubType];

                        if (!hasKey) {
                            acc[value.customerSubType] = new MatTableDataSource<ICitizenWithoutOrder>();
                        }

                        acc[value.customerSubType].data.push(value);
                        return acc;
                    }, {} as IGroupCitizenWithoutOrders);
                }, {} as IGroupCitizenWithoutOrders),
                catchError((err, caught) => {
                    this.handleWithErrors(err);
                    return caught;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(
                results => {
                    this.citizenWithoutOrders = results;
                    this.isFetching = false;
                    this.isLoadingResults = false;
                    this.isFetching = false;
                    this.haveResults = true;

                    if (isEmpty(results)) {
                        this.haveResults = false;
                    }
                    this.cd.markForCheck();
                },
                err => this.handleWithErrors(err)
            );
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    // When choose a citizen open the transfer dialog to perform a reason;
    openDialogActions = (citizen: CitizenWithoutOrder): Promise<boolean> => {
        const citizenName = citizen.firstName + ' ' + citizen.lastName;
        const title = Translations.replaceTokens(
            Translations.municipality.citizenStatus.dialog.dialogTitle,
            citizenName
        );

        return this.transferReasonDialogService
            .open({
                callRecordId: citizen.callRecordId || 0,
                customerNo: citizen.customerNo || '',
                noOrderDate: citizen.noOrderDate,
                customerSubType: citizen.customerSubType,
                dialogTitle: title
            })
            .pipe(
                tap(
                    res =>
                        res.success
                            ? res.action !== 0
                                ? this.successfullyTransferred(citizen, res)
                                : this.alreadyTransferred()
                            : undefined,
                    () => this.failedTransfer(citizen)
                ),
                map(res => res.success)
            )
            .toPromise();
    };

    successfullyTransferred(citizen: ICitizenWithoutOrder, res: ITransferReasonDialogOutput) {
        const actionString = Translations.global.transferReasons[CitizenTransferReason[res.action]];
        this._updateDataTableAndNotify(citizen, res.action);
        this.dialogService.showSnackMessage({
            message: Translations.replaceTokens(
                Translations.municipality.citizenStatus.transferDialog.messageOk,
                citizen.firstName + ' ' + citizen.lastName,
                actionString
            )
        });
    }

    alreadyTransferred() {
        this.dialogService.showSnackMessage({
            message: Translations.municipality.citizenStatus.transferDialog.messageAlreadyDone
        });
    }

    failedTransfer(citizen: ICitizenWithoutOrder) {
        this.dialogService.showSnackMessage({
            message: Translations.replaceTokens(
                Translations.municipality.citizenStatus.transferDialog.messageError,
                citizen.firstName + ' ' + citizen.lastName
            )
        });
    }

    private handleWithErrors(err: IntervareHttpErrorResponse) {
        this.validationErrors = [];

        if (err.validationErrors && err.validationErrors.length > 0) {
            this.validationErrors = err.validationErrors;
        } else {
            this.validationErrors.push(err.error.statusText);
        }

        this.isLoadingResults = false;
        this.haveResults = false;
        this.citizenWithoutOrders = undefined;
        this.cd.markForCheck();
    }

    /**
     * Build the form with districts
     *
     * @private
     * @memberof MunicipalityCitizenStatusComponent
     */
    private _buildForm(municipalities: IUserDefaultMunicipalities) {
        // control when the component is unloded and the user$ is undefined,
        // because is happening when we do logout
        if (!this.userService.user$.getValue()) {
            return;
        }

        const roles = this.userService.user$.getValue()!.roles as IUserRoles[];

        this.inputs = [
            new FormBuilderMunicipalityPicker({
                name: 'districts',
                multipleSubdistricts: true,
                allowEmptyValue: roles.some(x => IDENTITIES.adminAndCustomerService.includes(x)),
                value: municipalities,
                required: true
            })
        ];

        this.form = this.formBuilder.toFormGroup(this.inputs);

        this._watchFormChanges();

        this.form.updateValueAndValidity({ onlySelf: true, emitEvent: true });
        this.cd.markForCheck();
    }

    private _watchFormChanges() {
        this.form.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(changes => {
            // Get Data from form
            if (changes.districts) {
                this.filterCitizenStatus.municipalities = changes.districts.municipality;
                this.filterCitizenStatus.districts = changes.districts.districts;
                this.filterCitizenStatus.subDistricts = changes.districts.subdistricts;

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const params: { municipalities?: string, districts?: string, subDistricts?: string } = {};

                if (changes.districts.municipality) {
                    params.municipalities = changes.districts.municipality;
                }

                if (changes.districts.districts) {
                    params.districts = changes.districts.districts;
                }

                if (changes.districts.subdistricts && changes.districts.subdistricts.length) {
                    params.subDistricts = changes.districts.subdistricts;
                }

                const httpParams = new HttpParams({ fromObject: params });
                this.urlService.setState(httpParams);
            }

            this.filterCitizenStatus.customerSubTypes = [];
            this._filterCitizenStatus.next(this.filterCitizenStatus);
        });
    }

    /**
     * Should show or not an action for specific citizen;
     *
     * @public
     * @param {CitizenWithoutOrder} citizen
     * @returns {boolean}
     * @memberof MunicipalityCitizenStatusComponent
     */
    public _validateTypeOfCitizen(citizen: CitizenWithoutOrder): boolean {
        return (
            citizen.customerSubType === CustomerSubType.TastSelvBorger ||
            citizen.customerSubType === CustomerSubType.RingerSelv
        );
    }

    /**
     * Should process the title for the citizen log history
     *
     * @private
     * @param {CitizenWithoutOrder} citizen
     * @returns {string}
     * @memberof MunicipalityCitizenStatusComponent
     */
    private _processLinkTitle(citizen: CitizenWithoutOrder): string {
        return Translations.replaceTokens(
            Translations.municipality.citizenStatus.logLinkTitle,
            this._concatName(citizen)
        );
    }

    /**
     * Concat the the properties of the name, like FirstName and LastName;
     *
     * @public
     * @param {CitizenWithoutOrder} citizen
     * @returns {string}
     * @memberof MunicipalityCitizenStatusComponent
     */
    public _concatName(citizen: ICitizenWithoutOrder): string {
        return citizen.firstName + ' ' + citizen.lastName;
    }

    /**
     * Concat multiple address properties into on string;
     *
     * @public
     * @param {CitizenWithoutOrder} citizen
     * @returns {string}
     * @memberof MunicipalityCitizenStatusComponent
     */
    public _concatAddress(citizen: CitizenWithoutOrder): string {
        let addressString = '';

        if (citizen) {
            addressString = citizen.streetName + ' ' + citizen.streetNumber;

            if (citizen.addressFloor) {
                addressString += ', ' + citizen.addressFloor + '. ';
            }

            if (citizen.addressSide) {
                addressString += citizen.addressSide;
            }

            if (citizen.cityName) {
                addressString += '<br/>' + citizen.cityName;
            }
        }

        return addressString;
    }

    private _updateDataTableAndNotify(citizen: ICitizenWithoutOrder, action: number = 0) {
        if (this.citizenWithoutOrders) {
            const matTableDataSource = this.citizenWithoutOrders[citizen.customerSubType];
            const index = matTableDataSource.data.findIndex(current => current.customerNo === citizen.customerNo);
            const actionString = Translations.global.transferReasons[CitizenTransferReason[action]];

            matTableDataSource.data.splice(index, 1);
            this.citizenWithoutOrders[citizen.customerSubType].data = matTableDataSource.data;

            this.dialogService.showSnackMessage({
                message: Translations.replaceTokens(
                    Translations.municipality.citizenStatus.transferDialog.messageOk,
                    this._concatName(citizen),
                    actionString
                )
            });
        }
    }
}
