import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Translations } from '../../translations/translations';
import { CitizenSummaryComponent } from '../citizen/citizen-summary.component';
import { CitizenGuard } from '../citizen/routing/citizen.guard';
import { MunicipalityAdminMenuComponent } from '../municipality/municipality-admin-menu.component';
import { MunicipalityMenuComponent } from '../municipality/municipality-menu.component';
import { AuthGuard } from '../user/auth.guard';
import { IDENTITIES } from '../user/user-roles';
import { AdministrationCallListComponent } from './administration-call-list.component';
import { AdministrationCitizenSearchComponent } from './administration-citizen-search.component';
import { AdministrationIntervareMenuComponent } from './administration-intervare-menu.component';
import { AdministrationMessagesCreateUpdateComponent } from './administration-messages-create-update.component';
import { AdministrationMessagesComponent } from './administration-messages.component';
import { AdministrationPendingCallsComponent } from './administration-pending-calls.component';
import { AdministrationSearchNemligComponent } from './administration-search-nemlig.component';
import { AdministrationSettingsComponent } from './administration-settings.component';
import { AdministrationStockComponent } from './administration-stock.component';
import { AdministrationWeeklyoffersAdminComponent } from './administration-weeklyoffers-admin.component';
import { AdministrationCreationErrorsComponent } from './administration-creation-errors.component';
import { AdministrationDeliveryDateComponent } from './administration-delivery-date.component';
import { AdministrationCreateUserComponent } from './administration-create-user.component';
import { AdministrationViewUsersComponent } from './administration-view-users.component';
import { AdministrationUserHistoryComponent } from './administration-user-history.component';

export const administrationRoutes: Routes = [
    {
        path: 'calllist',
        component: AdministrationCallListComponent,
        outlet: 'admin',
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.callList
        }
    },
    {
        path: 'citizen/:id/:callRecordId',
        component: CitizenSummaryComponent,
        outlet: 'admin',
        canActivate: [AuthGuard, CitizenGuard],
        data: {
            allowedRoles: IDENTITIES.adminAndCustomerService
        }
    },
    {
        path: 'citizen/:id/:callRecordId/:inboundCallNotificationId',
        component: CitizenSummaryComponent,
        outlet: 'admin',
        canActivate: [AuthGuard, CitizenGuard],
        data: {
            allowedRoles: IDENTITIES.adminAndCustomerService
        }
    },
    {
        path: 'customersearch',
        component: AdministrationCitizenSearchComponent,
        outlet: 'admin',
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.adminAndCustomerService
        }
    },
    {
        path: 'municipalityportal',
        component: MunicipalityMenuComponent,
        outlet: 'admin',
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.municipalityPortal
        }
    },
    {
        path: 'municipalityadmin',
        component: MunicipalityAdminMenuComponent,
        outlet: 'admin',
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.municipalityAdmin
        }
    },
    {
        path: 'nemlig',
        component: AdministrationSearchNemligComponent,
        outlet: 'admin',
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.searchNemlig
        }
    },
    {
        path: 'stock',
        component: AdministrationStockComponent,
        outlet: 'admin',
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.stockView
        }
    },
    {
        path: 'intervare',
        component: AdministrationIntervareMenuComponent,
        outlet: 'admin',
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.adminAndCustomerService
        }
    },
    {
        path: Translations.intervare.paths.messageAdmin.path,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.admin
        },
        children: [
            {
                path: 'edit/:id',
                component: AdministrationMessagesCreateUpdateComponent,
                data: {
                    pageData: {
                        title: Translations.intervare.messageAdmin.editTitle
                    }
                }
            },
            {
                path: 'edit',
                component: AdministrationMessagesCreateUpdateComponent,
                data: {
                    pageData: {
                        title: Translations.intervare.messageAdmin.createTitle
                    }
                }
            },
            {
                path: '',
                component: AdministrationMessagesComponent,
                data: {
                    pageData: {
                        title: Translations.intervare.paths.messageAdmin.title
                    }
                }
            }
        ]
    },
    {
        path: Translations.intervare.paths.settingsAdmin.path,
        component: AdministrationSettingsComponent,
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.admin,
            pageData: {
                title: Translations.intervare.paths.settingsAdmin.title
            }
        }
    },
    {
        path: Translations.intervare.paths.weeklyOffersAdmin.path,
        component: AdministrationWeeklyoffersAdminComponent,
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.admin,
            pageData: {
                title: Translations.intervare.paths.weeklyOffersAdmin.title
            }
        }
    },
    {
        path: Translations.intervare.paths.pendingCalls.path,
        component: AdministrationPendingCallsComponent,
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.pendingCalls,
            pageData: {
                title: Translations.intervare.paths.pendingCalls.title
            }
        }
    },
    {
        path: Translations.intervare.paths.creationError.path,
        component: AdministrationCreationErrorsComponent,
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.admin,
            pageData: {
                title: Translations.intervare.paths.creationError.title
            }
        }
    },
    {
        path: Translations.municipality.paths.deliveryDate.path,
        component: AdministrationDeliveryDateComponent,
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.admin,
            pageData: {
                title: Translations.municipality.paths.deliveryDate.title
            }
        }
    },
    {
        path: Translations.municipality.paths.createInternalUser.path,
        component: AdministrationCreateUserComponent,
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.admin,
            pageData: {
                title: Translations.municipality.paths.createInternalUser.title
            }
        }
    },
    {
        path: Translations.municipality.paths.editInternalUser.path + '/:username',
        component: AdministrationCreateUserComponent,
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.admin,
            pageData: {
                title: Translations.municipality.paths.editInternalUser.title
            }
        }
    },
    {
        path: Translations.municipality.paths.listInternalUsers.path,
        component: AdministrationViewUsersComponent,
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.admin,
            pageData: {
                title: Translations.municipality.paths.listInternalUsers.title
            }
        }
    },
    {
        path: Translations.municipality.paths.userHistory.path,
        component: AdministrationUserHistoryComponent,
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.admin,
            pageData: {
                title: Translations.municipality.paths.userHistory.title
            }
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(administrationRoutes)],
    exports: [RouterModule]
})
export class AdministrationRoutingModule {}
