import { IRealTimeInboundCallEvent } from 'Client/api/services';

export enum EventSourceReadyState {
    CONNECTING = 0,
    OPEN = 1,
    CLOSED = 2
}

export type EventSourceEventType = 'Error' | 'Success';

export interface CallEventMessage {
    type: EventSourceEventType;
    readyState: EventSourceReadyState;
    message?: IRealTimeInboundCallEvent;
    error?: string;
}
