import { Component, Inject, OnInit, Optional } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { filter, switchMapTo, tap } from 'rxjs/operators';

import { CauseViewModel, ICauseViewModel } from '../../api/services';
import { Translations } from '../../translations/translations';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { DialogService } from '../shared/dialog.service';
import { NotesAdminService } from './notes-admin.service';

@Component({
    selector: 'iv-notes-admin-create-cause',
    template: `
        <article class="dialog alt-theme">
            <h2 mat-dialog-title>
                {{ editMode ? '${Translations.intervare.notesAdmin.editCause.header}' : '${Translations.intervare.notesAdmin.createCause.header}' }}
            </h2>
            <form #form="ngForm" (submit)="close(form)">
                <mat-dialog-content>
                    <mat-form-field class="form__element">
                        <input type="text" matInput placeholder="${Translations.intervare.notesAdmin.createCause.name}" name="causeName" [(ngModel)]="model.causeName" required>
                    </mat-form-field>
                </mat-dialog-content>
                <mat-dialog-actions>
                    <iv-progress-button class="municipality__btn" [loadingState]="loading" color="primary">
                        {{ editMode ? '${Translations.intervare.notesAdmin.editCause.submit}' : '${Translations.intervare.notesAdmin.createCause.submit}' }}
                    </iv-progress-button>
                    <button type="button" mat-button mat-dialog-close>${Translations.global.btnCancel}</button>
                    <iv-progress-button buttonType="button" class="municipality__btn" color="warn" [callback]="delete" *ngIf="editMode">${Translations.global.btnDelete}</iv-progress-button>
                </mat-dialog-actions>
            </form>
        </article>
    `
})
export class NotesAdminCreateCauseComponent implements OnInit {
    loading = false;
    editMode = false;
    model: ICauseViewModel = new CauseViewModel();

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: ICauseViewModel | undefined,
        private dialogRef: MatDialogRef<NotesAdminCreateCauseComponent>,
        private notesAdmin: NotesAdminService,
        private dialogService: DialogService
    ) { }

    ngOnInit() {
        if (this.dialogData && this.dialogData.causeId) {
            this.model = this.dialogData;
            this.editMode = true;
        }
    }

    close(form: NgForm) {
        if (!form.valid) {
            return;
        }
        this.loading = true;

        if (this.editMode) {
            this.notesAdmin.updateCause(this.model).subscribe(
                () => {
                    this.notesAdmin.getCauses();
                    this.loading = false;
                    this.dialogService.showSnackMessage({
                        message: Translations.intervare.notesAdmin.editCause.messageOk
                    });
                    this.dialogRef.close();
                },
                (err: IntervareHttpErrorResponse) => {
                    this.loading = false;
                    this.dialogService.showSnackMessage({
                        message: Translations.intervare.notesAdmin.editCause.messageError + ' (' + err.validationErrors.join(', ') + ')'
                    });
                }
            );
        } else {

            this.notesAdmin.createCause(this.model).subscribe(
                () => {
                    this.notesAdmin.getCauses();
                    this.loading = false;
                    this.dialogService.showSnackMessage({
                        message: Translations.intervare.notesAdmin.createCause.messageOk
                    });
                    this.dialogRef.close();
                },
                (err: IntervareHttpErrorResponse) => {
                    this.loading = false;
                    this.dialogService.showSnackMessage({
                        message: Translations.intervare.notesAdmin.createCause.messageError + ' (' + err.validationErrors.join(', ') + ')'
                    });
                }
            );
        }
    }

    delete = (): Promise<boolean> => {
        return this.dialogService.confirm(Translations.intervare.notesAdmin.editCause.confirmDelete).afterClosed().pipe(
            filter((res: boolean) => res && this.model.causeId !== undefined),
            switchMapTo(this.notesAdmin.deleteCause(this.model.causeId)),
            tap(
                () => {
                    this.notesAdmin.getCauses();
                    this.dialogService.showSnackMessage({
                        message: Translations.intervare.notesAdmin.editCause.messageDeleteOk
                    });
                    this.dialogRef.close();
                },
                (err: IntervareHttpErrorResponse) => this.dialogService.showSnackMessage({
                    message: Translations.replaceTokens(Translations.intervare.notesAdmin.editCause.messageDeleteError, err.validationErrors.join(', '))
                })
            )
        ).toPromise();
    }
}
