import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Injectable,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, combineLatest } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { IProductListViewModel } from '../../api/services';
import { Translations } from '../../translations/translations';
import { MatPaginatorIntlResults } from '../material-ui/internationalization';
import { ProductService } from '../services/product.service';
import { IDENTITIES } from '../user/user-roles';
import { UserService } from '../user/user.service';
import { UtilService } from '../util/util.service';
import { IProductSettings } from './product-settings';

interface IKeyValuePair {
    key: keyof IProductSettings;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
}

@Injectable()
export class MatPaginatorIntlProduct extends MatPaginatorIntlResults {
    itemsPerPageLabel = Translations.commerce.productFilterPage.itemsPerPageLabel;
}

@Component({
    selector: 'iv-commerce-product-filter-page',
    template: `
        <div class="commerce-product-filter-page" *ngIf="!showErrorPage; else error">
            <ng-container *ngIf="!validationErrors.length; else validationError">
                <iv-commerce-product-filter-page-quick-links
                    *ngIf="productSettings.showDiscounts"
                ></iv-commerce-product-filter-page-quick-links>

                <div class="commerce-product-filter-page__filter">
                    <div class="commerce-product-filter-page__count">
                        <ng-template [ngIf]="productViewModel">
                            {{ productViewModel.numFound }} ${Translations.commerce.productFilterPage.products}
                        </ng-template>
                    </div>

                    <div class="commerce-product-filter-page__sort">
                        <mat-form-field tabindex="-1">
                            <mat-label>${Translations.commerce.productFilterPage.sortBy}</mat-label>
                            <mat-select
                                [(ngModel)]="productSettings.sorting"
                                (selectionChange)="onSelectChange()"
                                tabindex="-1"
                            >
                                <mat-option *ngFor="let sorting of sortings" [value]="sorting.value">
                                    {{ sorting.key }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <iv-commerce-product-list
                    [products]="productViewModel?.products"
                    [pageSize]="productSettings.take"
                    [skip]="productSettings.skip"
                    [sorting]="productSettings.sorting"
                ></iv-commerce-product-list>

                <mat-paginator
                    #paginator
                    *ngIf="productViewModel && productViewModel.numFound > productSettings.take && !disablePagination"
                    [length]="productViewModel.numFound"
                    [pageSize]="productSettings.take"
                    [pageSizeOptions]="pageSizeOptions"
                    (page)="onPaginatorChange($event)"
                ></mat-paginator>
            </ng-container>

            <ng-template #validationError>
                <div class="commerce-product-filter-page__status">
                    <div class="commerce-product-filter-page__status-error" *ngFor="let error of validationErrors">
                        {{ error }}
                    </div>
                </div>
            </ng-template>
        </div>

        <ng-template #error>
            <iv-page-not-found></iv-page-not-found>
        </ng-template>
    `,
    providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlProduct }],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommerceProductFilterPageComponent implements OnInit, OnDestroy {
    productViewModel: IProductListViewModel;
    pageSizeOptions = [5, 10, 20, 30];
    sortings = [
        Translations.commerce.productFilterPage.recommended,
        Translations.commerce.productFilterPage.lastPurchased,
        Translations.commerce.productFilterPage.mostPurchased,
        Translations.commerce.productFilterPage.alphabetic,
        Translations.commerce.productFilterPage.priceLowest,
        Translations.commerce.productFilterPage.priceHighest
    ];
    validationErrors: string[] = [];

    @ViewChild('paginator') paginator?: MatPaginator;

    productSettings: IProductSettings = {
        mainCategoryId: '',
        subCategoryId: '',
        pageIndex: 0,
        skip: 0,
        take: this._setPageSize(),
        sorting: this._setSorting(),
        showDiscounts: false,
        searchTerm: undefined,
        showSpecials: undefined,
        isRootCategory: undefined
    };

    showErrorPage = false;
    disablePagination = false;

    private _productSettings = new Subject<IProductSettings>();
    private _scrollContainer: HTMLElement | Window = window;
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private cd: ChangeDetectorRef,
        private productService: ProductService,
        private route: ActivatedRoute,
        private utilService: UtilService,
        private userService: UserService
    ) { }

    ngOnInit() {
        this._productSettings
            .pipe(
                tap(settings => {
                    if (this.paginator) {
                        this.paginator.pageIndex = settings.pageIndex;
                    }
                    this.validationErrors.splice(0);
                }),
                switchMap(() => this.getProducts()),
                takeUntil(this.unsubscribe)
            )
            .subscribe(
                viewModel => {
                    this.productViewModel = viewModel;
                    this._scrollToTop();
                    this.cd.detectChanges();
                },
                err => {
                    this.validationErrors = err.validationErrors;
                    this.cd.detectChanges();
                }
            );

        let oldSearch: string | null = '';

        combineLatest([this.userService.user$, this.route.data, this.route.paramMap])
            .pipe(
                tap(() => (this.disablePagination = false)),
                takeUntil(this.unsubscribe)
            )
            .subscribe(data => {
                const routeData = data[1];
                const paramMap = data[2];

                if (routeData.pageData.data === '404') {
                    this.showErrorPage = true;
                } else {
                    const settings: IKeyValuePair[] = [
                        { key: 'showSpecials', value: undefined },
                        { key: 'sorting', value: this._setSorting() },
                        { key: 'searchTerm', value: undefined },
                        { key: 'pageIndex', value: 0 }
                    ];
                    if (routeData.pageData && routeData.pageData.data !== undefined) {
                        this.showErrorPage = false;
                        settings.push(
                            { key: 'mainCategoryId', value: routeData.pageData.data.mainNode.id },
                            {
                                key: 'subCategoryId',
                                value: routeData.pageData.data.subNode ? routeData.pageData.data.subNode.id : ''
                            },
                            { key: 'skip', value: 0 },
                            { key: 'showDiscounts', value: routeData.pageData.data.isDiscount }
                        );
                    }

                    if (routeData.isRootCategory) {
                        settings.push({ key: 'isRootCategory', value: routeData.isRootCategory });
                    }

                    if (routeData.showSpecials) {
                        settings.filter(setting => setting.key === 'showSpecials')[0].value = routeData.showSpecials;

                        if (routeData.showSpecials === Translations.shop.paths.topProductsPage.path) {
                            this.disablePagination = true;
                            settings.filter(setting => setting.key === 'sorting')[0].value =
                                Translations.commerce.productFilterPage.mostPurchased.value;
                        }
                    }

                    const searchTerm: string | null = paramMap.get('term');

                    // eslint-disable-next-line no-null/no-null
                    if (searchTerm !== null) {
                        settings.filter(setting => setting.key === 'searchTerm')[0].value = searchTerm;
                        settings.filter(setting => setting.key === 'sorting')[0].value = this.sortings[0].value;

                        // if the term was changed should reset the settings
                        if (oldSearch !== searchTerm) {
                            oldSearch = searchTerm;
                            this.productSettings.skip = 0;
                            this.productSettings.pageIndex = 0;
                        }
                    }

                    this._setProductSettings(settings);
                }

                this._scrollToTop();
            });

        this.userService.activeRoles
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                roles =>
                (this._scrollContainer =
                    roles.length && roles.some(x => IDENTITIES.customerCenter.includes(x))
                        ? (document.querySelector('#content > .util-hidden-scroll') as HTMLElement)
                        : window)
            );
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getProducts(): Observable<IProductListViewModel> {
        return this.productService.getProducts(this.productSettings).pipe(takeUntil(this.unsubscribe));
    }

    onPaginatorChange(event: PageEvent) {
        if (event.pageSize === this.productSettings.take) {
            this._scrollToTop();
        }

        const settingsArray: IKeyValuePair[] = [];
        if (event.pageIndex * this.productSettings.take !== this.productSettings.skip) {
            settingsArray.push({ key: 'skip', value: event.pageIndex * this.productSettings.take });
        }
        if (event.pageSize !== this.productSettings.take) {
            settingsArray.push({ key: 'take', value: event.pageSize });
            localStorage.setItem('pageSize', event.pageSize.toString());
        }

        settingsArray.push({ key: 'pageIndex', value: event.pageIndex });

        this._setProductSettings(settingsArray);
    }

    onSelectChange() {
        const settings = Object.assign({}, this.productSettings);
        settings.pageIndex = 0;
        this._productSettings.next(settings);
        localStorage.setItem('sorting', this.productSettings.sorting);
    }

    private _setPageSize(): number {
        try {
            const pageSize = localStorage.getItem('pageSize');
            if (pageSize) {
                return parseInt(pageSize);
            }
            return this.pageSizeOptions[1];
        } catch {
            return this.pageSizeOptions[1];
        }
    }

    private _setSorting(): string {
        return this.sortings[0].value;
    }

    private _setProductSettings(keyValues: IKeyValuePair[]) {
        keyValues.forEach(keyValue => {
            // eslint-disable-next-line no-prototype-builtins, @typescript-eslint/ban-types
            if ((this.productSettings as {}).hasOwnProperty(keyValue.key)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (this.productSettings as any)[keyValue.key] = keyValue.value;
            }
        });
        this._productSettings.next(this.productSettings);

        this.cd.detectChanges();
    }

    private _scrollToTop() {
        this.utilService.scrollElementTo(this._scrollContainer, 0);
    }
}
