import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'iv-ellipsis',
    template: `
        <span matTooltip="{{ text }}">
            {{ text }}
        </span>
	`,
})

export class EllipsisComponent {
    @HostBinding('class.ellipsis') @Input() text: string = "";
}
