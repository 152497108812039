import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { NgForm, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { finalize, map, takeUntil, tap } from 'rxjs/operators';

import { CreateCreditNoteRowModel, ICreditNoteViewModel, IOrderHistoryViewModel } from '../../api/services';
import { Translations } from '../../translations/translations';
import { FormBuilderOption } from '../form-builder/form-builder-element.model';
import { toFormOptions } from '../form-builder/form-builder-mappings';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { OrderService } from '../services/order.service';
import { IDialogOptionsData } from '../shared/dialog-interfaces';
import { DialogOptionsComponent } from '../shared/dialog-options.component';
import { DialogService } from '../shared/dialog.service';
import { watchOrdersWithBottleDeposit } from './helpers/credit-note.helper';

export interface IOrderReturnLine {
    qty: number;
    reason: string;
}

interface IOrderCreditFormModel {
    orderNo: string;
    orderLines: UntypedFormGroup;
}

interface ICreditNoteDialogState {
    isFetching: boolean;
    isCreating: boolean;
    state: 'initial' | 'order' | 'receipt';
}

/**
 * Component to show the create credit note dialog
 */
@Component({
    selector: 'iv-citizen-credit-note-dialog',
    template: `
        <article class="create-credit-note dialog">
            <h2 mat-dialog-title class="dialog__header">${Translations.administration.citizenCreditNotes.title}</h2>
            <mat-dialog-content class="create-credit-note__content dialog__content">
                <iv-util-hidden-scroll class="create-credit-note__hidden-scroll" height="100%" scrollbox="true">
                    <div class="create-credit-note__input" *ngIf="!data">
                        <form #orderForm="ngForm" [formGroup]="orderNoForm" (submit)="getOrder()">
                            <mat-form-field>
                                <input
                                    formControlName="orderNo"
                                    name="orderNo"
                                    type="text"
                                    matInput
                                    placeholder="${Translations.administration.citizenCreditNotes.labelInvoiceNo}"
                                    pattern="[0-9]+"
                                    required
                                />
                                <mat-error *ngIf="orderNo && orderNo.hasError('notFound') && orderNo.touched">
                                    {{ orderNo.getError('notFound') }}
                                </mat-error>
                            </mat-form-field>
                            <button
                                class="create-credit-note__btn"
                                mat-raised-button
                                color="primary"
                                [disabled]="state.isFetching || !orderNoForm.valid"
                            >
                                ${Translations.administration.citizenCreditNotes.btnGetOrder}
                            </button>
                            <mat-progress-spinner
                                class="create-credit-note__spinner"
                                *ngIf="state.isFetching"
                                color="accent"
                                mode="indeterminate"
                                [strokeWidth]="3"
                                [diameter]="20"
                            ></mat-progress-spinner>
                        </form>
                    </div>

                    <iv-commerce-invoice
                        class="create-credit-note__receipt"
                        [invoice]="creditNoteReceipt"
                        *ngIf="state.state === 'receipt'"
                    ></iv-commerce-invoice>

                    <div class="create-credit-note__order" *ngIf="state.state === 'order' && (order$ | async) as order">
                        <h3>${Translations.administration.citizenCreditNotes.invoice} {{ order.orderNo }}</h3>
                        <div class="credit-lines" *ngIf="createCreditNoteForm">
                            <iv-commerce-orders-rows
                                [causes]="creditNoteCauses"
                                [rows]="order.rows"
                                [formGroup]="createCreditNoteForm.get('orderLines')"
                                creditMode="true"
                            ></iv-commerce-orders-rows>
                            <iv-commerce-summary [data]="order"></iv-commerce-summary>
                        </div>
                    </div>
                </iv-util-hidden-scroll>
            </mat-dialog-content>

            <mat-dialog-actions class="dialog__actions alt-theme">
                <iv-progress-button
                    *ngIf="state.state === 'order' && order"
                    buttonType="button"
                    class="create-credit-note__action-btn create-credit-note__submit"
                    color="primary"
                    [loadingState]="state.isCreating"
                    (click)="onSubmitCreditNote()"
                    >${Translations.administration.citizenCreditNotes.btnPost}</iv-progress-button
                >
                <iv-progress-button
                    *ngIf="state.state === 'order' && order"
                    buttonType="button"
                    class="create-credit-note__action-btn"
                    color="accent"
                    [loadingState]="state.isCreating"
                    (click)="onSubmitAllLines()"
                    >${Translations.administration.citizenCreditNotes.btnPostAll}</iv-progress-button
                >
                <button
                    *ngIf="state.state === 'receipt'"
                    type="button"
                    mat-raised-button
                    color="primary"
                    mat-dialog-close
                >
                    ${Translations.global.btnAcknowledge}
                </button>
                <button type="button" class="create-credit-note__action-btn" mat-button mat-dialog-close>
                    ${Translations.global.btnCancel}
                </button>
            </mat-dialog-actions>
        </article>
    `
})
export class CitizenCreditNoteDialogComponent implements OnInit, OnDestroy {
    @ViewChild('orderForm') orderFormElement: NgForm;

    order$: Observable<IOrderHistoryViewModel>;
    order: IOrderHistoryViewModel;
    creditNoteReceipt: ICreditNoteViewModel;
    creditNoteCauses: FormBuilderOption[];
    state: ICreditNoteDialogState = {
        isFetching: false,
        isCreating: false,
        state: 'initial'
    };

    orderNoForm = this.formBuilder.group({
        orderNo: this.formBuilder.control('', [Validators.required])
    });

    get orderNo() {
        return this.orderNoForm.get('orderNo');
    }

    createCreditNoteForm: UntypedFormGroup;

    hide = false;

    private unsubscribeS$: Subject<void> = new Subject();

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public data: string | undefined,
        private orderService: OrderService,
        private formBuilder: UntypedFormBuilder,
        private dialogService: DialogService
    ) { }

    ngOnInit() {
        this.orderService
            .getCreditReturnReasons()
            .pipe(
                map(causes => causes.map(cause => toFormOptions(cause.code, cause.description))),
                takeUntil(this.unsubscribeS$)
            )
            .subscribe(causes => (this.creditNoteCauses = causes));

        if (this.data) {
            this.getOrder(this.data);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeS$.next();
        this.unsubscribeS$.complete();
    }

    getOrder(orderNo?: string) {
        if (this.orderNoForm.valid || orderNo) {
            this.state.isFetching = true;
            this.state.state = 'initial';

            if (!orderNo) {
                orderNo = this.orderNo && this.orderNo.value;
            }

            if (orderNo) {
                this.state.state = 'order';
                this.order$ = this.orderService.getOrderByNo(orderNo, true, true).pipe(
                    tap(
                        order => {
                            this.order = order;
                            this.orderNoForm.reset();
                            this.resetForm();

                            this.createCreditNoteForm = this.formBuilder.group(this._orderToFormModel(order));
                        },
                        (err: IntervareHttpErrorResponse) => {
                            this.orderNo!.setErrors({
                                notFound: err.validationErrors.join(', ')
                            });
                        }
                    ),
                    finalize(() => (this.state.isFetching = false))
                );
            } else {
                this.state.isFetching = false;
            }
        }
    }

    onSubmitCreditNote(): void {
        if (this.createCreditNoteForm.valid) {
            //INT-2031: this.createCreditNoteForm.value don't return disabled fields values (bottle deposit rows)
            const model = Object.assign({}, this.createCreditNoteForm.getRawValue());

            const rows = this._createCreditNoteRows(model.orderLines, this.order);

            if (!rows.length) {
                return;
            }

            this._createCreditNote(model.orderNo, rows);
            this.hide = true;
        }
    }

    onSubmitAllLines(): void {
        // this.createCreditNoteForm.value don't return disabled fields values (bottle deposit rows)
        const model = Object.assign({}, this.createCreditNoteForm.getRawValue());
        const orderLines = model.orderLines as Record<string, IOrderReturnLine>;

        const data: IDialogOptionsData = {
            title: Translations.administration.citizenCreditNotes.allLinesTitle,
            placeholder: Translations.administration.citizenCreditNotes.allLinesPlaceholder,
            actionText: Translations.form.actions.choose,
            options: this.creditNoteCauses,
            isDropdown: true
        };

        this.dialogService
            .openDialogWithComponent(DialogOptionsComponent, { data })
            .afterClosed()
            .pipe(takeUntil(this.unsubscribeS$))
            .subscribe(reason => {
                if (!reason) {
                    return;
                }

                Object.entries<IOrderReturnLine>(orderLines).map(entry => {
                    // Set selected reason for all undecided lines
                    if (!orderLines[entry[0]].reason) {
                        orderLines[entry[0]].reason = reason;
                    }
                });

                const rows = this._createCreditNoteRows(orderLines, this.order, true);

                if (!rows.length) {
                    this.dialogService.showSnackMessage({
                        message: Translations.administration.citizenCreditNotes.messageErrorAllLines
                    });
                    return;
                }

                this._createCreditNote(model.orderNo, rows);
            });
    }

    private _createCreditNote(orderNo: string, creditNoteRows: CreateCreditNoteRowModel[]): void {
        this.state.isCreating = true;

        this.orderService
            .createCreditNote({
                orderNo,
                isPriceAdjustmentCreditNote: false,
                creditNoteRows
            })
            .pipe(
                finalize(() => (this.state.isCreating = false)),
                takeUntil(this.unsubscribeS$)
            )
            .subscribe(
                creditNote => {
                    if (creditNote.hasErrors && creditNote.errorMessages) {
                        this.dialogService.showValidationResult(creditNote.errorMessages);
                        return;
                    }

                    this.dialogService.showSnackMessage({
                        message: Translations.administration.citizenCreditNotes.messageOk
                    });
                    this.creditNoteReceipt = creditNote;
                    this.state.state = 'receipt';
                },
                (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
            );
    }

    private _orderToFormModel(order: IOrderHistoryViewModel): IOrderCreditFormModel {
        const orderLines = {};

        if (order.rows) {
            order.rows.forEach(row => {
                if (row.productNo) {
                    const qtyAvailable = row.quantityDelivered - row.quantityCredited;
                    const ctrl = new UntypedFormControl(0, [Validators.min(0), Validators.maxLength(4)]);
                    const ctrlReason = new UntypedFormControl(0);

                    // INT-2028: Disable input when is bottle deposit row 
                    if (qtyAvailable === 0 || row.bottleRelatesToProduct) {
                        ctrl.disable();
                        ctrlReason.disable();
                    }

                    orderLines[row.bottleRelatesToProduct ? row.lineType + row.bottleRelatesToProduct : row.productNo] =
                        new UntypedFormGroup({
                            qty: ctrl,
                            reason: ctrlReason
                        });
                }
            });
        }

        const orderFormModel: IOrderCreditFormModel = {
            orderNo: order.orderNo || '',
            orderLines: this.formBuilder.group(orderLines)
        };


        watchOrdersWithBottleDeposit(order, orderLines, this.unsubscribeS$);

        return orderFormModel;
    }

    private _createCreditNoteRows(
        orderLines: Record<string, IOrderReturnLine>,
        originalOrder: IOrderHistoryViewModel,
        creditAll = false
    ): CreateCreditNoteRowModel[] {
        const rows: CreateCreditNoteRowModel[] = [];
        const errors: string[] = [];

        Object.keys(orderLines).forEach(line => {
            const originalLine = originalOrder.rows!.find(
                x => (x.bottleRelatesToProduct ? x.lineType + x.bottleRelatesToProduct : x.productNo) === line
            );
            const row = new CreateCreditNoteRowModel();

            if (!originalLine) {
                return;
            }

            const quantityAvailable = originalLine.quantityDelivered - originalLine.quantityCredited;
            const reasonWhy = orderLines[line].reason;

            let quantityCredited = orderLines[line].qty;

            if (originalLine.lineType === 'Deposit') {
                const bottleDepositLine =
                    originalOrder.rows![
                    originalOrder.rows!.findIndex(x => x.lineType! + x.bottleRelatesToProduct! === line) - 1
                    ];
                const bottleDepositLineQuantityAvailable =
                    originalLine.quantityDelivered - originalLine.quantityCredited;

                if (creditAll || quantityCredited > bottleDepositLineQuantityAvailable) {
                    quantityCredited = bottleDepositLineQuantityAvailable;
                }

                row.isBottleDepositRow = true;
                row.bottleRelatesToProduct = bottleDepositLine.productNo;
            } else if (creditAll || quantityCredited > quantityAvailable) {
                quantityCredited = quantityAvailable;
            }

            if (quantityCredited > 0) {
                row.productId = originalLine.productNo;
                row.quantity = Number(quantityCredited);
                row.returnReason = reasonWhy;

                if (!creditAll) {
                    if (reasonWhy === '' || Number(reasonWhy) === 0) {
                        errors.push(line);
                    } else {
                        rows.push(row);
                    }
                } else {
                    rows.push(row);
                }
            }
        });

        if (errors && errors.length > 0) {
            this.dialogService.showMessage(
                Translations.administration.citizenCreditNotes.messageError + '<br /> ' + errors.join('</br>'),
                Translations.administration.citizenCreditNotes.messageErrorDialogTitle,
                'alertdialog'
            );
            return [];
        }

        return rows;
    }

    private resetForm() {
        if (this.orderFormElement) {
            this.orderFormElement.resetForm();
        }
    }
}
