import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

import { Observable, of } from 'rxjs';

import { CallListAdminService } from '../../services/call-list-admin.service';
import { CallerCreateModel, ICallerCreateModel, ICaller } from '../../../api/services';

export const CallerResolver: ResolveFn<Observable<ICaller | ICallerCreateModel>> = (activatedRouteSnapshot: ActivatedRouteSnapshot):  Observable<ICaller | ICallerCreateModel> => {
    const callListAdminService: CallListAdminService = inject(CallListAdminService);
    const id = activatedRouteSnapshot.paramMap.get('caller') || '';
    if (id === 'new') {
        return of(new CallerCreateModel());
    } else {
        return callListAdminService.getCaller(id);
    }
}
