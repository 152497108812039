import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';

import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ICreditNoteRowViewModel, IOrderHistoryRowViewModel, IOrderRowViewModel } from '../../api/services';
import { Translations } from '../../translations/translations';
import { CURRENCY_CODE } from '../app.locale_data';
import { FormBuilderOption } from '../form-builder/form-builder-element.model';
import { ProductService } from '../services/product.service';
import { DialogService } from '../shared/dialog.service';
import { CommerceProductDetailsComponent } from './commerce-product-details.component';
import {
    isEnter,
    isMinus,
    isNotArrowKeys,
    isNotEnterBackspaceDelete,
    isNotNumberKeys,
    isNotShiftTab,
    isNotTab,
    isPlus
} from './keycode-helper';

@Component({
    selector: 'iv-commerce-orders-rows',
    template: `
        <div class="commerce-orders-rows" [formGroup]="formGroup">
            <div class="commerce-orders-rows__head">
                <div class="commerce-orders-rows__name">${Translations.commerce.productHead.products}</div>
                <div class="commerce-orders-rows__id">${Translations.commerce.productHead.orderNumber}</div>
                <div class="commerce-orders-rows__quantity">${Translations.commerce.productHead.quantity}</div>
                <div class="commerce-orders-rows__info" *ngIf="historyMode || creditMode || priceAdjustmentMode">
                    ${Translations.commerce.productHead.noter}
                </div>
                <div class="commerce-orders-rows__unit-price" *ngIf="!priceAdjustmentInvoiceMode">
                    ${Translations.commerce.productHead.price}
                </div>
                <div class="commerce-orders-rows__row-price">${Translations.commerce.productHead.total}</div>
                <div class="commerce-orders-rows__addtobasket" *ngIf="historyMode"></div>
                <div class="commerce-orders-rows__credit" *ngIf="creditMode">
                    ${Translations.commerce.productHead.credit}
                </div>
                <ng-container *ngIf="priceAdjustmentMode">
                    <div class="commerce-orders-rows__price-adjustment">
                        ${Translations.commerce.productHead.newUnitPrice}
                    </div>
                    <div class="commerce-orders-rows__price-adjustment">
                        ${Translations.commerce.productHead.newTotalPrice}
                    </div>
                </ng-container>
            </div>

            <ng-container *ngIf="rows.length; else noRows">
                <ng-container *ngFor="let row of rows">
                    <div
                        class="commerce-orders-rows__row"
                        [ngClass]="{
                            disabled: priceAdjustmentMode && row.quantityCredited > 0,
                            highlighted:
                                priceAdjustmentMode &&
                                formGroup?.value[
                                    row.bottleRelatesToProduct
                                        ? row.lineType + row.bottleRelatesToProduct
                                        : row.productNo
                                ]?.newUnitPrice > 0
                        }"
                    >
                        <div class="commerce-orders-rows__name" (click)="showProductDetail(row.productNo)">
                            {{ row.productName }}
                        </div>
                        <div class="commerce-orders-rows__id" (click)="showProductDetail(row.productNo)">
                            {{ row.catalogItemNo }}
                        </div>
                        <div class="commerce-orders-rows__quantity">
                            {{ row | orderQuantity }}
                        </div>
                        <div
                            class="commerce-orders-rows__info"
                            *ngIf="historyMode || creditMode || priceAdjustmentMode"
                        >
                            <span *ngIf="row.originalProductNo">
                                ${Translations.commerce.productHead.replacedItem}<br />{{
                                    row.originalProductCatalogItemNo
                                        ? row.originalProductCatalogItemNo
                                        : row.originalProductNo
                                }}
                                {{ row.originalProductName ? ' - ' + row.originalProductName : '' }}
                            </span>
                            <span *ngIf="row.originalProductNo" class="commerce-orders-rows__product-unit-price">
                                ${Translations.commerce.productHead.originalPrice}{{
                                    row.originalUnitPrice | currency : currencyCode : 'symbol-narrow'
                                }}
                            </span>
                            <span *ngIf="row.quantity !== row.quantityDelivered">
                                ${Translations.commerce.productHead.reducedQty}{{ row.quantity }}
                            </span>
                        </div>
                        <div class="commerce-orders-rows__unit-price" *ngIf="!priceAdjustmentInvoiceMode">
                            <span
                                class="commerce-orders-rows__product-unit-price"
                                [class.commerce-orders-rows__product-unit-price_line-through]="row.discountUnitPrice"
                            >
                                {{ row.productUnitPrice | currency : currencyCode : 'symbol-narrow' }}
                            </span>

                            <span class="commerce-orders-rows__discount-unit-price" *ngIf="row.discountUnitPrice">
                                &nbsp;{{ row.discountUnitPrice | currency : currencyCode : 'symbol-narrow' }}
                            </span>
                        </div>

                        <div class="commerce-orders-rows__row-price">
                            {{ row.totalRowPrice | currency : currencyCode : 'symbol-narrow' }}
                        </div>

                        <iv-commerce-addtobasket
                            *ngIf="historyMode"
                            class="commerce-orders-rows__addtobasket"
                            [product]="{ id: row.productNo, isQuantitySoldOut: false }"
                        >
                        </iv-commerce-addtobasket>

                        <ng-container
                            [formGroupName]="
                                row.bottleRelatesToProduct ? row.lineType + row.bottleRelatesToProduct : row.productNo
                            "
                            *ngIf="(creditMode || priceAdjustmentMode) && causes && causes.length > 0"
                        >
                            <div class="commerce-orders-rows__credit  clear-input-arrow" *ngIf="creditMode">
                                <ng-container>
                                    <input
                                        class="commerce-orders-rows__credit-input"
                                        type="number"
                                        pattern="[0-9]*"
                                        formControlName="qty"
                                        (keydown)="onKeyDown($event, row.productNo)"
                                        (blur)="onKeyDown($event, row.productNo)"
                                    />
                                    <div class="commerce-orders-rows__credited">
                                        ({{
                                            '${Translations.commerce.invoice.creditedHint}'
                                                | translation : row.quantityCredited
                                        }})
                                    </div>
                                </ng-container>
                            </div>

                            <ng-container *ngIf="priceAdjustmentMode">
                                <div class="commerce-orders-rows__price-adjustment clear-input-arrow">
                                    <input
                                        class="commerce-orders-rows__price-adjustment-input"
                                        type="number"
                                        formControlName="newUnitPrice"
                                        dontAllowNegativeValues
                                    />
                                </div>

                                <div class="commerce-orders-rows__price-adjustment">
                                    {{
                                        formGroup?.value
                                            | newTotalPrice : row
                                            | currency : currencyCode : 'symbol-narrow'
                                    }}
                                </div>
                            </ng-container>

                            <div class="commerce-orders-rows__causes">
                                <mat-select
                                    formControlName="reason"
                                    placeholder="${Translations.administration.citizenCreditNotes.labelChooseReason}"
                                >
                                    <mat-option *ngFor="let cause of causes" [value]="cause.value">
                                        {{ cause.label }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
            <ng-template #noRows>
                <div class="commerce-orders-rows__unavailable">${Translations.commerce.orders.noRows}</div>
            </ng-template>
        </div>
    `
})
export class CommerceOrdersRowsComponent implements OnInit, OnDestroy {
    @Input() rows: IOrderRowViewModel[] | ICreditNoteRowViewModel[] | IOrderHistoryRowViewModel[];
    @Input() formGroup = new UntypedFormGroup({});
    @Input() creditMode: boolean;
    @Input() historyMode: boolean;
    @Input() priceAdjustmentMode: boolean;
    @Input() priceAdjustmentInvoiceMode: boolean = false;
    @Input() causes: FormBuilderOption[] = [];
    @Output() init = new EventEmitter();

    showAddToBasket = false;
    matcher = new ErrorStateMatcher();

    private unsubscribe = new Subject<void>();

    constructor(
        @Inject(CURRENCY_CODE) public currencyCode: string,
        private productService: ProductService,
        private dialogService: DialogService
    ) { }

    ngOnInit() {
        if (!this.rows.length) {
            this.init.emit();
        }
    }

    onKeyDown(event: KeyboardEvent, productNo: string) {
        // TODO: since the keycode is deprecated, we should use the key property instead, this need to be refactored
        // eslint-disable-next-line deprecation/deprecation
        const keyCode = event.keyCode;
        const element = event.target as HTMLInputElement;

        if (Number.isNaN(parseInt(element.value))) {
            element.value = "0";
            return;
        }

        if (isPlus(keyCode)) {
            event.preventDefault();
            element.value = (parseInt(element.value) + 1).toString();
        } else if (isMinus(keyCode)) {
            event.preventDefault();
            element.value = (parseInt(element.value) - 1).toString();
        } else if (
            (isEnter(keyCode) &&
                isNotNumberKeys(keyCode) &&
                isNotEnterBackspaceDelete(keyCode) &&
                isNotArrowKeys(keyCode) &&
                isNotTab(keyCode)) ||
            isNotShiftTab(keyCode, event.shiftKey) ||
            event.altKey
        ) {
            event.preventDefault();
        }

        this.formGroup.get(productNo)?.get('qty')?.patchValue(Math.max(0, parseInt(element.value)).toString());

    }

    showProductDetail(productNo: string): void {
        this.productService
            .getProductDetails(productNo)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(data => {
                this.dialogService.openDialogWithComponent(CommerceProductDetailsComponent, { data });
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
