import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize, takeUntil } from 'rxjs/operators';

import { Subject } from 'rxjs';
import { CitizenReceivedMethod, ITemporaryCitizenInactivationViewModel } from '../../api/services';
import { check, cross } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import {
    FormBuilderDatepicker,
    FormBuilderRadio,
    FormBuilderTextInput,
    FormBuilderTypes
} from '../form-builder/form-builder-element.model';
import { FormBuilderService } from '../form-builder/form-builder.service';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { CitizenService } from '../services/citizen.service';
import { DialogService } from '../shared/dialog.service';
import { Helpers } from '../util/helpers';

@Component({
    selector: 'iv-citizen-inactivate-dialog',
    template: `
        <article class="citizen-inactivate dialog">
            <h3 mat-dialog-title>${Translations.administration.citizen.inactivationPrompt.header}</h3>
            <form [formGroup]="form" (submit)="onSubmit()" *ngIf="form">
                <mat-dialog-content>
                    <mat-vertical-stepper formArrayName="formArray" #stepper="matVerticalStepper">
                        <ng-template matStepperIcon="edit"> ${check} </ng-template>

                        <ng-template matStepperIcon="done"> ${check} </ng-template>

                        <ng-template matStepperIcon="error"> ${cross} </ng-template>

                        <mat-step
                            label="${Translations.administration.citizen.inactivationPrompt.form.step1}"
                            formGroupName="0"
                            [stepControl]="formArray.get([0])"
                        >
                            <iv-form-builder-element
                                [form]="formArray.get([0])"
                                [input]="input"
                                *ngFor="let input of inputsFirst"
                            ></iv-form-builder-element>

                            <div *ngIf="formArray">
                                <button
                                    type="button"
                                    mat-raised-button
                                    color="primary"
                                    matStepperNext
                                    [disabled]="formArray.get([0])!.invalid"
                                >
                                    ${Translations.administration.citizen.inactivationPrompt.form.nextStep}
                                </button>
                            </div>
                        </mat-step>

                        <mat-step
                            label="${Translations.administration.citizen.inactivationPrompt.form.step2}"
                            formGroupName="1"
                            [stepControl]="formArray.get([1])"
                        >
                            <iv-form-builder-element
                                [form]="formArray.get([1])"
                                [input]="input"
                                *ngFor="let input of inputsSecond"
                            ></iv-form-builder-element>
                        </mat-step>
                    </mat-vertical-stepper>
                </mat-dialog-content>
                <mat-dialog-actions class="dialog__actions alt-theme" *ngIf="form">
                    <iv-progress-button color="primary" [loadingState]="isLoading" [disabled]="form.invalid"
                        >${Translations.administration.citizen.inactivationPrompt.form.submit}</iv-progress-button
                    >
                    <button type="button" mat-button mat-dialog-close>${Translations.form.actions.cancel}</button>
                </mat-dialog-actions>
            </form>
        </article>
    `
})
export class CitizenInactivateDialogComponent implements OnInit, OnDestroy {
    isLoading = false;
    inputsFirst: FormBuilderTypes[];
    inputsSecond: FormBuilderTypes[];
    form: UntypedFormGroup;
    formArray: UntypedFormArray;

    private unsubscribeS$: Subject<void> = new Subject();

    constructor(
        @Inject(MAT_DIALOG_DATA) private customerNo: string,
        private dialogRef: MatDialogRef<CitizenInactivateDialogComponent>,
        private formBuilder: FormBuilderService,
        private dialogService: DialogService,
        private citizenService: CitizenService
    ) {}

    ngOnInit() {
        this._buildForm();
    }

    ngOnDestroy(): void {
        this.unsubscribeS$.next();
        this.unsubscribeS$.complete();
    }

    onSubmit() {
        if (this.form.valid) {
            this.isLoading = true;

            const values = this.form.value;
            const { fromDate, toDate }: { fromDate: Date; toDate: Date } = values.formArray[0];
            const {
                receivedDate,
                receivedMethod,
                receivedFrom
            }: { receivedDate: Date; receivedMethod: CitizenReceivedMethod; receivedFrom: string } =
                values.formArray[1];

            Helpers.localToUtcDate(fromDate);
            Helpers.localToUtcDate(receivedDate);

            if (toDate) {
                Helpers.localToUtcDate(toDate);
            }

            const model: ITemporaryCitizenInactivationViewModel = {
                customerNo: this.customerNo,
                fromDate,
                toDate,
                receivedDate,
                receivedMethod,
                receivedFrom
            };

            this.citizenService
                .inactivateCitizen(model)
                .pipe(
                    finalize(() => (this.isLoading = false)),
                    takeUntil(this.unsubscribeS$)
                )
                .subscribe(
                    () => {
                        this.dialogService.showSnackMessage({
                            message: Translations.administration.citizen.inactivationPrompt.form.messageOk
                        });
                        this.dialogRef.close(true);
                    },
                    (err: IntervareHttpErrorResponse) =>
                        this.dialogService.showSnackMessage({
                            message:
                                Translations.administration.citizen.inactivationPrompt.form.messageError +
                                ' (' +
                                err.validationErrors.join(', ') +
                                ')'
                        })
                );
        }
    }

    private _buildForm() {
        this.inputsFirst = [
            new FormBuilderDatepicker({
                label: Translations.administration.citizen.inactivationPrompt.form.dateFrom,
                name: 'fromDate',
                required: true
            }),
            new FormBuilderDatepicker({
                label: Translations.administration.citizen.inactivationPrompt.form.dateTo,
                name: 'toDate',
                filter: (d): boolean => {
                    if (!d) {
                        return false;
                    }
                    const formGroup = this.formArray.controls[0] as UntypedFormGroup;
                    const dayBefore: Date = formGroup ? formGroup.controls['fromDate'].value : new Date();
                    const nextDay = new Date(dayBefore.getFullYear(), dayBefore.getMonth(), dayBefore.getDate() + 1);

                    // Prevent the day selected to the starting day shall be selected
                    return Helpers.filterDates(d, nextDay);
                }
            })
        ];

        const formFirst = this.formBuilder.toFormGroup(this.inputsFirst);

        this.inputsSecond = [
            new FormBuilderDatepicker({
                label: Translations.administration.citizen.inactivationPrompt.form.receivedDate,
                name: 'receivedDate',
                required: true
            }),
            new FormBuilderRadio({
                label: Translations.administration.citizen.inactivationPrompt.form.receivedMethod,
                name: 'receivedMethod',
                required: true,
                options: [
                    {
                        label: Translations.administration.citizen.inactivationPrompt.form.receivedVocally,
                        value: CitizenReceivedMethod.Vocally
                    },
                    {
                        label: Translations.administration.citizen.inactivationPrompt.form.receivedWritten,
                        value: CitizenReceivedMethod.Written
                    }
                ]
            }),
            new FormBuilderTextInput({
                label: Translations.administration.citizen.inactivationPrompt.form.receivedFrom,
                name: 'receivedFrom',
                required: true
            })
        ];

        const formSecond = this.formBuilder.toFormGroup(this.inputsSecond);

        this.formArray = new UntypedFormArray([formFirst, formSecond]);

        this.form = new UntypedFormGroup({
            formArray: this.formArray
        });
    }
}
