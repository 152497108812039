import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { filter, first, map, switchMapTo, takeUntil } from 'rxjs/operators';

import { ICloseCallDayViewModel } from '../../api/services';
import { Translations } from '../../translations/translations';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { CallListAdminService } from '../services/call-list-admin.service';
import { DialogService } from '../shared/dialog.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'iv-end-day',
    template: `
    <form (submit)="onSubmit()">
        <h1 mat-dialog-title>${Translations.callListAdmin.endDay.header}</h1>
        <mat-dialog-content class="low-density-dialog-content">
            <mat-form-field>
                <input matInput [min]="minDate" [matDatepicker]="picker" [(ngModel)]="closeCallDay.releaseCopyTo" name="releaseCopyTo" placeholder="${Translations.callListAdmin.endDay.doCallsDate}" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <br>
            <mat-form-field>
                <mat-select [(ngModel)]="closeCallDay.releaseStartingHour" name="releaseStartingHour" placeholder="${Translations.callListAdmin.endDay.fromTime}">
                    <mat-option *ngFor="let time of times" [value]="time.value">
                        {{ time.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="form__element" *ngIf="isYesterdayOpen && yesterdayDate">
                <p>${Translations.callListAdmin.endDay.yesterday.text} ({{ yesterdayDate | date }})</p>
                <mat-checkbox name="userWantsToCloseYesterday" color="primary" [(ngModel)]="userWantsToCloseYesterday">${Translations.callListAdmin.endDay.yesterday.confirm}</mat-checkbox>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions class="dialog__actions alt-theme">
            <button mat-raised-button color="primary">${Translations.global.btnAcknowledge}</button>
            <button mat-raised-button type="button" mat-dialog-close>${Translations.global.btnCancel}</button>
        </mat-dialog-actions>
    </form>
    `
})
export class EndDayComponent implements OnInit, OnDestroy {

    minDate = new Date();
    times = Translations.callListAdmin.endDay.timeSlots;
    closeCallDay: ICloseCallDayViewModel;
    isYesterdayOpen = false;
    yesterdayDate: Date | undefined;
    userWantsToCloseYesterday = false;

    private unsubscribeS$: Subject<void> = new Subject();

    constructor(
        private dialogRef: MatDialogRef<EndDayComponent>,
        private dialogService: DialogService,
        private callListAdminService: CallListAdminService,
        private datePipe: DatePipe
    ) {
        const date = new Date();
        date.setDate(date.getDate() + 1);

        this.closeCallDay = {
            releaseCopyTo: date,
            releaseStartingHour: this.times[0].value,
            closeDay: new Date()
        };
    }

    ngOnInit() {
        this._isYesterdayClosed();
    }

    ngOnDestroy(): void {
        this.unsubscribeS$.next();
        this.unsubscribeS$.complete();
    }

    onSubmit() {
        if (this.userWantsToCloseYesterday && this.yesterdayDate) {
            this.closeCallDay.closeDay = this.yesterdayDate;
        }

        this.dialogService.confirm(Translations.replaceTokens(Translations.callListAdmin.endDay.confirmText, this.datePipe.transform(this.closeCallDay.releaseCopyTo, 'longDate'), this.closeCallDay.releaseStartingHour)).afterClosed().pipe(
            filter(res => !!res),
            switchMapTo(this.callListAdminService.endDay(this.closeCallDay)),
            takeUntil(this.unsubscribeS$)
        ).subscribe(
            res => {
                this.callListAdminService.getPlanningdays();
                this.dialogRef.close();
                this.dialogService.showMessage(Translations.replaceTokens(Translations.callListAdmin.endDay.messageOk, res.citizensTransferedToFourthTry));
            },
            (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
        );
    }

    private _isYesterdayClosed(): void {
        this.callListAdminService.actualCallDays$.pipe(
            first(),
            map(days => {
                const today = days.findIndex(x => x.isToday);
                if (today >= 0) {
                    this.closeCallDay.closeDay = days[today].callDate;

                    const yesterday = today + 1;
                    if (days[yesterday]) {
                        this.yesterdayDate = days[yesterday].callDate;
                        return days[yesterday].callDayClosed;
                    }
                }
                return true;
            }),
            takeUntil(this.unsubscribeS$)
        ).subscribe(isYesterdayClosed => {
            this.isYesterdayOpen = !isYesterdayClosed;
        });
    }
}
