import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { combineLatest, EMPTY, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, switchMapTo, takeUntil, tap } from 'rxjs/operators';

import {
    BasketRowViewModel,
    DeliveryFrequency,
    FeeTypeEnum,
    IAllowedDeliveryDate,
    IBasketViewModel,
    IProductListViewModel,
    ISubmitOrderModel,
    IUserViewModel,
    UserViewModel,
} from '../../api/services';
import { Translations } from '../../translations/translations';
import { CURRENCY_CODE } from '../app.locale_data';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { BasketService, BasketStates } from '../services/basket.service';
import { CitizenService } from '../services/citizen.service';
import { OrderService } from '../services/order.service';
import { ProductService } from '../services/product.service';
import { IDialogContentSettings } from '../shared/dialog-interfaces';
import { DialogService } from '../shared/dialog.service';
import { BASKETIDENTITIES, IDENTITIES, IUserRoles } from '../user/user-roles';
import { UserService } from '../user/user.service';
import { UtilService } from '../util/util.service';
import { ROLES } from '../user/user-roles';
import { getRowsReservationStatus } from './commerce-utils';
import { IRowsReservationStatus } from './commerce.models';

@Component({
    selector: 'iv-commerce-basket',
    template: `
        <div class="commerce-basket">
            <form role="table" (ngSubmit)="onSubmit()" [formGroup]="form" *ngIf="basket && basket !== 'empty' && basket !== 'impersonate' && !isSubmitting; else unavailable">
                <div class="basket-item-layout commerce-basket__head">
                    <div class="basket-item-layout__image commerce-basket__image">
                        ${Translations.commerce.productHead.products}
                    </div>

                    <div class="basket-item-layout__content" role="rowgroup">
                        <div class="basket-item-layout__row" role="row">
                            <div class="basket-item-layout__id" role="columnheader">
                                ${Translations.commerce.productHead.orderNumber}
                            </div>

                            <div class="basket-item-layout__prices commerce-basket__prices" role="columnheader">
                                ${Translations.commerce.productHead.price}
                            </div>

                            <div class="basket-item-layout__total-row-price commerce-basket__total-row-price" role="columnheader">
                                ${Translations.commerce.productHead.total}
                            </div>

                            <div class="basket-item-layout__addtobasket commerce-basket__addtobasket" role="columnheader">
                                ${Translations.commerce.productHead.quantity}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="commerce-basket__items" role="rowgroup">
                    <iv-commerce-basket-item [item]="item" *ngFor="let item of basket.rows; trackBy: trackByFn"></iv-commerce-basket-item>
                </div>

                <div class="commerce-basket__info">
                    <div class="commerce-basket__clear-basket">
                        <button type="button" mat-button color="accent" (click)="clearBasket()">
                            ${Translations.commerce.basket.clearBasket}
                        </button>
                    </div>

                    <iv-commerce-summary class="commerce-basket__summary" [data]="basket"></iv-commerce-summary>
                </div>

                <div class="commerce-basket__remember" *ngIf="topNotInBasket && topNotInBasket.numFound">
                    <h2 class="commerce-basket__sub-head">
                        ${Translations.commerce.basket.remember}
                    </h2>

                    <iv-commerce-basket-item [item]="product" *ngFor="let product of topNotInBasket.products"></iv-commerce-basket-item>
                </div>

                <ng-container *ngIf="isAdmin && showWeekOffers">
                    <div class="commerce-basket__week-offer" [class.commerce-basket__week-offer_is-admin]="isAdmin" *ngIf="weeksOffers && weeksOffers.numFound">
                        <h2 class="commerce-basket__sub-head">
                            ${Translations.commerce.basket.weekOffer}
                        </h2>

                        <iv-commerce-basket-item [item]="product" *ngFor="let product of weeksOffers.products"></iv-commerce-basket-item>
                    </div>
                </ng-container>

                <ng-container *ngIf="isAdmin && form.controls.deliveryDate">
                    <em *ngIf="changeFromDeliveryDate" style="color: red">${Translations.layout.navigation.impersonated.deliveryChangedFrom} {{changeFromDeliveryDate | date : 'fullDate'}}</em>
                    <em *ngIf="changeToDeliveryDate" style="color: red">${Translations.layout.navigation.impersonated.deliveryChangedTo} {{changeToDeliveryDate | date : 'fullDate'}}</em>
                    <em *ngIf="citizenIsInactiveForPeriod" style="color: red">
                        <br>${Translations.commerce.basket.validation.citizenInactive.forPeriod} {{citizenInactiveFrom | date: 'fullDate'}} - {{citizenInactiveUntil | date: 'fullDate'}}
                    </em>
                    <em *ngIf="citizenIsInactiveForFuture" style="color: red">
                        <br>${Translations.commerce.basket.validation.citizenInactive.forFuture} {{citizenInactiveFrom | date: 'fullDate'}}
                    </em>

                    <iv-commerce-delivery-date
                        *ngIf="isAdminOrCustomerService"
                        controlName="deliveryDate"
                        [control]="form.controls.deliveryDate"
                        [deliveryDates]="deliveryDates"
                    ></iv-commerce-delivery-date>

                    <div class="commerce-basket__radio-group">
                        <mat-radio-group formControlName="feeType">
                            <mat-radio-button class="commerce-basket__radio" [value]="${FeeTypeEnum.NormalFee}" color="primary">${Translations.commerce.basket.normalFee}</mat-radio-button>
                            <mat-radio-button class="commerce-basket__radio" [value]="${FeeTypeEnum.UrgentDeliveryFee}" color="primary">${Translations.commerce.basket.urgentDeliveryFee}</mat-radio-button>
                            <mat-radio-button class="commerce-basket__radio" [value]="${FeeTypeEnum.NoFee}" color="primary">${Translations.commerce.basket.noFee}</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div class="commerce-basket__radio-group" *ngIf="doesCitizenBuysEveryOtherWeek">
                        <mat-checkbox required (change)="setWeeklyNewspaper($event.checked)">
                            ${Translations.commerce.basket.addWeeklyNewspaper}
                        </mat-checkbox>
                    </div>
                </ng-container>

                <div class="commerce-basket__radio-group" *ngIf="isOrderAmountOverLimitation">
                    <mat-checkbox required (change)="confirmOrderOverLimitation($event.checked)">
                        {{ '${Translations.commerce.basket.confirmOrderOverLimitation}' | translation : ${Translations.variables.orderAmountLimitation} }}
                    </mat-checkbox>
                </div>

                <div class="commerce-basket__container">
                    <div class="commerce-basket__max-buy" *ngIf="basket.maxBuyLimit">
                        <div class="commerce-basket__max-buy-alert alert alert-info" *ngIf="basket.remainingBeforeBuyLimit >= 0; else buyLimitReached">
                            {{ '${Translations.commerce.basket.maxbuy}' | translation : (basket.maxBuyLimit | currency : currencyCode : 'symbol-narrow' : '1.0-2') }}
                        </div>

                        <ng-template #buyLimitReached>
                            <div class="commerce-basket__max-buy-alert alert alert-danger">
                                {{ '${Translations.commerce.basket.buyLimitReached}' | translation : (-basket.remainingBeforeBuyLimit | currency : currencyCode : 'symbol-narrow') }}
                            </div>
                        </ng-template>
                    </div>

                    <div class="commerce-basket__delivery" *ngIf="!isAdmin">
                        <div class="commerce-basket__delivery-wrap" *ngIf="!changeFromDeliveryDate && !changeToDeliveryDate">
                            <div class="commerce-basket__delivery-text">
                                ${Translations.commerce.basket.deliveryDate}:
                            </div>
                            <div class="commerce-basket__delivery-date">
                                {{ basket.deliveryDate | date : 'shortDate' }}
                            </div>
                        </div>

                        <div class="commerce-basket__delivery-wrap" *ngIf="changeFromDeliveryDate || changeToDeliveryDate">
                            <div class="commerce-basket__delivery-text" style="color: red">
                                ${Translations.layout.navigation.impersonated.deliveryChanged} {{ basket.deliveryDate | date : 'fullDate' }}
                            </div>
                        </div>
                    </div>

                    <div class="commerce-basket__checkout">
                        <ng-container *ngIf="showRepeatOrder && form.controls.repeatedOrder">
                            <mat-error class="commerce-basket__error" *ngIf="form.controls.repeatedOrder.invalid && form.controls.repeatedOrder.dirty">${Translations.commerce.basket.validation.repeatedOrder}</mat-error>
                            <mat-checkbox class="commerce-basket__checkbox" formControlName="repeatedOrder" required color="primary">${Translations.commerce.basket.repeatedOrder}</mat-checkbox>
                        </ng-container>
                        <ng-container *ngIf="form.controls.noteComment && !isPda">
                            <mat-form-field appearance="fill" class="commerce-basket__checkout-comment">
                                <mat-label>${Translations.commerce.basket.noteComment}</mat-label>
                                <input formControlName="noteComment" matInput>
                            </mat-form-field>
                        </ng-container>
                        
                        <button
                                class="commerce-basket__checkout-btn"
                                [disabled]="basket.remainingBeforeBuyLimit < 0 || disableSubmitOrderButton"
                                mat-raised-button
                                color="primary"
                        >
                            ${Translations.commerce.basket.checkout}
                        </button>
                    </div>

                </div>
            </form>

            <ng-template #unavailable>
                <div class="commerce-basket__status alt-theme" *ngIf="!basket || isSubmitting">
                    <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="3" [diameter]="60"></mat-progress-spinner>
                </div>

                <div class="commerce-basket__status" *ngIf="basket === 'empty' && !isSubmitting">
                    ${Translations.commerce.basket.emptyBasket}
                </div>

                <div class="commerce-basket__status" *ngIf="basket === 'impersonate' && !isSubmitting">
                    ${Translations.commerce.basket.impersonate}
                </div>
            </ng-template>
        </div>
    `
})
export class CommerceBasketComponent implements OnInit, OnDestroy {
    basket: BasketStates;
    isAdmin = false;
    isAdminOrCustomerService = false;
    isPda = false;
    isCitizen = false;
    showWeekOffers = false;
    showRepeatOrder = false;
    isSubmitting = false;
    isBlocked = false;
    allowMergeToHappen = false;
    deliveryDates: IAllowedDeliveryDate[];
    noteComment = '';
    preferredDeliveryDates: Date[];
    topNotInBasket: IProductListViewModel;
    weeksOffers: IProductListViewModel;
    loggedUser: string | undefined;
    isCustomerService = false;
    citizenBuysEveryOtherWeek = false;
    disableSubmitOrderButton = false;
    doesCitizenBuysEveryOtherWeek = false;
    isNewsletterChecked = false;
    isOrderAmountOverLimitationChecked = false;
    isOrderAmountOverLimitation = false;

    form = this.formBuilder.group({});

    private updateProducts$ = new Subject();
    private submitOrder$ = new Subject<UntypedFormGroup>();
    private unsubscribe: Subject<void> = new Subject();
    private _scrollContainer: HTMLElement | Window = window;

    private citizenFirstDeliveryDate: Date;
    public changeFromDeliveryDate: Date | undefined;
    public changeToDeliveryDate: Date | undefined;
    public citizenInactiveFrom: Date | undefined;
    public citizenInactiveUntil: Date | undefined;
    public citizenIsInactiveForPeriod: boolean;
    public citizenIsInactiveForFuture: boolean;

    constructor(
        @Inject(CURRENCY_CODE) public currencyCode: string,
        private cd: ChangeDetectorRef,
        private basketService: BasketService,
        private productService: ProductService,
        private userService: UserService,
        private formBuilder: UntypedFormBuilder,
        private orderService: OrderService,
        private router: Router,
        private dialogService: DialogService,
        private utilService: UtilService,
        private citizenService: CitizenService
    ) { }

    ngOnInit() {
        this.basketService.basket$.pipe(
            tap(basket => this.checkBasketForSubmitStatus(basket)),
            takeUntil(this.unsubscribe)
        ).subscribe(basket => {
            this.basket = basket;
            this.checkIfOrderAmountOverLimitation();
            this.checkIfNewspaper();
        });

        this.updateProducts$.pipe(
            switchMapTo(this.basketService.getTopNotInBasket()),
            takeUntil(this.unsubscribe)
        ).subscribe(products => this.topNotInBasket = products);

        this.updateProducts$.pipe(
            switchMapTo(this.productService.getThisWeeksOffers()),
            takeUntil(this.unsubscribe)
        ).subscribe(products => this.weeksOffers = products);

        this.userService.user$.pipe(
            filter(user => {
                if (!user) {
                    this.form = this.formBuilder.group({});
                    return false;
                }
                return user instanceof UserViewModel;
            }),
            map(user => user as IUserViewModel),
            tap(() => this.updateProducts$.next()),
            filter(user => {
                this.loggedUser = user.name;
                const userRoles = user.roles as IUserRoles[];
                const isTastSelvCitizen = !!(userRoles.length && userRoles.some(x => x === ROLES.Citizen));
                const isPdaUser = !!(userRoles.length && userRoles.some(x => IDENTITIES.pda.includes(x)));

                // check if the user have Customer Service role;
                this.isCustomerService = userRoles.includes(ROLES.CustomerService);

                this.isCitizen = !!(userRoles.length && userRoles.some(x => x === ROLES.Citizen));
                this.isPda = !!(userRoles.length && userRoles.some(x => IDENTITIES.pda.includes(x)));

                this.isAdmin = !!(userRoles.length && userRoles.some(x => BASKETIDENTITIES.admin.includes(x)));
                this.isAdminOrCustomerService = !!(userRoles.length && userRoles.some(x => IDENTITIES.adminAndCustomerService.includes(x)));
                this.showWeekOffers = !(isPdaUser || isTastSelvCitizen);
                this.showRepeatOrder = user.impersonatedCitizenQuickInfo ? user.impersonatedCitizenQuickInfo.shouldRepeatOrder && this.isAdmin : false;
                this._scrollContainer = (document.querySelector('#content > .util-hidden-scroll') as HTMLElement) || window;

                return this.isAdmin && !!user.impersonatedCitizenQuickInfo;
            }),
            switchMap(user => combineLatest([this.citizenService.getCitizen(user.impersonatedCitizenQuickInfo!.customerNumber!), this.basketService.basket$]).pipe(takeUntil(this.unsubscribe))),
            tap(([citizen, basket]) => {
                // check if the citizen buys every second week;
                this.basket = basket;
                this.citizenBuysEveryOtherWeek = citizen.deliveryFrequencyId === DeliveryFrequency.EveryOtherWeek;

                this.checkIfNewspaper();
                this.checkIfOrderAmountOverLimitation();
                this.checkBasketForSubmitStatus(basket);

                this.isBlocked = citizen.isBlocked;
                this.citizenFirstDeliveryDate = citizen.deliveryStart;
                if (new Date().setHours(0, 0, 0, 1) <= citizen.deliveryStart.setHours(0, 0, 0, 0)) {
                    this.deliveryDialogError();
                }
                if (citizen.futureInactivatationValidFrom) {
                    this.citizenInactiveFrom = citizen.futureInactivatationValidFrom;
                }
                if (citizen.futureInactivatationValidUntil) {
                    this.citizenInactiveUntil = citizen.futureInactivatationValidUntil;
                }
            }),
            switchMapTo(this.basketService.getAllowedDeliveryDates()),
            takeUntil(this.unsubscribe)
        ).subscribe(deliveryDates => {
            this.deliveryDates = deliveryDates;
            this.preferredDeliveryDates = this.deliveryDates.filter(deliveryDate => deliveryDate.isPreferredDeliveryDay).map(deliveryDate => deliveryDate.date);
            this.buildForm();
            if (this.deliveryDates) {
                this.changeFromDeliveryDate = this.deliveryDates.find(delivery => delivery.isSelected)!.changedFrom || undefined;
                this.changeToDeliveryDate = this.deliveryDates.find(delivery => delivery.isSelected)!.changedTo || undefined;
                this.checkCitizenIsInactive(this.deliveryDates.find(dates => dates.isSelected)!.date);
            }

            this.isCitizen ? this.form.get('noteComment')?.disable() : this.form.get('noteComment')?.enable();

            this.cd.markForCheck();
        });

        const orderSubmit$ = this.submitOrder$.pipe(
            filter(form => form.valid),
            tap(() => this.isSubmitting = true),
            switchMapTo(this.orderService.getEditingAndMergeInfo()),
            switchMap(data => {
                if (data.differentEditingSessionIdActivated && data.differentEditingSessionIdActivated === true) {
                    this.allowMergeToHappen = true;
                }
                if (data.editingOrderId || data.mergeOrderId) {
                    const dialogSettings: IDialogContentSettings = {
                        data: {
                            header: data.editingOrderId ? Translations.commerce.basket.edit.header : Translations.commerce.basket.merge.header,
                            content: data.editingOrderId ? Translations.commerce.basket.edit.text : (data.differentEditingSessionIdActivated && data.differentEditingSessionIdActivated === true ? Translations.commerce.basket.merge.textAllowMerge : Translations.commerce.basket.merge.text),
                            buttons: {
                                button1: {
                                    color: 'primary',
                                    confirm: true,
                                    text: Translations.global.btnAcknowledge
                                },
                                button2: {
                                    color: 'accent',
                                    confirm: false,
                                    text: Translations.global.btnCancel
                                }
                            }
                        }
                    };

                    return this.dialogService.openDialog(dialogSettings).afterClosed();
                }

                return of(true);
            }),
            filter(confirmed => {
                if (!confirmed) {
                    this.isSubmitting = false;
                }
                return confirmed;
            }),
            switchMap(() => {
                let orderDate;

                if (this.utilService.isBrowser() && window.sessionStorage) {
                    const orderDateValue = sessionStorage.getItem('orderDate');

                    if (orderDateValue) {
                        orderDate = new Date(orderDateValue);
                    }
                }

                const feeType = this.form.controls['feeType'];
                const noteComment = this.form.controls['noteComment'];
                const submitOrderModel: ISubmitOrderModel = {
                    orderDate,
                    feeType: feeType ? feeType.value : FeeTypeEnum.NormalFee,
                    noteComment: noteComment ? noteComment.value : '',
                    allowMergeToHappen: this.allowMergeToHappen
                };

                return this.orderService.submitOrder(submitOrderModel).pipe(
                    catchError((err: IntervareHttpErrorResponse) => {
                        this.isSubmitting = false;
                        this.dialogService.showValidationResult(err.validationErrors);
                        return EMPTY;
                    })
                );
            })
        );

        combineLatest([orderSubmit$, this.userService.activeRoles]).pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(([order, userRoles]) => {
            if (userRoles.some(x => BASKETIDENTITIES.admin.includes(x))) {
                this.dialogService.showSnackMessage({ message: Translations.commerce.order.completed });

                const hasCallListAccess = userRoles.some(x => IDENTITIES.callList.includes(x));

                // check if the user have Customer Service role;
                const isCustomerService = userRoles.includes(ROLES.CustomerService);

                const route: string = !hasCallListAccess || isCustomerService ? 'customersearch' : 'calllist';

                // eslint-disable-next-line no-null/no-null
                this.router.navigate([{ outlets: { primary: null, admin: route } }]);
            }
            else if (userRoles.some(x => BASKETIDENTITIES.municipality.includes(x)) || userRoles.some(x => BASKETIDENTITIES.citizen.includes(x))) {
                this.router.navigate([Translations.shop.paths.receiptPage.path], { queryParams: { orderNo: order.orderId } });
            }
        }, (err: IntervareHttpErrorResponse) => {
            this.isSubmitting = false;
            this.dialogService.showValidationResult(err.validationErrors);
        });
    }

    checkIfCitizenBuysEveryOtherWeek(): boolean {
        return this.isCustomerService && this.citizenBuysEveryOtherWeek;
    }

    setWeeklyNewspaper(checked: boolean): void {
        this.isNewsletterChecked = checked;
        this.checkIfNewspaper();
        if (this.isOrderAmountOverLimitation && !this.isOrderAmountOverLimitationChecked) {
            this.checkIfOrderAmountOverLimitation();
        }
    }

    confirmOrderOverLimitation(checked: boolean): void {
        this.isOrderAmountOverLimitationChecked = checked;
        this.checkIfOrderAmountOverLimitation();
        if (!this.isNewsletterChecked) {
            this.checkIfNewspaper();
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    buildForm() {
        const group: { deliveryDate: Date, feeType: FeeTypeEnum, repeatedOrder?: boolean, noteComment?: string } = {
            deliveryDate: this.deliveryDates.filter(deliveryDate => deliveryDate.isSelected)[0].date,
            feeType: FeeTypeEnum.NormalFee,
            noteComment: this.noteComment
        };

        if (this.showRepeatOrder) {
            group.repeatedOrder = false;
        }

        this.form = this.formBuilder.group(group);

        this.form.controls['deliveryDate'].valueChanges.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe((value: Date) => {
            if (this.form.controls['deliveryDate'].valid) {
                this.basketService.setDeliveryDate({ deliveryDate: value })
                    .pipe(
                        tap(basket => this.basketService._nextBasket(basket)),
                        switchMap(() => this.basketService.getAllowedDeliveryDates()),
                        takeUntil(this.unsubscribe)
                    )
                    .subscribe(deliveryDates => {
                        this.deliveryDates = deliveryDates;
                        if (this.deliveryDates) {
                            this.changeFromDeliveryDate = this.deliveryDates.find(delivery => delivery.isSelected)!.changedFrom || undefined;
                            this.changeToDeliveryDate = this.deliveryDates.find(delivery => delivery.isSelected)!.changedTo || undefined;
                            this.checkCitizenIsInactive(this.deliveryDates.find(dates => dates.isSelected)!.date);
                        }
                    });
            }
        });
    }

    onSubmit() {
        const currDate = new Date();
        if (this.isAdmin && currDate.setHours(0, 0, 0, 1) <= this.citizenFirstDeliveryDate.setHours(0, 0, 0, 0) && this.form.value.deliveryDate.setHours(0, 0, 0, 1) <= this.citizenFirstDeliveryDate.setHours(0, 0, 0, 0)) {
            this.deliveryDialogError();
        } else {
            this.submitBasket();
        }
    }

    submitBasket() {
        if (this.showRepeatOrder) {
            this.form.controls.repeatedOrder.markAsDirty();
        }

        if (this.isBlocked) {
            const dialogSettings: IDialogContentSettings = {
                data: {
                    header: Translations.commerce.basket.citizenBlocked.header,
                    content: Translations.commerce.basket.citizenBlocked.text,
                    buttons: {
                        button1: {
                            color: 'primary',
                            confirm: true,
                            text: Translations.global.btnAcknowledge
                        }
                    }
                }
            };

            this.dialogService.openDialog(dialogSettings);
        }
        else {
            this.submitOrder$.next(this.form);
        }
    }

    clearBasket() {
        const dialogSettings: IDialogContentSettings = {
            data: {
                header: Translations.commerce.basket.clearBasketPrompt.header,
                content: Translations.commerce.basket.clearBasketPrompt.text,
                buttons: {
                    button1: {
                        color: 'primary',
                        confirm: true,
                        text: Translations.global.btnAcknowledge
                    },
                    button2: {
                        color: 'accent',
                        confirm: false,
                        text: Translations.global.btnCancel
                    }
                },
            }
        };

        this.dialogService.openDialog(dialogSettings).afterClosed().pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(response => {
            if (response) {
                this.basketService.clearBasket();
            }
        });
    }

    setDeliveryDate(event: MouseEvent, preferredDeliveryDate: Date) {
        event.preventDefault();
        if (preferredDeliveryDate !== this.form.controls['deliveryDate'].value) {
            this.form.controls['deliveryDate'].setValue(preferredDeliveryDate);
        }
    }

    deliveryDateFilter = (date: Date | null): boolean => {
        if (!date) {
            return false;
        }

        return this.deliveryDates.filter(deliveryDate => {
            deliveryDate.date.setHours(0, 0, 0, 0);
            date.setHours(0, 0, 0, 0);
            return deliveryDate.date.toUTCString() === date.toUTCString();
        }).length > 0;
    }

    trackByFn(_index: number, item: BasketRowViewModel) {
        return item.id;
    }

    private checkIfNewspaper(): void {
        this.doesCitizenBuysEveryOtherWeek = this.checkIfCitizenBuysEveryOtherWeek();

        // disable submit button if user is Customer Service & Citizen buys every second week
        if (this.doesCitizenBuysEveryOtherWeek) {
            this.disableSubmitOrderButton = !this.isNewsletterChecked;
        }
    }

    private checkIfOrderAmountOverLimitation(): void {
        this.isOrderAmountOverLimitation = (this.basket as IBasketViewModel)?.amount > Translations.variables.orderAmountLimitation;

        if (!this.isOrderAmountOverLimitation && (this.isNewsletterChecked || !this.doesCitizenBuysEveryOtherWeek)) {
            this.disableSubmitOrderButton = false;
        }

        // disable submit button if confirm order amount over limitation is unchecked
        if (this.isOrderAmountOverLimitation) {
            this.disableSubmitOrderButton = !this.isOrderAmountOverLimitationChecked;
        }
    }

    private checkBasketForSubmitStatus(basket: BasketStates): void {
        // disable submit button if there are reservations errors
        const { reservationHasErrors = false, showReservationErrors = false } = this.checkBasketForReservationErrors(basket);
        this.disableSubmitOrderButton = showReservationErrors ? reservationHasErrors || this.disableSubmitOrderButton : false;
    }

    private checkBasketForReservationErrors(basket: BasketStates): IRowsReservationStatus {
        return (typeof basket !== 'string' && basket && basket.rows) ? getRowsReservationStatus(basket.rows) : { reservationHasErrors: false, showReservationErrors: false };
    }

    private deliveryDialogError() {
        const dialogSetting: IDialogContentSettings = {
            data: {
                header: Translations.commerce.basket.deliveryDateDialog.title,
                content: Translations.replaceTokens(Translations.commerce.basket.deliveryDateDialog.text, this.citizenFirstDeliveryDate.toLocaleDateString()),
                buttons: {
                    button2: {
                        color: 'accent',
                        confirm: false,
                        text: Translations.global.btnCancel
                    }
                },
            }
        };
        this.dialogService.openDialog(dialogSetting);
    }

    private checkCitizenIsInactive(date?: Date) {
        if (date && this.citizenInactiveFrom && this.citizenInactiveUntil) {
            this.citizenIsInactiveForPeriod = !!(date && this.citizenInactiveFrom && this.citizenInactiveUntil);
        }
        if (date && this.citizenInactiveFrom && !this.citizenInactiveUntil) {
            this.citizenIsInactiveForFuture = (date && this.citizenInactiveFrom && !this.citizenInactiveUntil);
        }
    }
}
