import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DialogOutletComponent } from '../../shared/dialog-outlet.component';
import { AuthGuard } from '../../user/auth.guard';
import { IDENTITIES } from '../../user/user-roles';
import { CitizenDetailsComponent } from '../citizen-details.component';
import { CitizenResolver } from './citizen-resolver.service';
import { CitizenGuard } from './citizen.guard';

export const citizenRoutes: Routes = [
    {
        path: 'citizen/:id',
        component: DialogOutletComponent,
        outlet: 'dialog',
        canActivate: [AuthGuard],
        data: {
            allowedRoles: IDENTITIES.citizenDetails,
            dialogComponent: CitizenDetailsComponent,
            fullscreen: false,
            nomargin: true,
            width: '1028px',
            maxWidth: '1028px',
            height: '850px',
            maxHeight: '90vh'
        },
        resolve: {
            data: CitizenResolver
        }
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(citizenRoutes)
    ],
    exports: [
        RouterModule
    ],
    providers: [
        CitizenGuard
    ]
})
export class CitizenRoutingModule { }
