import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouteReuseStrategy } from '@angular/router';

export class DefaultRouteReuseStrategy implements RouteReuseStrategy {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shouldDetach(route: ActivatedRouteSnapshot): boolean { return false; }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    store(route: ActivatedRouteSnapshot): void { }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    shouldAttach(route: ActivatedRouteSnapshot): boolean { return false; }
    // eslint-disable-next-line
    retrieve(route: ActivatedRouteSnapshot): null { return null; }
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
    }
}

@Injectable()
export class CustomRouteReuseStrategy extends DefaultRouteReuseStrategy {
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        const reuse = (future.data && future.data?.reuse) ? future.data.reuse : true;
        return super.shouldReuseRoute(future, curr) && reuse;
    }
}
