import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, filter, switchMapTo, tap } from 'rxjs/operators';

import {
    IDetailedMessageViewModel,
    ISkipTakeResultObjectOfDetailedMessageViewModel,
    IUpdateMessageViewModel,
} from '../../api/services';
import { Translations } from '../../translations/translations';
import { MessageService } from '../services/message.service';
import { DialogService } from '../shared/dialog.service';

@Component({
    selector: 'iv-administration-messages',
    template: `
        <section class="administration-messages">
            <ng-container *ngIf="messages$ | async as messages; else fetching">
                <div class="administration-messages__item" *ngFor="let message of messages.results">
                    <div class="administration-messages__text" [innerHTML]="message.textMessage"></div>
                    <div class="administration-messages__info">
                        <dl>
                            <dt>${Translations.intervare.messageAdmin.isActive}</dt>
                            <dd><mat-checkbox [checked]="message.isActive" (change)="activateMessage(message)"></mat-checkbox></dd>
                            <ng-container *ngIf="message.startDate">
                                <dt>${Translations.intervare.messageAdmin.startDate}</dt>
                                <dd>{{ message.startDate | date }}</dd>
                            </ng-container>
                            <ng-container *ngIf="message.endDate">
                                <dt>${Translations.intervare.messageAdmin.endDate}</dt>
                                <dd>{{ message.endDate | date }}</dd>
                            </ng-container>
                        </dl>
                        <div class="administration-messages__actions">
                            <a class="administration-messages__btn" [routerLink]="['edit', message.messageId]" mat-raised-button color="accent">${Translations.intervare.messageAdmin.goToEditBtn}</a>
                            <iv-progress-button class="administration-messages__btn" buttonType="button" color="warn" [callback]="deleteMessage.bind(this, message.messageId)" width="full">${Translations.intervare.messageAdmin.deleteBtn}</iv-progress-button>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-template #fetching>
                <div class="center-content alt-theme">
                    <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="3" [diameter]="60"></mat-progress-spinner>
                </div>
            </ng-template>
        </section>
    `
})
export class AdministrationMessagesComponent implements OnInit, OnDestroy {

    messages$: Observable<ISkipTakeResultObjectOfDetailedMessageViewModel>;

    private unsubscribe = new Subject<void>();

    constructor(
        private messageService: MessageService,
        private dialogService: DialogService
    ) { }

    ngOnInit() {
        this._getMessages();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    activateMessage(message: IDetailedMessageViewModel) {
        const updateMessage = message as IUpdateMessageViewModel;
        updateMessage.isActive = !updateMessage.isActive;

        this.messageService.updateMessage(updateMessage)
            .subscribe(result => this.dialogService.showSnackMessage({
                message: result.isActive ? Translations.intervare.messageAdmin.activateMsg : Translations.intervare.messageAdmin.deactivateMsg
            }));
    }

    deleteMessage(messageId: number): Promise<boolean> {
        return this.dialogService.confirm(Translations.intervare.messageAdmin.confirmDeleteMsg).afterClosed().pipe(
            filter((res: boolean) => res),
            switchMapTo(this.messageService.deleteMessage(messageId)),
            tap(() => {
                this._getMessages();
                this.dialogService.showSnackMessage({ message: Translations.intervare.messageAdmin.deleteMsg });
            }),
            takeUntil(this.unsubscribe)
        ).toPromise();
    }

    private _getMessages() {
        this.messages$ = this.messageService.getMessages();
    }

}
