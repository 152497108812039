import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';

import { ICaller, ICallerCreateModel, ISimpleUserModel } from '../../api/services';
import { Translations } from '../../translations/translations';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { CallListAdminService } from '../services/call-list-admin.service';
import { DialogService } from '../shared/dialog.service';
import { filter, switchMapTo, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'iv-edit-caller',
    template: `
    <form (submit)="onSubmit()" #form="ngForm">
        <h1 mat-dialog-title>{{ editMode ? '${Translations.callListAdmin.editCaller.edit}' : '${Translations.callListAdmin.editCaller.add}' }} ${Translations.callListAdmin.editCaller.header}</h1>
        <mat-dialog-content class="low-density-dialog-content">
            <mat-form-field>
                <input matInput placeholder="${Translations.callListAdmin.editCaller.firstName}" [(ngModel)]="caller.firstName" name="firstName" required>
            </mat-form-field>
            <br/>
            <mat-form-field>
                <input matInput placeholder="${Translations.callListAdmin.editCaller.lastName}" [(ngModel)]="caller.lastName" name="lastName" required>
            </mat-form-field>
            <br/>
            <mat-form-field>
                <mat-select placeholder="${Translations.callListAdmin.editCaller.userId}" [(ngModel)]="caller.userId" name="userId">
                    <mat-option *ngFor="let user of users | async" [value]="user.userId">
                        {{ user.userName ? user.userName : user.userLoginNo }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <br/>
            <mat-form-field>
                <input matInput placeholder="${Translations.callListAdmin.editCaller.localNumber}" [(ngModel)]="caller.localExtensionNumber" name="localExtensionNumber" type="phone">
            </mat-form-field>
            <br/>
            <mat-form-field>
                <input matInput placeholder="${Translations.callListAdmin.editCaller.callerCapacity}" [(ngModel)]="caller.capacity" name="capacity" type="number" required>
            </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions class="alt-theme">
            <button mat-raised-button color="warn" type="button" (click)="onDelete()" *ngIf="editMode">${Translations.callListAdmin.editCaller.deleteBtn}</button>
            <button mat-raised-button type="button" mat-dialog-close>${Translations.callListAdmin.editCaller.cancelBtn}</button>
            <button mat-raised-button color="primary" [disabled]="!form.valid">${Translations.callListAdmin.editCaller.okBtn}</button>
        </mat-dialog-actions>
    </form>
    `
})
export class EditCallerComponent implements OnInit, OnDestroy {

    caller: ICaller | ICallerCreateModel;
    editMode = false;
    users: Observable<ISimpleUserModel[]>;

    private unsubscribeS$: Subject<void> = new Subject();

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: ICaller | ICallerCreateModel,
        private dialogRef: MatDialogRef<EditCallerComponent>,
        private dialogService: DialogService,
        private callListAdminService: CallListAdminService
    ) { }

    ngOnInit() {
        this.caller = this.data;
        this.users = this.callListAdminService.getUsers(this.caller.userId);
        if ((this.caller as ICaller).callerProfileId) {
            this.editMode = true;
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeS$.next();
        this.unsubscribeS$.complete();
    }

    onSubmit() {
        this.callListAdminService.saveCaller(this.caller)
        .pipe(takeUntil(this.unsubscribeS$))
        .subscribe(
            () => {
                this.callListAdminService.getCallers();
                this.dialogRef.close();
            },
            (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
        );
    }

    onDelete() {
        this.dialogService.confirm(Translations.callListAdmin.editCaller.confirmText).afterClosed().pipe(
            filter((res: boolean) => res),
            switchMapTo(this.callListAdminService.deleteCaller((this.caller as ICaller).callerProfileId)),
            takeUntil(this.unsubscribeS$)
        ).subscribe(
            () => {
                this.callListAdminService.getCallers();
                this.dialogRef.close();
            },
            (err: IntervareHttpErrorResponse) => {
                this.dialogService.showValidationResult(err.validationErrors);
            });
    }
}
