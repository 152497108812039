import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    CauseViewModel,
    ICause,
    ICauseCreateModel,
    ICauseViewModel,
    ISubCauseCreateModel,
    ISubCauseViewModel,
    ISubSubCauseCreateModel,
    SubCauseViewModel,
    SubSubCauseViewModel,
    ISubCause,
    ISubSubCause,
    ISubSubCauseViewModel,
} from '../../api/services';
import { BaseService } from '../services/base-service';

@Injectable()
export class NotesAdminService extends BaseService {
    causes = new Subject<ICauseViewModel[]>();

    constructor(private http: HttpClient) {
        super();
    }

    //#region Cause

    getCause(id: string): Observable<ICauseViewModel> {
        return this.http.get(this.apiBaseUrl('/Note/GetCause'), {
            params: { id }
        }).pipe(map(CauseViewModel.fromJS));
    }

    getCauses(): void {
        this.http.get<object[]>(this.apiBaseUrl('/Note/GetCauses')).pipe(
            map(x => x.map(CauseViewModel.fromJS))
        ).subscribe(causes => this.causes.next(causes));
    }

    createCause(model: ICauseCreateModel): Observable<boolean> {
        return this.http.post(this.apiBaseUrl('/Note/AdminCreateCause'), model).pipe(
            map(() => true)
        );
    }

    updateCause(model: ICause): Observable<boolean> {
        return this.http.put(this.apiBaseUrl('/Note/AdminUpdateCause'), model).pipe(
            map(() => true)
        );
    }

    deleteCause(causeId: number): Observable<boolean> {
        return this.http.delete(this.apiBaseUrl('/Note/AdminDeleteCause'), { params: { causeId: causeId.toString() } }).pipe(
            map(() => true)
        );
    }

    //#endregion

    //#region SubCause

    getSubCause(id: string): Observable<ISubCauseViewModel> {
        return this.http.get(this.apiBaseUrl('/Note/GetSubCause'), {
            params: { id }
        }).pipe(map(SubCauseViewModel.fromJS));
    }

    getSubCauses(id: string): Observable<SubSubCauseViewModel> {
        return this.http.get(this.apiBaseUrl('/Note/GetSubcauses'), {
            params: { id }
        }).pipe(map(SubSubCauseViewModel.fromJS));
    }

    createSubCause(model: ISubCauseCreateModel): Observable<boolean> {
        return this.http.post(this.apiBaseUrl('/Note/AdminCreateSubCause'), model).pipe(map(() => true));
    }

    updateSubCause(model: ISubCause): Observable<boolean> {
        return this.http.put(this.apiBaseUrl('/Note/AdminUpdateSubCause'), model).pipe(map(() => true));
    }

    deleteSubCause(subCauseId: number) {
        return this.http.delete(this.apiBaseUrl('/Note/AdminDeleteSubCause'), { params: { subcauseId: subCauseId.toString() } }).pipe(
            map(() => true)
        );
    }

    //#endregion

    //#region SubSubCause

    getSubSubCause(id: string): Observable<ISubSubCauseViewModel> {
        return this.http.get(this.apiBaseUrl('/Note/GetSubSubCause'), {
            params: { id }
        }).pipe(map(SubSubCauseViewModel.fromJS));
    }

    createSubSubCause(model: ISubSubCauseCreateModel) {
        return this.http.post(this.apiBaseUrl('/Note/AdminCreateSubSubCause'), model).pipe(map(() => true));
    }

    updateSubSubCause(model: ISubSubCause): Observable<boolean> {
        return this.http.put(this.apiBaseUrl('/Note/AdminUpdateSubSubCause'), model).pipe(map(() => true));
    }

    deleteSubSubCause(subSubCauseId: number) {
        return this.http.delete(this.apiBaseUrl('/Note/AdminDeleteSubSubCause'), { params: { subSubCauseId: subSubCauseId.toString() } }).pipe(
            map(() => true)
        );
    }

    //#endregion
}
