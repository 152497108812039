import { Component, OnInit } from '@angular/core';

import { Translations } from '../../translations/translations';
import { RouteService } from '../shared/route.service';
import { NotesAdminService } from './notes-admin.service';

@Component({
    selector: 'iv-notes-admin-causes',
    template: `
        <a class="category-menu__link" [routerLink]="['', { outlets: { dialog: ['${Translations.intervare.paths.notesAdmin.path}', 'cause', ''] } }]" routerLinkActive="category-menu__link_active">
            ${Translations.intervare.notesAdmin.navigation.createCause}
        </a>
        <ng-container *ngIf="(notesAdmin.causes | async) as causes; else fetching">
            <iv-notes-admin-cause *ngFor="let cause of causes" [cause]="cause"></iv-notes-admin-cause>
        </ng-container>
        <ng-template #fetching>
            <div class="alt-theme">
                <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="2" [diameter]="20"></mat-progress-spinner>
            </div>
        </ng-template>
    `
})
export class NotesAdminCausesComponent implements OnInit {

    constructor(
        public notesAdmin: NotesAdminService,
        private routeService: RouteService
    ) { }

    ngOnInit() {
        this.notesAdmin.getCauses();
    }
}
