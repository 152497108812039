import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IComponentLoaderOptions } from './component-loader';

@Component({
    selector: 'iv-util-component-loader',
    template: ''
})
export class UtilComponentLoaderComponent implements OnInit, OnDestroy {
    @Input() input: IComponentLoaderOptions<any>;
    @Output() output = new EventEmitter();

    private unsubscribe = new Subject<void>();

    constructor(private resolver: ComponentFactoryResolver, private viewContainer: ViewContainerRef) { }

    ngOnInit() {
        const factory = this.resolver.resolveComponentFactory(this.input.component);
        const component = this.viewContainer.createComponent(factory);
        (component.instance as any)['data'] = this.input.data as any;

        if ((component.instance as any)['output']) {
            (component.instance as any)['output'].pipe(
                takeUntil(this.unsubscribe)
            ).subscribe((data: any) => this.output.emit(data));
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
