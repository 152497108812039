import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';

import { CustomRouteReuseStrategy } from './app.route-reuse-strategy';
import { PageNotFoundComponent } from './shared/page-not-found.component';
import { RedirectProductGuard } from './shared/redirect-product.guard';

export const appRoutes: Routes = [
    {
        path: '**',
        component: PageNotFoundComponent,
        canActivate: [
            RedirectProductGuard
        ],
        data: {
            pageData: {
                title: '404',
                hideTitleTag: true
            }
        }
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
            {
                enableTracing: false
            }
        )
    ],
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy
        }
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
