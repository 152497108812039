import { Injectable } from '@angular/core';
import { DialogService } from './dialog.service';
import { InternalUserService } from '../services/internal-user.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IUser } from '../../api/services';
import { Translations } from '../../translations/translations';
import { FormBuilderTextInput } from '../form-builder/form-builder-element.model';
import { Validators } from '@angular/forms';
import { FormBuilderValidators } from '../form-builder/validators/form-builder-validators';
import { IDialogForm, IDialogSettings } from './dialog-interfaces';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

type DialogFormUsername = {
    currentUsername: string;
    newUsername: string;
};

@Injectable({
    providedIn: 'root'
})
export class DialogUserManagementService extends DialogService {

    constructor(protected dialog: MatDialog,
        protected snackBar: MatSnackBar,
        private internalUserService: InternalUserService) {

        super(dialog, snackBar);
    }

    public changeUsername(user: IUser) {
        const numMaxOfChars: number = 2;
        return this.showForm({
            data: {
                title: Translations.forms.labels.editUsername.title,
                inputs: [
                    new FormBuilderTextInput({
                        condition: () => false,
                        name: 'currentUsername',
                        label: Translations.forms.labels.editUsername.currentUsername.label,
                        value: user?.username
                    }),
                    new FormBuilderTextInput({
                        name: 'newUsername',
                        label: Translations.forms.labels.editUsername.newUsername.label,
                        required: true,
                        validation: [Validators.required, Validators.minLength(numMaxOfChars)],
                        asyncValidation: [FormBuilderValidators.uniqueUsernameValidator(this.internalUserService, user?.username)]
                    })
                ],
                actionText: Translations.forms.labels.editUsername.submit,
                alert: {
                    type: 'info',
                    text: Translations.forms.labels.editUsername.info
                }
            },
            maxWidth: 500
        }).afterClosed()
            .pipe(
                filter(res => !!res),
                switchMap((res: DialogFormUsername) => {
                    const currentUsername = user?.username || '';
                    const newUsername = res.newUsername || '';

                    if (currentUsername === newUsername) {
                        return EMPTY;
                    }

                    return this.internalUserService.updateUsername(currentUsername, newUsername)
                        .pipe(map(() => ({ ...res, currentUsername })));
                }),
                take(1),
            )
    }

    public changePassword() {
        const dialogForm: IDialogSettings<IDialogForm> = {
            data: {
                title: Translations.forms.labels.editPassword.title,
                inputs: [
                    new FormBuilderTextInput({
                        name: Translations.forms.labels.editPassword.password.name,
                        label: Translations.forms.labels.editPassword.password.label,
                        validation: [Validators.minLength(6)],
                        valueChanged: (value, form) =>
                            form &&
                            form.controls[
                                Translations.forms.labels.editPassword.confirmPassword.name
                            ].updateValueAndValidity(),
                        type: 'password',
                        required: true
                    }),
                    new FormBuilderTextInput({
                        name: Translations.forms.labels.editPassword.confirmPassword.name,
                        label: Translations.forms.labels.editPassword.confirmPassword.label,
                        validation: [
                            FormBuilderValidators.matchFieldValidator(
                                Translations.forms.labels.editPassword.password.name
                            )
                        ],
                        type: 'password',
                        required: true
                    })
                ],
                actionText: Translations.forms.labels.editPassword.submit,
                alert: {
                    type: 'info',
                    text: Translations.forms.labels.editPassword.info
                }
            },
            maxWidth: 500
        };

        return this.showForm(dialogForm).afterClosed()
    }

}
