import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../user/auth.guard';
import { IDENTITIES } from '../user/user-roles';
import { PageFrontpageComponent } from './page-frontpage.component';
import { PageHomecarerComponent } from './page-homecarer.component';

export const pageRoutes: Routes = [
    {
        path: 'pda',
        component: PageHomecarerComponent,
        data: {
            allowedRoles: IDENTITIES.pda
        },
        canActivate: [AuthGuard]
    },
    /* Default routes */
    {
        path: '',
        component: PageFrontpageComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(pageRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class PageRoutingModule { }
