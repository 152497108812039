/* eslint-disable no-null/no-null */
import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Translations } from 'Client/translations/translations';
import { MunicipalityService } from '../../municipality/municipality.service';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { InternalUserService } from '../../services/internal-user.service';
import { cities } from '../elements/cities';

export class FormBuilderValidators {
    static municipality(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control?.value) {
                return null;
            }

            const hasMunicipality = control.value['municipality'];
            const hasDistrict = Array.isArray(control.value['districts'])
                ? control.value['districts'].length
                : control.value['districts'];
            const hasSubdistrict = Array.isArray(control.value['subdistricts'])
                ? control.value['subdistricts'].length
                : control.value['subdistricts'];
            const valid = !!(hasMunicipality && hasDistrict && hasSubdistrict);

            return valid ? null : { municipality: 'Fill out all selects' };
        };
    }

    static city(required?: boolean): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control?.value) {
                return null;
            }

            const whenRequired = required && control.value['city'];
            const whenOptional =
                !required && ((control.value['zipCode'] && control.value['city']) || !control.value['zipCode']);
            const valid = !!(whenRequired || whenOptional);

            return valid ? null : { city: 'No city matched on zip' };
        };
    }

    static zipCode(required?: boolean): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control?.value) {
                return null;
            }

            const whenRequired = required && !!control.value && !!cities[control.value];
            const whenOptional = !required && ((!!control.value && !!cities[control.value]) || !control.value);
            const valid = !!(whenRequired || whenOptional);

            // tslint:disable-next-line:no-null-keyword
            return valid ? null : { city: 'No city matched on zip' };
        };
    }

    static cpr(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const valid = !control.value || /^[0-3][0-9][0-1][0-9][0-9][0-9]([0-9]{4}|\*\*\*\*)$/.test(control.value);

            return valid ? null : { cpr: 'CPR must be formatted as ddmmyyxxxx' };
        };
    }

    static matchFieldValidator(element: string | AbstractControl): ValidatorFn {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (control: AbstractControl): { [key: string]: any } | null => {
            // eslint-disable-line @typescript-eslint/no-explicit-any

            let comparableField: AbstractControl | null;

            if (typeof element !== 'string') {
                comparableField = element;
            } else {
                if (control.parent) {
                    comparableField = control.parent.get(element);
                } else {
                    comparableField = null;
                }
            }

            if (comparableField) {
                const invalid = comparableField.value ? comparableField.value !== control.value : false;

                return invalid ? { matchField: { value: control.value } } : null;
            }

            return null;
        };
    }

    static valueLength(maxLen: number, fieldName: string, ...valueControls: AbstractControl[] | string[]) {
        return (control: AbstractControl): ValidationErrors | null => {
            // If active control holds no value, do not try and validate to avoid confusion
            if (!control.value) {
                return null;
            }

            const combinedValue = [control, ...valueControls]
                .map(c => {
                    let comparableField: AbstractControl | null = null;

                    if (typeof c !== 'string') {
                        comparableField = c;
                    } else if (control.parent) {
                        comparableField = control.parent.get(c);
                    }

                    return comparableField?.value;
                })
                .filter(v => !!v)
                .join(' ');

            const valid = combinedValue.length <= maxLen;

            return valid
                ? null
                : {
                      valueLength: Translations.replaceTokens(
                          Translations.forms.staticValidation.valueLength,
                          fieldName,
                          maxLen
                      )
                  };
        };
    }

    static uniqueUsernameValidator(internalUserService: InternalUserService, originalUsername: string | undefined): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            const username = control.value;

            if (username?.length < 3 || username === originalUsername) {
                return of(null);
            }

            return internalUserService.checkUsernameExists(username).pipe(
                map(response => response ? { usernameExists: true} : null)
            );
        };
    }
}
