import { Component, Inject, Input } from '@angular/core';

import { IBasketViewModel, ICreditNoteViewModel, IOrderHistoryViewModel } from '../../api/services';
import { Translations } from '../../translations/translations';
import { CURRENCY_CODE } from '../app.locale_data';

@Component({
    selector: 'iv-commerce-summary',
    template: `
        <div class="commerce-summary" *ngIf="data">
            <div class="commerce-summary__key">
                {{ '${Translations.commerce.basket.quantity}' | translation : data.rows.length + ' ' + (data.rows.length === 1 ? '${Translations.commerce.basket.linePluralization.line}' : '${Translations.commerce.basket.linePluralization.lines}') : data.totalProducts + ' ' +  (data.totalProducts === 1 ? '${Translations.commerce.basket.productPluralization.product}' : '${Translations.commerce.basket.productPluralization.products}') }}
            </div>

            <div class="commerce-summary__value">
                {{ data.totalProductsPrice | currency : currencyCode : 'symbol-narrow' }}
            </div>

            <ng-container *ngIf="data.totalBottleDeposits">
                <div class="commerce-summary__key">
                    {{ (data.totalBottleDeposits === 1 ? '${Translations.commerce.basket.bottleDeposit}' : '${Translations.commerce.basket.bottleDeposits}') | translation : data.totalBottleDeposits }}
                </div>

                <div class="commerce-summary__value">
                    {{ data.totalBottleDepositsPrice | currency : currencyCode : 'symbol-narrow' }}
                </div>
            </ng-container>

            <ng-container *ngIf="data.totalDiscount">
                <div class="commerce-summary__key">
                    ${Translations.commerce.basket.savedOnDiscount}
                </div>

                <div class="commerce-summary__value commerce-summary__value_highlight">
                    {{ data.totalDiscount | currency : currencyCode : 'symbol-narrow' }}
                </div>
            </ng-container>

            <div class="commerce-summary__amount">
                {{ '${Translations.commerce.basket.amount}' | translation : (data.amount | currency : currencyCode : 'symbol-narrow') }}
            </div>
        </div>
    `
})
export class CommerceSummaryComponent {
    @Input() data: IBasketViewModel | IOrderHistoryViewModel | ICreditNoteViewModel;

    constructor( @Inject(CURRENCY_CODE) public currencyCode: string) { }
}
