import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ResetPasswordViewModel } from '../../api/services';
import { Translations } from '../../translations/translations';
import { AuthService } from '../services/auth.service';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'iv-new-password',
    template: `
        <section>
            <div class="reset-password" *ngIf="!states.expired; else expiredBlock">

                <ng-container *ngIf="!states.success; else successBlock">
                    <p id="reset-password-explanation">${Translations.pages.newPassword.explanatoryText}</p>
                    <form #resetForm="ngForm" (ngSubmit)="onReset(resetForm)" aria-labelledby="reset-password-explanation">
                        <mat-form-field>
                            <input name="password" [(ngModel)]="resetModel.password" matInput type="password" placeholder="${Translations.pages.newPassword.newPasswordLabel}" required>
                        </mat-form-field>
                        <mat-form-field>
                            <input name="confirmPassword" [(ngModel)]="resetModel.confirmPassword" matInput type="password" placeholder="${Translations.pages.newPassword.newPasswordConfirmLabel}" required>
                        </mat-form-field>
                        <div role="status" class="error" *ngIf="errorMsg">{{ errorMsg }}</div>
                        <div class="reset__actions">
                            <iv-progress-button class="reset__btn" [loadingState]="states.loading" color="primary">${Translations.pages.newPassword.newPasswordSubmit}</iv-progress-button>
                        </div>
                    </form>
                </ng-container>

            </div>

            <ng-template #expiredBlock>
                <p role="alert">${Translations.pages.newPassword.expiredText}</p>
            </ng-template>

            <ng-template #successBlock>
                <div class="success-feedback">

                    <div class="success-feedback__text">
                        ${Translations.pages.editPassword.editPasswordSuccess}
                    </div>

                    <a [routerLink]="['', { outlets: { primary: null } }]" class="success-feedback__btn" mat-raised-button color="primary">
                        ${Translations.pages.editPassword.goToFrontPage}
                    </a>
                </div>
            </ng-template>
        </section>
    `
})
export class NewPasswordComponent implements OnInit, OnDestroy {

    states = {
        expired: false,
        loading: false,
        success: false
    };

    resetModel = new ResetPasswordViewModel();

    errorMsg = '';

    successMsg = '';

    private unsubscribe = new Subject<void>();

    onReset(f: NgForm): void {
        this.errorMsg = '';
        this.successMsg = '';

        if (f.valid) {
            this.states.loading = true;
            this.authService.resetPassword(this.resetModel).pipe(
                takeUntil(this.unsubscribe),
                finalize(() => this.states.loading = false)
            ).subscribe(
                msg => {
                    this.states.success = true;
                    this.successMsg = msg;
                },
                (err: IntervareHttpErrorResponse) => {
                    this.errorMsg = err.validationErrors.join(', ');
                    this.states.success = false;
                }
            );
        }
    }

    ngOnInit() {
        this.activatedRoute.queryParamMap.pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(map => {
            const userId = map.get('userId');
            const token = map.get('token');

            if (userId === null || token === null) {
                this.states.expired = true;
            } else {
                this.resetModel.userId = userId;
                this.resetModel.token = token;
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        private authService: AuthService
    ) { }

}
