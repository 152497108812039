import { Injectable, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { IGenesysSettingsViewModel, IUserViewModel } from 'Client/api/services';
import { environment } from 'Client/environments/environment';
import * as platformClient from 'purecloud-platform-client-v2';
import { BehaviorSubject, Observable, ReplaySubject, Subject, from, of } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { GenesysState, State } from './genesys.model';
import { IDENTITIES, IUserRoles } from 'Client/app/user/user-roles';
import { SettingsService } from '../settings.service';
import { Params } from '@angular/router';
import { UtilUrlService } from 'Client/app/util/util-url.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class GenesysService implements OnDestroy {
    private readonly genesysTokenKey = 'genesys_token';
    private client: platformClient.ApiClientClass;
    private tokensApiInstance: platformClient.TokensApi;
    private authTokenBS = new BehaviorSubject<string>('');
    private conversationSettingsBS = new BehaviorSubject<IGenesysSettingsViewModel>({
        listOfAllowedPhoneNumbers: [],
        useListOfAllowedPhoneNumbers: false
    });
    private unsubscribeS = new Subject<void>();
    private genesysStateRS = new ReplaySubject<GenesysState>(1);

    public conversationsApiInstance: platformClient.ConversationsApi;
    public conversationSettings$ = this.conversationSettingsBS.asObservable();
    genesysState$ = this.genesysStateRS.asObservable();

    constructor(
        private settingsService: SettingsService,
        private utilUrlService: UtilUrlService,
        private location: Location
    ) {}

    ngOnDestroy(): void {
        this.unsubscribeS.next();
        this.unsubscribeS.complete();
    }

    init(user?: IUserViewModel): void {
        const isCustomerServiceUser = user?.roles?.some(x =>
            IDENTITIES.adminAndCustomerService.includes(x as IUserRoles)
        );

        if (!isCustomerServiceUser || !user?.hasCallerProfile) {
            this.genesysStateRS.next({ state: State.IDLE });
            return;
        }

        if (!this.client) {
            console.log('GenesysService.init()');
            this.genesysStateRS.next({ state: State.LOADING, message: 'Loading Genesys' });
            this.getSettings();
            this.client = platformClient.ApiClient.instance;
            this.conversationsApiInstance = new platformClient.ConversationsApi();
            this.tokensApiInstance = new platformClient.TokensApi();
            this.client.setEnvironment('https://api.mypurecloud.de');
            this.loadToken();
        }
    }

    checkAuthentication(params: Params, user?: IUserViewModel) {
        if (!user?.hasCallerProfile) {
            return of(false);
        }

        const token = params[this.genesysTokenKey];

        if (token) {
            this.storeToken(token);
            this.clearGenesysToken(params);
            return of(true);
        }

        return this.validateCurrentToken().pipe(
            map(isValid => {
                if (isValid) {
                    this.genesysStateRS.next({ state: State.READY, message: 'Genesys Ready' });
                    this.clearGenesysToken(params);
                    return true;
                } else {
                    this.genesysStateRS.next({ state: State.ERROR, message: 'Error validating token' });
                    if (user?.hasCallerProfile) {
                        console.warn('Genesys token is invalid, re-authenticating');
                        this.authenticate();
                    }
                    return false;
                }
            })
        );
    }

    authenticate() {
        const url = environment.production
            ? `${location.protocol}//${location.host}/Genesys/Login`
            : 'https://localhost:5001/Genesys/Login';
        location.href = url;
    }

    disconnect() {
        localStorage.removeItem(this.genesysTokenKey);
    }

    getSettings() {
        this.settingsService
            .getGenesysSettings()
            .pipe(takeUntil(this.unsubscribeS))
            .subscribe(res => {
                this.conversationSettingsBS.next(res);
            });
    }

    private clearGenesysToken(params: Params) {
        if (params[this.genesysTokenKey]) {
            const httpParams = new HttpParams({ fromObject: {} });
            const pathname = this.utilUrlService.pathname();
            this.location.replaceState(pathname, httpParams.toString());
        }
    }

    private loadToken() {
        try {
            const token = localStorage.getItem(this.genesysTokenKey);

            if (token) {
                this.authTokenBS.next(token);
                this.client.setAccessToken(token);
                this.genesysStateRS.next({ state: State.READY, message: 'Genesys Ready' });
            }
        } catch (e) {
            console.warn('Error loading token from storage', e);
            this.genesysStateRS.next({ state: State.ERROR, message: 'Error loading token from storage' });
        }
    }

    private storeToken(token: string) {
        try {
            localStorage.setItem(this.genesysTokenKey, token);
            if (token) {
                this.authTokenBS.next(token);
                this.client.setAccessToken(token);
                this.genesysStateRS.next({ state: State.READY, message: 'Genesys Ready' });
            }
        } catch (e) {
            console.warn('Error loading token from storage', e);
            this.genesysStateRS.next({ state: State.ERROR, message: 'Error loading token from storage' });
        }
    }

    private validateCurrentToken(): Observable<boolean> {
        if (!this.authTokenBS.value) {
            return of(false);
        }

        return from(this.tokensApiInstance.headTokensMe()).pipe(
            map(() => true),
            catchError(err => {
                console.warn('Error validating token', err);
                return of(false);
            })
        );
    }
}
