import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BasketViewModel, IUserViewModel } from '../../api/services';
import { burger, cart, creditcard, lock, logOut, logo, starMenu } from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { SearchFieldComponent } from '../search/search-field.component';
import { AuthService } from '../services/auth.service';
import { BasketService } from '../services/basket.service';
import { SettingsService } from '../services/settings.service';
import { IDENTITIES, IUserRoles } from '../user/user-roles';
import { UserService } from '../user/user.service';
import { production } from '../util/helpers';

@Component({
    selector: 'iv-layout-navigation',
    template: `
        <header
            role="banner"
            class="layout-navigation alt-theme"
            [ngClass]="{ 'layout-navigation_logged-in': user, 'layout-navigation_admin': adminMode }"
        >
            <div class="layout-navigation__impersonated" *ngIf="showImpersonated">
                <div class="layout-navigation__impersonated-citizen">
                    ${Translations.layout.navigation.impersonated.citizen}: {{ user.impersonatedCitizenQuickInfo.name }}
                </div>

                <div
                    class="layout-navigation__impersonated-delivery"
                    [ngStyle]="{ color: user.deliveryDateChanged && user.deliveryDateChanged === true ? 'yellow' : '' }"
                >
                    <span *ngIf="!user.deliveryDateChanged">
                        ${Translations.layout.navigation.impersonated.delivery}
                        {{ user.nextDeliveryDate | date : 'shortDate' }}
                    </span>
                    <span *ngIf="user.deliveryDateChanged">
                        ${Translations.layout.navigation.impersonated.deliveryChanged}
                        {{ user.nextDeliveryDate | date : 'fullDate' }}
                    </span>
                </div>
            </div>

            <nav
                role="navigation"
                class="layout-navigation__row"
                *ngIf="!adminMode"
                [ngClass]="{ 'layout-navigation__row--dev-mode': isDev }"
            >
                <div class="layout-navigation__left">
                    <button
                        class="layout-navigation__menu-trigger layout-navigation__menu-trigger_mobile btn-with-icon"
                        *ngIf="showMenuTrigger"
                        (click)="toggleMenu()"
                        mat-mini-fab
                        color="accent"
                        aria-label="${Translations.layout.trigger.label}"
                    >
                        <span class="layout-navigation__menu-trigger-icon icon" aria-hidden="true">${burger}</span>
                    </button>

                    <ng-container *ngIf="!homecarerMode">
                        <a
                            routerLink="/${Translations.shop.paths.shopPage.path}"
                            routerLinkActive="layout-navigation__item_active"
                            class="layout-navigation__item"
                            [routerLinkActiveOptions]="{ exact: true }"
                        >
                            ${Translations.layout.navigation.products}
                        </a>

                        <ng-container *ngIf="user">
                            <a
                                routerLink="${Translations.shop.paths.shopPage.path}/${Translations.shop.paths
                                    .discountPage.path}"
                                routerLinkActive="layout-navigation__item_active"
                                class="layout-navigation__item"
                            >
                                ${Translations.layout.navigation.sales}
                            </a>
                            <a
                                routerLink="/${Translations.shop.paths.topProductsPage.path}"
                                routerLinkActive="layout-navigation__item_active"
                                class="layout-navigation__item"
                            >
                                ${Translations.layout.navigation.topProducts}
                            </a>
                        </ng-container>
                    </ng-container>
                </div>

                <a
                    [routerLink]="[{ outlets: { primary: null } }]"
                    class="layout-navigation__logo"
                    aria-label="${Translations.global.logo}"
                >
                    ${logo}
                </a>

                <div class="layout-navigation__right">
                    <ng-container *ngIf="user">
                        <button class="layout-navigation__item" (click)="logoutUser()">
                            <span class="layout-navigation__icon icon" aria-hidden="true">${logOut}</span>
                            <span class="layout-navigation__text">${Translations.layout.navigation.logout}</span>
                        </button>
                        <a
                            routerLink="/${Translations.pages.editPassword.path}"
                            routerLinkActive="layout-navigation__item_active"
                            class="layout-navigation__item"
                            *ngIf="canEditPassword"
                        >
                            <div class="layout-navigation__icon icon" aria-hidden="true">${lock}</div>
                            <div class="layout-navigation__text">${Translations.layout.navigation.editPassword}</div>
                        </a>
                        <ng-container *ngIf="!homecarerMode">
                            <a
                                routerLink="/${Translations.shop.paths.ordersPage.path}"
                                routerLinkActive="layout-navigation__item_active"
                                class="layout-navigation__item"
                            >
                                <div class="layout-navigation__icon icon" aria-hidden="true">${creditcard}</div>
                                <div class="layout-navigation__text">${Translations.layout.navigation.orders}</div>
                            </a>
                            <a
                                routerLink="/${Translations.shop.paths.favoritesPage.path}"
                                routerLinkActive="layout-navigation__item_active"
                                class="layout-navigation__item"
                            >
                                <div class="layout-navigation__icon icon" aria-hidden="true">${starMenu}</div>
                                <div class="layout-navigation__text">${Translations.layout.navigation.favourite}</div>
                            </a>
                            <a
                                routerLink="/${Translations.shop.paths.basketPage.path}"
                                routerLinkActive="layout-navigation__item_active"
                                class="layout-navigation__item layout-navigation__basket"
                            >
                                <div class="layout-navigation__basket-container">
                                    <div class="layout-navigation__basket-quantity" *ngIf="totalProducts !== 0">
                                        {{ totalProducts }}
                                    </div>
                                    <div class="layout-navigation__icon icon" aria-hidden="true">${cart}</div>
                                </div>

                                <div class="layout-navigation__text">${Translations.layout.navigation.basket}</div>
                            </a>
                        </ng-container>
                    </ng-container>
                </div>
            </nav>

            <div
                class="layout-navigation__row"
                *ngIf="!homecarerMode"
                [ngClass]="{ 'layout-navigation__row--dev-mode': isDev }"
            >
                <button
                    class="layout-navigation__menu-trigger layout-navigation__menu-trigger_tablet btn-with-icon"
                    *ngIf="showMenuTrigger"
                    (click)="toggleMenu()"
                    mat-mini-fab
                    color="accent"
                    aria-label="${Translations.layout.trigger.label}"
                >
                    <span class="layout-navigation__menu-trigger-icon icon" aria-hidden="true">${burger}</span>
                </button>

                <button
                    class="layout-navigation__menu-trigger layout-navigation__menu-trigger_desktop btn-with-icon"
                    *ngIf="showMenuTrigger"
                    (click)="toggleMenu()"
                    mat-fab
                    color="accent"
                    aria-label="${Translations.layout.trigger.label}"
                >
                    <span class="layout-navigation__menu-trigger-icon icon" aria-hidden="true">${burger}</span>
                </button>

                <a
                    [routerLink]="[{ outlets: { primary: null } }]"
                    *ngIf="adminMode; else search"
                    class="layout-navigation__logo"
                    aria-label="${Translations.global.logo}"
                >
                    ${logo}
                </a>

                <ng-template #search>
                    <iv-search-field #searchField class="layout-navigation__search" *ngIf="user"></iv-search-field>
                </ng-template>
            </div>
        </header>
    `
})
export class LayoutNavigationComponent implements OnInit, OnDestroy {
    @ViewChild('searchField') searchField: SearchFieldComponent;

    @Input() showMenuTrigger: boolean;
    @Input() adminMode: boolean;
    @Input() showImpersonated: boolean;
    @Input() homecarerMode: boolean;
    @Output() menuToggled = new EventEmitter();
    public isDev: boolean;

    user: IUserViewModel | undefined;
    totalProducts = 0;
    canEditPassword = false;
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private userService: UserService,
        private authService: AuthService,
        private router: Router,
        private basketService: BasketService,
        private settingService: SettingsService
    ) {}

    ngOnInit() {
        this.settingService
            .getCurrentEnvironment()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(env => (this.isDev = env === production ? false : true));

        this.basketService.basket$
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(basket => (this.totalProducts = basket instanceof BasketViewModel ? basket.totalProducts : 0));

        this.userService.user$.pipe(takeUntil(this.unsubscribe)).subscribe(user => {
            this.user = user;
            const userRoles = user ? (user.roles as IUserRoles[]) : [];
            this.canEditPassword = userRoles.some(x => IDENTITIES.editOwnPassword.includes(x));

            // Triggering focus on  search products after searching for a user and select him;
            if (user && this.searchField) {
                this.searchField.setFocus();
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    toggleMenu() {
        this.menuToggled.emit();
    }

    logoutUser() {
        this.authService
            .logout()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.router.navigate(['/']);
            });
    }
}
