import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { map } from 'rxjs/operators';

import { Translations } from '../../../translations/translations';
import { CallListAdminService, IPlanningDayDetails } from '../../services/call-list-admin.service';
import { IDefaultPageData } from '../../shared/route-data';
import { Observable } from 'rxjs';

export const PlanningDayResolver: ResolveFn<Observable<IDefaultPageData<IPlanningDayDetails>>> = (activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<IDefaultPageData<IPlanningDayDetails>> => {

    const callListAdminService: CallListAdminService = inject(CallListAdminService)

    const id = activatedRouteSnapshot.paramMap.get('planning-day-id') || '';
    return callListAdminService.getPlanningDayDetails(id).pipe(
        map(planningDayDetails => ({ title: Translations.callListAdmin.planningPage.header + ' ' + planningDayDetails.title, data: planningDayDetails })));

}
