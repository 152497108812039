import { Pipe, PipeTransform } from '@angular/core';
import { IDistrict } from '../../api/services';

@Pipe({
    name: 'districtNames'
})
export class DistrictNamesPipe implements PipeTransform {

    /**
     * Generates the label of the users districts with an ellipsis if needed
     * @param {IDistrict[]} districts
     * @param {boolean} ellipsis
     * @returns {string}
     *
     */
    transform(districts: IDistrict[], ellipsis: boolean = false): string {
        if (!districts) {
            return '';
        }

        const names = districts
            .map(district => district.districtName)
            .join(', ');

        return ellipsis && districts.length > 1 ? names.slice(0, districts[0].districtName!.length + 5) + '...' : names;
    }

}
