import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlSegment } from '@angular/router';
import { merge, of } from 'rxjs';
import { ProductService } from '../services/product.service';
import { catchError, mapTo, switchMap, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

/**
 * NOTE: INT-2011
 * Guard that redirects to a product route if a valid product number is provided by product description.
 * @param route - The activated route snapshot.
 * @returns An observable that emits a boolean indicating whether navigation is allowed or not.
 */
export const RedirectProductGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot
) => {
    const productService = inject(ProductService);
    const dialogService = inject(MatDialog);
    const router = inject(Router);
    const productNo = getProduct(route.url);

    if (!productNo) {
        return of(true);
    }

    productService
        .getProductDetails(productNo)
        .pipe(
            take(1),
            catchError(() => of(true)),
        ).subscribe(() => {
            dialogService.closeAll()
        });

    const fromDialogs = dialogService.openDialogs.map(ref => ref.afterClosed());

    if (fromDialogs?.length > 0) {
        return merge(fromDialogs)
            .pipe(
                switchMap(() => of(getProductRoute(productNo, router))),
                mapTo(true)
            )
    }

    return of(getProductRoute(productNo, router)).pipe(mapTo(true));

};

const getProductRoute = (productNo: string, router: Router) => {
    return router.navigate(['/', { outlets: { dialog: ['product', productNo] } }], { state: { redirectFromProduct: true } });
}


const getProduct = (url: UrlSegment[]): string | undefined => {
    if (url.length > 0) {
        const product = url[0].path;
        if (product.includes("-")) {
            return product.split('-').pop();
        }
    }
    return;
}
