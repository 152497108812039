import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CitizenModule } from '../citizen/citizen.module';
import { FormBuilderModule } from '../form-builder/form-builder.module';
import { MaterialUiModule } from '../material-ui/material-ui.module';
import { AddressPipe } from '../shared/address.pipe';
import { CustomerTypePipe } from '../shared/customer-type.pipe';
import { SharedModule } from '../shared/shared.module';
import { MunicipalityAdminMenuComponent } from './municipality-admin-menu.component';
import { MunicipalityCitizenDraftsComponent } from './municipality-citizen-drafts.component';
import { MunicipalityCitizenOverviewComponent } from './municipality-citizen-overview.component';
import { MunicipalityCitizenPaymentComponent } from './municipality-citizen-payment.component';
import { MunicipalityCitizenStatusComponent } from './municipality-citizen-status.component';
import { MunicipalityCitizenTableComponent } from './municipality-citizen-table.component';
import { MunicipalityCreateUserComponent } from './municipality-create-user.component';
import { MunicipalityDriverMessagesComponent } from './municipality-driver-messages.component';
import { MunicipalityEditPasswordCitizenComponent } from './municipality-edit-password-citizen.component';
import { MunicipalityMenuComponent } from './municipality-menu.component';
import { MunicipalityPdaStatisticsComponent } from './municipality-pda-statistics.component';
import { MunicipalityRoutingModule } from './municipality-routing.module';
import { MunicipalitySubscribeCitizenComponent } from './municipality-subscribe-citizen.component';
import { MunicipalityTemporaryAddressComponent } from './municipality-temporary-address.component';
import { MunicipalityUserListComponent } from './municipality-user-list.component';
import { MunicipalityViewCitizensComponent } from './municipality-view-citizens.component';
import { MunicipalityService } from './municipality.service';
import { IncludesDataValuePipe } from './includes';

@NgModule({
    imports: [
        CommonModule,
        MunicipalityRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialUiModule,
        FormBuilderModule,
        SharedModule,
        CitizenModule
    ],
    declarations: [
        MunicipalityCreateUserComponent,
        MunicipalityUserListComponent,
        MunicipalitySubscribeCitizenComponent,
        MunicipalityCitizenOverviewComponent,
        MunicipalityPdaStatisticsComponent,
        MunicipalityCitizenTableComponent,
        MunicipalityCitizenDraftsComponent,
        MunicipalityCitizenStatusComponent,
        MunicipalityViewCitizensComponent,
        MunicipalityCitizenTableComponent,
        MunicipalityDriverMessagesComponent,
        MunicipalityTemporaryAddressComponent,
        MunicipalityCitizenPaymentComponent,
        MunicipalityMenuComponent,
        MunicipalityAdminMenuComponent,
        MunicipalityEditPasswordCitizenComponent,
        IncludesDataValuePipe,
    ],
    providers: [
        MunicipalityService,
        AddressPipe,
        CustomerTypePipe
    ],
})
export class MunicipalityModule { }
