import { Component, OnInit } from '@angular/core';

import { Translations } from '../../translations/translations';

import { controlreport } from '../../scripts/generated/icons';

@Component({
    selector: 'iv-layout-footer',
    template: `
        <footer class="layout-footer">
            <address class="layout-footer__col">
                <h3 class="layout-footer__head">
                    ${Translations.layout.footer.company}
                </h3>

                <div class="layout-footer__row">
                    ${Translations.layout.footer.streetName} ${Translations.layout.footer.streetNumber}
                </div>

                <div class="layout-footer__row">
                    ${Translations.layout.footer.zip} ${Translations.layout.footer.municipality}
                </div>
            </address>

            <div class="layout-footer__col">
                <h3 class="layout-footer__head">
                    ${Translations.layout.footer.customerService}
                </h3>

                <div class="layout-footer__row">
                    <div class="layout-footer__key">
                        ${Translations.layout.footer.open.key}
                    </div>

                    <div class="layout-footer__value">
                        ${Translations.layout.footer.open.value}
                    </div>
                </div>

                <div class="layout-footer__row">
                    <div class="layout-footer__key">
                        ${Translations.layout.footer.phone.key}
                    </div>

                    <div class="layout-footer__value">
                        <a href="tel:${Translations.layout.footer.phone.value}">${Translations.layout.footer.phone.value}</a>
                    </div>
                </div>

                <div class="layout-footer__row">
                    <div class="layout-footer__key">
                        ${Translations.layout.footer.email.key}
                    </div>

                    <div class="layout-footer__value">
                        <a href="mailto:${Translations.layout.footer.email.value}">${Translations.layout.footer.email.value}</a>
                    </div>
                </div>
            </div>

            <div class="layout-footer__col layout-footer__control-report">
                <a class="layout-footer__control-report-link" href="${Translations.layout.footer.controlreport.link}" target="_blank">
                    <div class="layout-footer__control-report-icon icon">${controlreport}</div>
                    <div class="layout-footer__control-report-text">${Translations.layout.footer.controlreport.text}</div>
                </a>
            </div>
        </footer>
    `
})
export class LayoutFooterComponent {
}
