import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';

import { Translations } from '../../translations/translations';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { CallListAdminService } from '../services/call-list-admin.service';
import { DialogService } from '../shared/dialog.service';

@Component({
    selector: 'iv-end-fax-day',
    template: `
        <article class="dialog">
            <h1 mat-dialog-title>${Translations.callListAdmin.endFaxDay.header}</h1>
            <mat-dialog-content>
                ${Translations.callListAdmin.endFaxDay.text}
            </mat-dialog-content>
            <mat-dialog-actions class="alt-theme">
                <iv-progress-button color="primary" [callback]="endFaxDay">${Translations.global.btnAcknowledge}</iv-progress-button>
                <button mat-raised-button type="button" mat-dialog-close>${Translations.global.btnCancel}</button>
            </mat-dialog-actions>
        </article>
    `
})
export class EndFaxDayComponent {
    constructor(
        private dialogRef: MatDialogRef<EndFaxDayComponent>,
        private callListAdminService: CallListAdminService,
        private dialogService: DialogService
    ) { }

    endFaxDay = (): Promise<boolean> => {
        return this.callListAdminService.endFaxDay().pipe(
            tap(
                () => {
                    this.callListAdminService.getPlanningdays();
                    this.dialogService.showSnackMessage({ message: Translations.callListAdmin.endFaxDay.messageOk });
                    this.dialogRef.close();
                },
                (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
            )
        ).toPromise();
    }
}
