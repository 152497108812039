import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { ICauseViewModel } from '../../../api/services';
import { NotesAdminService } from '../notes-admin.service';
import { Observable } from 'rxjs';

export function NotesAdminCauseResolver(route: ActivatedRouteSnapshot): Observable<ICauseViewModel> | undefined {
    const notesAdminService: NotesAdminService = inject(NotesAdminService);
    const id = route.paramMap.get('id');
    return id ? notesAdminService.getCause(id) : undefined;
}
