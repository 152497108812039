import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import {
    CreateMessageViewModel,
    ICreateMessageViewModel,
    IUpdateMessageViewModel,
    RoleTypeEnum
} from '../../api/services';
import { Translations } from '../../translations/translations';
import {
    FormBuilderCheckbox,
    FormBuilderDatepicker,
    FormBuilderRichtextEditor,
    FormBuilderSelect,
    FormBuilderTypes
} from '../form-builder/form-builder-element.model';
import { FormBuilderService } from '../form-builder/form-builder.service';
import { IntervareHttpErrorResponse } from '../services/base-service';
import { MessageService } from '../services/message.service';
import { DialogService } from '../shared/dialog.service';

@Component({
    selector: 'iv-administration-messages-create-update',
    template: `
        <section class="messages-form">
            <form *ngIf="form" [formGroup]="form" (submit)="submit(form)" class="alt-theme form">
                <iv-form-builder-element
                    *ngFor="let input of inputs"
                    [form]="form"
                    [input]="input"
                ></iv-form-builder-element>
                <div class="messages-form__actions">
                    <a *ngIf="state.isEditing" mat-raised-button color="accent" routerLink="/messages"
                        >${Translations.global.btnCancel}</a
                    >
                    <iv-progress-button
                        class="messages-form__submit"
                        buttonType="submit"
                        color="primary"
                        [disabled]="!form.valid"
                        [loadingState]="state.isLoading"
                    >
                        {{
                            state.isEditing
                                ? '${Translations.intervare.messageAdmin.editBtn}'
                                : '${Translations.intervare.messageAdmin.createBtn}'
                        }}
                    </iv-progress-button>
                </div>
            </form>
        </section>
    `
})
export class AdministrationMessagesCreateUpdateComponent implements OnInit, OnDestroy {
    form: UntypedFormGroup;
    @Input() message: ICreateMessageViewModel | IUpdateMessageViewModel = new CreateMessageViewModel();
    inputs: FormBuilderTypes[];
    state = {
        isEditing: false,
        isLoading: false
    };

    private unsubscribe = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private messageService: MessageService,
        private formBuilder: FormBuilderService,
        private dialogService: DialogService
    ) {}

    ngOnInit() {
        this._getMessage();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    submit(form: UntypedFormGroup) {
        if (form.valid) {
            this.state.isLoading = true;
            const newMessage = form.value as ICreateMessageViewModel;

            if (this.messageService.isUpdateMessage(this.message)) {
                (newMessage as IUpdateMessageViewModel).messageId = this.message.messageId;
            }

            if (newMessage.endDate) {
                newMessage.endDate.setHours(23);
                newMessage.endDate.setMinutes(59);
            }

            this.messageService
                .updateMessage(newMessage)
                .pipe(
                    takeUntil(this.unsubscribe),
                    finalize(() => (this.state.isLoading = false))
                )
                .subscribe(
                    () => {
                        this.dialogService.showSnackMessage({
                            message: Translations.intervare.messageAdmin.successMsg
                        });
                        this.router.navigate(['/messages']);
                    },
                    (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
                );
        }
    }

    private _getMessage() {
        const param = this.route.snapshot.paramMap.get('id');
        if (param) {
            this.state.isEditing = true;
            const messageId = +param;
            this.messageService
                .getMessage(messageId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(
                    message => {
                        this.message = message as ICreateMessageViewModel;
                        this._buildForm();
                    },
                    (err: IntervareHttpErrorResponse) => this.dialogService.showValidationResult(err.validationErrors)
                );
        } else {
            this._buildForm();
        }
    }

    private _buildForm() {
        this.inputs = [
            new FormBuilderSelect({
                name: 'allowedUserTypes',
                label: Translations.intervare.messageAdmin.roleField,
                value: this.message.allowedUserTypes,
                multiple: true,
                options: [
                    { label: Translations.intervare.messageAdmin.roles.Admin, value: RoleTypeEnum.Admin },
                    { label: Translations.intervare.messageAdmin.roles.Citizen, value: RoleTypeEnum.Citizen },
                    { label: Translations.intervare.messageAdmin.roles.HomeCarer, value: RoleTypeEnum.HomeCarer },
                    {
                        label: Translations.intervare.messageAdmin.roles.CustomerService,
                        value: RoleTypeEnum.CustomerService
                    },
                    {
                        label: Translations.intervare.messageAdmin.roles.MunicipalityAdmin,
                        value: RoleTypeEnum.MunicipalityAdmin
                    }
                ]
            }),
            new FormBuilderDatepicker({
                name: 'startDate',
                label: Translations.intervare.messageAdmin.startDate,
                value: this.message.startDate
            }),
            new FormBuilderDatepicker({
                name: 'endDate',
                label: Translations.intervare.messageAdmin.endDate,
                value: this.message.endDate
            }),
            new FormBuilderCheckbox({
                name: 'isActive',
                label: Translations.intervare.messageAdmin.isActive,
                value: this.message.isActive
            }),
            new FormBuilderRichtextEditor({
                name: 'textMessage',
                required: true,
                label: Translations.intervare.messageAdmin.textMessage,
                value: this.message.textMessage,
                richtextOptions: {
                    toolbar: {
                        buttons: [
                            'bold',
                            'italic',
                            'underline',
                            'strikethrough',
                            'anchor',
                            'orderedlist',
                            'unorderedlist',
                            'h2',
                            'h3'
                        ]
                    }
                }
            })
        ];

        this.form = this.formBuilder.toFormGroup(this.inputs);
    }
}
