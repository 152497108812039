import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';

import { UserViewModel } from '../../../api/services';
import { Translations } from '../../../translations/translations';
import { AuthService } from '../../services/auth.service';
import { BasketService } from '../../services/basket.service';
import { IDialogContentSettings } from '../../shared/dialog-interfaces';
import { DialogService } from '../../shared/dialog.service';
import { UserService } from '../../user/user.service';

@Injectable()
export class CitizenGuard {
    canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
        let currentCustomerNo = '';
        const nextCustomerNo = next.paramMap.get('id') || '';
        const callRecordIdParam = next.paramMap.get('callRecordId');
        const inboundCallNotificationIdParam = next.paramMap.get('inboundCallNotificationId');
        const callRecordId = callRecordIdParam ? +callRecordIdParam : undefined;
        const inboundCallNotificationId = inboundCallNotificationIdParam ? +inboundCallNotificationIdParam : undefined;
        const cameFromCallList = !!callRecordId;
        const cameFromInboundCallNotification = !!inboundCallNotificationId;
        const basketIsEmpty = this.basketService.isEmpty;

        if (!nextCustomerNo) {
            return of(false);
        }

        if (cameFromCallList) {
            return this.switch(nextCustomerNo, false, callRecordId);
        }

        if (cameFromInboundCallNotification) {
            return this.switch(nextCustomerNo, false, undefined, inboundCallNotificationId);
        }

        return this.userService.isImpersonating.pipe(
            first(),
            switchMap(isImpersonating => {
                if (isImpersonating) {
                    currentCustomerNo = isImpersonating.customerNumber!;
                }

                if (!isImpersonating || basketIsEmpty) {
                    return this.switch(nextCustomerNo, false, callRecordId);
                } else if (isImpersonating.customerNumber !== nextCustomerNo) {
                    const dialogSettings: IDialogContentSettings = {
                        data: {
                            header: Translations.administration.citizenQuickView.switchCitizenPrompt.header,
                            content: Translations.administration.citizenQuickView.switchCitizenPrompt.text,
                            buttons: {
                                button1: {
                                    color: 'primary',
                                    confirm: true,
                                    text: Translations.global.btnAcknowledge
                                },
                                button2: {
                                    color: 'accent',
                                    confirm: false,
                                    text: Translations.global.btnCancel
                                }
                            }
                        },
                        disableClose: true
                    };
                    return this.dialogService
                        .openDialog(dialogSettings)
                        .afterClosed()
                        .pipe(
                            switchMap(dialogResponse => {
                                if (dialogResponse) {
                                    return this.switch(nextCustomerNo, false, callRecordId);
                                }

                                return of(false);
                            })
                        );
                }
                return of(true);
            }),
            tap(isImpersonated => {
                if (isImpersonated && currentCustomerNo !== nextCustomerNo) {
                    // Router can't handle two outlets changes simultaneously. Hence the timeout. Ref: https://github.com/angular/angular/issues/13523
                    setTimeout(() => {
                        // eslint-disable-next-line no-null/no-null
                        this.router.navigate([{ outlets: { primary: null } }]);
                    });
                }
            })
        );
    }

    private showOkMessage(citizenName: string) {
        this.dialogService.showSnackMessage({
            message: Translations.replaceTokens(
                Translations.administration.citizenQuickView.switchCitizenPrompt.messageOk,
                citizenName
            )
        });
    }

    private switch(
        customerNo: string,
        takeOverLock: boolean,
        callRecordId?: number,
        inboundCallNotificationId?: number
    ): Observable<boolean> {
        return this.authService
            .switchToCitizen({ customerNo, takeOverLock, inboundCallNotificationId }, callRecordId)
            .pipe(
                switchMap(res => {
                    if (res instanceof UserViewModel) {
                        return of(res);
                    }

                    return this.authService.switchToCitizen(
                        { customerNo, takeOverLock: true, inboundCallNotificationId },
                        callRecordId
                    );
                }),
                map(res => {
                    if (res instanceof UserViewModel && res.impersonatedCitizenQuickInfo) {
                        this.showOkMessage(res.impersonatedCitizenQuickInfo.name!);
                        return true;
                    }
                    return false;
                })
            );
    }

    constructor(
        private userService: UserService,
        private dialogService: DialogService,
        private basketService: BasketService,
        private authService: AuthService,
        private router: Router
    ) {}
}
