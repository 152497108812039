import { TemporaryCitizenViewModel } from './../../api/services';
import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { HistoryItemType, IDayAndTime, IHistoryItem } from '../../api/services';
import { environment } from '../../environments/environment';
import {
    busy,
    cart,
    magnifyingGlass,
    municipalityportal,
    note,
    opkald,
    unansweredcall,
} from '../../scripts/generated/icons';
import { Translations } from '../../translations/translations';
import { ICitizenTableRow, MunicipalityCitizenTableComponent } from '../municipality/municipality-citizen-table.component';
import { CitizenService } from '../services/citizen.service';
import { NoteService } from '../services/note.service';
import { AddressPipe } from '../shared/address.pipe';
import { DialogService } from '../shared/dialog.service';

@Component({
    selector: 'iv-citizen-history-list',
    template: `
        <ul *ngIf="historyList" class="history-list">
            <li *ngFor="let historyItem of historyList">
                <div [ngSwitch]="historyItem.historyItemType" class="history-item">
                    <ng-container *ngSwitchCase="${HistoryItemType.InCommingCall}">
                        ${opkald}
                    </ng-container>
                    <ng-container *ngSwitchCase="${HistoryItemType.OutGoingCall}">
                        ${opkald}
                    </ng-container>
                    <ng-container *ngSwitchCase="${HistoryItemType.UnAnsweredCall}">
                        ${unansweredcall}
                    </ng-container>
                    <ng-container *ngSwitchCase="${HistoryItemType.BusyCall}">
                        ${busy}
                    </ng-container>
                    <ng-container *ngSwitchCase="${HistoryItemType.Note}">
                        ${note}
                        <button class="history-item__link" [ivHistoryTooltip]="historyItem.id" (click)="onClickNoteDetails(historyItem.id)">${Translations.administration.citizenHistory.detailsNoteBtn}</button>
                    </ng-container>
                    <ng-container *ngSwitchCase="${HistoryItemType.UpdatedCitizen}">
                        ${municipalityportal}
                        <button class="history-item__link" (click)="onClickUpdateDetails(historyItem.id)">${Translations.administration.citizenHistory.detailsNoteBtn}</button>
                    </ng-container>
                    <ng-container *ngSwitchCase="${HistoryItemType.Order}">
                        ${cart}
                        <a *ngIf="historyItem.id" class="history-item__link" href="${environment.apiBaseUrl}/api/Order/GetOrderPdf?orderNo={{ historyItem.id }}" target="_blank">${Translations.administration.citizenHistory.detailsOrderBtn}</a>
                        <span *ngIf="!historyItem.id" class="history-item__link">${Translations.administration.citizenHistory.syncing}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="${HistoryItemType.CreditNote}">
                        ${cart}
                        <a class="history-item__link" href="${environment.apiBaseUrl}/api/Order/GetCreditNotePdf?creditNo={{ historyItem.id }}" target="_blank">${Translations.administration.citizenHistory.detailsOrderBtn}</a>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        ${magnifyingGlass}
                    </ng-container>
                    <h4 [innerHTML]="parseTitle(historyItem.heading)"></h4>
                    <p class="history-item__data-and-user">
                        <ng-container *ngIf="!historyItem.createdBy || historyItem.createdBy !== 'System'">{{ historyItem.createdDate | date: 'short' }}</ng-container>
                        <ng-container *ngIf="historyItem.createdBy === 'System'">{{ historyItem.createdDate | date }}</ng-container>
                        <ng-container *ngIf="historyItem.createdBy">, {{ historyItem.createdBy }}</ng-container>
                    </p>
                </div>
            </li>
        </ul>
  `
})
export class CitizenHistoryListComponent implements OnDestroy {
    @Input() historyList: IHistoryItem[];
    @Input() customerNo: string;

    @Input() parseHTML = false;

    private editMode = false;

    private unsubscribe = new Subject<void>();

    constructor(
        private noteService: NoteService,
        private citizenService: CitizenService,
        private dialogService: DialogService,
        private date: DatePipe,
        private addressPipe: AddressPipe
    ) { }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onClickNoteDetails(noteId: string) {
        this.noteService.getCustomerNote(noteId).pipe(
            takeUntil(this.unsubscribe)
        ).subscribe(noteVm => {

            const beginTpl = '<dl>';
            const endTpl = '</dl>';

            let tmpl = `<dt>${Translations.administration.citizenHistory.noteDetails.date}</dt>
                        <dd>${this.date.transform(noteVm.dateCreated, 'short')}</dd>`;

            if (noteVm.causeName) {
                tmpl += `<dt>${Translations.administration.citizenHistory.form.cause}</dt>
                         <dd>${noteVm.causeName}</dd>`;
            }

            if (noteVm.subCauseName) {
                tmpl += `<dt>${Translations.administration.citizenHistory.form.subCause}</dt>
                         <dd>${noteVm.subCauseName}</dd>`;
            }

            if (noteVm.subSubCauseName) {
                tmpl += `<dt>${Translations.administration.citizenHistory.form.subSubCause}</dt>
                         <dd>${noteVm.subSubCauseName || Translations.administration.citizenHistory.noteDetails.noSubSubCause}</dd>`;
            }

            if (noteVm.comment) {
                tmpl += `<dt>${Translations.administration.citizenHistory.form.comment}</dt>
                         <dd>${noteVm.comment || ''}</dd>`;
            }

            if (noteVm.emailRecipients) {
                tmpl += `<dt>${Translations.administration.citizenHistory.form.emailRecipients}</dt>
                         <dd>${noteVm.emailRecipients || ''}</dd>`;
            }

            if (noteVm.emailBody) {
                tmpl += `<dt>${Translations.administration.citizenHistory.form.emailBody}</dt>
                         <dd>${this._replaceBreaklines(noteVm.emailBody || '')}</dd>`;
            }

            const fullTmpl = beginTpl + tmpl + endTpl;

            this.dialogService.showMessage(fullTmpl, Translations.administration.citizenHistory.noteDetails.header);
        });
    }

    parseTitle(title: string): string {
        // to make two lines on the title if it contains some breaks to do
        // add the parseHTML flag is active
        if (this.parseHTML) {
            return this._replaceBreaklines(title);
        }

        return title;
    }

    private _replaceBreaklines(str: string): string {
        // replace all the break lines for html breakline
        str = str.replace(/[\n\r]/g, '<br/>');

        // if the first char was a break should be removed;
        if (str.indexOf('<br/>') === 0) {
            str = str.replace(/<br\s*\/?>/, '');
        }

        return str;
    }

    onClickUpdateDetails(noteId: string) {
        this.citizenService.getCitizenEditRecordById(noteId, this.customerNo).pipe(
            map(records => {

                const dataSource = new MatTableDataSource<ICitizenTableRow>();

                if (!records.editRecord && !records.oldRecord) {
                    return dataSource;
                }

                const newRecord = records.editRecord;
                const oldRecord = records.oldRecord;

                this.editMode = oldRecord ? true : false;

                const table: ICitizenTableRow[] = [];

                // Municipality
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.customerNo, newRecord!.customerNo, oldRecord ? oldRecord.customerNo : ''));
                table.push(this.getTableRow(Translations.forms.labels.municipality.muncipalityId, newRecord!.municipalityName, oldRecord ? oldRecord.municipalityName : ''));
                table.push(this.getTableRow(Translations.forms.labels.municipality.districtId, newRecord!.districtName, oldRecord ? oldRecord.districtName : ''));
                table.push(this.getTableRow(Translations.forms.labels.municipality.subdistrictId, newRecord!.subDistrictName, oldRecord ? oldRecord.subDistrictName : ''));

                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.info.customerSubType.label,
                    Translations.municipality.subscribeCitizen.customerSubTypeEnum[newRecord!.customerSubType],
                    oldRecord ? Translations.municipality.subscribeCitizen.customerSubTypeEnum[oldRecord.customerSubType] : ''));

                // Address
                const newAddress = newRecord!.citizenAddress;
                const oldAddress = oldRecord ? oldRecord.citizenAddress : '';
                table.push(this.getTableRow(Translations.forms.labels.municipality.name,
                    newAddress.firstName + ' ' + (newAddress.middleName || '') + ' ' + newAddress.lastName,
                    oldAddress ? oldAddress.firstName + ' ' + (oldAddress.middleName || '') + ' ' + oldAddress.lastName : ''
                ));
                table.push(this.getTableRow(Translations.administration.citizen.address,
                    this.addressPipe.transform(newAddress),
                    oldAddress ? this.addressPipe.transform(oldAddress) : ''
                ));
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.info.cpr.label, newRecord!.cPR, oldRecord ? oldRecord.cPR : ''));
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.info.gender.label,
                    Translations.municipality.subscribeCitizen.genderEnum[newRecord!.gender],
                    oldRecord ? Translations.municipality.subscribeCitizen.genderEnum[oldRecord.gender] : ''));

                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.info.phoneNumber.label, newAddress.phoneNumber, oldAddress ? oldAddress.phoneNumber : ''));
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.info.mobileNumber.label, newAddress.mobileNumber, oldAddress ? oldAddress.mobileNumber : ''));
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.info.email.label, newRecord!.email, oldRecord ? oldRecord.email : ''));

                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.info.invoiceEmailList.label,
                    newRecord ? newRecord.invoiceEmailList : '',
                    oldRecord ? oldRecord.invoiceEmailList : ''
                ));
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.info.orderConfirmationEmailList.label,
                    newRecord ? newRecord.orderConfirmationEmailList : '',
                    oldRecord ? oldRecord.orderConfirmationEmailList : ''
                ));

                table.push(this.getTableRow(Translations.administration.citizen.replacementProducts,
                    newRecord && newRecord.replacementProduct !== undefined && !newRecord!.replacementProduct ? Translations.administration.citizen.replacementProductsValue : '',
                    oldRecord && oldRecord.replacementProduct !== undefined && !oldRecord.replacementProduct ? Translations.administration.citizen.replacementProductsValue : ''
                ));

                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.info.specialInformationIds.label,
                    newRecord && newRecord.specialInformationIds ? newRecord.specialInformationIds.join(', ') : '',
                    oldRecord && oldRecord.specialInformationIds ? oldRecord.specialInformationIds.join(', ') : ''
                ));

                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.info.driverId.label, newRecord!.driverName, oldRecord ? oldRecord.driverName : ''));
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.info.driverInformationIds.label,
                    newRecord && newRecord.driverInformationIds ? newRecord.driverInformationIds.join(', ') : '',
                    oldRecord && oldRecord.driverInformationIds ? oldRecord.driverInformationIds.join(', ') : '',
                ));

                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.info.keyForCustomer.label, newRecord!.keyForCustomer, oldRecord ? oldRecord.keyForCustomer : ''));
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.info.signUpDate.label,
                    newRecord && newRecord.signUpDate ? this.date.transform(newRecord.signUpDate, 'shortDate') || '' : '',
                    oldRecord && oldRecord.signUpDate ? this.date.transform(oldRecord.signUpDate, 'shortDate') || '' : ''
                ));
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.info.dateForReceivingSignUp.label,
                    newRecord && newRecord.dateForReceivingSignUp ? this.date.transform(newRecord!.dateForReceivingSignUp, 'shortDate') || '' : '',
                    oldRecord && oldRecord.dateForReceivingSignUp ? this.date.transform(oldRecord.dateForReceivingSignUp, 'shortDate') || '' : ''
                ));
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.info.deliveryStart.label,
                    newRecord && newRecord.deliveryStart ? this.date.transform(newRecord!.deliveryStart, 'shortDate') || '' : '',
                    oldRecord && oldRecord.deliveryStart ? this.date.transform(oldRecord.deliveryStart, 'shortDate') || '' : ''
                ));
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.info.visitationId.label,
                    newRecord ? Translations.municipality.subscribeCitizen.visitationEnum[newRecord.visitationId] : '',
                    oldRecord ? Translations.municipality.subscribeCitizen.visitationEnum[oldRecord.visitationId] : ''
                ));
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.info.deliveryEnd.label,
                    newRecord && newRecord.deliveryEnd ? this.date.transform(newRecord.deliveryEnd, 'shortDate') || '' : '',
                    oldRecord && oldRecord.deliveryEnd ? this.date.transform(oldRecord.deliveryEnd, 'shortDate') || '' : ''
                ));

                // Delivery
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.info.deliveryFrequencyId.label,
                    newRecord && newRecord.deliveryFrequencyId ? Translations.municipality.subscribeCitizen.deliveryFrequencyEnum[newRecord.deliveryFrequencyId] : '',
                    oldRecord && oldRecord.deliveryFrequencyId ? Translations.municipality.subscribeCitizen.deliveryFrequencyEnum[oldRecord.deliveryFrequencyId] : ''
                ));
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.info.deliveryDay.label,
                    this.getDays(newRecord!.deliveryDayIds, false),
                    oldRecord ? this.getDays(oldRecord.deliveryDayIds, false) : ''
                ));

                // Order days
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.info.orderDay.label,
                    this.getDays(newRecord!.orderDayIds),
                    oldRecord ? this.getDays(oldRecord.orderDayIds) : ''
                ));

                // Relatives
                const newRelative = newRecord!.citizenRelative;
                const oldRelative = oldRecord ? oldRecord.citizenRelative : undefined;
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.relatives.primaryUser.label,
                    newRecord ? Translations.municipality.subscribeCitizen.primaryUserEnum[newRecord.primaryUser] : '',
                    oldRecord ? Translations.municipality.subscribeCitizen.primaryUserEnum[oldRecord.primaryUser] : ''));
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.relatives.relationID.label,
                    newRelative ? Translations.municipality.subscribeCitizen.citizenRelationEnum[newRelative.relationID] : '',
                    oldRelative && oldRelative ? Translations.municipality.subscribeCitizen.citizenRelationEnum[oldRelative.relationID] : ''
                ));
                table.push(this.getTableRow(Translations.forms.labels.municipality.name,
                    newRelative ? (newRelative.firstName || '') + ' ' + (newRelative.middleName || '') + ' ' + (newRelative.lastName || '') : '',
                    oldRelative && oldRelative ? (oldRelative.firstName || '') + ' ' + (oldRelative.middleName || '') + ' ' + (oldRelative.lastName || '') : ''
                ));
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.relatives.name2.label, newRelative ? newRelative.name2 : '', oldRelative ? oldRelative.name2 : ''));
                table.push(this.getTableRow(Translations.administration.citizen.address,
                    this.addressPipe.transform(newRelative!),
                    oldRelative ? this.addressPipe.transform(oldRelative!) : ''
                ));
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.relatives.phoneNumber.label, newRelative ? newRelative.phoneNumber : '', oldRelative ? oldRelative.phoneNumber : ''));
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.relatives.mobileNumber.label, newRelative ? newRelative.mobileNumber : '', oldRelative ? oldRelative.mobileNumber : ''));
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.relatives.faxNumber.label, newRelative ? newRelative.faxNumber : '', oldRelative ? oldRelative.faxNumber : ''));
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.relatives.email.label, newRelative ? newRelative.email : '', oldRelative ? oldRelative.email : ''));
                table.push(this.getTableRow(Translations.municipality.subscribeCitizen.relatives.comment.label, newRelative ? newRelative.comment : '', oldRelative ? oldRelative.comment : ''));

                // Payment
                table.push(this.getTableRow(Translations.municipality.citizenPayment.paymentMethodCode.label,
                    newRecord && newRecord.citizenPayment ? Translations.global.paymentTypes[newRecord.citizenPayment.paymentMethodCode] : '',
                    oldRecord && oldRecord.citizenPayment ? Translations.global.paymentTypes[oldRecord.citizenPayment.paymentMethodCode] : ''
                ));
                if (newRecord && newRecord.citizenPayment!.dIBSTicketId !== undefined || oldRecord && oldRecord!.citizenPayment!.dIBSTicketId !== undefined) {
                    table.push(this.getTableRow(Translations.municipality.citizenPayment.dibsTicketId.label,
                        newRecord!.citizenPayment!.dIBSTicketId,
                        oldRecord ? oldRecord.citizenPayment!.dIBSTicketId : ''
                    ));
                }

                // Driver messages
                // Temporary messages
                const newTempMessages = newRecord && newRecord.driverMessages && newRecord.driverMessages.filter(x => x.isTemporary).length ? newRecord.driverMessages.filter(x => x.isTemporary).map(x => x.content).join('<br/>') : '';
                const oldTempMessages = oldRecord && oldRecord.driverMessages && oldRecord.driverMessages.filter(x => x.isTemporary).length ? oldRecord.driverMessages.filter(x => x.isTemporary).map(x => x.content).join('<br/>') : '';
                table.push(this.getTableRow(Translations.municipality.driverMessages.placeholderTemp, newTempMessages, oldTempMessages));

                // Permanent messages
                const newPermMessages = newRecord && newRecord.driverMessages && newRecord.driverMessages.filter(x => !x.isTemporary).length ? newRecord.driverMessages.filter(x => !x.isTemporary).map(x => x.content).join(', ') : '';
                const oldPermMessages = oldRecord && oldRecord.driverMessages && oldRecord.driverMessages.filter(x => !x.isTemporary).length ? oldRecord.driverMessages.filter(x => !x.isTemporary).map(x => x.content).join(', ') : '';
                table.push(this.getTableRow(Translations.municipality.driverMessages.placeholderPerm, newPermMessages, oldPermMessages));

                dataSource.data = table;
                return dataSource;
            }),
            switchMap(dataSource => {
                const dialogRef = this.dialogService.openDialogWithComponent(MunicipalityCitizenTableComponent, {
                    maxWidth: '90vh',
                    minWidth: '800px',
                    closeOnNavigation: true,
                    panelClass: 'auto-scroll'
                });
                dialogRef.componentInstance['editMode'] = this.editMode;
                dialogRef.componentInstance['dataSource'] = dataSource;
                return dialogRef.afterClosed();
            }),
            takeUntil(this.unsubscribe)
        ).subscribe();
    }

    private getTableRow(field: string, newValue?: string, oldValue?: string): ICitizenTableRow {
        newValue = newValue || '';
        oldValue = oldValue || '';
        if (newValue !== oldValue) {
            field = '<strong>' + field + ' *</strong>';
        }
        return { field, new: newValue, old: oldValue };
    }

    private getDays(days?: IDayAndTime[], timeOfDay = true): string {
        return days && days.length ? days.map(x => Translations.global.weekDayNos[x.dayOfWeek] + (timeOfDay && x.timeOfDay ? (', kl. ' + x.timeOfDay + '-' + ++x.timeOfDay) : '')).join(' og ') : '';
    }
}
