import { NgModule } from '@angular/core';

import { CommerceModule } from '../commerce/commerce.module';
import { SharedModule } from '../shared/shared.module';
import { CitizenCreditNoteDialogComponent } from './citizen-credit-note-dialog.component';
import { CitizenDetailsCreditnotesComponent } from './citizen-details-creditnotes.component';
import { CitizenDetailsDebtorpostsComponent } from './citizen-details-debtorposts.component';
import { CitizenDetailsInfoComponent } from './citizen-details-info.component';
import { CitizenDetailsNotesComponent } from './citizen-details-notes.component';
import { CitizenDetailsComponent } from './citizen-details.component';
import { CitizenHistoryListComponent } from './citizen-history-list.component';
import { CitizenInactivateDialogComponent } from './citizen-inactivate-dialog.component';
import { CitizenNoteFormComponent } from './citizen-note-form.component';
import { CitizenNotesFormComponent } from './citizen-notes-form.component';
import { CitizenPriceAdjustmentDialogComponent } from './citizen-price-adjustment-dialog.component';
import { CitizenSpecialinformationComponent } from './citizen-specialinformation.component';
import { CitizenSummaryComponent } from './citizen-summary.component';
import { CitizenUnsubscribeDialogComponent } from './citizen-unsubscribe-dialog.component';
import { CitizenColorDirective } from './directives/citizen-color.directive';
import { CitizenTooltipDirective } from './directives/citizen-tooltip.directive';
import { CitizenRoutingModule } from './routing/citizen-routing.module';

@NgModule({
    imports: [SharedModule, CommerceModule, CitizenRoutingModule],
    declarations: [
        CitizenCreditNoteDialogComponent,
        CitizenDetailsCreditnotesComponent,
        CitizenDetailsDebtorpostsComponent,
        CitizenDetailsInfoComponent,
        CitizenDetailsNotesComponent,
        CitizenDetailsComponent,
        CitizenHistoryListComponent,
        CitizenInactivateDialogComponent,
        CitizenSpecialinformationComponent,
        CitizenSummaryComponent,
        CitizenNoteFormComponent,
        CitizenUnsubscribeDialogComponent,
        CitizenTooltipDirective,
        CitizenColorDirective,
        CitizenNotesFormComponent,
        CitizenPriceAdjustmentDialogComponent
    ],
    exports: [
        CitizenDetailsInfoComponent,
        CitizenHistoryListComponent,
        CitizenSpecialinformationComponent,
        CitizenSummaryComponent,
        CitizenTooltipDirective,
        CitizenColorDirective
    ]
})
export class CitizenModule {}
