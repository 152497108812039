import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Translations } from '../../translations/translations';
import { CitizenService } from '../services/citizen.service';

@Component({
    selector: 'iv-citizen-specialinformation',
    template: `
        <ul *ngIf="show; else buttonSpecialInformation" class="citizen-specialinformation">
            <li *ngFor="let info of specialInformation | async" class="citizen-specialinformation__item">{{ info }}</li>
        </ul>
        <ng-template #buttonSpecialInformation>
            <button mat-raised-button color="accent" type="button" (click)="show = true">${Translations.administration.citizen.showSpecialInformation}</button>
        </ng-template>
    `
})
export class CitizenSpecialinformationComponent implements OnInit {

    @Input() citizenNo: string;

    @Input() show = false;

    specialInformation: Observable<string[]>;

    constructor(
        public citizenService: CitizenService
    ) { }

    ngOnInit() {
        this.specialInformation = this.citizenService.getCitizenSpecialInformation(this.citizenNo);
    }
}
