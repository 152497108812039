import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { Translations } from '../../translations/translations';
import { MunicipalityService } from '../municipality/municipality.service';
import { CitizenService } from '../services/citizen.service';

@Component({
    selector: 'iv-generate-list',
    template: `
        <form (submit)="onSubmit()" >
            <h1 mat-dialog-title>${Translations.callListAdmin.generateLists.header}</h1>
            <mat-dialog-content class="low-density-dialog-content">
                <mat-form-field>
                    <mat-select placeholder="${Translations.callListAdmin.generateLists.type}" [(ngModel)]="options.type" name="type">
                        <mat-option [value]="i + 1" *ngFor="let type of citizenTypes; index as i">{{type}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <br/>
                <ng-container *ngIf="options.type === 1">
                    <mat-label>${Translations.callListAdmin.callCitizenType}</mat-label>
                    <mat-radio-group  [(ngModel)]="options.callRecordsTime" name="callCitizenType">
                        <mat-radio-button class="form__radio-button" color="primary" [value]="i" *ngFor="let type of callCitizenTypes; index as i">{{type}}</mat-radio-button>
                    </mat-radio-group>
                    <br/>
                </ng-container>
                <mat-form-field>
                    <mat-select placeholder="${Translations.callListAdmin.generateLists.municipality}" [(ngModel)]="options.municipality" multiple name="municipality">
                        <mat-option [value]="0">${Translations.callListAdmin.generateLists.allMunicipality}</mat-option>
                        <mat-option [value]="municipality.id" *ngFor="let municipality of municipality$ | async">{{ municipality.municipalityName }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <br/>
                <mat-form-field>
                    <input matInput [matDatepicker]="picker" placeholder="${Translations.callListAdmin.generateLists.date}" [(ngModel)]="options.date" name="date" required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-hint class="hint-warning" *ngIf="options.type === 4">${Translations.callListAdmin.generateLists.dateMessage}</mat-hint>
                </mat-form-field>
            </mat-dialog-content>
            <mat-dialog-actions class="alt-theme">
                <button mat-raised-button color="primary">${Translations.callListAdmin.generateLists.okBtn}</button>
                <button mat-raised-button type="button" mat-dialog-close>${Translations.callListAdmin.generateLists.cancelBtn}</button>
            </mat-dialog-actions>
        </form>
    `
})
export class GenerateListComponent {
    citizenTypes = Translations.callListAdmin.citizenTypes;
    callCitizenTypes = Translations.callListAdmin.callCitizenTypes;
    municipality$ = this.municipalityService.getMunicipalities();

    constructor(
        private dialogRef: MatDialogRef<GenerateListComponent>,
        private municipalityService: MunicipalityService,
        private citizenService: CitizenService
    ) { }

    options = {
        type: 1,
        municipality: [0],
        date: new Date(),
        callRecordsTime: 0
    };

    onSubmit() {
        let url;
        if (this.options.type === 1 && this.options.callRecordsTime === 0) {
            url = this.citizenService.getUrlExportRingborgersCitizensWithoutOrders(this.options.municipality.map(x => x.toString()), this.options.date);
        }
        else {
            url = this.citizenService.getUrlExportCitizensWithoutOrders(this.options.municipality.map(x => x.toString()), [this.options.type.toString()], this.options.date, this.options.type === 1 ? this.options.callRecordsTime : undefined);
        }
        const aTag = document.createElement('a');
        aTag.href = url;
        aTag.download = Translations.callListAdmin.generateLists.fileName;
        aTag.click();
        this.dialogRef.close();
    }
}
