import { Component, Input } from '@angular/core';

import { IProductViewModel } from '../../api/services';
import { Translations } from '../../translations/translations';

@Component({
    selector: 'iv-commerce-product-declarations',
    template: `
        <div *ngIf="product && product.declarations">
            <section class="product-details__declarations-table" *ngIf="product.declarations.showDeclarations || product.declarationLabel">
                <hr class="product-details__spacer">
            	<h3 class="product-details__declarations-head">
            		${Translations.commerce.productDetails.declarations.declarationHead}
            	</h3>
                <div class="product-details__declaration-label" *ngIf="product.declarationLabel">
                    ${Translations.commerce.productDetails.declarations.ingredientList}: <span [innerHTML]="product.declarationLabel"></span>
                </div>
                <div *ngIf="product.declarations.showDeclarations">
                    <hr class="product-details__spacer">
                    <table class="table">
                        <tr class="table__row">
                            <th class="table__head">${Translations.commerce.productDetails.declarations.nutrition}:</th>
                        </tr>
                        <tr class="table__row">
                            <td class="table__col">${Translations.commerce.productDetails.declarations.nutritionEnergy}</td>
                            <td class="table__col">
                                {{ product.declarations.energyKj }}
                                ${Translations.commerce.productDetails.declarations.nutritionKiloJoule}
                                /
                                {{ product.declarations.energyKcal }}
                                ${Translations.commerce.productDetails.declarations.nutritionKiloCalories}
                            </td>
                        </tr>
                        <tr class="table__row">
                            <td class="table__col">${Translations.commerce.productDetails.declarations.nutritionFat}</td>
                            <td class="table__col">{{ product.declarations.nutritionalContentFat }} ${Translations.commerce.productDetails.declarations.nutritionGram}</td>
                        </tr>
                        <tr class="table__row">
                            <td class="table__col">${Translations.commerce.productDetails.declarations.nutritionSaturatedFattyAcids}</td>
                            <td class="table__col">{{ product.declarations.saturatedFattyAcid }} ${Translations.commerce.productDetails.declarations.nutritionGram}</td>
                        </tr>
                        <tr class="table__row">
                            <td class="table__col">${Translations.commerce.productDetails.declarations.nutritionCarbohydrate}</td>
                            <td class="table__col">{{ product.declarations.nutritionalContentCarbohydrate }} ${Translations.commerce.productDetails.declarations.nutritionGram}</td>
                        </tr>
                        <tr class="table__row">
                            <td class="table__col">${Translations.commerce.productDetails.declarations.nutritionSugars}</td>
                            <td class="table__col">{{ product.declarations.sugar }} ${Translations.commerce.productDetails.declarations.nutritionGram}</td>
                        </tr>
                        <tr class="table__row">
                            <td class="table__col">${Translations.commerce.productDetails.declarations.nutritionDietaryFibers}</td>
                            <td class="table__col">{{ product.declarations.dietaryFiber }} ${Translations.commerce.productDetails.declarations.nutritionGram}</td>
                        </tr>
                        <tr class="table__row">
                            <td class="table__col">${Translations.commerce.productDetails.declarations.nutritionProtein}</td>
                            <td class="table__col">{{ product.declarations.nutritionalContentProtein }} ${Translations.commerce.productDetails.declarations.nutritionGram}</td>
                        </tr>
                        <tr class="table__row">
                            <td class="table__col">${Translations.commerce.productDetails.declarations.nutritionSalt}</td>
                            <td class="table__col">{{ product.declarations.salt }} ${Translations.commerce.productDetails.declarations.nutritionGram}</td>
                        </tr>
                    </table>
                </div>
            </section>

            <section class="product-details__technical-description" *ngIf="product.technicalDescription">
                <span [innerHTML]="product.technicalDescription"></span>
            </section>

            <section class="product-details__vknumber text-center_mobile" *ngIf="product.vkNumber">
                <img src="/assets/logo-vk.png" title="${Translations.commerce.productDetails.declarations.vkNumber}" alt="${Translations.commerce.productDetails.declarations.vkNumber}" class="vk-logo">
                <br><br>
                <span>VK {{ product.vkNumber }}</span>
            </section>
        </div>
    `,
})
export class CommerceProductDeclarationsComponent {

    @Input() product: IProductViewModel;

    constructor() { }
}
