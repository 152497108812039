import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { search } from '../../scripts/generated/icons';
import { ElementRenderer } from '../shared/element-renderer.service';

@Component({
    selector: 'iv-citizen-search-field',
    template: `
        <div class="citizen-search-field">
            <input class="citizen-search-field__input" #input type="text" [placeholder]="placeholder" [(ngModel)]="text" (input)="onInput()" (keydown.enter)="enterPressed()">
            <div class="citizen-search-field__icon" aria-hidden="true">${search}</div>
        </div>
    `
})
export class CitizenSearchFieldComponent implements OnInit {
    @Input() placeholder: string;
    @Output() searched = new EventEmitter();
    @Output() entered = new EventEmitter();
    @Input() text: string;

    @ViewChild('input', { read: ElementRef, static: true }) input: ElementRef<HTMLInputElement>;

    private searchQueries = new Subject<string>();
    private unsubscribe = new Subject<void>();

    constructor(private renderer: ElementRenderer) { }

    ngOnInit() {
        this.searchQueries.pipe(
            takeUntil(this.unsubscribe),
            debounceTime(200),
            distinctUntilChanged()
        ).subscribe(text => this.searched.emit(text));

        setTimeout(() => {
            this.renderer.invokeElementMethod(this.input, 'focus');
        }, 0);

    }

    onInput() {
        this.searchQueries.next(this.text);
    }

    enterPressed() {
        this.entered.emit(true);
    }
}
