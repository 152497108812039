import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { Translations } from '../../translations/translations';
import {
    AdministrationDeliveryService,
    IChangeDeliveryDay,
    IUpsertAlternativeDeliveryDay,
} from '../services/administration-delivery.service';
import { DialogService } from '../shared/dialog.service';
import { tableDetailExpand } from '../shared/dynamic-table.animations';
import { AdministrationEditDeliveryDateComponent } from './administration-edit-delivery-date.component';

@Component({
    selector: 'iv-administration-delivery-date',
    template: `
        <section class="administration-delivery-date">
            <div class="administration-delivery-date__wrapper">

                <form class="administration-delivery-date__form" #f="ngForm" (ngSubmit)="addDate(f)" novalidate>
                    <div class="administration-delivery-date__column">
                        <div class="administration-delivery-date__from">
                            <mat-form-field>
                                <mat-label>${Translations.municipality.deliveryDateChange.fromDate}</mat-label>
                                <input required matInput [matDatepicker]="fromDate"
                                    [min]="minDate"
                                    name="from" ngModel #from="ngModel">
                                <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                                <mat-datepicker #fromDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="administration-delivery-date__to">
                            <mat-form-field>
                                <mat-label>${Translations.municipality.deliveryDateChange.toDate}</mat-label>
                                <input required matInput [matDatepicker]="toDate"
                                    [min]="minDate" name="to" ngModel #to="ngModel">
                                <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                                <mat-datepicker #toDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="administration-delivery-date__column">
                        <div class="administration-delivery-date__original-order-date">
                            <mat-form-field>
                                <mat-label>${Translations.municipality.deliveryDateChange.originalOrderDate}</mat-label>
                                <input required matInput [matDatepicker]="originalOrderDate"
                                    [min]="minOrderDate"
                                    name="originalOrderDate" ngModel #originalOrder="ngModel">
                                <mat-datepicker-toggle matSuffix [for]="originalOrderDate"></mat-datepicker-toggle>
                                <mat-datepicker #originalOrderDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="administration-delivery-date__destination-order-date">
                            <mat-form-field>
                                <mat-label>${Translations.municipality.deliveryDateChange.destinationOrderDate}</mat-label>
                                <input required matInput [matDatepicker]="destinationOrderDate"
                                    [min]="minOrderDate"
                                    name="destinationOrderDate" ngModel #destinationOrder="ngModel">
                                <mat-datepicker-toggle matSuffix [for]="destinationOrderDate"></mat-datepicker-toggle>
                                <mat-datepicker #destinationOrderDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="administration-delivery-date__btn">
                        <button type="submit" mat-raised-button color="primary" [disabled]="!f.valid">
                            <span>${Translations.municipality.deliveryDateChange.addButton}</span>
                        </button>
                    </div>
                </form>

                <div class="administration-delivery-date__info-section">
                    <div class="administration-delivery-date__column administration-delivery-date__info">
                        <h4>${Translations.municipality.deliveryDateChange.deliveryInfo.title}</h4>
                        <p>${Translations.municipality.deliveryDateChange.deliveryInfo.description}</p>
                    </div>

                    <div class="administration-delivery-date__column administration-delivery-date__info">
                        <h4>${Translations.municipality.deliveryDateChange.followUpInfo.title}</h4>
                        <p>${Translations.municipality.deliveryDateChange.followUpInfo.description}</p>
                    </div>
                </div>
            </div>

            <div class="administration-delivery-date__data">
                <ng-container *ngIf="!isFetching; else fetching">
                    <ng-container *ngTemplateOutlet="deliveryListTpl; context: { data: deliveryList }" ></ng-container>
                </ng-container>
            </div>

            <div *ngIf="!isFetching;" class="administration-delivery-date__history">
                <button type="submit" mat-raised-button color="primary" (click)="showHistory = !showHistory">
                    <span *ngIf="!showHistory">${Translations.municipality.deliveryDateChange.showHistoryLogButton}</span>
                    <span *ngIf="showHistory">${Translations.municipality.deliveryDateChange.hideHistoryLogButton}</span>
                </button>

                <iv-administration-history-delivery-date *ngIf="showHistory"></iv-administration-history-delivery-date>
            </div>
        </section>

        <!-- GENERIC TEMPLATE -->
        <ng-template #fetching>
            <div class="center-content alt-theme">
                <mat-progress-spinner color="accent" mode="indeterminate" [strokeWidth]="3" [diameter]="60"></mat-progress-spinner>
            </div>
        </ng-template>

        <ng-template #emptyTpl>
            ${Translations.municipality.deliveryDateChange.emptyDeliveryDates}
        </ng-template>

        <ng-template #deliveryListTpl let-data="data">
            <table mat-table [dataSource]="data" style="width: 100%">
                <ng-container matColumnDef="From">
                    <th mat-header-cell *matHeaderCellDef>${Translations.municipality.deliveryDateChange.fromDate}</th>
                    <td mat-cell *matCellDef="let element"> {{formatDate(element.From)}} </td>
                </ng-container>
                <ng-container matColumnDef="To">
                    <th mat-header-cell class="align-right" *matHeaderCellDef>${Translations.municipality.deliveryDateChange.toDate}</th>
                    <td mat-cell class="align-right" *matCellDef="let element"> {{formatDate(element.To)}} </td>
                </ng-container>
                <ng-container matColumnDef="OriginalOrderDate">
                    <th mat-header-cell class="align-right" *matHeaderCellDef>${Translations.municipality.deliveryDateChange.originalOrderDate}</th>
                    <td mat-cell class="align-right" *matCellDef="let element"> {{formatDate(element.OriginalOrderDate)}} </td>
                </ng-container>
                <ng-container matColumnDef="DestinationOrderDate">
                    <th mat-header-cell class="align-right" *matHeaderCellDef>${Translations.municipality.deliveryDateChange.destinationOrderDate}</th>
                    <td mat-cell class="align-right" *matCellDef="let element"> {{formatDate(element.DestinationOrderDate)}} </td>
                </ng-container>
                <ng-container matColumnDef="Edit">
                    <th mat-header-cell class="align-center" *matHeaderCellDef>${Translations.municipality.deliveryDateChange.edit}</th>
                    <td mat-cell class="align-center" *matCellDef="let element">
                        <button mat-raised-button color="primary" (click)="editDate(element)">${Translations.municipality.deliveryDateChange.edit}</button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Delete">
                    <th mat-header-cell class="align-center" *matHeaderCellDef>${Translations.municipality.deliveryDateChange.delete}</th>
                    <td mat-cell class="align-center" *matCellDef="let element">
                        <button mat-raised-button color="accent" (click)="deleteDate(element.AlternativeDeliveryDateId, element.From, element.To)">${Translations.municipality.deliveryDateChange.delete}</button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="LastEdit">
                    <th mat-header-cell class="align-right" *matHeaderCellDef>${Translations.municipality.deliveryDateChange.lastEdited}</th>
                    <td mat-cell class="align-right" *matCellDef="let element"> {{formatDate(element.LastModifiedDate)}} </td>
                </ng-container>
                <ng-container matColumnDef="LastModifiedBy">
                    <th mat-header-cell class="align-right" *matHeaderCellDef>${Translations.municipality.deliveryDateChange.editedBy}</th>
                    <td mat-cell class="align-right" *matCellDef="let element"> {{element.LastModifiedBy}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </ng-template>
    `,
    animations: [tableDetailExpand]
})
export class AdministrationDeliveryDateComponent implements OnInit, OnDestroy {

    deliveryList: IChangeDeliveryDay[];
    displayedColumns: string[] = ['From', 'To', 'OriginalOrderDate', 'DestinationOrderDate', 'Edit', 'Delete', 'LastEdit', 'LastModifiedBy'];
    hasActiveDeliveryDates = false;
    isFetching = true;
    selectedGroupDate: string;
    selectedElement: IChangeDeliveryDay;
    showHistory = false;
    minDate = new Date();
    minOrderDate = new Date();

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private administrationDeliveryService: AdministrationDeliveryService,
        private dialogService: DialogService
    ) {

        this.minOrderDate.setDate(this.minDate.getDate() - 7);
    }

    ngOnInit(): void {
        this.getDeliveryList();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getDeliveryList() {
        this.administrationDeliveryService.getDeliveryList()
            .pipe(
                takeUntil(this.unsubscribe),
                map(list => list.sort(this.originalOrder))
            )
            .subscribe(list => {
                this.isFetching = false;
                this.hasActiveDeliveryDates = !!list.length;
                this.deliveryList = list;
            }, err => {
                this.isFetching = false;
                this.hasActiveDeliveryDates = false;
            });
    }

    addDate(f: NgForm) {
        const date: IUpsertAlternativeDeliveryDay = {
            alternativeDeliveryDateId: 0,
            from: f.value.from,
            to: f.value.to,
            originalOrderDate: f.value.originalOrderDate,
            destinationOrderDate: f.value.destinationOrderDate
        };
        this.administrationDeliveryService.addDelivery(date)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                f.resetForm();
                f.form.setErrors({});
                f.form.markAsUntouched();
                f.form.markAsPristine();
                f.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });
                this.getDeliveryList();
            });
    }

    changeDate(data: IUpsertAlternativeDeliveryDay) {
        const newDate: IUpsertAlternativeDeliveryDay = {
            alternativeDeliveryDateId: data.alternativeDeliveryDateId,
            from: new Date(data.from).toISOString(),
            to: new Date(data.to).toISOString(),
            originalOrderDate: new Date(data.originalOrderDate).toISOString(),
            destinationOrderDate: new Date(data.destinationOrderDate).toISOString()
        };

        this.administrationDeliveryService.addDelivery(newDate)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((updatedData) => {
                // update the selected element to avoid fetch again
                Object.assign(this.selectedElement, updatedData);
            });
    }

    deleteDate(id: string, from: string, to: string) {
        this.selectedGroupDate = from;
        this.administrationDeliveryService.deleteDelivery(id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.dialogService.showMessage(Translations.replaceTokens(Translations.municipality.deliveryDateChange.deleteMessage, ...[this.formatDate(from), this.formatDate(to)]));
                this.getDeliveryList();
            });
    }

    formatDate(date: string) {
        return new Date(date).toLocaleDateString(Translations.variables.dateFormat);
    }

    editDate(element: IChangeDeliveryDay) {
        this.selectedGroupDate = element.From.toString();
        this.selectedElement = element;
        const dialogRef = this.dialogService.openDialogWithComponent(AdministrationEditDeliveryDateComponent, {
            data: JSON.parse(JSON.stringify(element))
        });
        dialogRef.afterClosed()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(data => {
                if (data) {
                    this.changeDate(data);
                }
            });
    }

    // This is to avoid the keyvalue pipe make it's own order, this will force the original order
    // we put on the object
    originalOrder = (a: IChangeDeliveryDay, b: IChangeDeliveryDay): number => {
        const aDate = new Date(a.From);
        const bDate = new Date(b.From);
        if (aDate.getTime() < bDate.getTime()) { return -1; }
        if (aDate.getTime() > bDate.getTime()) { return 1; }
        return 0;
    }
}
